import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import style from './AffiliateTable.module.scss';
import BalanceFormat from '../../../../components/BalanceFormat/BalanceFormat';
import { useRootModel } from '../../../../models/RootStore';
import date from '../../../../util/date';

const AffiliateTable = () => {
  const { t } = useTranslation();
  const {
    settings: { language },
    user: {
      referentsData: { referents },
    },
  } = useRootModel();

  return (
    <div className={style.tableAffiliate}>
      <div className={cn(style.grid, style.header)}>
        <div>{t('email')}</div>
        <div>{t('created')}</div>
        <div>{t('total_deposited')}</div>
        <div>{t('last_deposit')}</div>
        <div className={style.end}>{t('commission')}</div>
      </div>
      <p className={style.line} />
      <div className={style.body}>
        {referents.length > 0 ? (
          referents.map(
            ({ userId, email, createdAt, totalDeposits, lastDepositAt, unpaidBonus }) => {
              return (
                <div className={cn(style.grid, style.row)} key={userId}>
                  <div>{email}</div>
                  <div className={style.centered}>{date.toLocaleString(createdAt, language)}</div>
                  <div className={style.centered}>
                    <BalanceFormat value={totalDeposits} />
                  </div>
                  <div className={style.centered}>
                    {date.toLocaleString(lastDepositAt, language)}
                  </div>
                  <div className={style.end}>
                    <BalanceFormat value={unpaidBonus} />
                  </div>
                </div>
              );
            },
          )
        ) : (
          <p className={style.noData}>{t('no_invite')}</p>
        )}
      </div>
    </div>
  );
};

export default observer(AffiliateTable);
