import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './FavoritesMobile.module.scss';
import { useRootModel } from '../../../models/RootStore';
import FavoritesMobileItem from './FavoritesAllMobileItem/FavoritesMobileItem';
import instrumentIcon from '../../../assets/instrumentIcon';
import Title from '../../../components/ui/Table/TableTitle/TableTitle';

/**
 * Represents Favorites page in Mobile version
 * @returns {JSX.Element}
 */

const FavoritesMobile = () => {
  const { t } = useTranslation();
  const {
    finance: {
      favoriteTrades: { items, getFavorites, closeDataStreamFavorites },
    },
  } = useRootModel();

  useEffect(() => {
    getFavorites();

    return () => {
      closeDataStreamFavorites();
    };
  }, []);

  return (
    <>
      <div className={style.header}>
        <Title title={t('favorite')} />
        <p className={style.headerTitle}>{t('daily')}</p>
      </div>
      {items?.map(({ s, alias, lp, cpd, type, oldPrice, favorite, toggleFavorite, isLoading }) => {
        const instType = instrumentIcon[type];
        const Icon = instType ? instrumentIcon[type][s] : null;
        return (
          <FavoritesMobileItem
            key={s}
            icon={Icon}
            alias={alias}
            symbol={s}
            price={lp}
            oldPrice={oldPrice}
            type={type}
            percent={cpd}
            isFavorite={favorite}
            toggleFavorite={toggleFavorite}
            isLoading={isLoading}
          />
        );
      })}
    </>
  );
};

export default observer(FavoritesMobile);
