import React from 'react';
import style from './LimitProfitsAndLost.module.scss';
import TakeProfitInput from '../TakeProfitInput/TakeProfitInput';
import StopLossInput from '../StopLossInput/StopLossInput';

/**
 * Represents LimitProfitsAndLost component
 * @returns {JSX.Element}
 */

const LimitProfitsAndLost = () => {
  return (
    <div className={style.section}>
      <TakeProfitInput />
      <StopLossInput />
    </div>
  );
};

export default LimitProfitsAndLost;
