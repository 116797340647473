import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import style from './ProfilePage.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/image/common/arrowRight.svg';

/**
 * Represents Profile page in Mobile version
 * @returns {JSX.Element}
 */

const ProfilePage = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      tabLabel: t('general'),
      url: '/profile/general',
    },
    {
      id: 2,
      tabLabel: t('personal_details'),
      url: '/profile/details',
    },
    {
      id: 3,
      tabLabel: t('sessions'),
      url: '/profile/sessions',
    },
    {
      id: 4,
      tabLabel: t('security'),
      url: '/profile/security',
    },
    {
      id: 5,
      tabLabel: t('notifications'),
      url: '/profile/notification',
    },
    // {
    //   id: 6,
    //   tabLabel: t('other_settings'),
    //   url: '/profile/settings',
    // },
    {
      id: 7,
      tabLabel: t('documents'),
      url: '/profile/documents',
    },
    // {
    //   id: 8,
    //   tabLabel: t('About us'),
    //   url: '/info/about',
    // },
    // {
    //   id: 9,
    //   tabLabel: t('Objectives & Vision'),
    //   url: '/info/objectives&vision',
    // },
  ];

  // const handleLogout = async () => {
  //   await logout();
  //   history.push('/login');
  // };

  return (
    <section className={style.section}>
      <h3 className={style.title}>{t('my_profile')}</h3>
      <nav className={style.navigation}>
        {tabs.map(({ id, url, tabLabel }) => (
          <Link to={url} className={style.item} key={id}>
            {tabLabel}
            <ArrowIcon />
          </Link>
        ))}
      </nav>
    </section>
  );
};

export default observer(ProfilePage);
