import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './HeaderButtons.module.scss';
import { useRootModel } from '../../../models/RootStore';
import { ReactComponent as BrightnessIcon } from '../../../assets/image/common/brightness.svg';
import Tooltip from '../../ui/Tooltip/Tooltip';

/**
 * Represents HeaderButtons
 * @returns {JSX.Element}
 */
const HeaderButtons = () => {
  const { t } = useTranslation();
  const {
    user: { isAuthorized },
    settings: { toggleTheme, isLightMode },
  } = useRootModel();

  return (
    <div className={style.options}>
      {!isAuthorized && (
        <Tooltip
          content={isLightMode ? t('dark') : t('light')}
          position='bottom'
          tooltipWrapperClassName={style.tooltip}
        >
          <button
            type='button'
            className={cn(style.optionsItem, style.optionsItemBrightness, {
              [style.lightThemeItemBrightness]: isLightMode,
            })}
            onClick={toggleTheme}
          >
            <BrightnessIcon />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default observer(HeaderButtons);
