import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './Commissions.module.scss';

/**
 * Represents Commissions tab in Close Trade content
 * @returns {JSX.Element}
 */

const Commissions = () => {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <p className={style.header}>{t('accruals')}, $</p>
      <div>
        <div className={style.item}>
          <p className={style.title}>{t('operations')}</p>
          <p>—</p>
        </div>
        <div className={style.item}>
          <p className={style.title}>{t('market')}</p>
          <p>00.23</p>
        </div>
        <div className={style.item}>
          <p className={style.title}>{t('total')}</p>
          <p>00.23</p>
        </div>
      </div>
    </div>
  );
};

export default Commissions;
