import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import style from './Error.module.scss';
import { ReactComponent as ErrorIcon } from '../../assets/image/common/errorIcon.svg';

/**
 * Represents Error component
 * @param text - error text
 * @param {CSSStyleRule} className - wrapper className
 * @returns {JSX.Element}
 */
const Error = ({ text, className }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(style.wrapper, className)}>
      <ErrorIcon />
      <p className={style.text}>
        {text || (
          <span>
            {t('wrong')}
            <br />
            {t('refresh')}
          </span>
        )}
      </p>
    </div>
  );
};

export default Error;
