import React, { useContext, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import style from './WithdrawMoney.module.scss';

import CurrencyInput from '../CurrencyInput/CurrencyInput';
import useWindowSize from '../../hooks/useWindowSize';
import { useRootModel } from '../../models/RootStore';
import AlertContext from '../../context/alert/alertContext';
import { ERROR_TEXT_REQUIRED } from '../../util/validationSchema';
import Button from '../ui/Button/Button';
import { balanceCurrency } from '../../constant/walletConstants';

/**
 * Represents Withdraw money component
 * @returns {JSX.Element}
 */

const WithdrawMoney = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const alert = useContext(AlertContext);
  const {
    payment: { withdraw, withdrawCurrency, setWithdrawCurrency, maxWithdrawBalance },
    modal: {
      withdraw: { close },
    },
  } = useRootModel();

  const formik = useFormik({
    initialValues: {
      withdrawAddress: '',
      withdrawAmount: '',
    },
    validationSchema: Yup.object({
      withdrawAddress: Yup.string().required(ERROR_TEXT_REQUIRED),
      withdrawAmount: Yup.number()
        .min(0.0002, 'Less then 0.0002')
        .max(maxWithdrawBalance, 'Insufficient funds')
        .required(ERROR_TEXT_REQUIRED),
    }),
    onSubmit: async ({ withdrawAddress, withdrawAmount }, { resetForm }) => {
      try {
        await withdraw(withdrawAddress, withdrawAmount);
        resetForm();
        alert.show(t('withdraw_request'), 'success');
      } catch (e) {
        alert.show(t(e.message));
      }
    },
  });

  const handleChange = (e) => {
    const { value } = e.target;

    if (/^\d+(\.?\d*)?$/.test(value) || value === '') {
      formik.handleChange(e);
    }
  };

  const setMaxAmount = () => {
    formik.setFieldValue('withdrawAmount', maxWithdrawBalance);
  };

  useEffect(() => {
    formik.setFieldValue('withdrawAmount', '');
  }, [withdrawCurrency]);

  return (
    <form className={style.withdrawForm} onSubmit={formik.handleSubmit}>
      <CurrencyInput currency={withdrawCurrency} onChange={setWithdrawCurrency} />

      <div className={style.addressBlock}>
        <div className={style.labelWrapper}>
          <label htmlFor='withdrawAddress' className={style.textLabel}>
            {balanceCurrency[withdrawCurrency]?.title} {t('address_small')}
          </label>
          {formik.touched.withdrawAddress && formik.errors.withdrawAddress && (
            <p className={style.error}>{formik.errors.withdrawAddress}</p>
          )}
        </div>
        <input
          id='withdrawAddress'
          name='withdrawAddress'
          type='text'
          value={formik.values.withdrawAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          inputMode='text'
          className={cn(style.addressInput, {
            [style.errorBorder]: formik.touched.withdrawAddress && formik.errors.withdrawAddress,
          })}
          autoComplete='off'
        />
      </div>

      <div className={style.amountWrapper}>
        <div className={style.amountWrapper__block}>
          <div className={style.labelWrapper}>
            <label className={style.textLabel} htmlFor='withdrawAmount'>
              {t('amount')}{' '}
              <span className={style.textLabel__secondary}>
                ({t('min')} 0.0002, {t('max')} {maxWithdrawBalance})
              </span>
            </label>
            {formik.touched.withdrawAmount && formik.errors.withdrawAmount && (
              <p className={style.error}>{formik.errors.withdrawAmount}</p>
            )}
          </div>
          <input
            id='withdrawAmount'
            name='withdrawAmount'
            type='text'
            value={formik.values.withdrawAmount}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            className={cn(style.amountInput, {
              [style.errorBorder]: formik.touched.withdrawAmount && formik.errors.withdrawAmount,
            })}
            autoComplete='off'
          />
        </div>
        {!isMobile && (
          <Button text={t('max_upper')} onClick={setMaxAmount} className={style.maxButton} />
        )}
      </div>

      <p className={style.commission}>5% {t('commission')}</p>

      <div className={style.buttonWrapper}>
        {isMobile ? (
          <Link to='/balance' className={style.cancel}>
            {t('cancel')}
          </Link>
        ) : (
          <Button
            text={t('cancel')}
            color='secondary'
            className={style.requestButton}
            onClick={close}
          />
        )}

        <Button
          text={t('request')}
          color='primary'
          className={style.requestButton}
          disabled={!(formik.isValid && formik.dirty)}
          submit
        />
      </div>
    </form>
  );
};

export default observer(WithdrawMoney);
