import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from './SearchTradesMobile.module.scss';
import { useRootModel } from '../../../models/RootStore';
import { ReactComponent as SearchIcon } from '../../../assets/image/common/search.svg';

/**
 * Represents Search component in Trades on Mobile version
 * @returns {JSX.Element}
 */

const SearchTradesMobile = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const {
    finance: {
      activeTrades: { setName },
      pendingTrades: { setName: setNamePending },
      closedTrades: { getClosedTrades, setName: setNameClosed },
    },
  } = useRootModel();

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    setName(searchValue);
    setNamePending(searchValue);
    setNameClosed(searchValue);
    getClosedTrades();
  }, [searchValue]);

  return (
    <div className={style.searchBlock}>
      <SearchIcon className={style.image} />
      <input
        className={style.input}
        type='text'
        placeholder={t('search_mob')}
        value={searchValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default observer(SearchTradesMobile);
