import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import style from './DropdownWithInput.module.scss';
import string from '../../../util/string';
import useOnClick from '../../../hooks/useOnClick';
import getAnimationClasses from '../../../util/getAnimationClasses';
import useChangeCursor from '../../../hooks/useChangeCursor';
import useWindowSize from '../../../hooks/useWindowSize';

import { ReactComponent as ArrowUp } from '../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/common/arrowDown.svg';

/**
 * Represents a dropdown for codes and countries
 * @param {function} onChange - onChange handler
 * @param {boolean} withError - if need to show error under dropdown set true
 * @param {string} error - error to show
 * @param {function} setIsError - set isError
 * @param {CSSStyleRule} wrapperClassName - wrapper className
 * @param {CSSStyleRule} inputClassName - input className
 * @param {string} value - current value
 * @param {boolean} filter - if need to filter with input set true
 * @param {InputMode} inputMode - input mode
 * @param {Array<string>} children - children
 * @returns {JSX.Element}
 */
const DropdownWithInput = ({
  onChange,
  withError = false,
  error,
  setIsError,
  wrapperClassName,
  inputClassName,
  value,
  filter = false,
  inputMode = 'text',
  children,
}) => {
  const { isMobile } = useWindowSize();
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const refs = useRef({});

  const defaultValue = value?.toString() || children[0]?.toString();
  const [showContent, setShowContent] = useState(false);
  const [currentItem, setCurrentItem] = useState(defaultValue || '');
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [inputChanged, setInputChanged] = useState(false);

  const setCurrentValue = (val) => {
    setCurrentItem(String(val));
    setInputValue(String(val));
  };
  const toggleShowContent = () => setShowContent((prev) => !prev);
  const closeMenu = () => setShowContent(false);
  const focusFirstElement = () => {
    const elem = refs.current[0];
    if (elem) {
      elem.focus();
      setCurrentValue(elem.textContent);
    }
  };
  const isValueMatch = (val) =>
    children.some((child) => String(child).toLowerCase().indexOf(val.toLowerCase()) !== -1);

  useOnClick(wrapperRef, closeMenu);

  const filteredItems =
    filter && inputChanged
      ? children?.filter((item) => item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
      : children;

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (setIsError) setIsError(!currentItem);
    if (onChange) onChange(currentItem);
  }, [currentItem]);

  useEffect(() => {
    if (!filteredItems.length) closeMenu();

    if (filteredItems.length === 1) {
      setCurrentItem(filteredItems[0]);
      closeMenu();
      if (filter) setInputValue(filteredItems[0]);
    }
  }, [filteredItems]);

  const handleWrapperKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        refs.current[0]?.focus();
        e.preventDefault();
        break;
      case 'Enter':
        toggleShowContent();
        if (!showContent) inputRef.current.focus();
        break;
      case 'Escape':
        closeMenu();
        break;
      default:
    }
  };

  const handleInputClick = (e) => {
    setShowContent(true);
    e.stopPropagation();
  };

  const handleInputKeyDown = (e) => {
    switch (e.key) {
      case 'Backspace': {
        if (filter && currentItem) {
          setInputValue('');
          setInputChanged(false);
          setShowContent(true);
        }
        break;
      }
      case 'Enter':
        e.preventDefault();
        break;
      case 'ArrowDown':
        if (showContent) {
          e.preventDefault();
          focusFirstElement();
        }
        break;
      case 'Escape':
      case 'Tab':
        closeMenu();
        break;

      default:
    }
  };

  const handleInputOnChange = (e) => {
    if (isValueMatch(e.target.value)) {
      setInputValue(e.target.value);
      setInputChanged(true);
    }
  };

  const handleInputBlur = (e) => {
    const val = e.target.value.trim();
    if (!val) {
      setInputValue(currentItem);
      return;
    }
    const validValue = children?.find((child) => child?.toLowerCase() === val?.toLowerCase())
      ? val
      : value;
    setCurrentValue(string.toTitleString(validValue));
    setInputChanged(false);
  };

  const handleItemChange = (e, val) => {
    e.stopPropagation();
    if (onChange) onChange(val);
    setCurrentItem(val);
    setInputValue(val);
    closeMenu();
    inputRef.current.focus();
  };

  const handleInputFocus = () => {
    if (isMobile) wrapperRef.current.scrollIntoView();
  };

  return (
    <div
      role='button'
      className={cn(style.dropdown, { [style.withError]: withError }, wrapperClassName)}
      tabIndex={-1}
      onClick={toggleShowContent}
      onKeyDown={handleWrapperKeyDown}
      ref={wrapperRef}
    >
      <p className={style.value}>
        <input
          type='text'
          value={inputValue}
          className={cn(style.input, inputClassName)}
          onChange={handleInputOnChange}
          onClick={handleInputClick}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          onFocus={handleInputFocus}
          ref={inputRef}
          inputMode={inputMode}
        />

        <button type='button' tabIndex={-1} className={cn(style.arrow)}>
          {showContent ? <ArrowUp /> : <ArrowDown />}
        </button>
      </p>
      <CSSTransition
        timeout={700}
        in={showContent}
        classNames={getAnimationClasses(style)}
        unmountOnExit
      >
        <div
          role='menu'
          className={cn(style.dropdownContent, { [style.showContent]: showContent })}
        >
          {filteredItems.map((item, idx) => {
            return (
              <button
                key={item}
                type='button'
                ref={(elem) => {
                  refs.current[idx] = elem;
                }}
                tabIndex={-1}
                className={cn(style.item)}
                /* eslint-disable-next-line react-hooks/rules-of-hooks */
                onKeyDown={(e) => useChangeCursor(e, idx, refs, closeMenu, setCurrentValue)}
                onClick={(e) => handleItemChange(e, item)}
              >
                {item}
              </button>
            );
          })}
        </div>
      </CSSTransition>
      {withError && error && <p className={style.error}>{error}</p>}
    </div>
  );
};

export default DropdownWithInput;
