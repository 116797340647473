import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import style from './RightSideBlockNotAuthorized.module.scss';
import OpenTradeNotAuthorized from '../OpenTradeNotAuthorized/OpenTradeNotAuthorized';
import Button from '../ui/Button/Button';

const RightSideBlockNotAuthorized = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <OpenTradeNotAuthorized />
      <div className={style.block}>
        <Button
          text={t('login')}
          color='primary'
          fontWeight='bold'
          onClick={() => navigate('/login')}
          className={style.block__button}
        />
        <div className={style.block__wrapper}>
          <p className={style.block__text}>Don’t have an account?</p>
          <Link to='/registration' className={style.block__link}>
            Join now!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RightSideBlockNotAuthorized;
