import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from './PeriodDropdown.module.scss';
import { PERIOD, SORT_BY_TIME } from '../../constant/commonConstants';
import useOnClick from '../../hooks/useOnClick';
import { ReactComponent as ArrowUp } from '../../assets/image/common/arrowUpList.svg';
import { ReactComponent as ArrowDown } from '../../assets/image/common/arrowDownList.svg';
import { useRootModel } from '../../models/RootStore';

const PeriodDropdown = () => {
  const { t } = useTranslation();
  const {
    user: {
      paymentHistory: { changePeriod, period },
    },
  } = useRootModel();

  const [showContent, setShowContent] = useState(false);
  const ref = useRef();

  const handleOnClick = () => setShowContent(!showContent);
  const handlePeriodChange = (selectedPeriod) => {
    Object.entries(PERIOD).forEach(([key, value]) => {
      if (t(key) === selectedPeriod) changePeriod(value);
    });
    setShowContent(false);
  };

  useOnClick(ref, () => setShowContent(false));

  return (
    <div className={style.dropdown} ref={ref}>
      <button type='button' className={style.dropdown__button} onClick={handleOnClick}>
        <p>{SORT_BY_TIME[period]}</p>
        {showContent ? <ArrowUp /> : <ArrowDown />}
      </button>
      <CSSTransition
        in={showContent}
        timeout={700}
        classNames={{
          enter: style.enter,
          enterActive: style.enterActive,
          enterDone: style.enterDone,
          exit: style.exit,
          exitActive: style.exitActive,
          exitDone: style.exitDone,
        }}
        unmountOnExit
      >
        <div className={style.dropdown__content}>
          {Object.values(SORT_BY_TIME).map((item) => {
            return (
              <button
                key={item}
                type='button'
                className={style.dropdown__item}
                onClick={() => handlePeriodChange(item)}
              >
                {item}
              </button>
            );
          })}
        </div>
      </CSSTransition>
    </div>
  );
};

export default observer(PeriodDropdown);
