import React from 'react';
import { observer } from 'mobx-react';

import style from './ChatPicturePreview.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';

/**
 * Represents ChatPicturePreview
 * @returns {JSX.Element}
 */
const ChatPicturePreview = () => {
  const {
    modal: {
      chatPicturePreview: { isOpen, close },
    },
    chat: { picture },
  } = useRootModel();
  return (
    isOpen && (
      <Modal handleClose={close} wrapperClassname={style.modal}>
        <ModalBody className={style.body}>
          <img src={picture} alt='' className={style.image} />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(ChatPicturePreview);
