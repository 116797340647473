import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import EN from './locale/en';
import RU from './locale/ru';
import ES from './locale/es';
import IT from './locale/it';
import DE from './locale/de';
import NL from './locale/nl';
import PL from './locale/pl';
import PT from './locale/pt';
import TR from './locale/tr';
import FR from './locale/fr';
import ZH from './locale/zh';
import JA from './locale/ja';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN,
      },
      ru: {
        translation: RU,
      },
      es: {
        translation: ES,
      },
      it: {
        translation: IT,
      },
      de: {
        translation: DE,
      },
      nl: {
        translation: NL,
      },
      pl: {
        translation: PL,
      },
      pt: {
        translation: PT,
      },
      tr: {
        translation: TR,
      },
      fr: {
        translation: FR,
      },
      zh: {
        translation: ZH,
      },
      ja: {
        translation: JA,
      },
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
