import { types } from 'mobx-state-tree';

const HistoryItem = types.model('HistoryItem', {
  amount: types.optional(types.number, 0),
  date: types.optional(types.string, ''),
  fundsChangeAmount: types.optional(types.number, 0),
  multiplier: types.optional(types.number, 0),
  openingPrice: types.optional(types.number, 0),
  operation: types.optional(types.string, ''),
  result: types.optional(types.number, 0),
  stopLoss: types.maybeNull(types.number),
  takeProfit: types.maybeNull(types.number),
  tradeCodeId: types.optional(types.string, ''),
});

export default HistoryItem;
