import React from 'react';
import { observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import style from './Notifications.module.scss';
import { useRootModel } from '../../models/RootStore';

import NotificationItem from './NotificationItem/NotificationItem';
import NotificationsHeader from './NotificationsHeader/NotificationsHeader';
import ScrollLoader from '../ScrollLoader/ScrollLoader';
import { ReactComponent as NotificationIcon } from '../../assets/image/common/notification.svg';

/**
 * Represents Notifications component
 * @param {ref} wrapperRef - reference to wrapper
 * @returns {JSX.Element}
 */
const Notifications = ({ wrapperRef }) => {
  const { t } = useTranslation();
  const {
    user: {
      notifications: { items, isLastPage, isLoadingItems, setNextPage, getNotifications },
    },
  } = useRootModel();

  const handleLoadMoreNotifications = async () => {
    if (isLoadingItems && isLastPage) return;

    await setNextPage();
    getNotifications(true);
  };

  return (
    <div className={style.notifications} ref={wrapperRef}>
      <NotificationsHeader />
      {items.length ? (
        <InfiniteScroll
          dataLength={items.length}
          next={handleLoadMoreNotifications}
          hasMore={!isLastPage}
          loader={<ScrollLoader />}
          height={224}
          className={style.list}
        >
          {items.map(({ id, title, body, isRead }) => (
            <NotificationItem isRead={isRead} key={id} id={id} text={body} title={title} />
          ))}
        </InfiniteScroll>
      ) : (
        <div className={style.empty}>
          <NotificationIcon />
          <p>{t('no_notifications')}</p>
        </div>
      )}
    </div>
  );
};

export default observer(Notifications);
