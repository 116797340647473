import React, { useEffect } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';
import style from './Markets.module.scss';
import Instruments from './Instruments/Instruments';

/**
 * Represents Markets component
 * @returns {JSX.Element}
 */
const Markets = () => {
  const {
    finance: { getCategories, categories, setCurrentCategory, currentCategory, getData },
  } = useRootModel();

  const handleSetCategory = (id) => {
    setCurrentCategory(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCategories();
    };
    fetchData().then();
  }, [getCategories, getData]);

  useEffect(() => {
    if (currentCategory) getData();
  }, [currentCategory, getData]);

  return (
    <section className={style.section}>
      <nav className={style.navigation}>
        {categories.map(({ id, name }) => (
          <button
            key={id}
            type='button'
            className={cn(style.navigation__button, {
              [style.navigation__active]: id === currentCategory,
            })}
            onClick={() => handleSetCategory(id)}
          >
            {name}
          </button>
        ))}
      </nav>
      <Instruments />
    </section>
  );
};

export default observer(Markets);
