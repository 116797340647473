import React from 'react';
import { observer } from 'mobx-react';

import style from './InstumentsList.module.scss';

import { useRootModel } from '../../../../../models/RootStore';
import InstrumentsItem from './InstrumentsItem/InstrumentsItem';
import instrumentIcon from '../../../../../assets/instrumentIcon';

/**
 * Represents Instruments list
 * @returns {JSX.Element}
 */
const InstrumentsList = () => {
  const {
    finance: { sortedData },
  } = useRootModel();

  return (
    <div className={style.tableContent}>
      {sortedData.map(
        ({
          alias,
          s: symbol,
          lp: price,
          cpd: day,
          cpm: month,
          cpsm: halfYear,
          cpy: year,
          sm: strength,
          oldPrice,
          type,
          favorite,
          toggleFavorite,
          isLoading,
        }) => {
          const instType = instrumentIcon[type];
          const Icon = instType ? instrumentIcon[type][symbol] : null;
          return (
            <InstrumentsItem
              key={symbol}
              title={alias}
              symbol={symbol}
              type={type}
              price={price}
              day={day}
              month={month}
              halfYear={halfYear}
              year={year}
              strength={strength}
              oldPrice={oldPrice}
              icon={Icon}
              isFavorite={favorite}
              toggleFavorite={toggleFavorite}
              isLoading={isLoading}
            />
          );
        },
      )}
    </div>
  );
};

export default observer(InstrumentsList);
