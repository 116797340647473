import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import style from './TradeInput.module.scss';
import { ReactComponent as MinusIcon } from '../../assets/image/common/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/image/common/plus.svg';
import { formatAmountWithSpaces, formatStringToNumberWithoutSpace } from '../../util/string';
import { useRootModel } from '../../models/RootStore';
import { NUMBER_WITH_THREE_DIGITS_AFTER_DOT } from '../../constant/regexConstants';
import { VALUE_FOR_AMOUNT_CHANGE } from '../../constant/calculationConstants';

const TradeInput = () => {
  const { t } = useTranslation();
  const {
    user: { currency },
    trade: { increaseAmount, setIncreaseAmount },
  } = useRootModel();

  const amount = useMemo(() => formatStringToNumberWithoutSpace(increaseAmount), [increaseAmount]);

  const handleAmountChange = (e) => {
    const { value } = e.target;

    const formattedValue = value.split(' ').join('');

    if (!(NUMBER_WITH_THREE_DIGITS_AFTER_DOT.test(formattedValue) || formattedValue === '')) return;

    setIncreaseAmount(formattedValue);
  };

  const handleAmountBlur = () => {
    setIncreaseAmount(formatAmountWithSpaces(amount));
  };

  const handleDecreaseTradeAmount = () => {
    if (amount <= 0) return;

    const currentTradeAmount = amount - VALUE_FOR_AMOUNT_CHANGE;
    setIncreaseAmount(formatAmountWithSpaces(currentTradeAmount <= 0 ? 0 : currentTradeAmount));
  };

  const handleIncreaseTradeAmount = () => {
    setIncreaseAmount(formatAmountWithSpaces(amount + VALUE_FOR_AMOUNT_CHANGE));
  };

  useEffect(() => {
    return () => {
      setIncreaseAmount('');
    };
  }, [setIncreaseAmount]);

  return (
    <div>
      <p className={style.label}>{t('trade_amount')}</p>
      <div className={style.wrapper}>
        <div className={style.wrapper__section}>
          <p className={style.wrapper__icon}>{currency}</p>
          <input
            name='amount'
            type='text'
            value={amount}
            onChange={handleAmountChange}
            onBlur={handleAmountBlur}
            placeholder='0.000'
            inputMode='decimal'
            autoComplete='off'
            className={style.wrapper__input}
          />
          <div />
        </div>

        <div className={style.block}>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__left)}
            onClick={handleDecreaseTradeAmount}
          >
            <MinusIcon className={style.button__icon} />
          </button>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__right)}
            onClick={handleIncreaseTradeAmount}
          >
            <PlusIcon className={style.button__icon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(TradeInput);
