import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import style from './SearchModalItem.module.scss';
import string from '../../../../../util/string';
import useWindowSize from '../../../../../hooks/useWindowSize';

/**
 * Represents SearchModalItem component
 * @param {string} symbol - instrument symbol
 * @param {string} alias - instrument alias
 * @param {string} type - instrument type
 * @param {JSX.Element} Icon - instrument icon
 * @param {number} high - instrument high price
 * @param {number} low - instrument low price
 * @param {number} price - instrument last price
 * @param {number} change - instrument change in percent
 * @param {function} closeModal - closeModal handler
 * @param {number} oldPrice - instrument oldPrice
 * @returns {JSX.Element}
 */
const SearchModalItem = ({
  symbol,
  alias,
  type,
  icon: Icon,
  high,
  low,
  price,
  change,
  closeModal,
  oldPrice,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  return (
    <Link to={`/products/${type}/${symbol}`} onClick={closeModal} className={style.searchModelItem}>
      <i className={style.icon}>
        <Icon />
      </i>
      <p>
        {alias} <span className={style.symbol}>{symbol}</span>
      </p>

      {!isMobile && (
        <>
          <p>
            <span className={style.text}>{t('high')} </span>
            {string.formatNumber(high)}
          </p>
          <p>
            <span className={style.text}>{t('low')} </span>
            {string.formatNumber(low)}
          </p>
        </>
      )}
      <p
        className={cn({
          [style.colorDanger]: oldPrice > price,
          [style.colorSuccess]: oldPrice < price,
        })}
      >
        {string.formatNumber(price)}
      </p>
      <p className={cn({ [style.colorDanger]: change < 0, [style.colorSuccess]: change > 0 })}>
        {string.formatPercent(change)}
      </p>
    </Link>
  );
};

export default SearchModalItem;
