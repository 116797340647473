import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import style from './GeneralEmail.module.scss';
import useSendCodeState from '../../../../hooks/useSendCodeState';
import formikUtil from '../../../../util/formikUtil';
import useFormikApp from '../../../../hooks/useFormikApp';
import { CODE_LENGTH, EMAIL_CODE } from '../../../../util/validationSchema';
import { ENTER_CODE_RESTORE_SECONDS } from '../../../../constant/commonConstants';
import { useRootModel } from '../../../../models/RootStore';

import TextInput from '../../../../components/ui/TextInput/TextInput';
import ButtonCountdown from '../../../../components/ui/ButtonCountdown/ButtonCountdown';
import Button from '../../../../components/ui/Button/Button';

/**
 * Represents GeneralEmailInput - code
 * @param {function} onSubmit - submit handler
 * @param {function} sendCode - send code
 * @returns {JSX.Element}
 */
const GeneralEmailCode = ({ onSubmit, sendCode }) => {
  const {
    user: { userData },
  } = useRootModel();
  const { t } = useTranslation();
  const {
    setSecondsRestoreSendCodeGeneral,
    secondsRestoreSendCodeGeneral,

    setSecsEnterCode,
  } = useSendCodeState();

  const inputRef = useRef(null);

  const [secsEnterCode] = useState(0);

  const { search } = useLocation();

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef.current]);

  const handleSubmit = ({ emailCode }) => {
    if (onSubmit) onSubmit(emailCode);
    setSecsEnterCode(ENTER_CODE_RESTORE_SECONDS);
  };
  const formik = useFormikApp([EMAIL_CODE], handleSubmit);

  useEffect(() => {
    const match = search.match(/\?code=([^&]+)/);
    if (match && match.length) {
      const code = match[1];
      formikUtil.setValue(EMAIL_CODE, code, formik);
      onSubmit(code);
    }
  }, []);

  const handleSendCode = async () => {
    await sendCode();
  };

  const handleChangeCode = (e) => {
    formikUtil.checkByCode(e, formik);
  };

  const isValid = !secsEnterCode && formik.isValid;

  return (
    <form onSubmit={formik.handleSubmit} className={style.form}>
      <p className={style.text}>
        {t('code_sent_to')} <span className={style.email}>{userData?.user.email}</span>.{' '}
        {t('enter_below')}
      </p>

      <ButtonCountdown
        text={`${t('resend')}${secondsRestoreSendCodeGeneral > 0 ? ` ${t('in')}` : ''}`}
        onClick={handleSendCode}
        seconds={secondsRestoreSendCodeGeneral}
        handleChangeSeconds={setSecondsRestoreSendCodeGeneral}
        buttonClassName={style.buttonCountdown}
      />

      <div className={style.codeInputWrapper}>
        <p className={style.codeLabel}>Email Code</p>
        <TextInput
          id={EMAIL_CODE}
          placeholder={t('code')}
          onChange={handleChangeCode}
          formik={formik}
          inputMode='numeric'
          maxLength={CODE_LENGTH}
          autoComplete='nope'
        />
      </div>

      <Button
        size='small'
        text={t('verify_email')}
        fontWeight='normal'
        width='full'
        disabled={!isValid}
        submit
      />
    </form>
  );
};

export default GeneralEmailCode;
