import React from 'react';
import cn from 'classnames';
import style from './TitleNumberFormat.module.scss';
import string from '../../../util/string';
import BalanceFormat from '../../BalanceFormat/BalanceFormat';

/**
 * @typedef {Object} FormatOptions
 * @property {boolean} money - is format money
 * @property {boolean} withSign - is format money with plus sign
 * @property {boolean} withoutSign - format without sign
 */

/**
 * Represents TitleNumberFormat component
 * @param {string} title - string title
 * @param {number} value - string value
 * @param {CSSStyleRule} className - wrapper className
 * @param {CSSStyleRule} titleClassName - title className
 * @param {CSSStyleRule} valueClassName - value className
 * @param {boolean} formatted - is formatted number
 * @param {FormatOptions} options - options for format
 * @param {function} formatFunction - function for format
 * @param {string} textBeforeValue - text before value
 * @returns {JSX.Element}
 */
const TitleNumberFormat = ({
  title,
  value,
  formatted = true,
  formatFunction,
  options = {},
  className,
  titleClassName,
  valueClassName,
  textBeforeValue = '',
}) => {
  const { money, withSign, withoutSign } = options;
  const newValue = withoutSign ? Math.abs(value) : value;
  const formatWithOptions = money ? (
    <BalanceFormat value={newValue} withoutSign={!withSign} />
  ) : (
    string.formatNumber(newValue)
  );
  const formattedValue = formatFunction ? formatFunction(newValue) : formatWithOptions;
  return (
    <div className={cn(style.text, className)}>
      <p className={cn(style.secondaryColor, titleClassName)}>{title}:</p>
      <div className={cn(style.value, valueClassName)}>
        {textBeforeValue && <span>{textBeforeValue}</span>}
        <div>{formatted ? formattedValue : value}</div>
      </div>
    </div>
  );
};

export default TitleNumberFormat;
