import React, { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './TwoFactorAuth.module.scss';
import useSendCodeState from '../../../../hooks/useSendCodeState';

import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody/ModalBody';
import SecurityPhone from './TwoFactorAuthContent/TwoFactorAuthPhone';
import SecurityCode from './TwoFactorAuthContent/TwoFactorAuthCode';
import SecurityVerifiedError from './TwoFactorAuthContent/TwoFactorAuthVerifiedError';

/**
 * Represent popup for security Two Factor Authorization
 * @param {function} handleCloseModal - close handler
 * @param {function} handleSendCode - send code handler
 * @param {function} showPhone - showPhone
 * @param {function} setShowPhone - setShowPhone
 * @param {function} handleVerifyPhoneConfirm - handleVerifyPhoneConfirm
 * @param {number} attemptsLeft - attemptsLeft
 * @returns {JSX.Element}
 */
const TwoFactorAuthModal = ({
  handleCloseModal,
  handleSendCode,
  showPhone,
  setShowPhone,
  handleVerifyPhoneConfirm,
  attemptsLeft,
}) => {
  const { setSecondsRestoreAttempts, secondsRestoreAttempts } = useSendCodeState();
  const { t } = useTranslation();

  useEffect(() => {
    if (attemptsLeft === 3) setShowPhone(true);
    else setShowPhone(false);
  }, [attemptsLeft]);

  const content = showPhone ? (
    <SecurityPhone onSubmit={handleSendCode} />
  ) : (
    <SecurityCode
      onSubmit={handleVerifyPhoneConfirm}
      sendCode={handleSendCode}
      setShowPhone={setShowPhone}
    />
  );

  return (
    <Modal
      wrapperClassname={style.modal}
      handleClose={handleCloseModal}
      header
      headerTitle={t('two_factor')}
    >
      <ModalBody className={cn({ [style.modalBody]: setShowPhone })}>
        {attemptsLeft === 0 ? (
          <SecurityVerifiedError
            handleChangeSeconds={setSecondsRestoreAttempts}
            seconds={secondsRestoreAttempts}
            close={handleCloseModal}
          />
        ) : (
          content
        )}
      </ModalBody>
    </Modal>
  );
};

export default TwoFactorAuthModal;
