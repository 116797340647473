import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from './CommissionsAndReportsTop.module.scss';
import { useRootModel } from '../../../../../../../models/RootStore';
import string from '../../../../../../../util/string';

const CommissionsAndReportsTop = () => {
  const { t } = useTranslation();
  const {
    finance: {
      trade: { getCommission, commission },
      activeTrades: { activeTradeId },
    },
  } = useRootModel();

  const { forMakingOpsCmsnAmount, swapAccrualAmount, totalAmount } = commission;

  useEffect(() => {
    getCommission(activeTradeId);
  }, [activeTradeId, getCommission]);

  return (
    <div className={style.container}>
      <p className={style.header}>{t('accruals')}, $</p>
      <div>
        <div className={style.item}>
          <p className={style.title}>{t('operations')}</p>
          <p>{forMakingOpsCmsnAmount ? string.formatNumber(forMakingOpsCmsnAmount) : `-`}</p>
        </div>
        <div className={style.item}>
          <p className={style.title}>{t('for_swap')}</p>
          <p>{string.formatNumber(swapAccrualAmount)}</p>
        </div>
        <div className={style.item}>
          <p className={style.title}>{t('total')}</p>
          <p>{string.formatNumber(totalAmount)}</p>
        </div>
      </div>
    </div>
  );
};

export default observer(CommissionsAndReportsTop);
