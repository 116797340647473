import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from '../StatusInfoWeb.module.scss';

/**
 * Represents loyalty status trade button component
 * @returns {JSX.Element}
 */

const TradeBtn = ({ type, isActive }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/');
  };
  return (
    <div className={styles.tradeBtnWrap}>
      {!isActive && (
        <button
          type='button'
          onClick={onClick}
          className={cn(styles.statusBtn, styles.noVisible, styles[type])}
        >
          {t('trade')}
        </button>
      )}
    </div>
  );
};

export default TradeBtn;
