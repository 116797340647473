import React from 'react';
import { observer } from 'mobx-react';

import style from '../../AppModals.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import useWindowSize from '../../../../hooks/useWindowSize';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import DepositMoney from '../../../DepositMoney/DepositMoney';

/**
 * Represents DepositModal component
 * @returns {JSX.Element}
 */
const DepositModal = () => {
  const { isMobile } = useWindowSize();
  const {
    modal: {
      deposit: { isOpen, close },
    },
  } = useRootModel();

  return (
    !isMobile &&
    isOpen && (
      <Modal handleClose={close} header headerTitle='Deposit' wrapperClassname={style.balanceModal}>
        <ModalBody>
          <DepositMoney />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(DepositModal);
