import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './NotificationsHeader.module.scss';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';

/**
 * Represents NotificationsHeader component
 * @returns {JSX.Element}
 */

const NotificationsHeader = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    user: {
      notifications: { deleteNotifications, getNotifications, amountUnread },
    },
  } = useRootModel();

  const handleDelete = async () => {
    try {
      await deleteNotifications();
      getNotifications();
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.notificationsHeader}>
      <p className={style.title}>
        <span className={style.amount}>{amountUnread}</span>
        new notification{amountUnread !== 1 && 's'}
      </p>
      <button
        type='button'
        className={style.deleteLink}
        onClick={handleDelete}
        disabled={!amountUnread}
      >
        {t('clear_all')}
      </button>
    </div>
  );
};

export default observer(NotificationsHeader);
