import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import style from './LoginForm.module.scss';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';

import {
  EMAIL_MAX_LENGTH,
  ERROR_TEXT_REQUIRED,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from '../../../util/validationSchema';
import Button from '../../../components/ui/Button/Button';
import { ReactComponent as PasswordIcon } from '../../../assets/image/common/password.svg';
import { ReactComponent as EmailIcon } from '../../../assets/image/common/email.svg';
import { ReactComponent as VisibleOff } from '../../../assets/image/common/visibilityOff.svg';
import { ReactComponent as VisibleLogo } from '../../../assets/image/common/visibility.svg';

const LoginForm = () => {
  const { t } = useTranslation();
  const [emailInputActive, setEmailInputActive] = useState(false);
  const [passwordInputActive, setPasswordInputActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const alert = useContext(AlertContext);
  const {
    user: { login },
  } = useRootModel();

  const formik = useFormik({
    initialValues: {
      userEmail: '',
      userPassword: '',
    },
    validationSchema: Yup.object({
      userEmail: Yup.string()
        .email('Invalid email')
        .max(EMAIL_MAX_LENGTH, `Must be ${EMAIL_MAX_LENGTH} characters or less`)
        .required(ERROR_TEXT_REQUIRED),
      userPassword: Yup.string()
        .min(PASSWORD_MIN_LENGTH, `Must be at least ${PASSWORD_MIN_LENGTH} characters`)
        .max(PASSWORD_MAX_LENGTH, `Must be ${PASSWORD_MAX_LENGTH} characters or less`)
        .required(ERROR_TEXT_REQUIRED),
    }),
    onSubmit: async ({ userEmail, userPassword }) => {
      try {
        await login(userEmail.toLowerCase(), userPassword);
      } catch (e) {
        alert.show(t(e.message));
      }
    },
  });

  return (
    <form className={style.form} onSubmit={formik.handleSubmit}>
      <div className={style.inputContainer}>
        <div className={style.labelWrapper}>
          <label htmlFor='userEmail' className={style.label}>
            {t('email')}
          </label>
          {formik.touched.userEmail && formik.errors.userEmail && (
            <p className={style.error}>{formik.errors.userEmail}</p>
          )}
        </div>
        <div className={style.inputWrapper}>
          <p
            className={cn(style.icon, {
              [style.active]: emailInputActive,
              [style.errorIcon]: formik.touched.userEmail && formik.errors.userEmail,
            })}
          >
            <EmailIcon />
          </p>
          <input
            id='userEmail'
            name='userEmail'
            type='email'
            placeholder={t('enter_email')}
            value={formik.values.userEmail}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              setEmailInputActive(false);
            }}
            onFocus={() => setEmailInputActive(true)}
            className={cn(style.input, {
              [style.errorBorder]: formik.touched.userEmail && formik.errors.userEmail,
            })}
          />
        </div>
      </div>

      <div className={style.inputContainer}>
        <div className={style.labelWrapper}>
          <label htmlFor='userPassword' className={style.label}>
            {t('password')}
          </label>
          {formik.touched.userPassword && formik.errors.userPassword && (
            <p className={style.error}>{formik.errors.userPassword}</p>
          )}
        </div>
        <div className={style.inputWrapper}>
          <p
            className={cn(style.icon, {
              [style.active]: passwordInputActive,
              [style.errorIcon]: formik.touched.userPassword && formik.errors.userPassword,
            })}
          >
            <PasswordIcon />
          </p>
          <input
            id='userPassword'
            name='userPassword'
            type={showPassword ? 'text' : 'password'}
            placeholder={t('enter_password')}
            value={formik.values.userPassword}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              setPasswordInputActive(false);
            }}
            onFocus={() => setPasswordInputActive(true)}
            maxLength={PASSWORD_MAX_LENGTH}
            className={cn(style.input, {
              [style.errorBorder]: formik.touched.userPassword && formik.errors.userPassword,
            })}
          />
          <div className={style.passwordIconVisibility}>
            <button
              className={style.passwordButton}
              type='button'
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibleOff /> : <VisibleLogo />}
            </button>
          </div>
        </div>
      </div>

      <button type='button' className={style.info}>
        <Link to='/forgot-password'>{t('forgot')}</Link>
      </button>

      <Button
        text={t('login')}
        color='primary'
        disabled={!(formik.isValid && formik.dirty)}
        submit
      />
    </form>
  );
};

export default observer(LoginForm);
