import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';
import style from './ExchangeSchedule.module.scss';
import AreaItem from './AreaItem/AreaItem';
import Preloader from '../../components/ui/Preloader/Preloader';

/**
 * Represents exchange schedule page.
 * @returns {JSX.Element}
 */
const ExchangeSchedule = () => {
  const {
    market: { getTradingHours, items, isLoading },
  } = useRootModel();

  useEffect(() => {
    getTradingHours();
  }, []);

  if (isLoading) {
    return <Preloader className={style.loader} />;
  }
  return (
    <div className={style.container}>
      <p className={style.title}>Trading instruments</p>
      <div className={style.table}>
        <div className={style.tableTitle}>
          <p>Stock exchange</p>
          <p>Working hours (GMT)</p>
        </div>
        <div className={style.area}>Asian-Pacific area</div>
        {items.map(
          ({ location, exchange, openingTime, closingTime }) =>
            location === 'ASIAN_PACIFIC_AREA' && (
              <AreaItem
                key={exchange + openingTime}
                exchange={exchange}
                openingTime={openingTime}
                closingTime={closingTime}
              />
            ),
        )}
        <div className={style.area}>Europe</div>
        {items.map(
          ({ location, exchange, openingTime, closingTime }) =>
            location === 'EUROPE' && (
              <AreaItem
                key={exchange + openingTime}
                exchange={exchange}
                openingTime={openingTime}
                closingTime={closingTime}
              />
            ),
        )}
        <div className={style.area}>USA</div>
        {items.map(
          ({ location, exchange, openingTime, closingTime }) =>
            location === 'USA' && (
              <AreaItem
                key={exchange + openingTime}
                exchange={exchange}
                openingTime={openingTime}
                closingTime={closingTime}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default observer(ExchangeSchedule);
