import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import style from '../TwoFactorAuth.module.scss';
import TimeCountdown from '../../../../../components/TimeCountdown/TimeCountdown';
import Button from '../../../../../components/ui/Button/Button';

/**
 * Represents Security Verified Error
 * @param {function} close - close handler
 * @param {number} seconds - seconds to restore attempts
 * @param {function} handleChangeSeconds - function for execute (like setState)
 * @returns {JSX.Element}
 */

const TwoFactorAuthVerifiedError = ({ close, seconds = 0, handleChangeSeconds }) => {
  const { t } = useTranslation();
  const buttonRef = useRef(null);
  useEffect(() => {
    buttonRef?.current?.focus();
  }, [buttonRef.current]);

  return (
    <>
      <p className={style.verifiedErrorText}>
        {t('attempt')} <span>{t('try_again')} </span>
        <span className={style.time}>
          <TimeCountdown
            fullTime
            seconds={seconds}
            onChange={handleChangeSeconds}
            callBack={close}
          />
        </span>
      </p>
      <Button
        text={t('submit_later')}
        color='primary'
        onClick={close}
        fontWeight='normal'
        size='small'
        width='full'
        buttonRef={buttonRef}
        className={style.buttonSubmitError}
      />
    </>
  );
};

export default TwoFactorAuthVerifiedError;
