import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import style from './Security.module.scss';
import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';
import useWindowSize from '../../../hooks/useWindowSize';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';
import {
  SEND_CODE_RESTORE_SECONDS,
  SEND_CODE_RESTORE_STORAGE_STRING,
} from '../../../constant/commonConstants';

import Mobile from './TwoFactorAuth/TwoFactorAuthMobile';
import TwoFactorAuthModal from './TwoFactorAuth/TwoFactorAuthModal';
import NotificationItem from '../Notifications/NotificationsList/NotificationItem/NotificationItem';
import storage from '../../../util/storage';
import useSendCodeState from '../../../hooks/useSendCodeState';

/**
 * Represents a notifications component
 * @returns {JSX.Element}
 */
const Security = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showPhone, setShowPhone] = useState(true);

  const handleCloseModal = () => setShowModal(false);
  const alert = useContext(AlertContext);

  const {
    user: {
      userData,
      getAttemptsVerifyPhone,
      verifyPhone,
      verifyPhoneConfirm,
      changeTwoFaAuthorization,
    },
  } = useRootModel();

  const { attemptsLeft, handleSetAttempts } = useSendCodeState();

  useEffect(() => {
    if (userData) getAttemptsVerifyPhone();
  }, [userData]);

  useEffect(() => {
    if (attemptsLeft === 3) setShowPhone(true);
    else setShowPhone(false);
  }, [attemptsLeft]);

  const handleEnableTwoFaAuthorization = (value) => {
    changeTwoFaAuthorization(value);
  };

  const handleSwitch = async () => {
    if (!userData.emailConfirmed) {
      alert.show(t('need_to_verify_mail'), 'info');
      return;
    }
    if (userData.phoneConfirmed) {
      try {
        await handleEnableTwoFaAuthorization(!userData.twoFaEnabled);
        return;
      } catch (e) {
        alert.show(t(e.message));
      }
    }
    setShowModal(true);
  };

  const handleSendCode = async (phone) => {
    try {
      await verifyPhone(phone);
      setShowPhone(false);
      storage.setLocalRestoreTime(SEND_CODE_RESTORE_STORAGE_STRING, SEND_CODE_RESTORE_SECONDS);
      localStorage.setItem('phone', phone);
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const handleVerifyPhoneConfirm = async (code) => {
    try {
      await verifyPhoneConfirm(code);
      await handleEnableTwoFaAuthorization(true);
      setShowModal(false);
    } catch (e) {
      alert.show(t(e.message));
      handleSetAttempts();
    }
  };

  const { isMobile } = useWindowSize();
  return (
    <ErrorBoundary>
      <div className={style.security}>
        <NotificationItem
          id='security'
          title={t('two_factor')}
          handleSwitch={handleSwitch}
          status={userData?.twoFaEnabled || false}
        />
        {showModal &&
          (isMobile ? (
            <Mobile
              handleCloseModal={handleCloseModal}
              handleSendCode={handleSendCode}
              showPhone={showPhone}
              setShowPhone={setShowPhone}
              handleVerifyPhoneConfirm={handleVerifyPhoneConfirm}
              attemptsLeft={attemptsLeft}
            />
          ) : (
            <TwoFactorAuthModal
              handleCloseModal={handleCloseModal}
              handleSendCode={handleSendCode}
              showPhone={showPhone}
              setShowPhone={setShowPhone}
              handleVerifyPhoneConfirm={handleVerifyPhoneConfirm}
              attemptsLeft={attemptsLeft}
            />
          ))}
      </div>
    </ErrorBoundary>
  );
};

export default observer(Security);
