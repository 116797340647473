import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from './PendingContent.module.scss';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useRootModel } from '../../../../models/RootStore';
import TableTitle from '../../../../components/ui/Table/TableTitle/TableTitle';
import PendingList from './PendingList/PendingList';

/**
 * Represents pending trades content
 * @returns {JSX.Element}
 */

const PendingContent = ({ items }) => {
  const { t } = useTranslation();
  const { isDesktop, isLaptop } = useWindowSize();
  const {
    finance: {
      pendingTrades: { sortBy, setSortBy, setSortDirection, sortDirection, switchSortDirection },
    },
  } = useRootModel();

  return (
    <div>
      <div className={style.head}>
        <TableTitle
          className={style.title}
          title={t('name')}
          isActive={sortBy === 'alias'}
          handleOnClick={setSortBy}
          labelKey='alias'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        <TableTitle
          className={style.title}
          title={t('opening_date')}
          isActive={sortBy === 'openedAt'}
          handleOnClick={setSortBy}
          labelKey='openedAt'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        <div>{t('conditions')}</div>

        {isDesktop && <div>{t('profit_limit')}</div>}

        {isDesktop && <div>{t('loss_limit')}</div>}

        {isLaptop && <div>{t('profit_loss_limit')}</div>}

        <TableTitle
          className={style.title}
          title={t('amount')}
          isActive={sortBy === 'amount'}
          handleOnClick={setSortBy}
          labelKey='amount'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />
      </div>
      <PendingList items={items} />
    </div>
  );
};

export default observer(PendingContent);
