import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../TwoFactorAuth.module.scss';
import useWindowSize from '../../../../../hooks/useWindowSize';
import {
  ENTER_CODE_RESTORE_SECONDS,
  ENTER_CODE_RESTORE_STORAGE_STRING,
  SEND_CODE_RESTORE_SECONDS,
  SEND_CODE_RESTORE_STORAGE_STRING,
} from '../../../../../constant/commonConstants';
import storage from '../../../../../util/storage';
import { CODE_LENGTH, SMS_CODE } from '../../../../../util/validationSchema';
import useFormikApp from '../../../../../hooks/useFormikApp';
import useSendCodeState from '../../../../../hooks/useSendCodeState';

import TextInput from '../../../../../components/ui/TextInput/TextInput';
import ButtonCountdown from '../../../../../components/ui/ButtonCountdown/ButtonCountdown';
import Button from '../../../../../components/ui/Button/Button';
import TimeCountdown from '../../../../../components/TimeCountdown/TimeCountdown';

/**
 * Represents Security verification code
 * @param {function} onSubmit - onSubmit handler
 * @param {function} setShowPhone - setShowPhone
 * @param {function} sendCode - sendCode
 * @returns {JSX.Element}
 */
const TwoFactorAuthCode = ({ onSubmit, setShowPhone, sendCode }) => {
  const { t } = useTranslation();
  const { isLandscape, isMobile } = useWindowSize();
  const inputRef = useRef(null);
  const {
    secondsRestoreSendCode,
    setSecondsRestoreSendCode,
    secondsRestoreEnterCode,
    setSecondsRestoreEnterCode,
  } = useSendCodeState();

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const handleSubmit = (value) => {
    setSecondsRestoreEnterCode(ENTER_CODE_RESTORE_SECONDS);
    storage.setLocalRestoreTime(ENTER_CODE_RESTORE_STORAGE_STRING, ENTER_CODE_RESTORE_SECONDS);
    onSubmit(value[SMS_CODE]);
  };

  const formik = useFormikApp([SMS_CODE], handleSubmit);

  const handleChangeVerifyCode = (e) => {
    if (e.target.value.length > CODE_LENGTH) return;
    formik.handleChange(e);
  };

  const handleSendCode = () => {
    storage.setLocalRestoreTime(SEND_CODE_RESTORE_STORAGE_STRING, SEND_CODE_RESTORE_SECONDS);
    setSecondsRestoreSendCode(SEND_CODE_RESTORE_SECONDS);
    const phone = localStorage.getItem('phone');
    if (phone) sendCode(phone);
  };

  const handleChangePhoneNumber = () => {
    setShowPhone(true);
    localStorage.removeItem('phone');
  };

  const isValid = !secondsRestoreEnterCode && formik.isValid;
  const sendInTime = secondsRestoreSendCode > 0 ? ` ${t('in')}` : '';
  const timeCountdownText = `${t('send_me_code_again')}${sendInTime}`;
  return (
    <form onSubmit={formik.handleSubmit}>
      <p className={style.text}>{t('code_sent_number')}</p>
      <div className={style.label}>Code</div>
      <div className={style.inputCodeAndButtonWrapper}>
        <TextInput
          id={SMS_CODE}
          value={formik.values[SMS_CODE]}
          onBlur={formik.handleBlur}
          onChange={handleChangeVerifyCode}
          placeholder={t('enter_code')}
          inputMode='numeric'
          rightIcon={
            secondsRestoreEnterCode
              ? () => (
                  <TimeCountdown
                    seconds={secondsRestoreEnterCode}
                    onChange={setSecondsRestoreEnterCode}
                  />
                )
              : null
          }
          rightIconClassName={style.rightTimeIcon}
          inputRef={inputRef}
          errorClassName={style.error}
          maxLength={CODE_LENGTH}
        />
        {formik.touched[SMS_CODE] && formik.errors[SMS_CODE] && (
          <span className={style.error}>{formik.errors[SMS_CODE]}</span>
        )}
        <ButtonCountdown
          text={!isMobile || isLandscape ? timeCountdownText : ''}
          seconds={secondsRestoreSendCode}
          onClick={handleSendCode}
          buttonClassName={style.buttonWithCountdown}
          textClassName={style.timeText}
          handleChangeSeconds={setSecondsRestoreSendCode}
          label={t('send_me_code_again')}
        />
      </div>
      <Button
        text={t('verify')}
        submit
        fontWeight='normal'
        width='full'
        disabled={!isValid}
        marginBottom
      />

      <button className={style.changePhoneNumber} type='button' onClick={handleChangePhoneNumber}>
        {t('change_phone')}
      </button>
    </form>
  );
};

export default TwoFactorAuthCode;
