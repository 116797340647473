import React from 'react';
import cn from 'classnames';
import style from './Checkbox.module.scss';

/**
 * Represents checkbox
 * @param {string} id - id for checkbox
 * @param {string} label - checkbox label
 * @param {function} handleChange - onChange handler
 * @param {boolean} checked - is checked
 * @param {CSSStyleRule} wrapperClassName - className for checkbox wrapper
 * @param {CSSStyleRule} labelClassName - className for checkbox label
 * @returns {JSX.Element}
 */
const Checkbox = ({
  id = 'checkbox',
  label,
  handleChange,
  checked = false,
  wrapperClassName,
  labelClassName,
}) => {
  return (
    <label htmlFor={id} className={cn(style.checkbox, wrapperClassName)}>
      <input id={id} type='checkbox' onChange={handleChange} checked={checked} />
      <span className={style.square} />
      <span className={cn(style.label, labelClassName)}>{label}</span>
    </label>
  );
};

export default Checkbox;
