import React from 'react';
import { observer } from 'mobx-react';

import style from './HeaderNavigation.module.scss';
import HeaderLanguageWithoutAuth from '../HeaderLanguageWithoutAuth/HeaderLanguageWithoutAuth';
import HeaderButtons from '../HeaderButtons/HeaderButtons';

/**
 * Represents HeaderNavigation
 * @returns {JSX.Element}
 */
const HeaderNavigation = () => {
  return (
    <div className={style.navigation}>
      <HeaderButtons />
      <HeaderLanguageWithoutAuth />
    </div>
  );
};

export default observer(HeaderNavigation);
