import React, { useMemo } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from '../LimitProfitsAndLost/LimitProfitsAndLost.module.scss';
import { formatAmountWithSpaces, formatStringToNumberWithoutSpace } from '../../util/string';
import { useRootModel } from '../../models/RootStore';
import { ReactComponent as MinusIcon } from '../../assets/image/common/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/image/common/plus.svg';
import { VALUE_FOR_AMOUNT_CHANGE } from '../../constant/calculationConstants';
import { NUMBER_WITH_THREE_DIGITS_AFTER_DOT } from '../../constant/regexConstants';

const StopLossInput = () => {
  const { t } = useTranslation();
  const {
    user: { currency },
    finance: {
      trade: {
        amountData: { amount },
        stopLoss,
        setLoss,
        limitSellStopLoss,
      },
    },
  } = useRootModel();

  const currentLoss = useMemo(() => formatStringToNumberWithoutSpace(stopLoss), [stopLoss]);

  const currentLossInPercent = useMemo(
    () => Number(((currentLoss / amount) * 100).toFixed(3)),
    [amount, currentLoss],
  );

  const maxLoss = useMemo(
    () => Number(((amount * limitSellStopLoss) / 100).toFixed(3)),
    [amount, limitSellStopLoss],
  );

  const handleDecreaseLoss = () => {
    if (stopLoss <= 0) return;

    const decreasedLoss = currentLoss - VALUE_FOR_AMOUNT_CHANGE;
    setLoss(formatAmountWithSpaces(decreasedLoss <= 0 ? 0 : decreasedLoss));
  };

  const handleIncreaseLoss = () => {
    const increasedLoss = currentLoss + VALUE_FOR_AMOUNT_CHANGE;

    if (limitSellStopLoss && increasedLoss >= maxLoss) {
      return setLoss(formatAmountWithSpaces(maxLoss));
    }

    return setLoss(formatAmountWithSpaces(increasedLoss));
  };

  const handleBlurLoss = () => {
    if (limitSellStopLoss && currentLossInPercent >= limitSellStopLoss) {
      return setLoss(formatAmountWithSpaces(maxLoss));
    }

    return setLoss(formatAmountWithSpaces(currentLoss));
  };

  const handleChangeLoss = (e) => {
    const { value } = e.target;
    const formattedValue = value.split(' ').join('');

    if (!(NUMBER_WITH_THREE_DIGITS_AFTER_DOT.test(formattedValue) || formattedValue === '')) return;

    setLoss(formattedValue);
  };

  return (
    <div className={style.container}>
      <p className={style.label}>
        <span>{t('loss')}</span>{' '}
        {Boolean(amount) && Boolean(limitSellStopLoss) && (
          <span className={style.label__text}>(max: {limitSellStopLoss}%)</span>
        )}
      </p>
      <div className={style.wrapper}>
        <div className={style.block}>
          <p className={style.block__icon}>{currency}</p>
          <input
            name='loss'
            type='text'
            value={stopLoss}
            onChange={handleChangeLoss}
            onBlur={handleBlurLoss}
            placeholder='0.000'
            inputMode='decimal'
            autoComplete='off'
            maxLength='20'
            className={style.block__input}
          />
        </div>

        <div className={style.buttons}>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__left)}
            onClick={handleDecreaseLoss}
          >
            <MinusIcon className={style.button__icon} />
          </button>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__right)}
            onClick={handleIncreaseLoss}
          >
            <PlusIcon className={style.button__icon} />
          </button>
        </div>
      </div>
      {Boolean(currentLoss) && Boolean(amount) && (
        <p className={style.percent}>-{currentLossInPercent}%</p>
      )}
    </div>
  );
};

export default observer(StopLossInput);
