import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './NotFoundMobile.module.scss';
import { ReactComponent as Empty } from '../../../assets/image/common/noContent.svg';

/**
 * Represents Not Found component in Mobile version
 * @returns {JSX.Element}
 */

const NotFoundMobile = () => {
  const { t } = useTranslation();
  return (
    <div className={style.emptyBox}>
      <Empty />
      <p className={style.emptyText}>{t('not_found')}</p>
    </div>
  );
};

export default NotFoundMobile;
