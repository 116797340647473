import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useRootModel } from '../../../../../models/RootStore';

import style from './ClosedTradesMobileItem.module.scss';
import date from '../../../../../util/date';
import { OPERATION } from '../../../../../constant/commonConstants';

import { ReactComponent as Up } from '../../../../../assets/image/common/upArr.svg';
import { ReactComponent as Down } from '../../../../../assets/image/common/downArr.svg';
import BalanceFormat from '../../../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents Instrument item in Closed trades on Mobile version
 * @param {string} title - instrument title
 * @param {string} number - instrument code
 * @param {number} amount - amount of rate
 * @param {number} multiplier - multiplier
 * @param {string} dateClose - closing date
 * @param {number} profit - trade profit in $
 * @param {JSX.Element} icon - instrument icon
 * @param {'BUY' | 'SELL'} operation - trade operation
 * @returns {JSX.Element}
 */
const ClosedTradesMobileItem = ({
  title,
  number,
  amount,
  multiplier,
  dateClose,
  profit,
  icon: Icon,
  operation,
}) => {
  const {
    settings: { language },
  } = useRootModel();
  return (
    <div className={style.instrumentMobileItem}>
      <div className={style.iconAndPriceWrapper}>
        <div className={style.titleWrapper}>
          <i className={style.instrumentIcon}>
            <Icon />
          </i>
          <div>
            <div className={style.container}>
              <span className={style.title}>{title}</span>
              <span>{operation === OPERATION.buy ? <Up /> : <Down />}</span>
            </div>
            <p className={style.number}>{number}</p>
          </div>
        </div>

        <div className={style.priceTop}>
          <div
            className={cn(style.price, {
              [style.colorSuccess]: profit > 0,
              [style.colorDanger]: profit < 0,
            })}
          >
            <BalanceFormat value={profit} />
          </div>
          <div className={cn(style.number, style.positionEnd)}>
            <BalanceFormat value={amount} />x{multiplier},
            {dateClose ? date.toLocaleString(dateClose, language) : `-`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ClosedTradesMobileItem);
