import React, { useState } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import style from './Tooltip.module.scss';
import getAnimationClasses from '../../../util/getAnimationClasses';

/**
 * Represents a tooltip
 * @param {string} content - tooltip content
 * @param {('top' | 'bottom' | 'left' | 'right')} position - tooltip position
 * @param {CSSStyleRule} tooltipClassName - tooltip className
 * @param {CSSStyleRule} tooltipWrapperClassName - tooltip wrapper className
 * @param {JSX.Element} children - component what will be wrapped
 * @param {CSSStyleRule} childrenClassName - children className
 * @returns {JSX.Element}
 */
const Tooltip = ({
  content = '',
  position = 'top',
  tooltipClassName,
  tooltipWrapperClassName,
  children,
  childrenClassName,
}) => {
  const [visible, setVisible] = useState();
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <div className={cn(style.tooltip, tooltipWrapperClassName)}>
      <CSSTransition
        in={visible}
        timeout={400}
        classNames={getAnimationClasses(style)}
        unmountOnExit
      >
        <span
          className={cn(style.content, style[position], tooltipClassName)}
          onMouseEnter={show}
          onMouseLeave={hide}
        >
          {content}
        </span>
      </CSSTransition>

      <div
        onMouseEnter={show}
        onMouseLeave={hide}
        className={cn(style.children, childrenClassName)}
      >
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
