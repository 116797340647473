import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import style from './Verification.module.scss';
import { useRootModel } from '../../models/RootStore';
import AlertContext from '../../context/alert/alertContext';
import useWindowSize from '../../hooks/useWindowSize';
import { VERIFY_SEND_CODE_RESTORE_SECONDS } from '../../constant/commonConstants';

import Logo from '../../components/ui/Logo/Logo';
import Button from '../../components/ui/Button/Button';
import BackButton from '../../components/ui/BackButton/BackButton';
import Copyrights from '../../components/ui/Copyrights/Copyrights';
import TimeCountdown from '../../components/TimeCountdown/TimeCountdown';
import useSendCodeState from '../../hooks/useSendCodeState';

/**
 * Represents a verification page.
 * @returns {JSX.Element}
 */
const Verification = () => {
  const { isDesktop } = useWindowSize();
  const alert = useContext(AlertContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    user: { email, verify, verifySendCode, getUser, toggleTwoFAEnabled, userData },
  } = useRootModel();

  const { secondsRestoreSendVerifyCode, setSecondsRestoreSendVerifyCode } = useSendCodeState();

  const [showCopyrights, setShowCopyrights] = useState(true);
  const [verificationCode, setVerificationCode] = useState({});
  const inputRefs = useRef({});
  const range = [...Array(6).keys()];

  useEffect(() => {
    if (inputRefs.current) {
      inputRefs.current[0].focus();
    }
  }, []);

  if (!email) return navigate('/login');
  if (userData) return navigate('/');

  const handleVerify = async (e) => {
    // todo show error when attempts left === 0

    e.preventDefault();
    try {
      const confirmCode = Object.values(verificationCode).join('');
      await verify({ email, confirmCode });
      await getUser();
      navigate('/');
    } catch (err) {
      alert.show(t(err.message));
    }
  };

  const handleSendCodeAgain = () => {
    try {
      setSecondsRestoreSendVerifyCode(VERIFY_SEND_CODE_RESTORE_SECONDS);
      verifySendCode();
    } catch (e) {
      alert.show(t(e.message), 'info');
    }
  };

  const setDigit = (position, value) => {
    const digit = {
      ...verificationCode,
      [position]: value,
    };
    setVerificationCode(digit);
  };

  let isValid = false;
  if (inputRefs.current) {
    const inputs = inputRefs.current;
    const values = Object.values(inputs).map((input) => {
      return input.value;
    });

    isValid = values.length > 0 && values.every((i) => i.length);
  }

  const handleKeyDown = (e, position) => {
    if (e.key === verificationCode[position]) {
      e.preventDefault();
      if (inputRefs.current[position + 1]) {
        inputRefs.current[position + 1].focus();
      }
    }

    if (e.key === 'Backspace') {
      e.preventDefault();
      setDigit(position, '');
      inputRefs.current[position].value = '';
      if (inputRefs.current[position - 1]) {
        inputRefs.current[position - 1].focus();
      }
    }

    if (e.key === 'Enter' && isValid) {
      handleVerify(e);
    }
  };
  const preventCharacter = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputFocus = () => setShowCopyrights(false);
  const handleInputBlur = () => setShowCopyrights(true);

  const focusHandler = (e) => {
    e.target.select();
    handleInputFocus();
  };

  const changeHandler = (e, position) => {
    setDigit(position, e.target.value);
    if (!e.target.value) {
      return;
    }
    if (inputRefs.current[position + 1]) {
      inputRefs.current[position + 1].focus();
    }
  };

  const handleClickBackButton = () => toggleTwoFAEnabled(false);

  return (
    <section className={style.verificationWrapper}>
      <div className={style.verification}>
        <BackButton className={style.backButton} url='/login' onClick={handleClickBackButton} />
        <header className={style.header}>
          <Logo />
        </header>
        <span className={style.textBeforeForm}>{t('verification_send')}</span>
        <form className={style.form} onSubmit={handleVerify}>
          <label htmlFor='verification' className={style.label}>
            <p className={style.labelText}>{t('code')}</p>
            <div className={style.inputsWrapper}>
              {range.map((position) => {
                return (
                  <input
                    ref={(element) => {
                      inputRefs.current[position] = element;
                    }}
                    maxLength={1}
                    autoComplete='off'
                    key={position}
                    id='verification'
                    type='text'
                    inputMode='numeric'
                    onFocus={focusHandler}
                    onBlur={handleInputBlur}
                    onChange={(e) => changeHandler(e, position)}
                    onKeyDown={(e) => handleKeyDown(e, position)}
                    onKeyPress={preventCharacter}
                    className={style.input}
                  />
                );
              })}
            </div>
          </label>
          <div className={style.sendCodeWrapper}>
            <button
              type='button'
              className={style.sendCodeButton}
              disabled={secondsRestoreSendVerifyCode}
              onClick={handleSendCodeAgain}
            >
              {t('send_code_again')}
            </button>
            <div className={style.sendCodeTimer}>
              <TimeCountdown
                seconds={secondsRestoreSendVerifyCode}
                onChange={setSecondsRestoreSendVerifyCode}
              />
            </div>
          </div>
          <Button text={t('verify')} size='large' disabled={!isValid} submit />
        </form>
      </div>
      {isDesktop ? <Copyrights /> : showCopyrights && <Copyrights />}
    </section>
  );
};

export default Verification;
