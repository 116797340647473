import React from 'react';
import { observer } from 'mobx-react';
import Modal from '../../../Modal/Modal';
import style from '../../AppModals.module.scss';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useRootModel } from '../../../../models/RootStore';
import BalanceHistory from '../../../BalanceHistory/BalanceHistory';

const BalanceHistoryModal = () => {
  const { isMobile } = useWindowSize();
  const {
    modal: {
      balanceHistory: { isOpen, close },
    },
  } = useRootModel();

  return (
    !isMobile &&
    isOpen && (
      <Modal handleClose={close} header headerTitle='History' wrapperClassname={style.modal}>
        <ModalBody>
          <BalanceHistory />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(BalanceHistoryModal);
