import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './FlowOfFunds.module.scss';
import { sortByQuantity, SORT_BY_TIME, PERIOD } from '../../../constant/commonConstants';
import { useRootModel } from '../../../models/RootStore';
import useWindowSize from '../../../hooks/useWindowSize';

import Dropdown from '../../../components/ui/Dropdown/Dropdown';
import ButtonGroup from '../../../components/ui/ButtonGroup/ButtonGroup';
import FlowOfFundsContent from './FlowOfFundsContent/FlowOfFundsContent';
import Pagination from '../../../components/ui/Pagination/Pagination';

/**
 * Represents Flow of Funds component
 * @returns {JSX.Element}
 */

const FlowOfFunds = () => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const {
    finance: {
      flowOfFunds: {
        getFlowOfFunds,
        items,
        total,
        period,
        changePeriod,
        setPageSize,
        page,
        pageSize,
        setPage,
      },
    },
  } = useRootModel();

  const handleChangePage = async ({ currentPage }) => {
    await setPage(currentPage - 1);
    getFlowOfFunds();
  };

  const handleChangeSortByQuantity = async (quant) => {
    await setPageSize(quant);
    getFlowOfFunds();
  };

  const handleChangeSortByDate = (time) => {
    Object.entries(PERIOD).forEach(async ([key, value]) => {
      if (t(key) === time) await changePeriod(value);
    });
    getFlowOfFunds();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isDesktop) await setPageSize(20);
      getFlowOfFunds();
    };
    fetchData();
  }, []);

  return (
    <div className={style.flowOfFunds}>
      <div className={style.wrapper}>
        <p className={style.title}>{t('flow')}</p>
        <div className={style.optionsWrapper}>
          <div className={style.options}>
            <p className={style.optionTitle}>{t('show')}</p>
            <Dropdown
              onChange={handleChangeSortByQuantity}
              className={style.entries}
              contentClassName={style.content}
              currentItemWrapperClassName={style.currentItemWrapperClassName}
              value={pageSize}
            >
              {sortByQuantity}
            </Dropdown>
          </div>
          <ButtonGroup
            items={Object.values(SORT_BY_TIME)}
            className={style.buttonGroup}
            activeItem={SORT_BY_TIME[period]}
            onClick={handleChangeSortByDate}
          />
        </div>
      </div>
      <FlowOfFundsContent items={items} />

      {isDesktop && (
        <Pagination
          totalRecords={total}
          pageLimit={pageSize}
          pageNeighbours={2}
          wrapperClassName={style.pagination}
          onPageChanged={handleChangePage}
          currentPage={page + 1}
        />
      )}
    </div>
  );
};

export default observer(FlowOfFunds);
