import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import HTMLReactParser from 'html-react-parser';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import style from './CountryAndCodeDropdown.module.scss';
import countries from '../../../util/countries';
import useOnClick from '../../../hooks/useOnClick';
import useChangeCursor from '../../../hooks/useChangeCursor';
import { ERROR_TEXT_REQUIRED } from '../../../util/validationSchema';
import getAnimationClasses from '../../../util/getAnimationClasses';
import useWindowSize from '../../../hooks/useWindowSize';

import { ReactComponent as ArrowUp } from '../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/common/arrowDown.svg';

/**
 * Represents a dropdown for codes and countries
 * @param {('dialCode' | 'country')} content - dialCode / country
 * @param {function} onChange - onChange handler
 * @param {boolean} showError - if need to show error under dropdown set true
 * @param {string} [error] - error to show
 * @param {string} value - value
 * @returns {JSX.Element}
 */
const CountryAndCodeDropdown = ({
  content = 'country',
  onChange,
  showError = false,
  error,
  value,
  dropdownClassName,
  inputClassName,
  errorClassName,
  onChangeInnerError,
  countryCode,
}) => {
  const { data, defaultValue, filter, getCountryByCountryCode, getCountryByPhone, getObjectData } =
    countries;
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const isCountries = content === 'country';
  const contentToShow = isCountries ? 'country' : 'dialCode';

  const refs = useRef({});
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);

  const [currentItem, setCurrentItem] = useState(defaultValue);
  const [showContent, setShowContent] = useState(false);
  const [filteredItems, setFilteredItems] = useState(data);
  const toggleShowContent = () => {
    setShowContent((prev) => !prev);
    setFilteredItems(data);
  };
  const closeMenu = () => setShowContent(false);
  const [touched, setTouched] = useState(false);

  const currentObj = getObjectData(currentItem);
  const [inputValue, setInputValue] = useState(currentObj ? currentObj[contentToShow] : '');
  const [innerError, setInnerError] = useState('');

  const focusFirstElement = () => refs?.current[0]?.focus();
  const isRightKey = (e) =>
    (isCountries && /^[a-zA-Z-']$/.test(e.key)) ||
    (!isCountries && /^[0-9]$/.test(e.key)) ||
    e.code === 'Space';

  useOnClick(wrapperRef, closeMenu);

  const handleCursorChange = (e, position) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useChangeCursor(e, position, refs, closeMenu);
  };
  const setDefaultItem = () => {
    setCurrentItem(defaultValue);
    setInnerError(ERROR_TEXT_REQUIRED);
    setShowContent(true);
  };

  useEffect(() => {
    setFilteredItems(filter(inputValue, contentToShow));
  }, [inputValue]);

  useEffect(() => {
    if (!value) return;
    const current = isCountries ? getCountryByCountryCode(value) : getCountryByPhone(value);
    const val = getObjectData(current)[contentToShow];

    if (!current || !val) return;
    setCurrentItem(current);
    setInputValue(val);
  }, [value]);

  useEffect(() => {
    if (onChange) onChange(inputValue);
  }, [currentItem]);

  useEffect(() => {
    if (!filteredItems.length) closeMenu();

    const currStateValue = getObjectData(currentItem)[contentToShow];
    if (filteredItems.length === 1 && !currStateValue) {
      const lastFiltered = filteredItems[0];
      setCurrentItem(lastFiltered);
      setInputValue(getObjectData(lastFiltered)[contentToShow]);
      closeMenu();
    }
  }, [filteredItems]);

  useEffect(() => {
    if (onChangeInnerError) onChangeInnerError(innerError);
  }, [innerError]);

  const handleWrapperKeyPress = (e) => {
    // Focus on input if pressed right key
    if (isRightKey(e) && inputRef.current) {
      inputRef.current.focus();
      setCurrentItem(defaultValue);
    }
  };

  const handleWrapperKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        refs.current[0]?.focus();
        e.preventDefault();
        break;

      case 'Enter':
        toggleShowContent();
        if (!showContent) inputRef.current.focus();
        break;

      case 'Escape':
        closeMenu();
        break;
      default:
    }
  };

  const handleInputClick = (e) => {
    if (showContent) e.stopPropagation();
  };

  const handleInputKeyDown = (e) => {
    switch (e.key) {
      case 'Backspace': {
        setInputValue(isCountries ? '' : '+');
        if (isCountries && getObjectData(currentItem)[contentToShow]) {
          setDefaultItem();
          return;
        }
        if (!isCountries && e.target.value.length === 1) {
          e.preventDefault();
          return;
        }
        setDefaultItem();
        e.preventDefault();
        break;
      }
      case 'ArrowDown':
        if (showContent) {
          e.preventDefault();
          e.stopPropagation();
          focusFirstElement();
        }
        break;
      case 'Tab':
      case 'Escape':
        closeMenu();
        break;

      default:
        if (!isRightKey(e)) e.preventDefault();
    }

    if (isRightKey(e) && filteredItems.length === 1) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    setShowContent(true);

    setInnerError(e.target.value.trim() ? '' : ERROR_TEXT_REQUIRED);
    const val = isCountries ? e.target.value : e.target.value.replace(/^(\+?)(\d+)(\+?.*)$/, '+$2');

    if (!isCountries && val.length === 1) setDefaultItem();
    setInputValue(val);
  };

  const handleInputFocus = () => {
    inputRef.current?.select();
    setTouched(true);
    if (isMobile) inputRef.current.scrollIntoView();
  };

  const handleInputBlur = () => {
    if (!currentObj.country) {
      setInnerError(ERROR_TEXT_REQUIRED);
    } else {
      setInnerError('');
    }
  };

  const handleItemChange = (e, val) => {
    console.log(val);

    e.stopPropagation();
    setCurrentItem(val);
    const currentValue = getObjectData(val)[contentToShow];
    setInputValue(currentValue);
    onChange(currentValue);

    setInnerError('');
    closeMenu();
    wrapperRef.current.focus();
  };

  useEffect(() => {
    if (!countryCode) return;

    const currentCountryObject = data.find((item) => item[countryCode]);
    setCurrentItem(currentCountryObject);
    const currentValue = getObjectData(currentCountryObject)[contentToShow];
    setInputValue(currentValue);
    onChange(currentValue);
  }, [countryCode]);

  return (
    <div
      role='button'
      className={cn(
        style.dropdown,
        {
          [style.dialCode]: !isCountries,
        },
        dropdownClassName,
      )}
      tabIndex={0}
      onClick={toggleShowContent}
      onKeyPress={handleWrapperKeyPress}
      onKeyDown={handleWrapperKeyDown}
      ref={wrapperRef}
    >
      <p className={style.value}>
        <i className={cn(style.inputIcon)}>
          {inputValue && currentObj?.flag && HTMLReactParser(currentObj.flag)}
        </i>
        <input
          type='text'
          value={inputValue}
          className={cn(
            style.input,
            isCountries ? style.inputPaddingLarge : style.inputPaddingSmall,
            inputClassName,
            {
              [style.paddingMin]: inputValue === '',
              [style.important]: inputValue === '',
              [style.errorBorder]: error || innerError,
            },
          )}
          onChange={handleInputChange}
          onClick={handleInputClick}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          onFocus={handleInputFocus}
          ref={inputRef}
          inputMode={isCountries ? 'text' : 'numeric'}
          tabIndex={-1}
          autoComplete='nope'
          placeholder={isCountries ? t('select_or_type') : t('code')}
        />

        <button
          type='button'
          tabIndex={-1}
          className={cn(
            style.arrow,
            isCountries ? style.arrowPositionLarge : style.arrowPositionSmall,
          )}
        >
          {showContent ? <ArrowUp /> : <ArrowDown />}
        </button>
      </p>
      <CSSTransition
        timeout={700}
        in={showContent}
        classNames={getAnimationClasses(style)}
        unmountOnExit
      >
        <div
          role='menu'
          className={cn(style.dropdownContent, { [style.showContent]: showContent })}
        >
          {filteredItems
            .filter((item) => item !== currentItem)
            .map((item, idx) => {
              const country = getObjectData(item);
              return (
                <button
                  key={country.country}
                  type='button'
                  ref={(elem) => {
                    refs.current[idx] = elem;
                  }}
                  tabIndex={-1}
                  className={cn(
                    style.item,
                    isCountries ? style.itemPaddingLarge : style.itemPaddingSmall,
                  )}
                  onKeyDown={(e) => handleCursorChange(e, idx)}
                  onClick={(e) => handleItemChange(e, item)}
                >
                  <span className={style.icon}>{HTMLReactParser(country.flag)}</span>
                  <span className={style.title}>{country[contentToShow]}</span>
                </button>
              );
            })}
        </div>
      </CSSTransition>
      {showError && error && touched ? (
        <p className={style.error}>{error}</p>
      ) : (
        innerError && <p className={cn(style.error, errorClassName)}>{innerError}</p>
      )}
    </div>
  );
};

export default CountryAndCodeDropdown;
