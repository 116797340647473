import { useEffect } from 'react';

/**
 * Hook click outside an element
 * @param {Ref} ref - Ref for element
 * @param {function} callback - callback what will be execute
 * @param {boolean} outside - is to listen click outside
 */

const useOnClick = (ref, callback, outside = true) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref || !ref.current) return;
      if (outside && ref.current.contains(event.target)) return;
      if (!outside && !ref.current.contains(event.target)) return;

      if (callback) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, callback, outside]);
};

export default useOnClick;
