import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import style from './ChangePeriodDropdown.module.scss';
import { PERIOD_PRICE_CHANGE } from '../../constant/commonConstants';
import useOnClick from '../../hooks/useOnClick';
import { ReactComponent as Clock } from '../../assets/image/common/clock.svg';
import { ReactComponent as ArrowUp } from '../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../assets/image/common/arrowDown.svg';
import { ReactComponent as Done } from '../../assets/image/common/done.svg';
import { useRootModel } from '../../models/RootStore';

const ChangePeriodDropdown = () => {
  const {
    finance: { period, setActivePeriod },
  } = useRootModel();

  const [showContent, setShowContent] = useState(false);
  const ref = useRef();

  const handleOnClick = () => setShowContent(!showContent);
  const handlePeriodChange = (selectedPeriod) => {
    setActivePeriod(selectedPeriod);
    setShowContent(false);
  };

  useOnClick(ref, () => setShowContent(false));

  return (
    <div className={style.dropdown} ref={ref}>
      <button type='button' className={style.dropdown__button} onClick={handleOnClick}>
        <Clock />
        <p className={style.dropdown__text}>
          {PERIOD_PRICE_CHANGE.find((periodItem) => periodItem.key === period).title}
        </p>
        {showContent ? <ArrowUp /> : <ArrowDown />}
      </button>
      {showContent && (
        <div className={style.dropdown__content}>
          {PERIOD_PRICE_CHANGE.map(({ key }) => {
            return (
              <button
                key={key}
                type='button'
                className={style.dropdown__item}
                onClick={() => handlePeriodChange(key)}
              >
                <p>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
                <i>{key === period && <Done />}</i>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default observer(ChangePeriodDropdown);
