import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import cn from 'classnames';
import style from './ProductsItemDetailHeader.module.scss';
import string from '../../../../util/string';
import { useRootModel } from '../../../../models/RootStore';
import instrumentIcon from '../../../../assets/instrumentIcon';

import TraderSentiment from '../../../../components/TraderSentiment/TraderSentiment';
import FavoritesButton from '../../../../components/ui/FavoritesButton/FavoritesButton';
import PriceHighLowGraph from '../../../../components/PriceHighLowGraph/PriceHighLowGraph';
// eslint-disable-next-line max-len
import ProductsItemDetailPriceValue from '../ProductsItemDetailPriceValue/ProductsItemDetailPriceValue';

/**
 * Represents ProductsItemDetailHeader component
 * @returns {JSX.Element}
 */
const ProductsItemDetailHeader = () => {
  const { t } = useTranslation();
  const {
    finance: { activeItem },
  } = useRootModel();

  const Icon = instrumentIcon[activeItem?.type][activeItem?.s];

  const values = useMemo(
    () => [
      { title: t('for_day'), value: activeItem?.cpd },
      { title: t('for_week'), value: activeItem?.cpw },
      { title: t('for_month'), value: activeItem?.cpm },
      { title: t('for_year'), value: activeItem?.cpy },
    ],
    [activeItem?.cpd, activeItem?.cpw, activeItem?.cpm, activeItem?.cpy],
  );

  return (
    <div className={style.section}>
      <div className={style.topBlock}>
        <div className={style.titleWrapper}>
          <FavoritesButton
            onClick={activeItem?.toggleFavorite}
            isFavorite={activeItem?.favorite}
            isLoading={activeItem?.isLoading}
          />
          <i className={style.icon}>{Icon && <Icon />}</i>
          <div>
            <p className={style.title}>{activeItem?.alias}</p>
            <p className={style.shortName}>({activeItem?.s})</p>
          </div>
        </div>

        <div className={style.wrapper}>
          <div className={style.price}>
            <p
              className={cn(style.priceValue, {
                [style.backRed]: activeItem?.lp < activeItem?.oldPrice,
                [style.backGreen]: activeItem?.lp > activeItem?.oldPrice,
              })}
            >
              {string.formatNumber(activeItem?.lp)}
            </p>
            <p className={style.text}>{t('price')}</p>
          </div>
          <PriceHighLowGraph />
        </div>

        <div className={style.container}>
          {values.map(({ title, value }) => (
            <ProductsItemDetailPriceValue title={title} value={value} key={title} />
          ))}
        </div>
      </div>
      <TraderSentiment className={style.traderSentiment} />
    </div>
  );
};

export default observer(ProductsItemDetailHeader);
