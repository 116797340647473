import { flow, getParent, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../util/error';
import MessagesModel from './MessagesModel';

const { REACT_APP_CHAT } = process.env;

const ChatsItemsModel = types.model('ChatsItemsModel', {
  clientId: types.string,
  createdAt: types.string,
  id: types.number,
  lastText: types.maybeNull(types.string),
  lastTextTime: types.maybeNull(types.string),
  operatorId: types.maybeNull(types.string),
  status: types.string,
  unreadCount: types.string,
});

const ChatModel = types
  .model('ChatModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    items: types.array(ChatsItemsModel),
    messages: types.optional(MessagesModel, {}),
    picture: types.optional(types.string, ''),
  })
  .actions((chat) => ({
    setIsLoading(loading) {
      chat.isLoading = loading;
    },
    setError(err) {
      chat.error = err;
    },
    clearError() {
      chat.error = '';
    },
    setItems(items) {
      chat.items = items;
    },
    getChats: flow(function* getChats() {
      const { setIsLoading, clearError, setError, setItems } = chat;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(`${REACT_APP_CHAT}/chat/clientChats`);
        if (data) {
          setItems(data);
        }
      } catch (e) {
        setError(e.message);
        error.errorHandler(e.message);
      } finally {
        setIsLoading(false);
      }
    }),
    createChat: flow(function* createChat() {
      const { setIsLoading, clearError, setError, setItems } = chat;
      setIsLoading(true);
      clearError();
      try {
        const { userData } = getParent(chat);
        const requestData = {
          clientId: userData?.id,
          status: 'new',
          operatorId: 5217,
        };
        const { data } = yield axios.post(`${REACT_APP_CHAT}/chat`, requestData);
        if (data) {
          setItems(data);
        }
      } catch (e) {
        setError(e.message);
        error.errorHandler(e.message);
      } finally {
        setIsLoading(false);
      }
    }),
    setPicture(src) {
      chat.picture = src;
    },
  }));

export default ChatModel;
