const sortArrayBy = (arr, direction, sortBy) =>
  arr.slice().sort((a, b) => {
    if (direction === 'asc') return a[sortBy] > b[sortBy] ? 1 : -1;

    return a[sortBy] > b[sortBy] ? -1 : 1;
  });

const sortArrayByPeriod = (period, array) => {
  let filteredItemsByPeriod;
  const currentYear = new Date().getFullYear();
  switch (period) {
    case 'WEEK':
      filteredItemsByPeriod = array.filter(
        (item) =>
          new Date() >= new Date(item.openedAt) &&
          new Date(new Date() - 7 * 24 * 3600 * 1000) <= new Date(item.openedAt),
      );
      break;
    case 'MONTH':
      filteredItemsByPeriod = array.filter(
        (item) =>
          new Date().getMonth() === new Date(item.openedAt).getMonth() &&
          currentYear === new Date(item.openedAt).getFullYear(),
      );
      break;
    case 'QUARTER':
      filteredItemsByPeriod = array.filter(
        (item) =>
          new Date(item.openedAt).getMonth() > new Date().getMonth() - 3 &&
          currentYear === new Date(item.openedAt).getFullYear(),
      );
      break;
    default:
      filteredItemsByPeriod = array;
  }

  return filteredItemsByPeriod;
};

export default {
  sortArrayBy,
  sortArrayByPeriod,
};
