import { flow, getParent, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../util/error';
import HistoryItem from './TradesModels/HistoryItemModel';
import PeriodForTables from '../common/PeriodForTables';
import { formatStringToNumberWithoutSpace, formatStringWithoutComa } from '../../util/string';
import { MAX_AMOUNT_RANGE } from '../../constant/calculationConstants';

const CurrentTradeModel = types
  .model('CurrentTradeModel', {
    id: types.optional(types.number, 1),
  })
  .actions((currentTrade) => ({
    setId(id) {
      currentTrade.id = id;
    },
  }));

const TradeCommission = types.model('TradeCommission', {
  forMakingOpsCmsnAmount: types.maybeNull(types.number),
  swapAccrualAmount: types.optional(types.number, 0),
  totalAmount: types.optional(types.number, 0),
});

const InstrumentSettingsItem = types.model('InstrumentSettingsItem', {
  id: types.optional(types.number, 0),
  symbolCode: types.optional(types.string, ''),
  icon: types.maybeNull(types.string),
  maxMultiplier: types.optional(types.integer, 10),
  overnightCommission: types.maybeNull(types.number),
  swapBuyCommission: types.maybeNull(types.number),
  swapSellCommission: types.maybeNull(types.number),
  openCommission: types.optional(types.number, 0),
  closeCommission: types.optional(types.number, 0),
  minAmountRange: types.optional(types.number, 0),
  maxAmountRange: types.optional(types.number, 0),
  limitBuyStopLoss: types.optional(types.number, 0),
  limitSellStopLoss: types.optional(types.number, 0),
  sellAtPricePending: types.optional(types.number, 0),
  lossAtPricePending: types.optional(types.number, 0),
  autoClose: types.optional(types.number, 0),
});

const AmountModel = types
  .model('AmountModel', {
    amount: types.optional(types.number, 0),
    amountView: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setAmount(amount) {
      self.amount = formatStringToNumberWithoutSpace(amount);
      self.amountView = amount;
    },
  }));

const TradeModel = types
  .model('TradeModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    current: types.optional(CurrentTradeModel, {}),
    amountData: types.optional(AmountModel, {}),
    multiplier: types.optional(types.number, 1),
    maxMultiplier: types.optional(types.integer, 10),
    operation: types.optional(types.string, 'BUY'),
    pendingPrice: types.optional(types.string, ''),
    stopLoss: types.optional(types.string, ''),
    takeProfit: types.optional(types.string, ''),
    increaseAmount: types.optional(types.string, ''),
    historyItems: types.array(HistoryItem),
    period: PeriodForTables,
    commission: types.optional(TradeCommission, {}),
    settings: types.array(InstrumentSettingsItem),
  })
  .actions((trade) => ({
    setError(err) {
      trade.error = err;
    },
    clearError() {
      trade.error = '';
    },
    setIsLoading(loading) {
      trade.isLoading = loading;
    },
    setOperation(operation) {
      trade.operation = operation;
    },
    setMultiplier(multiplier) {
      trade.multiplier = multiplier;
    },
    setLoss(amount) {
      trade.stopLoss = amount;
    },
    setProfit(amount) {
      trade.takeProfit = amount;
    },
    setPendingPrice(amount) {
      trade.pendingPrice = amount;
    },
    setIncreaseAmount(amount) {
      trade.increaseAmount = amount;
    },
    changePeriod(period) {
      trade.period = period;
    },
    setCommission(data) {
      trade.commission = data;
    },
    setInitialStateForTrade() {
      trade.amountData.setAmount('');
      trade.multiplier = 1;
      trade.pendingPrice = '';
      trade.takeProfit = '';
      trade.stopLoss = '';
    },
    setInitialLimits() {
      trade.takeProfit = '';
      trade.stopLoss = '';
    },
    openTrade: flow(function* openTrade() {
      const { activeSymbol } = getParent(trade);
      const {
        setIsLoading,
        clearError,
        setError,
        amountData: { amount },
        multiplier,
        pendingPrice,
        operation,
        stopLoss,
        takeProfit,
      } = trade;
      setIsLoading(true);
      clearError();
      try {
        const pendingPriceToNumber = Number(formatStringWithoutComa(pendingPrice));
        const stopLossToNumber = formatStringToNumberWithoutSpace(stopLoss);
        const takeProfitToNumber = formatStringToNumberWithoutSpace(takeProfit);

        const tradeData = {
          amount,
          multiplier,
          operation,
          symbol: activeSymbol,
          pendingPrice: pendingPriceToNumber || null,
          stopLoss: stopLossToNumber || null,
          takeProfit: takeProfitToNumber || null,
        };

        yield axios.post(`/services/trading/api/trades/open`, tradeData);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    closeTrade: flow(function* closeTrade(id) {
      const { setIsLoading, clearError, setError } = trade;
      setIsLoading(true);
      clearError();
      try {
        yield axios.post(`/services/trading/api/trades/${id}/close`);
        const {
          activeTrades: { getActiveTrades },
        } = getParent(trade);
        getActiveTrades();
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    cancelTrade: flow(function* cancelTrade(id) {
      const { setIsLoading, setError, clearError } = trade;
      setIsLoading(true);
      clearError();
      try {
        yield axios.post(`/services/trading/api/trades/${id}/cancel`);
        const {
          pendingTrades: { removeItem },
        } = getParent(trade);
        removeItem(id);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    closeTrades: flow(function* closeTrades(option = 'ALL') {
      const { setIsLoading, clearError, setError } = trade;
      setIsLoading(true);
      clearError();
      try {
        yield axios.post(`/services/trading/api/trades/close?profit-status=${option}`);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    changeLimits: flow(function* changeLimit(id) {
      const { clearError, setError, stopLoss, takeProfit } = trade;
      clearError();
      try {
        const stopLossToNumber = formatStringToNumberWithoutSpace(stopLoss);
        const takeProfitToNumber = formatStringToNumberWithoutSpace(takeProfit);

        yield axios.patch(`/services/trading/api/trades/${id}`, {
          stopLoss: stopLossToNumber || null,
          takeProfit: takeProfitToNumber || null,
        });
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      }
    }),
    increase: flow(function* increase(id) {
      const { setIsLoading, clearError, setError, increaseAmount } = trade;
      setIsLoading(true);
      clearError();
      try {
        const increaseAmountToNumber = formatStringToNumberWithoutSpace(increaseAmount);

        yield axios.post(`/services/trading/api/trades/${id}/increase`, {
          amount: increaseAmountToNumber,
        });
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    getHistory: flow(function* getHistory(id) {
      const { setIsLoading, clearError, setError, period } = trade;
      setIsLoading(true);
      clearError();
      try {
        const params = {
          period,
        };
        const { data } = yield axios.get(`/services/trading/api/trades/${id}/history`, {
          params,
        });
        trade.historyItems = data;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    getTradeSettings: flow(function* getTradeSettings() {
      const { activeSymbol } = getParent(trade);
      const { setIsLoading, clearError, setError } = trade;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(
          `/services/trading/api/instruments/mask/${activeSymbol}/settings`,
        );
        trade.settings = data;
        trade.maxMultiplier = data[0].maxMultiplier;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    getCommission: flow(function* getCommission(id) {
      const { setIsLoading, clearError, setError, setCommission } = trade;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(`/services/trading/api/trades/${id}/commissions`);

        setCommission(data);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
  }))
  .views((trade) => ({
    get currentAmountSettings() {
      const {
        amountData: { amount },
        settings,
      } = trade;

      if (amount >= MAX_AMOUNT_RANGE) {
        return settings.find(({ maxAmountRange }) => maxAmountRange === MAX_AMOUNT_RANGE);
      }

      return settings.find(
        ({ minAmountRange, maxAmountRange }) =>
          minAmountRange <= amount && amount <= maxAmountRange,
      );
    },
    get limitBuyStopLoss() {
      const { currentAmountSettings } = trade;

      return currentAmountSettings?.limitBuyStopLoss;
    },
    get limitSellStopLoss() {
      const { currentAmountSettings } = trade;

      return currentAmountSettings?.limitSellStopLoss;
    },
    get openTradeCommission() {
      const { currentAmountSettings } = trade;

      return currentAmountSettings?.openCommission || 0;
    },
    get sellAtPricePending() {
      const { currentAmountSettings } = trade;

      return currentAmountSettings?.sellAtPricePending;
    },
    get lossAtPricePending() {
      const { currentAmountSettings } = trade;

      return currentAmountSettings?.lossAtPricePending;
    },
  }));

export default TradeModel;
