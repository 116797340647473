import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import style from './HeaderNotifications.module.scss';
import useOnClick from '../../../hooks/useOnClick';
import emptyFunction from '../../../util/emptyFunction';
import { useRootModel } from '../../../models/RootStore';

// eslint-disable-next-line max-len
import { ReactComponent as NotificationsIcon } from '../../../assets/image/common/notifications.svg';
import Notifications from '../../Notifications/Notifications';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * Represents HeaderNotifications
 * @returns {JSX.Element}
 */

const HeaderNotifications = () => {
  const { isMobile } = useWindowSize();
  const {
    settings: { userData },
    user: {
      notifications: { getNotifications, getNotificationsUpdates, amountUnread },
    },
    modal: {
      notifications: { isOpen },
    },
  } = useRootModel();

  useEffect(() => {
    getNotifications();
    getNotificationsUpdates();
  }, [userData?.user]);

  const [showNotifications, setShowNotifications] = useState(false);
  const toggleShowNotifications = () => setShowNotifications((prev) => !prev);
  const closeSnowNotifications = () => setShowNotifications(false);

  const notificationsRef = useRef();
  useOnClick(notificationsRef, isOpen ? emptyFunction : closeSnowNotifications);

  return (
    <div className={style.notifications}>
      <button
        type='button'
        className={cn(style.notifications__button, {
          [style.withModal]: showNotifications,
        })}
        onClick={toggleShowNotifications}
      >
        <i className={style.notificationsIconWrapper}>
          <NotificationsIcon />
          {amountUnread > 0 && <span className={style.badge} />}
        </i>
        {!isMobile && amountUnread > 0 && <span>{amountUnread > 99 ? '99+' : amountUnread}</span>}
      </button>
      {showNotifications && <Notifications wrapperRef={notificationsRef} />}
    </div>
  );
};

export default observer(HeaderNotifications);
