import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';
import QRCode from 'react-qr-code';
import style from './DepositMoney.module.scss';
import useWindowSize from '../../hooks/useWindowSize';

import { ReactComponent as Copy } from '../../assets/image/common/copy.svg';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { useRootModel } from '../../models/RootStore';
import { balanceCurrency } from '../../constant/walletConstants';

/**
 * Represents Deposit money component
 * @returns {JSX.Element}
 * @param {CSSStyleRule} className - className for QrBlock
 */

const DepositMoney = ({ className }) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const [isCopy, setIsCopy] = useState(false);
  const inputAreaRef = useRef(null);
  const {
    payment: {
      depositCurrency,
      setDepositCurrency,
      deposit,
      depositAddress: { address },
    },
  } = useRootModel();

  const copyCodeToClipboard = async () => {
    await navigator.clipboard.writeText(address);
    setIsCopy(true);

    setTimeout(() => setIsCopy(false), 3000);
  };

  useEffect(() => {
    deposit();
  }, [deposit]);

  return (
    <div className={style.deposit}>
      <div>
        <CurrencyInput currency={depositCurrency} onChange={setDepositCurrency} />
        <p className={style.commission}>5% {t('commission')}</p>
      </div>
      <div className={style.walletBlock}>
        <p className={style.textLabel}>
          {t('your')} {balanceCurrency[depositCurrency]?.title} {t('deposit address')}
        </p>
        <div style={{ position: 'relative' }}>
          <div className={style.depositInput}>
            <input
              className={style.wallet}
              type='text'
              value={address}
              readOnly
              ref={inputAreaRef}
            />
            {!isMobile && (
              <button type='button' className={style.copyButton} onClick={copyCodeToClipboard}>
                <Copy className={style.iconCopy} />
              </button>
            )}
          </div>
          <p className={style.tip}>{isCopy && t('copied')}</p>
        </div>
      </div>
      <div className={cn(style.qrBlock, className)}>
        <QRCode size={113} value={address} />
        <p className={style.qrText}>
          {t('only_send')} {balanceCurrency[depositCurrency]?.title} {t('deposit_confirmation')}
        </p>
      </div>
    </div>
  );
};

export default observer(DepositMoney);
