import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import style from './Empty.module.scss';
import { ReactComponent as EmptyBox } from '../../assets/image/common/emptyBox.svg';

/**
 * Represents Empty component
 * @param {JSX.Element} children - children
 * @param {CSSStyleRule} className - wrapper className
 * @returns {JSX.Element}
 */
const Empty = ({ children, className }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(style.empty, className)}>
      <EmptyBox />
      <p className={style.title}>{t('not_found')}</p>
      {children}
    </div>
  );
};

export default Empty;
