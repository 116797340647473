import React, { useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import style from './InstrumentInfo.module.scss';
import { useRootModel } from '../../../models/RootStore';
import instrumentIcon from '../../../assets/instrumentIcon';

import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';
import FavoritesButton from '../../../components/ui/FavoritesButton/FavoritesButton';
import { ReactComponent as ArrowMenu } from '../../../assets/image/common/arrowMenuTablet.svg';
import { ReactComponent as ArrowMenuUp } from '../../../assets/image/common/arrowMenuTabletUp.svg';
import InstrumentsLeftSideBlock from '../InstrumentsLeftSideBlock/InstrumentsLeftSideBlock';
import useWindowSize from '../../../hooks/useWindowSize';
import PriceChangeBlock from '../../../components/PriceChangeBlock/PriceChangeBlock';

/** Represents InstrumentInfo component
 * @returns {JSX.Element}
 */

const InstrumentInfo = () => {
  const { isLaptop } = useWindowSize();
  const {
    user: { isAuthorized },
    finance: { activeItem },
  } = useRootModel();

  const [showMenuTablet, setShowMenuTablet] = useState(false);

  const instType = instrumentIcon[activeItem?.type];
  const Icon = instType ? instType[activeItem?.s] : null;

  const openMenuButtonTablet = () => {
    return (
      <button
        type='button'
        className={style.menu}
        onClick={() => setShowMenuTablet(!showMenuTablet)}
      >
        <div className={style.menu__wrapper}>
          {isAuthorized && (
            <FavoritesButton
              onClick={activeItem?.toggleFavorite}
              isFavorite={activeItem?.favorite}
              isLoading={activeItem?.isLoading}
            />
          )}
          <i className={style.icon}>{Icon && <Icon />}</i>
          <div className={style.menu__label}>
            <p>{activeItem?.s}</p>
            <p className={style.menu__text}>{activeItem?.alias}</p>
          </div>
        </div>
        <i className={style.menu__icon}>{showMenuTablet ? <ArrowMenuUp /> : <ArrowMenu />}</i>
      </button>
    );
  };

  return (
    <ErrorBoundary className={style.error}>
      <div className={style.priceBlock}>
        {isLaptop ? (
          openMenuButtonTablet()
        ) : (
          <div className={style.menu__wrapper}>
            {isAuthorized && (
              <FavoritesButton
                onClick={activeItem?.toggleFavorite}
                isFavorite={activeItem?.favorite}
                isLoading={activeItem?.isLoading}
              />
            )}
            <i className={style.icon}>{Icon && <Icon />}</i>
            <div className={cn(style.gridColumn, style.menu__label)}>
              <p>{activeItem?.s}</p>
              <p className={style.menu__text}>{activeItem?.alias}</p>
            </div>
          </div>
        )}

        <PriceChangeBlock />
      </div>
      {showMenuTablet && <InstrumentsLeftSideBlock onClick={() => setShowMenuTablet(false)} />}
    </ErrorBoundary>
  );
};

export default observer(InstrumentInfo);
