import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './LoyaltyRulesMobile.module.scss';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';

/**
 * Represents Loyalty rules component in Mobile version
 * @param {function} handleClose - handle close Modal button
 * @returns {JSX.Element}
 */

const LoyaltyRulesMobile = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <section className={style.loyaltyRulesMobile}>
      <button type='button' onClick={handleClose} className={style.closeButton}>
        <CloseIcon />
      </button>
      <h2 className={style.rulesTitle}>{t('loyalty_rules')}</h2>
      <p className={style.rulesText}>{t('loyalty_program')}</p>

      <article className={style.questionBlock}>
        <h4 className={style.title}>{t('how_to_quality')}</h4>
        <div className={style.text}>
          <p>{t('status_determined')}</p>
          <ul className={style.list}>
            <li>
              <span>&bull;</span>
              {t('status_determined_first')}
            </li>
            <li>
              <span>&bull;</span>
              {t('status_determined_second')}
            </li>
          </ul>
          <br />
          <p className={style.text}>{t('status_qualifies')}</p>
          <ul className={style.list}>
            <li>
              <span>&bull;</span>
              {t('status_qualifies_first')}
            </li>
            <li>
              <span>&bull;</span>
              {t('status_qualifies_second')}
            </li>
          </ul>
          <p className={style.text}>{t('status_qualifies_text')}</p>
        </div>
      </article>

      <article className={style.questionBlock}>
        <h4 className={style.title}>{t('validity_period')}</h4>
        <div className={style.text}>
          <p>{t('validity_period_title_first')}</p>
          <p>{t('validity_period_title_second')}</p>
          <ul className={style.list}>
            <li>
              <span>&bull;</span>
              {t('validity_period_first')}
            </li>
            <li>
              <span>&bull;</span>
              {t('validity_period_second')}
            </li>
          </ul>
          <p>{t('validity_period_text')}</p>
        </div>
      </article>
    </section>
  );
};

export default LoyaltyRulesMobile;
