import React from 'react';
import { observer } from 'mobx-react';

import style from './FlowOfFundsList.module.scss';

import FlowOfFundsItem from './FlowOfFundsItem/FlowOfFundsItem';
import NoTrade from '../../../../../components/NoTrade/NoTrade';

/**
 * Represents flow of funds table
 * @returns {JSX.Element}
 */

const FlowOfFundsList = ({ items, tableRef }) => {
  return (
    <div className={style.body} ref={tableRef}>
      {items?.length > 0 ? (
        items.map(({ amount, balance, date, operation, tradeCodeId }, i) => (
          <FlowOfFundsItem
            key={`${date + i}`}
            tradeCodeId={tradeCodeId}
            date={date}
            operation={operation}
            balance={balance}
            amount={amount}
          />
        ))
      ) : (
        <NoTrade />
      )}
    </div>
  );
};

export default observer(FlowOfFundsList);
