import React from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import style from '../../AppModals.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import ActiveTradesContent from './ActiveTradesContent/ActiveTradesContent';
import instrumentIcon from '../../../../assets/instrumentIcon';

/**
 * Represents ActiveTradesModal component
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveTradeModal = () => {
  const { t } = useTranslation();
  const {
    modal: {
      activeTrade: { isOpen, close },
    },
    finance: {
      activeTrades: { activeTrade },
    },
  } = useRootModel();

  const Icon =
    instrumentIcon[activeTrade?.symbol?.type || 'crypto'][activeTrade?.symbol?.s || 'AAVEUSD'] ||
    null;

  const headerTitle = () => {
    return (
      <span className={style.headerWrapper}>
        <span className={style.symbolWrapper}>
          <i className={style.instrumentIcon}>{Icon && <Icon />}</i>
          {activeTrade?.symbol?.s}
        </span>
        <span className={style.statusWrapper}>
          <span className={style.activeCircle} />
          <span className={cn(style.statusWrapper__text, style.statusWrapper__active)}>
            {t('active')}
          </span>
        </span>
      </span>
    );
  };

  return (
    isOpen && (
      <Modal
        wrapperClassname={style.activeModal}
        handleClose={close}
        header
        headerTitle={headerTitle()}
      >
        <ModalBody>
          <ActiveTradesContent />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(ActiveTradeModal);
