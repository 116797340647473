import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import style from '../BalancePage.module.scss';
import WithdrawMoney from '../../../../components/WithdrawMoney/WithdrawMoney';
import { ReactComponent as BackIcon } from '../../../../assets/image/common/back.svg';

/**
 * Represents Withdraw Money in Mobile version
 * @returns {JSX.Element}
 */

const WithdrawMoneyMobile = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <Link to='/balance' className={style.back}>
          <BackIcon />
        </Link>
        <p className={style.title}>{t('withdrawal')}</p>
      </div>
      <WithdrawMoney />
    </div>
  );
};

export default WithdrawMoneyMobile;
