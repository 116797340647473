import React from 'react';
import cn from 'classnames';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BingoCoin } from '../../../../assets/image/common/bingo-coin.svg';

import styles from './StatusInfoModalBody.module.scss';

/**
 * Represents loyalty status modal body component [tablet]
 * @returns {JSX.Element}
 */

const StatusInfoModalBody = ({ type, requiredBalance, requiredVolume }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick = (btn) => {
    if (btn === 'deposit') navigate('/balance');
    if (btn === 'trade') navigate('/');
  };
  return (
    <div className={styles.statusInfoModal}>
      <p className={styles.text}>
        {t('qualify')} <span className={styles.capitalize}>{type}</span>{' '}
        <span className={styles.lowercase}>{t('status')}</span>, {t('meet_conditions')}:
      </p>
      <div className={styles.conditions}>
        <span className={styles.text}>1. {t('balance_required')}:</span>
        <BingoCoin className={styles.icon} />
        <span className={styles.sumText}>{requiredBalance}</span>
      </div>
      <div className={styles.conditions}>
        <span className={styles.text}>2. {t('volume_required')}:</span>
        <BingoCoin className={styles.icon} />
        <span className={styles.sumText}>{requiredVolume}</span>
      </div>
      <div className={styles.btnWrap}>
        <div className={styles.depositWrap}>
          <button
            onClick={() => onClick('deposit')}
            className={cn(styles.modalBtn, styles.deposit, styles[type])}
            type='button'
          >
            {t('deposit')}
          </button>
        </div>
        <div className={styles.tradeWrap}>
          <button
            onClick={() => onClick('trade')}
            className={cn(styles.modalBtn, styles[type])}
            type='button'
          >
            {t('trade')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusInfoModalBody;
