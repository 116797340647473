import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './InstrumentsContentItem.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import emptyFunction from '../../../../../util/emptyFunction';
import instrumentIcon from '../../../../../assets/instrumentIcon';
import string from '../../../../../util/string';

/**
 * Represents InstrumentsContentItem component
 * @param {string} alias - Instrument alias
 * @param {string} symbol - Instrument symbol
 * @param {number} lastPrice - Instrument lastPrice
 * @param {number} changePerDay - change per day (percent)
 * @param {number} oldPrice - Instrument oldPrice
 * @param {string} type - Instrument type
 * @param {function} onClick - onClick handler
 * @returns {JSX.Element}
 */
const InstrumentsContentItem = ({
  alias = '',
  symbol,
  lastPrice,
  changePerDay = 0,
  oldPrice,
  type,
  onClick = emptyFunction,
}) => {
  const {
    finance: { activeSymbol },
  } = useRootModel();

  const instType = instrumentIcon[type];
  const Icon = instType ? instrumentIcon[type][symbol] : null;
  const [firstPair, secondPair] = alias.split('/');
  return (
    <div
      role='button'
      tabIndex={-1}
      className={cn(style.item, { [style.active]: activeSymbol === symbol })}
      key={alias}
      onClick={onClick}
      onKeyDown={emptyFunction}
    >
      <div className={style.title}>
        <i className={style.instrumentIcon}>{Icon && <Icon />}</i>
        <p>
          <span>{firstPair}</span>
          {secondPair && <span className={style.secondPair}>/{secondPair}</span>}
        </p>
      </div>
      <p
        className={cn(style.price, {
          [style.backRed]: lastPrice < oldPrice,
          [style.backGreen]: lastPrice > oldPrice,
        })}
      >
        {string.formatNumber(lastPrice)}
      </p>
      <p
        className={cn(style.percent, {
          [style.colorDanger]: changePerDay < 0,
          [style.colorSuccess]: changePerDay > 0,
        })}
      >
        {string.formatPercent(changePerDay)}
      </p>
    </div>
  );
};

export default observer(InstrumentsContentItem);
