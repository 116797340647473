import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import style from '../BalancePage.module.scss';
import DepositMoney from '../../../../components/DepositMoney/DepositMoney';
import { ReactComponent as BackIcon } from '../../../../assets/image/common/back.svg';

/**
 * Represents Deposit Money component in Mobile version
 * @returns {JSX.Element}
 */

const DepositMoneyMobile = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <Link to='/balance' className={style.back}>
          <BackIcon />
        </Link>
        <p className={style.title}>{t('deposit')}</p>
      </div>
      <DepositMoney />
    </div>
  );
};

export default DepositMoneyMobile;
