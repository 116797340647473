import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './InstrumentsMobile.module.scss';
import instrumentIcon from '../../../../assets/instrumentIcon';
import { useRootModel } from '../../../../models/RootStore';

import TableTitle from '../../../../components/ui/Table/TableTitle/TableTitle';
import InstrumentsMobileItem from './InstrumentsMobileItem/InstrumentsMobileItem';

/**
 * Represents Markets table in Mobile version
 * @param {string} category - instruments category
 * @returns {JSX.Element}
 */

const InstrumentsMobile = ({ category }) => {
  const { t } = useTranslation();
  const {
    finance: {
      getData,
      getUpdateData,
      closeDataStream,
      sortedData,
      setSortBy,
      setSortDirection,
      sortDirection,
      switchSortDirection,
      sortBy,
    },
  } = useRootModel();

  useEffect(() => {
    const fetchData = async () => {
      await getData(category);
    };
    fetchData();
    getUpdateData(category);
    return () => {
      closeDataStream();
    };
  }, [category]);

  return (
    <>
      <div className={style.header}>
        <TableTitle
          title={t('instruments')}
          isActive={sortBy === 'alias'}
          handleOnClick={setSortBy}
          labelKey='alias'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />
        <p className={style.headerTitle}>{t('daily')}</p>
      </div>
      {sortedData.map(
        ({
          alias: title,
          s,
          lp: price,
          cpd: day,
          oldPrice,
          type,
          favorite,
          toggleFavorite,
          isLoading,
        }) => {
          const instType = instrumentIcon[type];
          const Icon = instType ? instrumentIcon[type][s] : null;
          return (
            <InstrumentsMobileItem
              key={title}
              title={title}
              s={s}
              price={price}
              day={day}
              oldPrice={oldPrice}
              type={type}
              icon={Icon}
              isFavorite={favorite}
              toggleFavorite={toggleFavorite}
              isLoading={isLoading}
            />
          );
        },
      )}
    </>
  );
};

export default observer(InstrumentsMobile);
