import React from 'react';
import { observer } from 'mobx-react';

import style from '../../AppModals.module.scss';

import { useRootModel } from '../../../../models/RootStore';
import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import CloseTradeContentMobile from './CloseTradeContentMobile/CloseTradeContentMobile';

const CloseTradeModalMobile = () => {
  const {
    modal: {
      closeTradeMobile: { isOpen, close },
    },
  } = useRootModel();

  return (
    isOpen && (
      <Modal wrapperClassname={style.modalWrapper} handleClose={close}>
        <ModalBody className={style.modal}>
          <CloseTradeContentMobile handleClose={close} />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(CloseTradeModalMobile);
