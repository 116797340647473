import React from 'react';
import style from './NotificationsList.module.scss';
import NotificationItem from './NotificationItem/NotificationItem';

/**
 * Represents list of notifications
 * @param {Array<NotificationItem>} notifications - notifications array
 * @param {function} handleSwitch - switch handler
 * @returns {JSX.Element}
 */

const NotificationsList = ({ notifications, handleSwitch }) => {
  return (
    <div className={style.notificationsList}>
      {notifications.map(({ id, title, status, attributeName }) => (
        <NotificationItem
          key={id}
          id={id}
          title={title}
          attributeName={attributeName}
          status={status}
          handleSwitch={handleSwitch}
        />
      ))}
    </div>
  );
};

export default NotificationsList;
