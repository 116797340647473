import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import style from './FAQ.module.scss';

import FaqItem from './FAQItem/FAQItem';
import Button from '../../components/ui/Button/Button';
import Modal from '../../components/Modal/Modal';
import ModalBody from '../../components/Modal/ModalBody/ModalBody';
import Question from './Question/Question';
import useWindowSize from '../../hooks/useWindowSize';

/**
 * Represents FAQ component
 * @returns {JSX.Element}
 */
const Faq = () => {
  const { t } = useTranslation();
  const items = [
    {
      id: 1,
      title: t('how_reset'),
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
    },
    {
      id: 2,
      title: t('after_change'),
      text:
        'If you change your password, you’ll be signed out everywhere except:\n' +
        "•‎ Devices you use to verify that it's you when you sign in.\n" +
        "•‎ Devices with third-party apps that you've given account access.",
    },
    {
      id: 3,
      title: t('how_reset'),
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ' +
        'A assumenda aut cumque fugiat modi molestias natus' +
        'necessitatibus numquam praesentium quisquam!',
    },
    {
      id: 4,
      title: t('how_reset'),
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ' +
        'A assumenda aut cumque fugiat modi molestias natus' +
        'necessitatibus numquam praesentium quisquam!',
    },
    {
      id: 5,
      title: t('how_reset'),
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ' +
        'A assumenda aut cumque fugiat modi molestias natus' +
        'necessitatibus numquam praesentium quisquam!',
    },
    {
      id: 6,
      title: t('how_reset'),
      text:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. ' +
        'A assumenda aut cumque fugiat modi molestias natus' +
        'necessitatibus numquam praesentium quisquam!',
    },
  ];

  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleSubmitRequest = () => {
    if (!isMobile) {
      openModal();
      return;
    }
    navigate('/support/question');
  };

  return (
    <div className={style.faq}>
      {items.map(({ id, title, text }) => (
        <FaqItem key={id} title={title} text={text} />
      ))}
      <p className={style.request}>{t('cant_find_answer')}</p>
      <Button
        color='primary'
        size='medium'
        text={t('submit_request')}
        onClick={handleSubmitRequest}
        className={style.submitRequestButton}
      />

      {showModal && (
        <Modal
          wrapperClassname={style.modal}
          handleClose={closeModal}
          header
          headerTitle={t('contact_client_department')}
        >
          <ModalBody className={style.modalBody}>
            <Question closeModal={closeModal} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default Faq;
