import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Route, Routes, useLocation } from 'react-router-dom';

import style from './MainApp.module.scss';

import { PAGES_WITHOUT_SIDEBAR } from '../constant/commonConstants';
import ErrorBoundary from '../components/Error/ErrorBoundary/ErrorBoundary';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import { useRootModel } from '../models/RootStore';
import useWindowSize from '../hooks/useWindowSize';

import Sidebar from '../components/Sidebar/Sidebar';
import Chat from '../components/Chat/Containers/Chat';
import Main from '../pages/Main/Main';
import Footer from '../components/Footer/Footer';
import MobileApp from '../MobileApp/MobileApp';
import RightSideBlock from '../components/RightSideBlock/RightSideBlock';
import Trades from '../pages/Trades/Trades';
import StatusInfo from '../pages/StatusInfo/StatusInfo';
import Balance from '../pages/Balance/Balance';
import Profile from '../pages/Profile/Profile';
import Documents from '../pages/Profile/Documents/Documents';
import FooterInfo from '../pages/FooterInfo/FooterInfo';
import Support from '../pages/Support/Support';
import Question from '../pages/Support/Question/Question';
import ExchangeSchedule from '../pages/ExchangeSchedule/ExchangeSchedule';
import NotFound from '../pages/NotFound/NotFound';
// eslint-disable-next-line max-len
import RightSideBlockNotAuthorized from '../components/RightSideBlockNotAuthorized/RightSideBlockNotAuthorized';
import Markets from '../pages/Markets/Markets';
import ProductsItemDetail from '../pages/Markets/ProductsItemDetail/ProductsItemDetail';

/**
 * Main App component
 * @returns {JSX.Element}
 */

const MainApp = () => {
  const { isMobile, isDesktop } = useWindowSize();
  const { pathname } = useLocation();
  const {
    user: { isAuthorized, userData, isNeedSessionStop },
    finance: {
      activeTrades: { getActiveTrades, closeDataStreamTrades },
    },
  } = useRootModel();

  const isHeader = !PAGES_WITHOUT_SIDEBAR.some((page) => pathname.includes(page));
  const isSidebar = isAuthorized && !PAGES_WITHOUT_SIDEBAR.some((page) => pathname.includes(page));
  const isPathname =
    pathname.includes('/trades') ||
    pathname.includes('/markets') ||
    pathname === '/' ||
    pathname === '/favorites' ||
    pathname === '/balance' ||
    pathname === '/profile';

  useEffect(() => {
    const getBalance = () => {
      if (userData) userData.getBalance();
    };
    getBalance();
    let interval = null;

    if (!isNeedSessionStop) interval = setInterval(getBalance, 1000 * 60 * 30);
    return () => {
      clearInterval(interval);
    };
  }, [isNeedSessionStop, userData]);

  useEffect(() => {
    getActiveTrades();

    return () => {
      closeDataStreamTrades();
    };
  }, [closeDataStreamTrades, getActiveTrades]);

  return isMobile ? (
    <MobileApp />
  ) : (
    <>
      <ErrorBoundary>
        {isAuthorized && <Chat />}
        <main className={style.main}>
          <Routes>
            <Route path='/' element={<Main />} />
            <Route element={<PrivateRoute />}>
              <Route path='/trades/:type' element={<Trades />} />
              <Route path='/markets' element={<Markets />} />
              <Route path='/markets/:category/:symbol' element={<ProductsItemDetail />} />
              <Route path='/profile/status' element={<StatusInfo />} />
              <Route path='balance' element={<Balance />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/profile/documents' element={<Documents />} />
            </Route>

            <Route path='/info/:type' element={<FooterInfo />} />
            <Route path='/support' element={<Support />} />
            <Route path='/support/question' element={<Question />} />
            <Route path='/schedule' element={<ExchangeSchedule />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          {!isAuthorized && isPathname && isDesktop && <RightSideBlockNotAuthorized />}
          {isAuthorized && isDesktop && <RightSideBlock />}
        </main>
      </ErrorBoundary>
      {isHeader && <Footer />}
      {isSidebar && !isDesktop && <Sidebar />}
    </>
  );
};

export default observer(MainApp);
