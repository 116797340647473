import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from '../../AppModals.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import IncreaseTradeContent from './IncreaseTradeContent/IncreaseTradeContent';
import { ReactComponent as Tip } from '../../../../assets/image/common/tip.svg';

/**
 * Represents IncreaseModal component
 * @returns {JSX.Element}
 */
const IncreaseModal = () => {
  const { t } = useTranslation();
  const {
    modal: {
      increaseTrade: { isOpen, close },
    },
  } = useRootModel();

  const headerTitle = () => {
    return (
      <div className={style.header}>
        <p>{t('increase_trade')}</p>
        <div className={style.tipBlock}>
          <Tip />
          <p className={style.tipContent}>
            {t('you_can_increase')} <br />
            {t('increase_speed')} <br />
            {t('avoid_loss')} <br />
            {t('increasing_your_trade')}
          </p>
        </div>
      </div>
    );
  };

  return (
    isOpen && (
      <Modal
        wrapperClassname={style.closeModal}
        handleClose={close}
        header
        headerTitle={headerTitle()}
      >
        <ModalBody>
          <IncreaseTradeContent />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(IncreaseModal);
