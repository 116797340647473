import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './DemoAccount.module.scss';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';
import emptyFunction from '../../../util/emptyFunction';

import { ReactComponent as Demo } from '../../../assets/image/common/demo.svg';
import { ReactComponent as Real } from '../../../assets/image/common/real.svg';
import { ReactComponent as Done } from '../../../assets/image/common/doneArr.svg';
import { ReactComponent as Hat } from '../../../assets/image/common/hat.svg';
import { ReactComponent as ArrDown } from '../../../assets/image/common/arrowDown.svg';
import { ReactComponent as ArrUp } from '../../../assets/image/common/arrowUp.svg';

import Dropdown from '../../ui/Dropdown/Dropdown';

/**
 * Represents a Demo AccountSettings block on Main Page.
 * @returns {JSX.Element}
 */
const DemoAccount = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const [isOpen, setIsOpen] = useState(false);
  const {
    user: { userData, switchAccount, isDemoVersion, isDemoVersionNull },
    payment: { accounts },
  } = useRootModel();
  const { demoAccount, realAccount } = accounts;

  const handleClickDemoButton = async () => {
    if (userData?.demoVersion) return;
    try {
      await switchAccount();
    } catch (err) {
      alert.show(err.message);
    }
  };

  const handleClickRealButton = async () => {
    if (!userData?.demoVersion) return;

    try {
      await switchAccount();
    } catch (err) {
      alert.show(err.message);
    }
  };

  const MainComponent = () => (
    <div
      className={cn(styles.demoAccount, { [styles.cursorDefault]: isDemoVersionNull })}
      role='button'
      tabIndex={-1}
      onKeyDown={emptyFunction}
    >
      <div className={styles.titleBlock}>
        <p className={styles.title}>
          <span>{isDemoVersion ? t('demo_account') : t('real_account')}</span>
          {!isDemoVersionNull && isOpen ? <ArrUp /> : <ArrDown />}
        </p>
        <Hat />
      </div>
      <p className={styles.text}>
        {t('account')} № {isDemoVersion ? demoAccount?.id : realAccount?.id}
      </p>
    </div>
  );

  if (isDemoVersionNull) {
    return <> </>;
  }

  return (
    <Dropdown
      mainComponent={MainComponent}
      changeMainComponent={false}
      showArrow={false}
      contentClassName={cn(styles.dropdownContent, { [styles.displayNone]: isDemoVersionNull })}
      itemClassName={styles.dropdownItem}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <button
        type='button'
        className={cn(styles.button, { [styles.active]: isDemoVersion })}
        onClick={handleClickDemoButton}
      >
        <div className={styles.buttonTitle}>
          <Demo />
          <p>{t('demo_account')}</p>
        </div>
        {isDemoVersion && <Done />}
      </button>
      <button
        type='button'
        className={cn(styles.button, { [styles.active]: !isDemoVersion })}
        onClick={handleClickRealButton}
      >
        <div className={styles.buttonTitle}>
          <Real />
          <p>{t('real_account')}</p>
        </div>
        {!isDemoVersion && <Done />}
      </button>
    </Dropdown>
  );
};

export default observer(DemoAccount);
