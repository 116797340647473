import { formatDateString, makeApiRequest } from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';
import { DEFAULT_INTERVAL } from '../../constant/commonConstants';

const lastBarsCache = new Map();

const numOfDecimal = (num = 0) => {
  const number = String(num);
  const index = number.lastIndexOf('.');
  return number.slice(index + 1).length;
};

const resolutions = {
  15: 'fifteen_min',
  30: 'fifteen_min',
  '1H': 'one_hour',
  60: 'one_hour',
  '4H': 'four_hour',
  240: 'four_hour',
  '1D': 'day',
  '1W': 'week',
  '1M': 'month',
};

const configurationData = {
  supported_resolutions: ['15', '30', '1H', '4H', '1D', '1W', '1M'],
  exchanges: [],
};

async function getAllSymbols() {
  try {
    return await makeApiRequest('symbols/full-list');
  } catch (e) {
    console.log(e.message);
  }
}

export default {
  onReady: (callback) => {
    setTimeout(() => {
      if (callback) callback(configurationData);
    });
  },

  searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
    console.log('[searchSymbols]: Method call', userInput);
    const symbols = await getAllSymbols();

    console.log('symbols', symbols);

    const newSymbols = symbols
      .filter((symbol) => {
        return symbol.s.toLowerCase().includes(userInput.toLowerCase());
      })
      .map((symbol) => ({
        description: symbol.s,
        exchange: symbol.s,
        full_name: symbol.s,
        symbol: symbol.s,
        type: symbol.type,
      }));

    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    const symbols = await getAllSymbols();
    const symbol = symbols?.find((symbol) => symbol?.s === symbolName);

    if (!symbol) return;
    const { s, lp, h, l } = symbol;
    const lastPrice = numOfDecimal(lp);
    const high = numOfDecimal(h);
    const low = numOfDecimal(l);
    const pricescale = Math.max(lastPrice, high, low);

    const symbolInfo = {
      ticker: s,
      name: s,
      description: s,
      type: symbol.type,
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: '',
      minmov: 1,
      pricescale: Math.pow(10, Math.max(2, pricescale)),
      has_intraday: true,
      has_no_volume: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: 'streaming',
    };

    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams;

    const urlParameters = {
      symbolCode: symbolInfo.full_name,
      from: formatDateString(from),
      to: formatDateString(to),
      resolution: resolutions[resolution] || resolutions[DEFAULT_INTERVAL],
    };

    const query = Object.keys(urlParameters)
      .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
      .join('&');
    try {
      const data = await makeApiRequest(`bars/${symbolInfo.type}?${query}`);
      if (!data) return;
      if ((data.Response && data.Response === 'Error') || data.length === 0) {
        // "noData" should be set if there is no data in the requested period.
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }

      let bars = [];

      data.forEach((bar) => {
        if (bar.t >= from * 1000 && bar.t < to * 1000) {
          bars = [
            ...bars,
            {
              time: bar.t,
              low: bar.l,
              high: bar.h,
              open: bar.o,
              close: bar.c,
            },
          ];
        }
      });
      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      console.log('[getBars]: Get error', error);
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback,
  ) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name),
    );
  },

  unsubscribeBars: (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },
};
