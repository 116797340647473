import { types } from 'mobx-state-tree';

const NewsItemModel = types.model('NewsItemModel', {
  date: types.optional(types.string, ''),
  description: types.maybeNull(types.string),
  source: types.optional(types.string, ''),
  title: types.optional(types.string, ''),
  url: types.optional(types.string, ''),
});

export default NewsItemModel;
