import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import style from '../OpenTradeContent/OpenTradeContent.module.scss';
import TabsForModalItem from '../ui/TabsForModal/TabsForModalItem/TabsForModalItem';
import InstrumentPriceInput from '../InstrumentPriceInput/InstrumentPriceInput';
import Now from '../Now/Now';
import LimitProfitsAndLost from '../LimitProfitsAndLost/LimitProfitsAndLost';
import OpenTradeButtons from '../OpenTradeButtons/OpenTradeButtons';
import { useRootModel } from '../../models/RootStore';

const OpenTradeNotAuthorized = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const {
    finance: {
      trade: { amount, setInitialStateForTrade },
    },
  } = useRootModel();

  const tabs = [
    {
      id: 1,
      tabLabel: t('market_tab'),
    },
    {
      id: 2,
      tabLabel: t('at_price'),
    },
  ];

  const {
    finance: {
      activeItem: { bid, ask },
    },
  } = useRootModel();

  useEffect(() => {
    return () => {
      setInitialStateForTrade();
    };
  }, [setInitialStateForTrade]);

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.buttonsAndTitleWrapper}>
          {tabs.map(({ tabLabel, id }) => (
            <TabsForModalItem
              key={id}
              id={id}
              activeTab={activeTab}
              label={tabLabel}
              onClick={setActiveTab}
            />
          ))}
        </div>
        {activeTab === 2 && <InstrumentPriceInput />}
        <Now />
      </div>

      <div className={style.container}>
        <LimitProfitsAndLost />
        <div className={style.block}>
          <OpenTradeButtons
            onClickSell={() => navigate('/registration')}
            onClickBuy={() => navigate('/registration')}
            disabled={amount < 1}
          />

          <p className={style.spread}>
            {t('spread')}: {Number((bid - ask).toFixed(10))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(OpenTradeNotAuthorized);
