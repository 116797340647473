import { OPERATION } from '../constant/commonConstants';

const countValues = (item) => {
  const { amount, multiplier, openingPrice, operation, lastPrice } = item;
  const profit =
    ((amount * multiplier) / openingPrice) *
    (lastPrice - openingPrice) *
    (operation === OPERATION.buy ? 1 : -1);
  const result = profit + amount;
  const profitPercent = (profit / amount) * 100;

  return {
    result,
    profitPercent,
    profit,
  };
};

export const amountWithCommission = (amount, commission) => {
  return Number(amount) - Number(amount) * (commission / 100);
};

export default {
  countValues,
};
