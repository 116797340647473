import i18n from '../translations/i18n';

export const tabs = [
  { id: 1, tabLabel: i18n.t('favorites'), url: '/products/favorites' },
  {
    id: 2,
    tabLabel: i18n.t('crypto'),
    url: '/products/crypto',
  },
  {
    id: 3,
    tabLabel: i18n.t('indices'),
    url: '/products/index',
  },
  {
    id: 4,
    tabLabel: i18n.t('oil'),
    url: '/products/oil-gas',
  },
  {
    id: 5,
    tabLabel: i18n.t('metals'),
    url: '/products/metal',
  },
  {
    id: 6,
    tabLabel: i18n.t('shares'),
    url: '/products/stock',
  },
  {
    id: 7,
    tabLabel: i18n.t('currencies'),
    url: '/products/forex',
  },
  {
    id: 8,
    tabLabel: i18n.t('agriculture'),
    url: '/products/agriculture',
  },
  {
    id: 9,
    tabLabel: i18n.t('etfs'),
    url: '/products/etf',
  },
];

export const tabsForTrades = [
  {
    id: 1,
    tabLabel: i18n.t('active_orders'),
    url: '/trades/active',
    active: true,
  },
  {
    id: 2,
    tabLabel: i18n.t('pending_orders'),
    url: '/trades/pending',
    active: false,
  },
  {
    id: 3,
    tabLabel: i18n.t('closed_orders'),
    url: '/trades/closed',
    active: false,
  },
  {
    id: 4,
    tabLabel: i18n.t('flow'),
    url: '/trades/flow-funds',
    active: false,
  },
];
