import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import style from '../../HeaderUserInfo.module.scss';
import { ReactComponent as Crown } from '../../../../../assets/image/common/crown-mini.svg';

/**
 * Represents ButtonStatusInfo component
 * @returns {JSX.Element}
 */
const ButtonStatusInfo = ({ onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleStatusInfo = () => {
    navigate('/profile/status');
    if (onClick) onClick();
  };

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Crown />
      </i>
      <button type='button' className={style.button} onClick={handleStatusInfo}>
        {t('status_info')}
      </button>
    </div>
  );
};

export default ButtonStatusInfo;
