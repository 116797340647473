import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import style from '../../../AppModals.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import LimitProfitsAndLost from '../../../../LimitProfitsAndLost/LimitProfitsAndLost';
import TradeInformation from '../../../../TradeInformation/TradeInformation';
import Button from '../../../../ui/Button/Button';
import AlertContext from '../../../../../context/alert/alertContext';

/**
 * Represents Active Trade Content in Modal after click on Active trades block
 * @returns {JSX.Element}
 */
const ActiveTradesContent = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    finance: {
      activeTrades: { activeTrade, getActiveTrades },
      trade: { closeTrade, changeLimits, setInitialLimits },
    },
    modal: {
      increaseTrade,
      activeTrade: { close },
    },
  } = useRootModel();

  const handleUpdateLimits = async () => {
    try {
      await changeLimits(activeTrade.id);
      setInitialLimits();
      alert.show(t('changes_saved'), 'success');
      close();
      getActiveTrades();
    } catch (err) {
      alert.show(err);
    }
  };

  const handleCloseTrade = async () => {
    try {
      await closeTrade(activeTrade.id);
      alert.show(t('order_closed'), 'success');
      close();
      getActiveTrades();
    } catch (err) {
      alert.show(err);
    }
  };

  const handleOpenIncreaseModal = () => {
    close();
    increaseTrade.open();
  };

  return (
    <div>
      <div className={style.container}>
        <TradeInformation trade={activeTrade} />
        <div className={style.line} />
        <div style={{ width: '100%' }}>
          <LimitProfitsAndLost />
          <button type='button' className={style.saveLimits} onClick={handleUpdateLimits}>
            {t('save_limits')}
          </button>
        </div>
      </div>

      <div className={style.buttonContainer}>
        <Button
          text={t('close_trade')}
          color='danger'
          fontWeight='bold'
          onClick={handleCloseTrade}
          width='100%'
        />
        <Button
          text={t('increase')}
          color='primary'
          fontWeight='bold'
          width='100%'
          onClick={handleOpenIncreaseModal}
        />
      </div>
    </div>
  );
};

export default observer(ActiveTradesContent);
