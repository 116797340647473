import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './PendingTradesMobile.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import PendingTradesMobileItem from './PendingTradesMobileItem/PendingTradesMobileItem';
import instrumentIcon from '../../../../assets/instrumentIcon';
import NotFoundMobile from '../../../components/NotFoundMobile/NotFoundMobile';
import Preloader from '../../../../components/ui/Preloader/Preloader';

/**
 * Represents Pending Trades component in Mobile version
 * @returns {JSX.Element}
 */

const PendingTradesMobile = () => {
  const { t } = useTranslation();
  const {
    finance: {
      pendingTrades: { getPendingTrades, sortedTable, quantity, isLoading },
    },
  } = useRootModel();

  useEffect(() => {
    getPendingTrades();
  }, []);

  if (isLoading)
    return (
      <div className={style.preloader}>
        <Preloader />
      </div>
    );

  return sortedTable?.length > 0 ? (
    <section className={style.container}>
      <div className={style.header}>
        <div className={style.instruments}>
          <p>{t('instruments')}</p>
          <p className={style.notifications}>{quantity}</p>
        </div>
        <p className={style.amount}>{t('amount')}</p>
      </div>
      {sortedTable?.map(
        ({
          id,
          amount,
          symbol: { alias, s, type },
          openedAt,
          pendingPrice,
          takeProfit,
          stopLoss,
          operation,
        }) => (
          <PendingTradesMobileItem
            key={id}
            id={id}
            alias={alias}
            symbol={s}
            pendingPrice={pendingPrice}
            takeProfit={takeProfit}
            stopLoss={stopLoss}
            date={openedAt}
            amount={amount}
            operation={operation}
            icon={instrumentIcon[type][s]}
          />
        ),
      )}
    </section>
  ) : (
    <NotFoundMobile />
  );
};

export default observer(PendingTradesMobile);
