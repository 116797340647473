import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from '../../HeaderUserInfo.module.scss';
import { ReactComponent as Support } from '../../../../../assets/image/common/support-mini.svg';

const ButtonSupport = ({ onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleSupport = () => {
    navigate('/support');
    if (onClick) onClick();
  };

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Support />
      </i>
      <button type='button' className={style.button} onClick={handleSupport}>
        {t('support')}
      </button>
    </div>
  );
};

export default ButtonSupport;
