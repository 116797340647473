import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import style from '../../AppModals.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import instrumentIcon from '../../../../assets/instrumentIcon';
import TradeInformation from '../../../TradeInformation/TradeInformation';

/**
 * Represents PendingTradesModal component
 * @returns {JSX.Element}
 * @constructor
 */
const CloseTradeInfoModal = () => {
  const { t } = useTranslation();
  const {
    modal: {
      closeTradeInfo: { isOpen, close },
    },
    finance: {
      closedTrades: { closedTrade },
    },
  } = useRootModel();

  const Icon =
    instrumentIcon[closedTrade?.symbol?.type || 'crypto'][closedTrade?.symbol?.s || 'AAVEUSD'] ||
    null;

  const headerTitle = () => {
    return (
      <span className={style.headerWrapper}>
        <span className={style.symbolWrapper}>
          <i className={style.instrumentIcon}>{Icon && <Icon />}</i>
          {closedTrade?.symbol?.s}
        </span>
        <span className={style.statusWrapper}>
          <span className={style.closedCircle} />
          <span className={cn(style.statusWrapper__text, style.statusWrapper__closed)}>
            {t('closed')}
          </span>
        </span>
      </span>
    );
  };

  return (
    isOpen && (
      <Modal
        wrapperClassname={style.closeModal}
        handleClose={close}
        header
        headerTitle={headerTitle()}
      >
        <ModalBody>
          <TradeInformation trade={closedTrade} />;
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(CloseTradeInfoModal);
