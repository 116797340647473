import React from 'react';
import style from './Instruments.module.scss';
import InstrumentsContent from './InstrumentsContent/InstrumentsContent';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents Instruments component
 * @returns {JSX.Element}
 */

const Instruments = () => {
  const {
    finance: { categoryName },
  } = useRootModel();

  return (
    <div className={style.instrumentsSection}>
      <p className={style.title}>{categoryName}</p>
      <InstrumentsContent />
    </div>
  );
};

export default Instruments;
