import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import style from '../GeneralInformation.module.scss';
import {
  EMAIL,
  EMAIL_MAX_LENGTH,
  PASSWORD,
  PASSWORD_MAX_LENGTH,
} from '../../../../util/validationSchema';
import { useRootModel } from '../../../../models/RootStore';
import useFormikApp from '../../../../hooks/useFormikApp';
import formikUtil from '../../../../util/formikUtil';
import AlertContext from '../../../../context/alert/alertContext';

import TextInput from '../../../../components/ui/TextInput/TextInput';
import Button from '../../../../components/ui/Button/Button';
import emptyFunction from '../../../../util/emptyFunction';
import GeneralEmailCode from './GeneralEmailCode';

/**
 * Represents email component for GeneralInformation
 * @returns {JSX.Element}
 */
const GeneralEmail = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const navigate = useNavigate();
  const {
    user: { userData, verifyEmail, changeEmail, changeEmailApprove, isLoading },
  } = useRootModel();

  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);

  const { pathname, search } = useLocation();

  useEffect(() => {
    const match = search.match(/\?code=([^&]+)/);
    if (pathname === '/profile/change-email' && match && match.length) {
      setIsCodeSent(true);
      setIsEmailChanged(true);
    }
  }, []);

  const handleVerify = async () => {
    try {
      await verifyEmail();
      alert.show(t('confirmation'), 'info');
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const formik = useFormikApp([EMAIL, PASSWORD], emptyFunction);

  const handleSaveChanges = async () => {
    try {
      const { email, password } = formik.values;
      if (userData.emailConfirmed) {
        await changeEmail(email);
        setIsCodeSent(true);
      } else {
        await changeEmailApprove({ newEmail: email, password });
        alert.show(t('email_saved'), 'success');
      }
      setIsEmailChanged(false);
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  useEffect(() => {
    if (userData) {
      formikUtil.setValue(EMAIL, userData.user.email, formik);
    }
  }, [userData?.user.email]);

  useEffect(() => {
    if (userData) {
      setIsEmailChanged(userData.user.email !== formik.values[EMAIL]);
    }
  }, [userData?.user.email, formik.values[EMAIL]]);

  const isEmailValid = formik.touched.email && !formik.errors.email && !isLoading;
  const isChangeValid = formik.isValid && isEmailChanged && !isLoading;

  const handleInputKeyDown = ({ key }) => {
    if (key === 'Enter' && isEmailValid) handleSaveChanges();
  };

  const handleSendCode = async () => {
    try {
      await changeEmail(formik.values[EMAIL]);
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const handleSubmitChanges = async (code) => {
    try {
      await changeEmailApprove({ code });
      setIsCodeSent(false);
      navigate('/profile');
      alert.show(t('confirmation'), 'info');
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className={cn({
          [style.withMarginBottom]: isEmailChanged || !userData?.emailConfirmed,
        })}
      >
        <TextInput
          id={EMAIL}
          label={t('email')}
          placeholder={t('enter_email')}
          formik={formik}
          type='email'
          inputMode='email'
          verifiedLabel
          isVerified={userData?.emailConfirmed}
          maxLength={EMAIL_MAX_LENGTH}
          onKeyDown={handleInputKeyDown}
        />

        {isEmailChanged && !userData?.emailConfirmed && (
          <TextInput
            id={PASSWORD}
            label={t('current_password')}
            type='password'
            placeholder={t('enter_password')}
            formik={formik}
            maxLength={PASSWORD_MAX_LENGTH}
          />
        )}

        {isEmailChanged && (
          <Button
            size='small'
            width='full'
            text={t('save_changes')}
            color='primary'
            disabled={userData?.emailConfirmed ? !isEmailValid : !isChangeValid}
            onClick={handleSaveChanges}
          />
        )}
        {!isEmailChanged && !userData?.emailConfirmed && !isCodeSent && (
          <Button
            size='small'
            text={t('verify_email')}
            onClick={handleVerify}
            disabled={!isEmailValid}
            width='full'
            height={45}
          />
        )}
      </form>
      {isCodeSent && <GeneralEmailCode onSubmit={handleSubmitChanges} sendCode={handleSendCode} />}
    </>
  );
};

export default observer(GeneralEmail);
