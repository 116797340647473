import { types } from 'mobx-state-tree';

const PeriodForTables = types.optional(
  types.union(
    types.literal('WEEK'),
    types.literal('MONTH'),
    types.literal('QUARTER'),
    types.literal('ALL_TIME'),
  ),
  'ALL_TIME',
);

export default PeriodForTables;
