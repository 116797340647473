import React from 'react';
import cn from 'classnames';
import style from './TabContent.module.scss';

/**
 * Represents TabItem content
 * @param {string} pageTitle - title for page
 * @param {Component} component - react component what will be show
 * @param {CSSStyleRule} className - className
 * * @returns {JSX.Element}
 */
const TabContent = ({ pageTitle = '', component: Component = null, className }) => {
  return (
    <section className={cn(style.tabContent, className)}>
      <h2 className={cn(style.pageTitle, className)}>{pageTitle}</h2>
      {Component && <Component />}
    </section>
  );
};

export default TabContent;
