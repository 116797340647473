import { useState, useRef, useCallback, useEffect } from 'react';

/**
 * Hook useStateCallback - callback after useState invoke
 * @param {any} initialState - state
 */

const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback
  const setStateCallback = useCallback((st, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(st);
  }, []);
  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);
  return [state, setStateCallback];
};

export default useStateCallback;
