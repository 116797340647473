import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import style from './InstrumentPriceInput.module.scss';
import string, { formatStringWithoutComa } from '../../util/string';
import { useRootModel } from '../../models/RootStore';
import { ReactComponent as MinusIcon } from '../../assets/image/common/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/image/common/plus.svg';

const InstrumentPriceInput = () => {
  const { t } = useTranslation();
  const {
    finance: {
      activeItem,
      trade: {
        amountData: { amount },
        pendingPrice,
        setPendingPrice,
        lossAtPricePending,
        sellAtPricePending,
      },
    },
  } = useRootModel();

  const currentPendingPrice = useMemo(
    () => Number(formatStringWithoutComa(pendingPrice)),
    [pendingPrice],
  );

  const maxPendingPrice = useMemo(
    () => Number((activeItem.lp + activeItem.lp * (sellAtPricePending / 100)).toFixed(8)),
    [activeItem.lp, sellAtPricePending],
  );

  const minPendingPrice = useMemo(
    () => Number((activeItem.lp - activeItem.lp * (lossAtPricePending / 100)).toFixed(8)),
    [activeItem.lp, lossAtPricePending],
  );

  const handlePriceChange = (e) => {
    const { value } = e.target;

    const formattedValue = formatStringWithoutComa(value);

    if (!(/^\d+(\.?\d{0,8})?$/.test(formattedValue) || formattedValue === '')) return;
    setPendingPrice(formattedValue);
  };

  const handlePriceBlur = () => {
    if (sellAtPricePending && currentPendingPrice >= maxPendingPrice) {
      return setPendingPrice(string.formatNumber(maxPendingPrice));
    }

    if (lossAtPricePending && currentPendingPrice <= minPendingPrice) {
      return setPendingPrice(string.formatNumber(minPendingPrice));
    }

    if (currentPendingPrice === 0) return setPendingPrice(string.formatNumber(activeItem.lp));

    return setPendingPrice(string.formatNumber(pendingPrice));
  };

  const handleIncreasePrice = () => {
    const num = string.decimalAmount(formatStringWithoutComa(pendingPrice));
    const plus = num > 0 ? 1 / 10 ** num : 1;
    const newPrice = String((Number(formatStringWithoutComa(pendingPrice)) + plus).toFixed(num));

    if (sellAtPricePending && newPrice > maxPendingPrice) return;
    setPendingPrice(string.formatNumber(newPrice));
  };

  const handleDecreasePrice = () => {
    if (!Number(pendingPrice.split(',').join(''))) return;
    const num = string.decimalAmount(formatStringWithoutComa(pendingPrice));
    const div = num > 0 ? 1 / 10 ** num : 1;
    const newPrice = String((Number(formatStringWithoutComa(pendingPrice)) - div).toFixed(num));

    if (lossAtPricePending && newPrice < minPendingPrice) return;

    setPendingPrice(string.formatNumber(newPrice));
  };

  useEffect(() => {
    setPendingPrice(string.formatNumber(activeItem?.lp ?? 0));
  }, [activeItem.s, amount]);

  useEffect(() => {
    return () => {
      setPendingPrice('');
    };
  }, [setPendingPrice]);

  return (
    <div className={style.container}>
      <p className={style.label}>
        <span>{t('price')}</span>{' '}
        {Boolean(amount) && (
          <span className={style.label__text}>
            {Boolean(sellAtPricePending) && `(max: ${sellAtPricePending}%)`}{' '}
            {Boolean(lossAtPricePending) && `(min: ${lossAtPricePending}%)`}
          </span>
        )}
      </p>
      <div className={style.wrapper}>
        <input
          name='price'
          type='text'
          value={pendingPrice}
          onChange={handlePriceChange}
          onBlur={handlePriceBlur}
          placeholder='0.000'
          inputMode='decimal'
          autoComplete='off'
          className={style.block__input}
        />

        <div className={style.block}>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__left)}
            onClick={handleDecreasePrice}
          >
            <MinusIcon className={style.button__icon} />
          </button>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__right)}
            onClick={handleIncreasePrice}
          >
            <PlusIcon className={style.button__icon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(InstrumentPriceInput);
