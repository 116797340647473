import React from 'react';
import { observer } from 'mobx-react';
import style from './BalancePage.module.scss';
import DemoAccount from '../../../components/RightSideBlock/DemoAccount/DemoAccount';
import Balance from '../../../pages/Balance/Balance';

/**
 * Represents Balance page in Mobile version
 * @returns {JSX.Element}
 */

const BalancePage = () => {
  return (
    <section className={style.section}>
      <DemoAccount />
      <Balance />
    </section>
  );
};

export default observer(BalancePage);
