import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import style from './Chat.module.scss';
import useWindowSize from '../../../hooks/useWindowSize';
import emptyFunction from '../../../util/emptyFunction';
import { useRootModel } from '../../../models/RootStore';
import socketUtils from '../../../util/socketUtils';

import { ReactComponent as ChatIcon } from '../../../assets/image/common/chat.svg';
import { ReactComponent as ChatCloseIcon } from '../../../assets/image/common/chatClose.svg';
import ChatHeader from '../ChatHeader/ChatHeader';
import ChatList from '../ChatList/ChatList';
import ChatSend from '../ChatSend/ChatSend';
import ChatArrow from '../ChatArrow/ChatArrow';

/**
 * Represents Chat component
 * @returns {JSX.Element}
 */
const Chat = () => {
  const [file, setFile] = useState(null);
  const handleDeleteFile = () => setFile(null);

  const {
    settings: { isLightMode },
    chat: {
      getChats,
      items: chats,
      isLoading,
      messages: {
        getMessages,
        getUpdateMessages,
        getUpdateMessage,
        items,
        clearItems,
        isNewMessages,
      },
    },
  } = useRootModel();
  const { isMobile } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleIsOpen = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);

  const chatListRef = useRef();
  const [showArrow, setShowArrow] = useState(false);
  const handleShowArrow = () => setShowArrow(true);
  const handleHideArrow = () => setShowArrow(false);

  const [initialization, setInitialization] = useState(true);
  const [pictures, setPictures] = useState({ count: 0, loaded: 0 });

  useEffect(() => {
    const fetchData = async () => {
      if (isLoading) return;
      await getChats();
      await getMessages();
      let picturesCount = 0;
      items.forEach((i) => {
        if (i.type === 'photo') picturesCount += 1;
      });
      setPictures((prev) => ({ ...prev, count: picturesCount }));
      getUpdateMessages();
      getUpdateMessage();
      setInitialization(false);
    };
    fetchData();

    return () => {
      socketUtils.removeListeners();
      clearItems();
    };
  }, []);

  const handleScrollToBottom = () => {
    const elem = chatListRef.current;
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  };

  if (chats?.length === 0) return null;

  return (
    !isMobile && (
      <div className={style.chat}>
        <div className={style.icon}>
          {isOpen ? (
            <i tabIndex={-1} role='button' onClick={handleToggleIsOpen} onKeyDown={emptyFunction}>
              <ChatCloseIcon />
            </i>
          ) : (
            chats.length > 0 && (
              <>
                <i
                  role='button'
                  tabIndex={-1}
                  onClick={handleToggleIsOpen}
                  onKeyDown={emptyFunction}
                >
                  <ChatIcon />
                </i>
                {isNewMessages && <i className={style.newMessages} />}
              </>
            )
          )}
        </div>
        {isOpen && (
          <div className={cn(style.container, { [style.lightShadow]: isLightMode })}>
            <ChatHeader close={handleClose} />
            <div className={style.chatList}>
              <ChatList
                chatListRef={chatListRef}
                showArrow={handleShowArrow}
                hideArrow={handleHideArrow}
                initialization={initialization}
                pictures={pictures}
                setPictures={setPictures}
              />
              <ChatSend
                file={file}
                setFile={setFile}
                handleDeleteFile={handleDeleteFile}
                handleScrollToBottom={handleScrollToBottom}
              />
              {showArrow && <ChatArrow isFile={file} onClick={handleScrollToBottom} />}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default observer(Chat);
