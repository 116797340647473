import { io } from 'socket.io-client';

const token = localStorage.getItem('id_token');
const socket = io(`${process.env.REACT_APP_CHAT}/`, {
  auth: { token: `Bearer ${token}` },
});

const onMessage = (listener) => {
  socket.on('message', listener);
};

const handleUpdateMessage = (listener) => {
  socket.on('updatedMessage', listener);
};

const handleConnection = (listener) => {
  socket.on('handleConnection', listener);
};

const removeListeners = () => {
  socket.removeAllListeners();
};

export default {
  onMessage,
  handleConnection,
  handleUpdateMessage,
  removeListeners,
};
