import React from 'react';
import cn from 'classnames';
import { ReactComponent as BingoCoin } from '../../../../assets/image/common/bingo-coin.svg';

import styles from './ChartLine.module.scss';

/**
 * Represents loyalty status chart line component
 * @returns {JSX.Element}
 */

const ChartLine = ({ status, type, requiredBalance, requiredVolume, isActive }) => {
  return (
    <div className={styles.levelWrap}>
      {type === 'balance' ? (
        <p className={styles.text}>
          {status === 'silver' ? (
            <span>Start</span>
          ) : (
            <span>
              <BingoCoin className={styles.bingoCoin} /> {requiredBalance}
            </span>
          )}
        </p>
      ) : (
        <p className={styles.text}>
          {status === 'silver' ? (
            <span>Start</span>
          ) : (
            <span>
              <BingoCoin className={styles.bingoCoin} /> {requiredVolume}
            </span>
          )}
        </p>
      )}
      <div className={styles.lineWrap}>
        <div
          className={cn(styles.line, {
            [styles.noVisible]: status === 'silver',
            [styles.active]: isActive,
          })}
        />
        <div className={cn(styles.dot, { [styles.active]: isActive })} />
        <div
          className={cn(styles.line, {
            [styles.noVisible]: status === 'vip',
            [styles.active]: isActive,
          })}
        />
      </div>
    </div>
  );
};

export default ChartLine;
