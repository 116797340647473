import React from 'react';
import cn from 'classnames';
import { ReactComponent as Tick } from '../../../../assets/image/common/tick.svg';

import styles from '../StatusInfoWeb.module.scss';

/**
 * Represents loyalty status ticks component
 * @returns {JSX.Element}
 */

const Ticks = ({ type, isActive }) => {
  const silver = type === 'silver';
  const gold = type === 'silver' || type === 'gold';
  const platinum = type === 'silver' || type === 'gold' || type === 'platinum';
  const diamond = type === 'silver' || type === 'gold' || type === 'platinum' || type === 'diamond';

  return (
    <div className={cn(styles.tickWrapper, { [styles.hoveredBackground]: !isActive })}>
      <div className={styles.tickContainer}>
        <Tick className={cn(styles.tick, styles[type])} />
      </div>
      <div className={styles.tickContainer}>
        <Tick className={cn(styles.tick, styles[type])} />
      </div>
      <div className={styles.tickContainer}>
        <Tick className={cn(styles.tick, { [styles[type]]: !silver })} />
      </div>
      <div className={styles.tickContainer}>
        <Tick className={cn(styles.tick, { [styles[type]]: !gold })} />
      </div>
      <div className={styles.tickContainer}>
        <Tick className={cn(styles.tick, { [styles[type]]: !platinum })} />
      </div>
      <div className={styles.tickContainer}>
        <Tick className={cn(styles.tick, { [styles[type]]: !diamond })} />
      </div>
    </div>
  );
};

export default Ticks;
