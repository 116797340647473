import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../../../assets/image/common/info.svg';
import styles from './StatusInfoHeader.module.scss';
import Modal from '../../../components/Modal/Modal';
import style from '../../FooterInfo/AffiliateProgram/AffiliateProgram.module.scss';
import ModalBody from '../../../components/Modal/ModalBody/ModalBody';
import LoyaltyRules from '../LoyaltyRules/LoyaltyRules';

/**
 * Represents loyalty status header component
 * @returns {JSX.Element}
 */

const StatusInfoHeader = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={styles.statusInfoHeader}>
        <span className={styles.text}>{t('loyalty_statuses')}</span>
        <button type='button' className={styles.link} onClick={handleOpenModal}>
          <span className={styles.linkText}>{t('status_program_rules')}</span>
          <InfoIcon />
        </button>
      </div>

      {showModal && (
        <Modal handleClose={handleCloseModal} wrapperClassname={style.modalWrapper}>
          <ModalBody className={style.modal}>
            <LoyaltyRules handleClose={handleCloseModal} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default StatusInfoHeader;
