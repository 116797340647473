import React from 'react';
import cn from 'classnames';
import style from './BalanceHistory.module.scss';
import { ReactComponent as DotIcon } from '../../assets/image/common/dot.svg';
import PeriodDropdown from '../PeriodDropdown/PeriodDropdown';

const historyData = [
  {
    id: 1,
    operation: 'Withdraw',
    date: '2023/01/01',
    time: '12:05',
    amount: '-1000USDT',
    status: 'Failed',
  },
  {
    id: 2,
    operation: 'Transfer',
    date: '2023/01/01',
    time: '12:05',
    amount: '+1000USDT',
    status: 'Completed',
  },
  {
    id: 3,
    operation: 'Transfer',
    date: '2023/01/01',
    time: '12:05',
    amount: '+1000USDT',
    status: 'Completed',
  },
  {
    id: 4,
    operation: 'Transfer',
    date: '2023/01/01',
    time: '12:05',
    amount: '+1000USDT',
    status: 'Completed',
  },
  {
    id: 5,
    operation: 'Transfer',
    date: '2023/01/01',
    time: '12:05',
    amount: '+1000USDT',
    status: 'Completed',
  },
  {
    id: 6,
    operation: 'Transfer',
    date: '2023/01/01',
    time: '12:05',
    amount: '+1000USDT',
    status: 'Completed',
  },
  {
    id: 7,
    operation: 'Transfer',
    date: '2023/01/01',
    time: '12:05',
    amount: '+1000USDT',
    status: 'Completed',
  },
];

const BalanceHistory = () => {
  return (
    <section>
      <PeriodDropdown />

      <div className={style.list}>
        {historyData.map(({ id, operation, date, time, amount, status }) => (
          <div className={style.list__item} key={id}>
            <div>
              <p>{operation}</p>
              <p className={style.list__block}>
                <span>{date}</span> <DotIcon />
                <span>{time}</span>
              </p>
            </div>
            <div className={style.list__wrapper}>
              <p>{amount}</p>
              <div className={style.list__block} style={{ justifyContent: 'flex-end' }}>
                <div
                  className={cn(style.list__dot, {
                    [style.list__dot_sucess]: status === 'Completed',
                  })}
                />
                <p
                  className={cn(style.list__text, { [style.list__sucess]: status === 'Completed' })}
                >
                  {status}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BalanceHistory;
