import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import StatusInfoIcon from '../StatusInfoIcon/StatusInfoIcon';
import StatusInfoHeader from '../StatusInfoHeader/StatusInfoHeader';
import StatusInfoActions from '../StatusInfoActions/StatusInfoActions';
import ChartLine from './ChartLine/ChartLine';
import DepositBtn from './DepositBtn/DepositBtn';
import TradeBtn from './TradeBtn/TradeBtn';
import Ticks from './Ticks/Ticks';
import StatusDesc from './StatusDesc/StatusDesc';

import { ReactComponent as BingoCoin } from '../../../assets/image/common/bingo-coin.svg';

import styles from './StatusInfoWeb.module.scss';

const layouts = [
  {
    type: 'silver',
    isActive: true,
    requiredBalance: '50',
    requiredVolume: '500',
  },
  {
    type: 'gold',
    isActive: false,
    requiredBalance: '500',
    requiredVolume: '5000',
  },
  {
    type: 'platinum',
    isActive: false,
    requiredBalance: '5000',
    requiredVolume: '50000',
  },
  {
    type: 'diamond',
    isActive: false,
    requiredBalance: '50000',
    requiredVolume: '500000',
  },
  {
    type: 'vip',
    isActive: false,
    requiredBalance: '500000',
    requiredVolume: '5000000',
  },
];

/**
 * Represents loyalty status component
 * @returns {JSX.Element}
 */

const StatusInfoWeb = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.statusInfoWeb}>
      <div className={styles.conditionsContainer}>
        <div className={styles.conditionsHeader}>
          <StatusInfoHeader />
          <StatusInfoActions />
        </div>
        <div>
          <p className={styles.chartSum}>
            <BingoCoin className={styles.bingoCoin} /> 0.00
          </p>
          <p className={styles.chartName}>{t('current_trading_balance')}</p>
        </div>
        <div className={styles.empty} />
        <div>
          <p className={styles.chartSum}>
            <BingoCoin className={styles.bingoCoin} /> 0.00
          </p>
          <p className={styles.chartName}>{t('current_trading_volume')}</p>
        </div>
        <div className={styles.empty} />
        <StatusDesc />
      </div>
      {layouts.map((layout) => (
        <div key={layout.type} className={cn(styles.statusContainer, styles[layout.type])}>
          <div
            className={cn(styles.crownWrap, {
              [styles.hoveredBackground]: !layout.isActive,
            })}
          >
            <StatusInfoIcon color={layout.type} isActive={layout.isActive} type={t(layout.type)} />
          </div>
          <ChartLine
            type='balance'
            status={layout.type}
            requiredBalance={layout.requiredBalance}
            requiredVolume={layout.requiredVolume}
            isActive={layout.isActive}
          />
          <DepositBtn type={layout.type} isActive={layout.isActive} />
          <ChartLine
            type='volume'
            status={layout.type}
            requiredBalance={layout.requiredBalance}
            requiredVolume={layout.requiredVolume}
            isActive={layout.isActive}
          />
          <TradeBtn type={layout.type} isActive={layout.isActive} />
          <Ticks type={layout.type} isActive={layout.isActive} />
        </div>
      ))}
    </div>
  );
};

export default StatusInfoWeb;
