import React from 'react';
import cn from 'classnames';
import style from './Badge.module.scss';

/**
 * Represents Badge component
 * @param {number} amount - amount
 * @param {CSSStyleRule} className - className
 * @returns {JSX.Element}
 */
const Badge = ({ amount, className }) => {
  return (
    <div
      className={cn(
        style.badge,
        { [style.twoSymbols]: amount > 9, [style.threeSymbols]: amount > 99 },
        className,
      )}
    >
      {amount < 100 ? amount : '99+'}
    </div>
  );
};

export default Badge;
