import { createPortal } from 'react-dom';

/**
 * Represents modal wrapper
 * @param {ChildNode} children - children
 * @return {React.ReactPortal}
 */
const Portal = ({ children }) => {
  const modalRoot = document.getElementById('modal');
  return createPortal(children, modalRoot);
};

export default Portal;
