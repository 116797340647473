import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from './PriceHighLowGraph.module.scss';
import TitleNumberFormat from '../ui/TitleNumberFormat/TitleNumberFormat';
import { ReactComponent as Triangle } from '../../assets/image/common/triangle.svg';
import { useRootModel } from '../../models/RootStore';

const PriceHighLowGraph = () => {
  const { t } = useTranslation();
  const {
    finance: { activeHigh, activeLow, pricePercent },
  } = useRootModel();

  return (
    <div className={style.container}>
      <div className={style.graph}>
        <div className={style.graph__line} />
        <i className={style.graph__triangle} style={{ bottom: `${pricePercent * 0.34}px` }}>
          <Triangle />
        </i>
      </div>
      <div className={style.block}>
        <TitleNumberFormat title={t('high')} value={activeHigh} />
        <TitleNumberFormat title={t('low')} value={activeLow} />
      </div>
    </div>
  );
};

export default observer(PriceHighLowGraph);
