import { ReactComponent as CANE } from '../assets/image/instruments/icons/agriculture/CANE.svg';
import { ReactComponent as CORN } from '../assets/image/instruments/icons/agriculture/CORN.svg';
import { ReactComponent as JO } from '../assets/image/instruments/icons/agriculture/JO.svg';
import { ReactComponent as NIB } from '../assets/image/instruments/icons/agriculture/NB.svg';
import { ReactComponent as SOYB } from '../assets/image/instruments/icons/agriculture/SOYBEANS.svg';
import { ReactComponent as WEAT } from '../assets/image/instruments/icons/agriculture/WHEAT.svg';

import { ReactComponent as AAVEUSD } from '../assets/image/instruments/icons/crypto/AAVEUSD.svg';
import { ReactComponent as ADAUSD } from '../assets/image/instruments/icons/crypto/ADAUSD.svg';
import { ReactComponent as ALGOUSD } from '../assets/image/instruments/icons/crypto/ALGOUSD.svg';
import { ReactComponent as ATOMUSD } from './image/instruments/icons/crypto/ATOMUSD.svg';
import { ReactComponent as AVAXUSD } from '../assets/image/instruments/icons/crypto/AVAXUSD.svg';
import { ReactComponent as BCHBTC } from '../assets/image/instruments/icons/crypto/BCHBTC.svg';
import { ReactComponent as BCHUSD } from '../assets/image/instruments/icons/crypto/BCHUSD.svg';
import { ReactComponent as BNBUSD } from '../assets/image/instruments/icons/crypto/BNBUSD.svg';
import { ReactComponent as BSVUSD } from '../assets/image/instruments/icons/crypto/BSVUSD.svg';
import { ReactComponent as BTCEUR } from '../assets/image/instruments/icons/crypto/BTCEUR.svg';
import { ReactComponent as BTCUSD } from '../assets/image/instruments/icons/crypto/BTCUSD.svg';
import { ReactComponent as BTGBTC } from '../assets/image/instruments/icons/crypto/BTGBTC.svg';
import { ReactComponent as BTGETH } from '../assets/image/instruments/icons/crypto/BTGETH.svg';
import { ReactComponent as BTGUSD } from '../assets/image/instruments/icons/crypto/BTGUSD.svg';
import { ReactComponent as CAKEUSD } from '../assets/image/instruments/icons/crypto/CAKEUSD.svg';
import { ReactComponent as CHZUSD } from '../assets/image/instruments/icons/crypto/CHZUSD.svg';
import { ReactComponent as COMPUSD } from '../assets/image/instruments/icons/crypto/COMPUSD.svg';
import { ReactComponent as DASHBTC } from '../assets/image/instruments/icons/crypto/DASHBTC.svg';
import { ReactComponent as DASHETH } from '../assets/image/instruments/icons/crypto/DASHETH.svg';
import { ReactComponent as DASHUSD } from '../assets/image/instruments/icons/crypto/DASHUSD.svg';
import { ReactComponent as DOGEUSD } from '../assets/image/instruments/icons/crypto/DOGEUSD.svg';
import { ReactComponent as DOTUSD } from '../assets/image/instruments/icons/crypto/DOTUSD.svg';
import { ReactComponent as EGLDUSD } from '../assets/image/instruments/icons/crypto/EGLDUSD.svg';
import { ReactComponent as ENJUSD } from '../assets/image/instruments/icons/crypto/ENJUSD.svg';
import { ReactComponent as EOSETH } from '../assets/image/instruments/icons/crypto/EOSETH.svg';
import { ReactComponent as EOSUSD } from '../assets/image/instruments/icons/crypto/EOSUSD.svg';
import { ReactComponent as ETCETH } from '../assets/image/instruments/icons/crypto/ETCETH.svg';
import { ReactComponent as ETCUSD } from '../assets/image/instruments/icons/crypto/ETCUSD.svg';
import { ReactComponent as ETHBTC } from '../assets/image/instruments/icons/crypto/ETHBTC.svg';
import { ReactComponent as ETHUSD } from '../assets/image/instruments/icons/crypto/ETHUSD.svg';
import { ReactComponent as FILUSD } from '../assets/image/instruments/icons/crypto/FILUSD.svg';
import { ReactComponent as IOTETH } from '../assets/image/instruments/icons/crypto/IOTETH.svg';
import { ReactComponent as IOTUSD } from '../assets/image/instruments/icons/crypto/IOTUSD.svg';
import { ReactComponent as LITECOIN } from '../assets/image/instruments/icons/crypto/LITECOIN.svg';
import { ReactComponent as LNKUSD } from '../assets/image/instruments/icons/crypto/LNKUSD.svg';
import { ReactComponent as LTCBTC } from '../assets/image/instruments/icons/crypto/LTCBTC.svg';
import { ReactComponent as LTCETH } from '../assets/image/instruments/icons/crypto/LTCETH.svg';
import { ReactComponent as LTCUSD } from '../assets/image/instruments/icons/crypto/LTCUSD.svg';
import { ReactComponent as LUNAUSD } from '../assets/image/instruments/icons/crypto/LUNAUSD.svg';
import { ReactComponent as MATICUSD } from '../assets/image/instruments/icons/crypto/MATICUSD.svg';
import { ReactComponent as MKRUSD } from '../assets/image/instruments/icons/crypto/MKRUSD.svg';
import { ReactComponent as NEOBTC } from '../assets/image/instruments/icons/crypto/NEOBTC.svg';
import { ReactComponent as NEOETH } from '../assets/image/instruments/icons/crypto/NEOETH.svg';
import { ReactComponent as NEOUSD } from '../assets/image/instruments/icons/crypto/NEOUSD.svg';
import { ReactComponent as OMGETH } from '../assets/image/instruments/icons/crypto/OMGETH.svg';
import { ReactComponent as OMGUSD } from '../assets/image/instruments/icons/crypto/OMGUSD.svg';
import { ReactComponent as ONTUSD } from '../assets/image/instruments/icons/crypto/ONTUSD.svg';
import { ReactComponent as QTMETH } from '../assets/image/instruments/icons/crypto/QTMETH.svg';
import { ReactComponent as QTMUSD } from '../assets/image/instruments/icons/crypto/QTMUSD.svg';
import { ReactComponent as RUNEUSD } from '../assets/image/instruments/icons/crypto/RUNEUSD.svg';
import { ReactComponent as SNXUSD } from '../assets/image/instruments/icons/crypto/SNXUSD.svg';
import { ReactComponent as SOLUSD } from '../assets/image/instruments/icons/crypto/SOLUSD.svg';
import { ReactComponent as SUSHIUSD } from '../assets/image/instruments/icons/crypto/SUSHIUSD.svg';
import { ReactComponent as THETAUSD } from '../assets/image/instruments/icons/crypto/THETAUSD.svg';
import { ReactComponent as TRXUSD } from '../assets/image/instruments/icons/crypto/TRXUSD.svg';
import { ReactComponent as UMAUSD } from '../assets/image/instruments/icons/crypto/UMAUSD.svg';
import { ReactComponent as UNIUSD } from '../assets/image/instruments/icons/crypto/UNIUSD.svg';
import { ReactComponent as VETUSD } from '../assets/image/instruments/icons/crypto/VETUSD.svg';
import { ReactComponent as XEMUSD } from '../assets/image/instruments/icons/crypto/XEMUSD.svg';
import { ReactComponent as XLMUSD } from '../assets/image/instruments/icons/crypto/XLMUSD.svg';
import { ReactComponent as XMRBTC } from '../assets/image/instruments/icons/crypto/XMRBTC.svg';
import { ReactComponent as XMRETH } from '../assets/image/instruments/icons/crypto/XMRETH.svg';
import { ReactComponent as XMRUSD } from '../assets/image/instruments/icons/crypto/XMRUSD.svg';
import { ReactComponent as XRPUSD } from '../assets/image/instruments/icons/crypto/XRPUSD.svg';
import { ReactComponent as XTZUSD } from '../assets/image/instruments/icons/crypto/XTZUSD.svg';
import { ReactComponent as ZECBTC } from '../assets/image/instruments/icons/crypto/ZECBTC.svg';
import { ReactComponent as ZECETH } from '../assets/image/instruments/icons/crypto/ZECETH.svg';
import { ReactComponent as ZECUSD } from '../assets/image/instruments/icons/crypto/ZECUSD.svg';
import { ReactComponent as ZRXUSD } from '../assets/image/instruments/icons/crypto/ZRXUSD.svg';

import { ReactComponent as AUDCAD } from '../assets/image/instruments/icons/currencies/AUDCAD.svg';
import { ReactComponent as AUDCHF } from '../assets/image/instruments/icons/currencies/AUDCHF.svg';
import { ReactComponent as AUDJPY } from '../assets/image/instruments/icons/currencies/AUDJPY.svg';
import { ReactComponent as AUDNZD } from '../assets/image/instruments/icons/currencies/AUDNZD.svg';
import { ReactComponent as AUDUSD } from '../assets/image/instruments/icons/currencies/AUDUSD.svg';
import { ReactComponent as CADCHF } from '../assets/image/instruments/icons/currencies/CADCHF.svg';
import { ReactComponent as CADJPY } from '../assets/image/instruments/icons/currencies/CADJPY.svg';
import { ReactComponent as CHFJPY } from '../assets/image/instruments/icons/currencies/CHFJPY.svg';
import { ReactComponent as EURAUD } from '../assets/image/instruments/icons/currencies/EURAUD.svg';
import { ReactComponent as EURCAD } from '../assets/image/instruments/icons/currencies/EURCAD.svg';
import { ReactComponent as EURCHF } from '../assets/image/instruments/icons/currencies/EURCHF.svg';
import { ReactComponent as EURGBP } from '../assets/image/instruments/icons/currencies/EURGBP.svg';
import { ReactComponent as EURJPY } from '../assets/image/instruments/icons/currencies/EURJPY.svg';
import { ReactComponent as EURNZD } from '../assets/image/instruments/icons/currencies/EURNZD.svg';
import { ReactComponent as EURUSD } from '../assets/image/instruments/icons/currencies/EURUSD.svg';
import { ReactComponent as GBPJPY } from '../assets/image/instruments/icons/currencies/GBPJPY.svg';
import { ReactComponent as GBPUSD } from '../assets/image/instruments/icons/currencies/GBPUSD.svg';
import { ReactComponent as NZDUSD } from '../assets/image/instruments/icons/currencies/NZDUSD.svg';
import { ReactComponent as USDCAD } from '../assets/image/instruments/icons/currencies/USDCAD.svg';
import { ReactComponent as USDCHF } from '../assets/image/instruments/icons/currencies/USDCHF.svg';
import { ReactComponent as USDJPY } from '../assets/image/instruments/icons/currencies/USDJPY.svg';

import { ReactComponent as AGG } from '../assets/image/instruments/icons/etfs/AGG.svg';
import { ReactComponent as EWG } from '../assets/image/instruments/icons/etfs/EWG.svg';
import { ReactComponent as EWU } from '../assets/image/instruments/icons/etfs/EWU.svg';
import { ReactComponent as EWW } from '../assets/image/instruments/icons/etfs/EWW.svg';
import { ReactComponent as EWZ } from '../assets/image/instruments/icons/etfs/EWZ.svg';
import { ReactComponent as FXI } from '../assets/image/instruments/icons/etfs/FXI.svg';
import { ReactComponent as IJH } from '../assets/image/instruments/icons/etfs/IJH.svg';
import { ReactComponent as ILF } from '../assets/image/instruments/icons/etfs/ILF.svg';
import { ReactComponent as SPY } from '../assets/image/instruments/icons/etfs/SPY.svg';
import { ReactComponent as VGK } from '../assets/image/instruments/icons/etfs/VGK.svg';

import { ReactComponent as DJI } from '../assets/image/instruments/icons/indices/DJI.svg';
import { ReactComponent as GSPC } from '../assets/image/instruments/icons/indices/ES.svg';
import { ReactComponent as FTSE } from '../assets/image/instruments/icons/indices/FTSE.svg';
import { ReactComponent as GDAXI } from '../assets/image/instruments/icons/indices/GDAXI.svg';
import { ReactComponent as N225 } from '../assets/image/instruments/icons/indices/NKD.svg';
import { ReactComponent as NDX } from '../assets/image/instruments/icons/indices/NQ.svg';
import { ReactComponent as RUT } from '../assets/image/instruments/icons/indices/RUT.svg';
import { ReactComponent as Z } from '../assets/image/instruments/icons/indices/Z.svg';

import { ReactComponent as XPDUSD } from '../assets/image/instruments/icons/metals/PA.svg';
import { ReactComponent as XPTUSD } from '../assets/image/instruments/icons/metals/PL.svg';
import { ReactComponent as XAGUSD } from '../assets/image/instruments/icons/metals/XAGUSD.svg';
import { ReactComponent as XAUUSD } from '../assets/image/instruments/icons/metals/XAUUSD.svg';

import { ReactComponent as LBRT } from '../assets/image/instruments/icons/oil_gas/BRN.svg';
import { ReactComponent as DTO } from '../assets/image/instruments/icons/oil_gas/DTO.svg';
import { ReactComponent as UNG } from '../assets/image/instruments/icons/oil_gas/NG.svg';
import { ReactComponent as SOIL } from '../assets/image/instruments/icons/oil_gas/WT.svg';

import { ReactComponent as AAPL } from '../assets/image/instruments/icons/shares/AAPL.svg';
import { ReactComponent as AIR } from '../assets/image/instruments/icons/shares/AIR.svg';
import { ReactComponent as AMC } from '../assets/image/instruments/icons/shares/AMC.svg';
import { ReactComponent as AMZN } from '../assets/image/instruments/icons/shares/AMZN.svg';
import { ReactComponent as BA } from '../assets/image/instruments/icons/shares/BA.svg';
import { ReactComponent as BABA } from '../assets/image/instruments/icons/shares/BABA.svg';
import { ReactComponent as GME } from '../assets/image/instruments/icons/shares/GME.svg';
import { ReactComponent as MRNA } from '../assets/image/instruments/icons/shares/MRNA.svg';
import { ReactComponent as NFLX } from '../assets/image/instruments/icons/shares/Netflix.svg';
import { ReactComponent as SPCE } from '../assets/image/instruments/icons/shares/SPCE.svg';
import { ReactComponent as TLRY } from '../assets/image/instruments/icons/shares/TLRY.svg';
import { ReactComponent as TSLA } from '../assets/image/instruments/icons/shares/TSLA.svg';

const icons = {
  agriculture: { CANE, CORN, JO, NIB, SOYB, WEAT },
  crypto: {
    AAVEUSD,
    ADAUSD,
    ALGOUSD,
    ATOMUSD,
    AVAXUSD,
    BCHBTC,
    BCHUSD,
    BNBUSD,
    BSVUSD,
    BTCEUR,
    BTCUSD,
    BTGBTC,
    BTGETH,
    BTGUSD,
    CAKEUSD,
    CHZUSD,
    COMPUSD,
    DASHBTC,
    DASHETH,
    DASHUSD,
    DOGEUSD,
    DOTUSD,
    EGLDUSD,
    ENJUSD,
    EOSETH,
    EOSUSD,
    ETCETH,
    ETCUSD,
    ETHBTC,
    ETHUSD,
    FILUSD,
    IOTETH,
    IOTUSD,
    LITECOIN,
    LNKUSD,
    LTCBTC,
    LTCETH,
    LTCUSD,
    LUNAUSD,
    MATICUSD,
    MKRUSD,
    NEOBTC,
    NEOETH,
    NEOUSD,
    OMGETH,
    OMGUSD,
    ONTUSD,
    QTMETH,
    QTMUSD,
    RUNEUSD,
    SNXUSD,
    SOLUSD,
    SUSHIUSD,
    THETAUSD,
    TRXUSD,
    UMAUSD,
    UNIUSD,
    VETUSD,
    XEMUSD,
    XLMUSD,
    XMRBTC,
    XMRETH,
    XMRUSD,
    XRPUSD,
    XTZUSD,
    ZECBTC,
    ZECETH,
    ZECUSD,
    ZRXUSD,
  },
  forex: {
    AUDCAD,
    AUDCHF,
    AUDJPY,
    AUDNZD,
    AUDUSD,
    CADCHF,
    CADJPY,
    CHFJPY,
    EURAUD,
    EURCAD,
    EURCHF,
    EURGBP,
    EURJPY,
    EURNZD,
    EURUSD,
    GBPJPY,
    GBPUSD,
    NZDUSD,
    USDCAD,
    USDCHF,
    USDJPY,
  },
  etf: {
    AGG,
    EWG,
    EWU,
    EWW,
    EWZ,
    FXI,
    IJH,
    ILF,
    SPY,
    VGK,
  },
  index: {
    DJI,
    GSPC,
    FTSE,
    GDAXI,
    N225,
    NDX,
    RUT,
    Z,
  },
  metal: { XPDUSD, XPTUSD, XAGUSD, XAUUSD },
  'oil-gas': { LBRT, DTO, UNG, SOIL },
  stock: {
    AAPL,
    AIR,
    AMC,
    AMZN,
    BA,
    BABA,
    GME,
    MRNA,
    NFLX,
    SPCE,
    TLRY,
    TSLA,
  },
};

export default icons;
