import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './Question.module.scss';
import { useRootModel } from '../../../models/RootStore';
import useFormikApp from '../../../hooks/useFormikApp';
import formikUtil from '../../../util/formikUtil';
import { DESCRIPTION, EMAIL, SUBJECT } from '../../../util/validationSchema';
import { ReactComponent as ClipIcon } from '../../../assets/image/common/clip.svg';
import { ReactComponent as Delete } from '../../../assets/image/common/cancelUpload.svg';
import AlertContext from '../../../context/alert/alertContext';

import TextInput from '../../../components/ui/TextInput/TextInput';
import TextArea from '../../../components/ui/TextArea/TextArea';
import ButtonWithIcon from '../../../components/ui/ButtonWithIcon/ButtonWithIcon';
import Button from '../../../components/ui/Button/Button';
import { supportSections } from '../../../constant/commonConstants';

/**
 * Represents Question component
 * @returns {JSX.Element}
 * @constructor
 */
const Question = ({ closeModal }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    user: { userData, sendRequest },
  } = useRootModel();
  const [type, setType] = useState('GENERAL_QUESTIONS');
  const [files, setFiles] = useState([]);

  const withStar = (str) => (
    <>
      {str} <span className={style.star}>*</span>
    </>
  );

  const submitHandler = async (values) => {
    const { subject, email, description } = values;

    const newData = {
      description,
      email,
      subject,
      type,
    };

    try {
      await sendRequest(newData, files);
      alert.show(t('request_sent'), 'success');
      if (closeModal) closeModal();
    } catch (err) {
      alert.show(err.message);
    }
  };

  const formik = useFormikApp([SUBJECT, EMAIL, DESCRIPTION], submitHandler);
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (userData) {
      formikUtil.setValue(EMAIL, userData.user.email, formik);
    }
  }, [userData?.user.email]);

  const handleClickInputFileButton = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleInputFileChang = (e) => {
    const commonSize = Object.values(e.target.files).reduce((acc, el) => acc + el.size, 0);

    if (commonSize > 1024 * 1024 * 5 || e.target.files.length > 5) {
      alert.show(t('file_load_error'), 'error');
      return;
    }

    const fileList = Object.values(e.target.files);
    setFiles(fileList);
  };

  const handleChangeType = (e) => {
    const { value } = e.target;

    setType(value);
  };

  const handleDeleteFile = (name) => {
    const updatedFileList = files.filter((el) => el.name !== name);
    setFiles(updatedFileList);
  };

  return (
    <form encType='multipart/form-data' className={style.form} onSubmit={formik.handleSubmit}>
      <TextInput
        id={SUBJECT}
        label={withStar(t('subject'))}
        placeholder={t('type_here')}
        formik={formik}
      />
      <label htmlFor='supportType'>
        <p className={style.selectTitle}>
          Type <span className={style.danger}>*</span>
        </p>
        <select
          className={style.select}
          id='supportType'
          name='supportType'
          value={type}
          onChange={handleChangeType}
        >
          {supportSections.map(({ name, supportType }) => (
            <option value={supportType} key={supportType}>
              {name}
            </option>
          ))}
        </select>
      </label>
      <TextInput
        id={EMAIL}
        label={withStar(t('email'))}
        placeholder={t('enter_email')}
        formik={formik}
      />
      <TextArea
        id={DESCRIPTION}
        label={withStar(t('description'))}
        inputClassName={style.textArea}
        placeholder={t('type_here')}
        formik={formik}
      />

      <input
        type='file'
        className={style.inputFile}
        ref={inputFileRef}
        onChange={handleInputFileChang}
        multiple
      />
      <ButtonWithIcon
        text={t('attach_file')}
        wrapperClassName={style.inputFileButtonWrapper}
        buttonClassName={style.inputFileButton}
        icon={ClipIcon}
        onClick={handleClickInputFileButton}
      />
      {files.map(({ name }) => (
        <div key={name} className={style.uploadBlock}>
          <span className={style.uploadImage}>
            {name.length > 30 ? `${name.slice(0, 30)}...` : name}
          </span>
          <button
            type='button'
            className={style.uploadButton}
            onClick={() => handleDeleteFile(name)}
          >
            <Delete />
          </button>
        </div>
      ))}
      <Button
        color='primary'
        text={t('submit')}
        submit
        width='full'
        className={style.submitButton}
      />
    </form>
  );
};

export default observer(Question);
