import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import cn from 'classnames';
import style from './ProductItemDetailMobile.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import FavoritesButton from '../../../../components/ui/FavoritesButton/FavoritesButton';
import Preloader from '../../../../components/ui/Preloader/Preloader';
import TVChart from '../../../../components/TVChart/TVChart';
import PriceChangeBlock from '../../../../components/PriceChangeBlock/PriceChangeBlock';
import instrumentIcon from '../../../../assets/instrumentIcon';
import OpenTradeButtons from '../../../../components/OpenTradeButtons/OpenTradeButtons';
import OpenTradeContent from '../../../../components/OpenTradeContent/OpenTradeContent';
import { ReactComponent as BackIcon } from '../../../../assets/image/common/back.svg';
import TraderSentiment from '../../../../components/TraderSentiment/TraderSentiment';
import useOnClick from '../../../../hooks/useOnClick';

/**
 * Represents Instrument details component on Mobile version
 * @returns {JSX.Element}
 */

const ProductItemDetailMobile = () => {
  const { category, s } = useParams();
  const navigate = useNavigate();
  const {
    settings: { theme, language },
    finance: {
      getItem,
      activeItem,
      activeSymbol,
      clearItem,
      closeDataStreamItem,
      activeInterval,
      isLoading,
    },
  } = useRootModel();

  const [isOpen, setIsOpen] = useState(false);
  const tradeRef = useRef();

  const instType = instrumentIcon[activeItem?.type];
  const Icon = instType ? instType[activeItem?.s] : null;

  useEffect(() => {
    const fetchData = async () => {
      await getItem(category, s);
    };
    fetchData().then();

    return () => {
      closeDataStreamItem();
      clearItem();
    };
  }, [category, clearItem, closeDataStreamItem, getItem, s]);

  useOnClick(tradeRef, () => setIsOpen(false));

  if (isLoading)
    return (
      <div className={style.preloader}>
        <Preloader />
      </div>
    );

  return (
    activeItem.s && (
      <>
        <section className={style.contentSection}>
          {isOpen && <div className={style.blur} />}
          <div className={style.container}>
            <Link to='/' onClick={() => navigate(-1)} className={style.back}>
              <BackIcon />
            </Link>
            <div className={style.titleBlock}>
              <i className={style.icon}>{Icon && <Icon />}</i>
              <p className={style.title}>{activeItem?.alias}</p>
            </div>
            <FavoritesButton
              onClick={activeItem?.toggleFavorite}
              isFavorite={activeItem?.favorite}
              isLoading={activeItem?.isLoading}
              className={style.favorite}
            />
          </div>
          <div className={style.wrapper}>
            <PriceChangeBlock />
          </div>
          <div className={style.chart_container}>
            <TVChart
              symbol={activeSymbol}
              language={language}
              theme={theme}
              activeInterval={activeInterval}
              wrapperClassName={style.chartWrapper}
              className={style.chart}
            />
          </div>
          <div className={style.block}>
            <TraderSentiment />
          </div>
          <OpenTradeButtons
            onClickSell={() => setIsOpen(true)}
            onClickBuy={() => setIsOpen(true)}
          />
        </section>

        <div className={cn(style.containerOpenTrade, { [style.open]: isOpen })}>
          <button type='button' className={style.button} onClick={() => setIsOpen(false)}>
            <span className={style.button__line} />
          </button>
          <div className={style.containerOpenTrade__block} ref={tradeRef}>
            <OpenTradeContent />
          </div>
        </div>
      </>
    )
  );
};

export default observer(ProductItemDetailMobile);
