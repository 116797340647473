import React from 'react';
import cn from 'classnames';
import style from './ButtonCountdown.module.scss';
import Button from '../Button/Button';
import TimeCountdown from '../../TimeCountdown/TimeCountdown';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * Represents button with countdown timer
 * @param {string} text - text before seconds
 * @param {number} seconds - seconds for counting
 * @param {function} onClick - click handler
 * @param {CSSStyleRule} buttonClassName - class name for button
 * @param {CSSStyleRule} textClassName - class name for text
 * @param {function} handleChangeSeconds - change seconds handler
 * @param {string} label - button label
 * @param {CSSStyleRule} labelClassName - class name for label
 * @returns {JSX.Element}
 */
const ButtonCountdown = ({
  text,
  seconds,
  onClick,
  buttonClassName,
  textClassName,
  handleChangeSeconds,
  label = '',
  labelClassName,
}) => {
  const { isMobile, isLandscape } = useWindowSize();

  return (
    <Button
      size='medium'
      color='secondary'
      onClick={onClick}
      width={isMobile && 'full'}
      className={cn(style.button, buttonClassName)}
      disabled={seconds}
    >
      <p className={textClassName}>
        <span>{text} </span>
        <TimeCountdown
          seconds={seconds}
          onChange={handleChangeSeconds}
          endText={isMobile && !isLandscape && 'Send'}
        />
      </p>
      {label && <span className={cn(style.label, labelClassName)}>{label}</span>}
    </Button>
  );
};

export default ButtonCountdown;
