import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Tick } from '../../../../assets/image/common/tick.svg';

import styles from './StatusInfoDescTablet.module.scss';

/**
 * Represents loyalty status description component [tablet]
 * @returns {JSX.Element}
 */

const StatusInfoDescTablet = ({ type, minBalance, minVolume }) => {
  const { t } = useTranslation();

  const silver = type === 'silver';
  const gold = type === 'silver' || type === 'gold';
  const platinum = type === 'silver' || type === 'gold' || type === 'platinum';
  const diamond = type === 'silver' || type === 'gold' || type === 'platinum' || type === 'diamond';

  return (
    <div className={styles.statusInfoDescTablet}>
      <div className={styles.descItemsWrap}>
        <div className={styles.descItem}>
          <span className={styles.descItemText}>{t('analytical_support')}</span>
          <Tick className={cn(styles[type])} />
        </div>
        <div className={styles.descItem}>
          <span className={styles.descItemText}>{t('hours_support')}</span>
          <Tick className={cn(styles[type])} />
        </div>
        <div className={styles.descItem}>
          <span className={cn(styles.descItemText, { [styles.disabledText]: silver })}>
            {t('multiplier_change')}
          </span>
          <Tick className={cn(styles.tick, { [styles[type]]: !silver })} />
        </div>
      </div>
      <div className={styles.descItemsWrap}>
        <div className={styles.descItem}>
          <span
            className={cn(styles.descItemText, {
              [styles.disabledText]: gold,
            })}
          >
            {t('premium_signals')}
          </span>
          <Tick className={cn(styles.tick, { [styles[type]]: !gold })} />
        </div>
        <div className={styles.descItem}>
          <span
            className={cn(styles.descItemText, {
              [styles.disabledText]: platinum,
            })}
          >
            {t('vip_manager')}
          </span>
          <Tick className={cn(styles.tick, { [styles[type]]: !platinum })} />
        </div>
        <div className={styles.descItem}>
          <span
            className={cn(styles.descItemText, {
              [styles.disabledText]: diamond,
            })}
          >
            {t('individual_conditions')}
          </span>
          <Tick className={cn(styles.tick, { [styles[type]]: !diamond })} />
        </div>
      </div>
      <div className={cn(styles.descConditionsWrap, { [styles.noVisible]: silver })}>
        <div className={styles.descConditionsItem}>
          <p className={styles.conditionsText}>{t('balance_required')}</p>
          <span className={styles.conditionsSum}>${minBalance}</span>
        </div>
        <div className={styles.descConditionsItem}>
          <p className={styles.conditionsText}>{t('volume_required')}</p>
          <span className={styles.conditionsSum}>${minVolume}</span>
        </div>
      </div>
    </div>
  );
};

export default StatusInfoDescTablet;
