import React from 'react';
import cn from 'classnames';
import style from './ButtonOpenDown.module.scss';
import { ReactComponent as ArrowUp } from '../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/common/arrowDown.svg';

/**
 * Represents button for open down
 * @param {boolean} isOpen - is open
 * @param {function} handleClick - click handler
 * @param {CSSStyleRule} className - className for the button
 * @returns {JSX.Element}
 */
const ButtonOpenDown = ({ isOpen, handleClick, className }) => {
  return (
    <button
      type='button'
      onClick={handleClick}
      className={cn(style.button, { [style.active]: isOpen }, className)}
    >
      {isOpen ? <ArrowUp /> : <ArrowDown />}
    </button>
  );
};

export default ButtonOpenDown;
