import axios from 'axios';
import { flow, types } from 'mobx-state-tree';
import { sortByQuantity } from '../../../constant/commonConstants';
import sort from '../../../util/sort';
import error from '../../../util/error';
import TradesSymbolModel from './TradesSymbolModel';
import PeriodForTables from '../../common/PeriodForTables';

const PendingTradesItemModel = types
  .model('PendingTradesItemModel', {
    amount: types.optional(types.number, 0),
    closedAt: types.maybeNull(types.string),
    closingPrice: types.maybeNull(types.number),
    id: types.maybeNull(types.number),
    multiplier: types.integer,
    openedAt: types.string,
    openingPrice: types.maybeNull(types.number),
    operation: types.string,
    pendingPrice: types.maybeNull(types.number),
    profit: types.maybeNull(types.number),
    profitPercent: types.maybeNull(types.number),
    result: types.maybeNull(types.number),
    status: types.string,
    stopLoss: types.maybeNull(types.number),
    symbol: TradesSymbolModel,
    takeProfit: types.maybeNull(types.number),
    userId: types.integer,
    alias: types.optional(types.string, ''),
    favorites: types.optional(types.boolean, false),
  })
  .actions((tradesItem) => ({
    update({ lp, favorites }) {
      tradesItem.lastPrice = lp;
      tradesItem.favorites = favorites;
    },
  }));

const PendingTradesModel = types
  .model('PendingTradesModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    pendingTradeId: types.optional(types.number, 1),
    items: types.array(PendingTradesItemModel),
    page: types.optional(types.number, 1),
    pageSize: types.optional(types.number, sortByQuantity[0]),
    sortBy: types.optional(types.string, ''),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'asc'),
    period: PeriodForTables,
    searchName: types.optional(types.string, ''),
  })
  .actions((pendingTrades) => ({
    addItem(item) {
      pendingTrades.items = [...pendingTrades.items, item];
    },
    setIsLoading(loading) {
      pendingTrades.isLoading = loading;
    },
    setError(err) {
      pendingTrades.error = err;
    },
    setPage(page) {
      pendingTrades.page = page;
    },
    setPageSize(quantity) {
      pendingTrades.page = 1;
      pendingTrades.pageSize = parseInt(quantity, 10);
    },
    changePeriod(period) {
      pendingTrades.page = 1;
      pendingTrades.period = period;
    },
    setItems(items) {
      pendingTrades.items = items;
    },
    removeItem(id) {
      const { setItems, items } = pendingTrades;
      setItems(items.filter((i) => i.id !== id));
    },
    setSortBy(key) {
      pendingTrades.sortBy = key;
    },
    setSortDirection(direction) {
      pendingTrades.sortDirection = direction;
    },
    switchSortDirection() {
      pendingTrades.sortDirection = pendingTrades.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    setName(name) {
      pendingTrades.searchName = name;
    },
    clearError() {
      pendingTrades.error = '';
    },
    setPendingTradeId(id) {
      pendingTrades.pendingTradeId = id;
    },
    getPendingTrades: flow(function* getPendingTrades() {
      const { setIsLoading, setError, setItems } = pendingTrades;
      setIsLoading(true);
      try {
        const { data } = yield axios.get(`/services/trading/api/trades/pending`);
        const newData = data.map((item) => ({ ...item, alias: item.symbol.alias }));
        setItems(newData);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    setActive: flow(function* setActive(id) {
      const { setIsLoading, setError, clearError } = pendingTrades;
      setIsLoading(true);
      clearError();
      try {
        yield axios.post(`/services/trading/api/trades/${id}/activate`);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
  }))
  .views((pendingTrades) => ({
    get sortedTable() {
      const { items, sortDirection, sortBy, period, searchName } = pendingTrades;
      if (!sortBy) return items;
      const filteredArrayByPeriod = sort.sortArrayByPeriod(period, items);

      const filteredArrayByName = filteredArrayByPeriod.filter((item) =>
        item.symbol.alias.toLowerCase().includes(searchName.toLowerCase()),
      );

      return sort.sortArrayBy(filteredArrayByName, sortDirection, sortBy);
    },
    get sortedTableWithPagination() {
      const { sortedTable, page, pageSize } = pendingTrades;
      return sortedTable.slice((page - 1) * pageSize, pageSize * page);
    },
    get quantity() {
      const { sortedTable } = pendingTrades;
      return sortedTable.length > 99 ? '99+' : sortedTable.length;
    },
    get totalAmountPending() {
      const { sortedTable } = pendingTrades;
      return sortedTable.reduce((acc, current) => acc + ((current && current.amount) || 0), 0);
    },
    get allTradesAmount() {
      return pendingTrades.items.length;
    },
    get pendingTrade() {
      const { items, pendingTradeId } = pendingTrades;
      return items.find((i) => i.id === pendingTradeId);
    },
  }));

export default PendingTradesModel;
