import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';
import AlertContext from '../../../../../context/alert/alertContext';

import style from './PendingTradesMobileItem.module.scss';
import Button from '../../../../../components/ui/Button/Button';
import string from '../../../../../util/string';
import date from '../../../../../util/date';
import { useRootModel } from '../../../../../models/RootStore';
import { OPERATION } from '../../../../../constant/commonConstants';
import { ReactComponent as Up } from '../../../../../assets/image/common/upArr.svg';
import { ReactComponent as Down } from '../../../../../assets/image/common/downArr.svg';
import BalanceFormat from '../../../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents Instrument item in Pending trades on Mobile version
 * @param {number} id - instrument id
 * @param {string} alias - instrument title
 * @param {string} symbol - instrument symbol
 * @param {string} date - opening date
 * @param {number} pendingPrice - execution conditions
 * @param {number} takeProfit - profit limit
 * @param {number} stopLoss - loss limit
 * @param {number} amount - amount
 * @param {JSX.Element} Icon - instrument icon
 * @param {'BUY' | 'SELL'} operation - trade operation
 * @returns {JSX.Element}
 */

const PendingTradesMobileItem = ({
  id,
  alias,
  symbol,
  date: openedAt,
  pendingPrice,
  takeProfit,
  stopLoss,
  amount,
  icon: Icon,
  operation,
}) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    settings: { language },
    finance: {
      pendingTrades: { getPendingTrades, setActive },
      activeTrades: { getActiveTrades },
      closedTrades: { getClosedTrades },
      trade: { cancelTrade },
    },
  } = useRootModel();

  const handleSetActive = async () => {
    try {
      await setActive(id);
      getPendingTrades();
      getActiveTrades();
      alert.show(t('order_set_active'), 'success');
    } catch (err) {
      alert.show(err);
    }
  };

  const handleCancelTrade = async () => {
    try {
      await cancelTrade(id);
      getClosedTrades();
      alert.show(t('order_deleted'), 'success');
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.instrumentMobileItem}>
      <div className={style.iconAndPriceWrapper}>
        <div className={style.titleWrapper}>
          <i className={style.instrumentIcon}>
            <Icon />
          </i>
          <div>
            <div className={style.container}>
              <span>{alias}</span>
              <span>{operation === OPERATION.buy ? <Up /> : <Down />}</span>
            </div>
            <p className={style.number}>{symbol}</p>
          </div>
        </div>
        <div className={style.priceTop}>
          <div className={style.price}>
            <BalanceFormat value={amount} />
          </div>
          <p className={style.number}>
            {t('opening_date')} {date.toLocaleString(openedAt, language)}
          </p>
        </div>
      </div>
      <div className={style.iconAndPriceWrapper}>
        <div>
          <p className={cn(style.price, style.positionStart)}>
            {string.formatNumber(pendingPrice)}
          </p>
          <p className={style.number}>Execution conditions</p>
        </div>
        <div className={style.priceTop}>
          <div className={style.price}>
            {takeProfit ? <BalanceFormat value={takeProfit} /> : `-`} /{' '}
            {stopLoss ? <BalanceFormat value={stopLoss} /> : `-`}
          </div>
          <p className={style.number}>
            {t('profit_limit')} / {t('loss_limit')}
          </p>
        </div>
      </div>
      <div className={style.options}>
        <Button
          text={t('delete')}
          color='danger'
          size='small'
          className={style.button}
          onClick={handleCancelTrade}
        />
        <Button
          text={t('set_as_active')}
          size='small'
          className={style.button}
          onClick={handleSetActive}
        />
      </div>
    </div>
  );
};

export default observer(PendingTradesMobileItem);
