import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './SearchModal.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import { ReactComponent as ClearIcon } from '../../../../assets/image/common/clearText.svg';
import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import TextInput from '../../../ui/TextInput/TextInput';
import SearchModalFooter from './SearchModalFooter/SearchModalFooter';

/**
 * Represents SearchModal component
 * @returns {JSX.Element}
 */
const SearchModal = () => {
  const { t } = useTranslation();
  const {
    modal: {
      search: { isOpen, close },
    },
    search: { term, setTerm, clearTerm, getSearch, clearData },
  } = useRootModel();

  const inputRef = useRef(null);

  useEffect(() => {
    // todo focus
    inputRef?.current?.focus();
  }, [inputRef?.current]);

  useEffect(() => {
    if (!isOpen) {
      clearTerm();
      clearData();
    }
  }, [isOpen]);

  const CloseButton = () => (
    <button type='button' className={style.clearButton} onClick={clearTerm}>
      <ClearIcon />
    </button>
  );

  const handleOnChange = async ({ target: { value } }) => {
    await setTerm(value);
    getSearch();
  };
  return (
    isOpen && (
      <Modal header headerTitle={t('search')} handleClose={close} wrapperClassname={style.modal}>
        <ModalBody className={style.body}>
          <TextInput
            width='full'
            value={term}
            rightIcon={CloseButton}
            onChange={handleOnChange}
            placeholder={t('typing')}
            labelClassName={style.inputLabel}
            inputRef={inputRef}
          />
        </ModalBody>
        <SearchModalFooter />
      </Modal>
    )
  );
};

export default observer(SearchModal);
