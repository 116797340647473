import React from 'react';
import cn from 'classnames';

import { observer } from 'mobx-react';
import style from './ActiveTradesItem.module.scss';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import string from '../../../../../../util/string';
import { useRootModel } from '../../../../../../models/RootStore';
import date from '../../../../../../util/date';
import { OPERATION } from '../../../../../../constant/commonConstants';

import { ReactComponent as Down } from '../../../../../../assets/image/common/downArr.svg';
import { ReactComponent as Up } from '../../../../../../assets/image/common/upArr.svg';
import { ReactComponent as UpArr } from '../../../../../../assets/image/common/up.svg';
import { ReactComponent as DownArr } from '../../../../../../assets/image/common/down.svg';
import { ReactComponent as Close } from '../../../../../../assets/image/common/cancel.svg';
import BalanceFormat from '../../../../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents instrument item in active trades
 * @param {number} id - trade id
 * @param {string} title - trade title
 * @param {string} symbol - trade symbol
 * @param {JSX.Element} Icon - icon for instrument
 * @param {string} date - trade opening date
 * @param {number} price - trade price
 * @param {number} funds - used funds
 * @param {number} multiplier - trade multiplier
 * @param {number} result - current account status
 * @param {number} profit - earnings or loss in $
 * @param {number} profitPercent - earnings or loss in %
 * @param {'BUY' | 'SELL'} operation - trade operation
 * @param {boolean} isPercent - show % or $
 * @returns {JSX.Element}
 */
const ActiveTradesItem = ({
  title = '',
  id,
  symbol,
  icon: Icon,
  date: openedAt,
  price,
  funds,
  multiplier,
  result,
  profit,
  profitPercent,
  operation,
  isPercent,
}) => {
  const { isDesktop, isLaptop } = useWindowSize();

  const {
    modal: { closeTrade, activeTrade },
    settings: { language },
    finance: {
      // setActiveSymbol,
      activeTrades: { setActiveTradeId },
    },
  } = useRootModel();

  const setSymbol = async () => {
    // await setActiveSymbol(symbol);
    await setActiveTradeId(id);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    await setSymbol();
    closeTrade.open();
  };

  const handleClickInfo = async (e) => {
    e.stopPropagation();
    await setSymbol();
    activeTrade.open();
  };
  const [firstPair, secondPair] = title.split('/');

  return (
    <div
      role='button'
      className={style.activeTradesItem}
      onClick={handleClickInfo}
      onKeyDown={handleClickInfo}
      tabIndex={-1}
    >
      <div className={style.titleWrapper}>
        <i className={style.instrumentIcon}>{Icon && <Icon />}</i>
        <div>
          <div className={style.title}>
            <p>
              <span>{firstPair}</span>
              {secondPair && <span className={style.secondPair}>/{secondPair}</span>}
            </p>
            <p className={style.symbol}>{symbol}</p>
          </div>
          {isLaptop && (
            <p className={style.laptopDate}>{date.toLocaleString(openedAt, language)}</p>
          )}
        </div>
        {operation === OPERATION.buy ? <Up /> : <Down />}
      </div>

      {isDesktop && <div>{date.toLocaleString(openedAt, language)}</div>}

      <div>
        {string.formatNumber(price)}
        {isLaptop && (
          <div className={style.fundsLaptop}>
            <BalanceFormat value={funds} />x{multiplier}
          </div>
        )}
      </div>

      {isDesktop && (
        <div className={style.amountWrapper}>
          <BalanceFormat value={funds} /> <span className={style.funds}>x {multiplier}</span>
        </div>
      )}

      {isDesktop && (
        <div>
          <BalanceFormat value={result} />
        </div>
      )}

      <div>-</div>

      <div>-</div>

      <div className={style.profit}>
        {isDesktop && <p>{profit > 0 ? <UpArr /> : <DownArr />}</p>}
        {isDesktop && (
          <div>
            {!isPercent ? (
              <div className={cn(style.priceDown, { [style.priceUp]: profit > 0 })}>
                <BalanceFormat value={profit} />
              </div>
            ) : (
              <span
                className={cn(style.priceDown, {
                  [style.priceUp]: profitPercent > 0,
                })}
              >
                ({string.formatPercent(profitPercent, { withSign: true })})
              </span>
            )}
          </div>
        )}
        {isLaptop && (
          <div>
            <div className={cn(style.priceDown, { [style.priceUp]: profit > 0 })}>
              <BalanceFormat value={profit} />
            </div>
            <p
              className={cn(style.percent, style.priceDown, {
                [style.priceUp]: profitPercent > 0,
              })}
            >
              ({string.formatPercent(profitPercent, { withSign: true })})
            </p>
          </div>
        )}

        <button type='button' onClick={handleDelete} className={cn(style.button, style.red)}>
          <Close />
        </button>
      </div>
    </div>
  );
};

export default observer(ActiveTradesItem);
