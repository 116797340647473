import { types, flow } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../util/error';

const CryptoItemBalanceModel = types.model('CryptoItemBalanceModel', {
  balance: types.optional(types.number, 0),
  convertedBalance: types.optional(types.number, 0),
  currency: types.optional(types.string, ''),
});

const DemoAccountModel = types.model('DemoAccountModel', {
  id: types.maybeNull(types.number),
  balance: types.optional(types.number, 0),
});

const RealAccountModel = types.model('RealAccountModel', {
  id: types.maybeNull(types.number),
  balance: types.optional(types.number, 0),
  cryptoBalance: types.array(CryptoItemBalanceModel),
});

const AccountModel = types.model('AccountModel', {
  demoAccount: types.maybeNull(DemoAccountModel),
  realAccount: types.optional(RealAccountModel, {}),
});

const ExchangeRatesItem = types.model('ExchangeRatesItem', {
  currency: types.optional(types.string, ''),
  rate: types.optional(types.number, 0),
  commission: types.optional(types.number, 0),
});

const DepositAddressModel = types.model('DepositAddressModel', {
  address: types.optional(types.string, ''),
  currency: types.optional(types.string, ''), // todo remove ?
});

const PaymentServiceModel = types
  .model('PaymentServiceModel', {
    alert: types.optional(types.string, ''),
    accounts: types.optional(AccountModel, {}),
    depositCurrency: types.optional(types.string, 'LTC'),
    withdrawCurrency: types.optional(types.string, 'BTC'),
    exchangeCurrency: types.optional(types.string, 'BTC'),
    exchangeRates: types.array(ExchangeRatesItem),
    depositAddress: types.optional(DepositAddressModel, {}),
  })
  .actions((accountsData) => ({
    setWithdrawCurrency(currency) {
      accountsData.withdrawCurrency = currency;
    },
    setExchangeCurrency(currency) {
      accountsData.exchangeCurrency = currency;
    },
    setDepositCurrency(currency) {
      accountsData.depositCurrency = currency;
    },
    setDepositAddress(address) {
      accountsData.depositAddress = address;
    },
    getAccounts: flow(function* getAccounts() {
      try {
        const { data } = yield axios.get(`/services/payment/api/accounts`);
        accountsData.accounts = data;
      } catch (err) {
        const message = err.response?.data.message || err.message;
        accountsData.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
    getExchangeRates: flow(function* getExchangeRates() {
      try {
        const { data } = yield axios.get(`/services/payment/api/exchange-rates`);
        accountsData.exchangeRates = data;
      } catch (err) {
        const message = err.response?.data.message || err.message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
    withdraw: flow(function* withdraw(address, amount) {
      const { withdrawCurrency } = accountsData;

      try {
        const withdrawData = {
          address,
          amount,
          currency: withdrawCurrency,
        };

        yield axios.post(`/services/payment/api/accounts/real/withdraw`, withdrawData);
      } catch (err) {
        const message = err.response?.data.message || err.message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
    exchange: flow(function* exchange(amount) {
      const { exchangeCurrency } = accountsData;
      try {
        const exchangeData = {
          amount,
          currency: exchangeCurrency,
        };

        yield axios.post(`/services/payment/api/accounts/real/exchange`, exchangeData);
      } catch (err) {
        const message = err.response?.data.message || err.message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
    deposit: flow(function* deposit() {
      const { depositCurrency, setDepositAddress } = accountsData;
      try {
        const { data } = yield axios.get(
          `/services/payment/api/accounts/address?currency=${depositCurrency}`,
        );

        setDepositAddress(data);
      } catch (err) {
        const message = err.response?.data.message || err.message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
  }))
  .views((accountsData) => ({
    get maxWithdrawBalance() {
      const {
        withdrawCurrency,
        accounts: {
          realAccount: { cryptoBalance },
        },
      } = accountsData;
      return cryptoBalance.find((item) => item.currency === withdrawCurrency)?.balance;
    },
    get maxExchangeBalance() {
      const {
        exchangeCurrency,
        accounts: {
          realAccount: { cryptoBalance },
        },
      } = accountsData;
      return cryptoBalance.find((item) => item.currency === exchangeCurrency)?.balance;
    },
    get rate() {
      const { exchangeRates, exchangeCurrency } = accountsData;
      const total = exchangeRates.find((item) => item.currency === exchangeCurrency)?.rate || 0;
      return +total;
    },
    get commission() {
      const { exchangeRates, exchangeCurrency } = accountsData;
      const total =
        exchangeRates.find((item) => item.currency === exchangeCurrency)?.commission || 0;
      return +total;
    },
  }));

export default PaymentServiceModel;
