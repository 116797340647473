import React from 'react';
import { observer } from 'mobx-react';

import style from './ActiveTradesList.module.scss';
import instrumentIcon from '../../../../../assets/instrumentIcon';

import ActiveTradesItem from './ActiveTradesItem/ActiveTradesItem';
import NoTrade from '../../../../../components/NoTrade/NoTrade';

/**
 * Represents active trades table
 * @returns {JSX.Element}
 */
const ActiveTradesList = ({ items, isPercent }) => {
  return (
    <div className={style.table}>
      {items?.length > 0 ? (
        items.map(
          ({
            id,
            openingPrice,
            amount,
            multiplier,
            openedAt,
            symbol,
            result,
            profit,
            profitPercent,
            operation,
          }) => {
            const { alias, s, type } = symbol;
            const icon = instrumentIcon[type][s];
            return (
              <ActiveTradesItem
                key={id}
                id={id}
                title={alias}
                symbol={s}
                icon={icon}
                date={openedAt}
                price={openingPrice}
                funds={amount}
                multiplier={multiplier}
                result={result}
                profit={profit}
                profitPercent={profitPercent}
                operation={operation}
                isPercent={isPercent}
              />
            );
          },
        )
      ) : (
        <NoTrade />
      )}
    </div>
  );
};

export default observer(ActiveTradesList);
