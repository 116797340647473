import React, { useContext, useRef } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import style from './Alert.module.scss';
import AlertContext from '../../../context/alert/alertContext';
import getAnimationClasses from '../../../util/getAnimationClasses';

import { ReactComponent as CloseIcon } from '../../../assets/image/common/closeIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/image/common/errorIconMini.svg';
import { ReactComponent as WarningIcon } from '../../../assets/image/common/warningIconMini.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/image/common/successIconMini.svg';
import { ReactComponent as InfoIcon } from '../../../assets/image/common/infoIconMini.svg';
import Portal from '../Portal/Portal';
import useOnClick from '../../../hooks/useOnClick';
import useExecutorForKey from '../../../hooks/useExecutorForKey';

const icon = {
  error: ErrorIcon,
  warning: WarningIcon,
  success: SuccessIcon,
  info: InfoIcon,
};

/**
 * Represents Alert component
 * @returns {JSX.Element | null}
 */
const Alert = () => {
  const {
    alert: { text, visible, type },
    hide,
  } = useContext(AlertContext);
  const wrapperRef = useRef();
  useOnClick(wrapperRef, hide);
  useExecutorForKey('Escape', hide);

  const Icon = icon[type];
  let prefix;

  switch (type) {
    case 'info':
      prefix = 'Information!';
      break;
    case 'success':
      prefix = 'Successful operation!';
      break;
    case 'warning':
      prefix = 'Warning!';
      break;
    default:
      prefix = 'Error';
  }

  return (
    <CSSTransition timeout={700} in={visible} unmountOnExit classNames={getAnimationClasses(style)}>
      <Portal>
        <div className={cn(style.alert, style[type])} ref={wrapperRef}>
          {Icon && <Icon />}
          <p className={style.title}>
            {prefix}
            <span className={style.text}>{text}</span>
          </p>
          <button type='button' onClick={hide} className={style.closeButton}>
            <CloseIcon />
          </button>
        </div>
      </Portal>
    </CSSTransition>
  );
};

export default Alert;
