import { types, flow, getRoot } from 'mobx-state-tree';
import axios from 'axios';
import { DEFAULT_CATEGORY } from '../../constant/commonConstants';
import error from '../../util/error';

const InstrumentItemModel = types
  .model('InstrumentItemModel', {
    isLoading: types.optional(types.boolean, false),
    purchase: types.optional(types.number, 0),
    sale: types.optional(types.number, 0),
    s: types.optional(types.string, ''),
    alias: types.optional(types.string, ''),
    type: types.optional(types.string, DEFAULT_CATEGORY),
    lp: types.optional(types.number, 0),
    h: types.optional(types.number, 0),
    l: types.optional(types.number, 0),
    cpd: types.optional(types.number, 0),
    hw: types.optional(types.number, 0),
    lw: types.optional(types.number, 0),
    cpw: types.optional(types.number, 0),
    hm: types.optional(types.number, 0),
    lm: types.optional(types.number, 0),
    cpm: types.optional(types.number, 0),
    hsm: types.optional(types.number, 0),
    lsm: types.optional(types.number, 0),
    cpsm: types.optional(types.number, 0),
    hy: types.optional(types.number, 0),
    ly: types.optional(types.number, 0),
    cpy: types.optional(types.number, 0),
    sm: types.optional(types.number, 0),
    t: types.optional(types.integer, 0),
    oldPrice: types.optional(types.number, 0),
    bid: types.optional(types.number, 0),
    ask: types.optional(types.number, 0),
    favorite: types.optional(types.boolean, false),
  })
  .actions((item) => ({
    setIsFavorite(favorite) {
      item.favorite = favorite;
    },
    toggleIsFavorite() {
      item.setIsFavorite(!item.favorite);
    },
    setIsLoading(load) {
      item.isLoading = load;
    },
    toggleFavorite: flow(function* toggleFavorite() {
      const { setIsLoading, s, toggleIsFavorite } = item;
      const {
        finance: { favoriteTrades },
      } = getRoot(item);

      setIsLoading(true);
      try {
        yield axios.post(`/services/trading/api/symbols/favorites?symbol=${s}`);
        favoriteTrades?.getFavorites();
        toggleIsFavorite();
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        error.errorHandler(message);
      } finally {
        item.setIsLoading(false);
      }
    }),
    update(data) {
      const {
        cpd,
        cpm,
        cpsm,
        cpw,
        cpy,
        t,
        sm,
        oldPrice,
        // favorite,
        bid,
        ask,
        h,
        hm,
        hsm,
        hw,
        hy,
        l,
        lm,
        lp,
        lsm,
        lw,
        ly,
      } = data;

      item.cpd = cpd;
      item.cpm = cpm;
      item.cpsm = cpsm;
      item.cpw = cpw;
      item.cpy = cpy;
      item.t = t;
      item.sm = sm;
      item.oldPrice = oldPrice;
      // item.favorite = favorite;
      item.bid = bid;
      item.ask = ask;
      item.h = h;
      item.hm = hm;
      item.hsm = hsm;
      item.hw = hw;
      item.hy = hy;
      item.l = l;
      item.lm = lm;
      item.lp = lp;
      item.lsm = lsm;
      item.lw = lw;
      item.ly = ly;
    },
  }));

export default InstrumentItemModel;
