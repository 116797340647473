import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Faq from './FAQ';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents support page
 * @returns {JSX.Element}
 */

const Support = () => {
  const {
    modal: {
      deposit: { close },
    },
  } = useRootModel();

  useEffect(() => {
    close();
  }, []);
  return <Faq />;
};

export default observer(Support);
