import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import cn from 'classnames';

import style from './RegistrationForm.module.scss';
import {
  EMAIL_MAX_LENGTH,
  ERROR_TEXT_REQUIRED,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PHONE_MAX_LENGTH,
  PHONE_MIN_LENGTH,
} from '../../../util/validationSchema';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';
import Button from '../../../components/ui/Button/Button';
import { ReactComponent as EmailIcon } from '../../../assets/image/common/email.svg';
import { ReactComponent as PasswordIcon } from '../../../assets/image/common/password.svg';
import { ReactComponent as VisibleOff } from '../../../assets/image/common/visibilityOff.svg';
import { ReactComponent as VisibleLogo } from '../../../assets/image/common/visibility.svg';
// eslint-disable-next-line max-len
import CountryAndCodeDropdown from '../../../components/ui/CountryAndCodeDropdown/CountryAndCodeDropdown';
import Checkbox from '../../../components/ui/Checkbox/Checkbox';
import PlatformCurrencyInput from '../../../components/PlatformCurrencyInput/PlatformCurrencyInput';
import { platformCurrency } from '../../../constant/walletConstants';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const RegistrationForm = () => {
  const { t } = useTranslation();
  const { refCode } = useParams();
  const navigate = useNavigate();
  const [currency, setCurrency] = useState(
    timeZone.includes('Europe') ? platformCurrency[1] : platformCurrency[0],
  );
  const [phoneCode, setPhoneCode] = useState('');
  const [errorPhoneCode, setErrorPhoneCode] = useState('');
  const [emailInputActive, setEmailInputActive] = useState(false);
  const [passwordInputActive, setPasswordInputActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const alert = useContext(AlertContext);
  const { user } = useRootModel();
  const { register, getUser, countryCode } = user;

  const formik = useFormik({
    initialValues: {
      registrationEmail: '',
      phoneNumber: '',
      registrationPassword: '',
      currency: 'EURO',
    },
    validationSchema: Yup.object({
      registrationEmail: Yup.string()
        .email('Invalid email')
        .max(EMAIL_MAX_LENGTH, `Must be ${EMAIL_MAX_LENGTH} characters or less`)
        .required(ERROR_TEXT_REQUIRED),
      phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(PHONE_MIN_LENGTH, `Must be at least ${PHONE_MIN_LENGTH} characters`)
        .max(PHONE_MAX_LENGTH, `Must be ${PHONE_MAX_LENGTH} characters or less`)
        .required(ERROR_TEXT_REQUIRED),
      registrationPassword: Yup.string()
        .min(PASSWORD_MIN_LENGTH, `Must be at least ${PASSWORD_MIN_LENGTH} characters`)
        .max(PASSWORD_MAX_LENGTH, `Must be ${PASSWORD_MAX_LENGTH} characters or less`)
        .required(ERROR_TEXT_REQUIRED),
    }),
    onSubmit: async ({ registrationEmail, registrationPassword, phoneNumber }) => {
      try {
        await register(
          registrationEmail.toLowerCase(),
          registrationPassword,
          phoneCode,
          phoneNumber,
          refCode,
          currency.id,
        );
        await getUser();
      } catch (e) {
        alert.show(t(e.message));
      }
    },
  });

  const handleChangePhoneNumber = (e) => {
    if (!/^[0-9]*$/.test(e.target.value)) return;
    formik.handleChange(e);
  };

  useEffect(() => {
    if (user.isAuthorized) {
      navigate('/');
    }
  }, [navigate, user.isAuthorized]);

  return (
    <form className={style.formRegistration} onSubmit={formik.handleSubmit}>
      <div className={style.inputContainer}>
        <div className={style.labelWrapper}>
          <label htmlFor='registrationEmail' className={style.label}>
            {t('email')}
          </label>
          {formik.touched.registrationEmail && formik.errors.registrationEmail && (
            <p className={style.error}>{formik.errors.registrationEmail}</p>
          )}
        </div>
        <div className={style.inputWrapper}>
          <p
            className={cn(style.icon, {
              [style.active]: emailInputActive,
              [style.errorIcon]:
                formik.touched.registrationEmail && formik.errors.registrationEmail,
            })}
          >
            <EmailIcon />
          </p>
          <input
            id='registrationEmail'
            name='registrationEmail'
            type='email'
            placeholder={t('enter_email')}
            value={formik.values.registrationEmail}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              setEmailInputActive(false);
            }}
            onFocus={() => setEmailInputActive(true)}
            className={cn(style.input, {
              [style.errorBorder]:
                formik.touched.registrationEmail && formik.errors.registrationEmail,
            })}
          />
        </div>
      </div>

      <div className={style.inputContainer}>
        <div className={style.labelWrapper}>
          <label htmlFor='registrationEmail' className={style.label}>
            {t('phone')}
          </label>
          <p className={style.error}>
            {(formik.touched.phoneNumber &&
              formik.errors.phoneNumber &&
              formik.errors.phoneNumber) ||
              errorPhoneCode}
          </p>
        </div>

        <div className={style.phoneContainer}>
          <CountryAndCodeDropdown
            showError
            content='dialCode'
            onChange={setPhoneCode}
            value={phoneCode}
            onChangeInnerError={setErrorPhoneCode}
            dropdownClassName={style.dropdown}
            inputClassName={style.inputPhoneCode}
            errorClassName={style.errorClassName}
            countryCode={countryCode}
          />
          <input
            id='phoneNumber'
            name='phoneNumber'
            type='text'
            placeholder={t('number')}
            value={formik.values.phoneNumber}
            onChange={handleChangePhoneNumber}
            onBlur={formik.handleBlur}
            maxLength={PHONE_MAX_LENGTH}
            className={cn(style.input, style.number, {
              [style.errorBorder]: formik.touched.phoneNumber && formik.errors.phoneNumber,
            })}
            autoComplete='nope'
          />
        </div>
      </div>

      <div className={style.inputContainer}>
        <div className={style.labelWrapper}>
          <label htmlFor='registrationPassword' className={style.label}>
            {t('main_platform_currency')}
          </label>
        </div>
        <PlatformCurrencyInput currency={currency} setCurrency={setCurrency} />
      </div>

      <div className={style.inputContainer}>
        <div className={style.labelWrapper}>
          <label htmlFor='registrationPassword' className={style.label}>
            {t('password')}
          </label>
          {formik.touched.registrationPassword && formik.errors.registrationPassword && (
            <p className={style.error}>{formik.errors.registrationPassword}</p>
          )}
        </div>
        <div className={style.inputWrapper}>
          <p
            className={cn(style.icon, {
              [style.active]: passwordInputActive,
              [style.errorIcon]:
                formik.touched.registrationPassword && formik.errors.registrationPassword,
            })}
          >
            <PasswordIcon />
          </p>
          <input
            id='registrationPassword'
            name='registrationPassword'
            type={showPassword ? 'text' : 'password'}
            placeholder={t('enter_password')}
            value={formik.values.registrationPassword}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              setPasswordInputActive(false);
            }}
            onFocus={() => setPasswordInputActive(true)}
            maxLength={PASSWORD_MAX_LENGTH}
            className={cn(style.input, {
              [style.errorBorder]:
                formik.touched.registrationPassword && formik.errors.registrationPassword,
            })}
          />
          <div className={style.passwordIconVisibility}>
            <button
              className={style.passwordButton}
              type='button'
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibleOff /> : <VisibleLogo />}
            </button>
          </div>
        </div>
      </div>

      <div className={style.agreement}>
        <Checkbox checked={agreement} handleChange={() => setAgreement(!agreement)} />
        <p>
          Agree to{' '}
          <Link to='/info/about' className={style.agreement__link}>
            Terms and Conditions
          </Link>
        </p>
      </div>

      <Button
        text={t('register')}
        color='primary'
        disabled={
          !(
            formik.isValid &&
            formik.dirty &&
            errorPhoneCode === '' &&
            phoneCode !== '' &&
            agreement
          )
        }
        submit
      />
    </form>
  );
};

export default observer(RegistrationForm);
