import React, { useState } from 'react';

import style from './ForgotPassword.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import { useRootModel } from '../../models/RootStore';

import Logo from '../../components/ui/Logo/Logo';
import BackButton from '../../components/ui/BackButton/BackButton';
import ForgotPasswordEmail from './ForgotPasswordEmail/ForgotPasswordEmail';
import ForgotPasswordConfirm from './ForgotPasswordConfirm/ForgotPasswordConfirm';
import Copyrights from '../../components/ui/Copyrights/Copyrights';

/**
 * Represents a forgot password page.
 * @returns {JSX.Element}
 */
const ForgotPassword = () => {
  const { isDesktop } = useWindowSize();
  const {
    user: { resetPasswordInit },
  } = useRootModel();
  const [response, setResponse] = useState(false);
  const [showCopyrights, setShowCopyrights] = useState(true);

  const onSubmit = (email) => {
    resetPasswordInit(email);
    setResponse(true);
  };

  const handleInputFocus = () => setShowCopyrights(false);
  const handleInputBlur = () => setShowCopyrights(true);

  return (
    <section className={style.forgotPasswordWrapper}>
      <div className={style.forgotPassword}>
        <BackButton url='/login' className={style.backButton} />
        <header className={style.header}>
          <Logo />
        </header>
        {response ? (
          <ForgotPasswordConfirm />
        ) : (
          <ForgotPasswordEmail
            onInputFocus={handleInputFocus}
            onInputBlur={handleInputBlur}
            onSubmit={onSubmit}
          />
        )}
      </div>
      {isDesktop ? <Copyrights /> : showCopyrights && <Copyrights />}
    </section>
  );
};

export default ForgotPassword;
