import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import style from './ConvertMoney.module.scss';
import Button from '../ui/Button/Button';
import { useRootModel } from '../../models/RootStore';
import { ReactComponent as SwitchIcon } from '../../assets/image/common/exchangeSwitch.svg';
import { ReactComponent as EqualIcon } from '../../assets/image/common/equal.svg';
import { ReactComponent as UsdIcon } from '../../assets/image/common/usd.svg';
import SelectCurrency from '../SelectCurrency/SelectCurrency';
import { ERROR_TEXT_REQUIRED } from '../../util/validationSchema';
import { balanceCurrency } from '../../constant/walletConstants';
import { amountWithCommission } from '../../util/financeUtils';
import useWindowSize from '../../hooks/useWindowSize';
import { formatAmountWithSpaces } from '../../util/string';

const ConvertMoney = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const {
    user: {
      userData: { userCurrency },
    },
    payment: {
      getExchangeRates,
      getAccounts,
      exchange,
      exchangeCurrency,
      maxExchangeBalance,
      rate,
      commission,
    },
    modal: {
      convert: { close },
    },
  } = useRootModel();

  const formik = useFormik({
    initialValues: {
      exchangeAmount: '',
    },
    validationSchema: Yup.object({
      exchangeAmount: Yup.number()
        .min(0.0002, 'Less then 0.0002')
        .max(maxExchangeBalance, 'Insufficient funds')
        .required(ERROR_TEXT_REQUIRED),
    }),
    onSubmit: async ({ exchangeAmount }, { resetForm }) => {
      try {
        await exchange(exchangeAmount);
        await getAccounts();
        resetForm();
        alert.show(t('exchange_success'), 'success');
      } catch (e) {
        alert.show(t(e.message));
      }
    },
  });

  const handleChange = (e) => {
    const { value } = e.target;

    if (/^\d+(\.?\d*)?$/.test(value) || value === '') {
      formik.handleChange(e);
    }
  };

  const calculatedAmount = useMemo(
    () =>
      Number((amountWithCommission(formik.values.exchangeAmount, commission) * rate).toFixed(10)),
    [formik.values.exchangeAmount, rate, commission],
  );

  useEffect(() => {
    formik.setFieldValue('exchangeAmount', '');
  }, [exchangeCurrency]);

  useEffect(() => {
    getExchangeRates();
  }, [getExchangeRates]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={cn(style.container, {
          [style.errorBorder]: formik.touched.exchangeAmount && formik.errors.exchangeAmount,
        })}
      >
        <div>
          <label htmlFor='exchangeAmount' className={style.container__text}>
            {t('quantity')} ({balanceCurrency[exchangeCurrency]?.title})
          </label>
          <div className={style.container__wrapper}>
            <input
              id='exchangeAmount'
              name='exchangeAmount'
              value={formik.values.exchangeAmount}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              type='text'
              autoComplete='off'
              className={style.container__amount}
            />
          </div>
        </div>
        <SelectCurrency />
      </div>
      {formik.touched.exchangeAmount && formik.errors.exchangeAmount && (
        <p className={style.error}>{formik.errors.exchangeAmount}</p>
      )}
      <button type='button' className={style.reverseButton} onClick={() => {}}>
        <SwitchIcon />
      </button>
      <div className={style.container}>
        <div>
          <p className={style.container__text}>Receive</p>
          <div className={style.container__wrapper}>
            <p>$</p>
            <input
              value={formatAmountWithSpaces(calculatedAmount)}
              type='text'
              className={style.container__input}
              readOnly
            />
          </div>
        </div>
        <div className={style.container__block}>
          <UsdIcon />
          <p>{userCurrency}</p>
        </div>
      </div>

      <div className={style.rate}>
        <p>{commission ? `${commission}% ${t('commission')}` : t('no_extra_fees')}</p>
        <div className={style.rate__block}>
          1 {balanceCurrency[exchangeCurrency]?.title} <EqualIcon /> {rate} {userCurrency}
        </div>
      </div>

      <div className={style.buttonWrapper}>
        {isMobile ? (
          <Link to='/balance' className={style.cancel}>
            {t('cancel')}
          </Link>
        ) : (
          <Button
            text={t('cancel')}
            color='secondary'
            className={style.requestButton}
            onClick={close}
          />
        )}

        <Button
          text={t('convert')}
          color='primary'
          className={style.requestButton}
          disabled={!(formik.isValid && formik.dirty)}
          submit
        />
      </div>
    </div>
  );
};

export default observer(ConvertMoney);
