import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';

import style from './CurrencyInput.module.scss';
import useOnClick from '../../hooks/useOnClick';
import { ReactComponent as ArrowUp } from '../../assets/image/common/arrowUpList.svg';
import { ReactComponent as ArrowDown } from '../../assets/image/common/arrowDownList.svg';
import { balanceCurrency } from '../../constant/walletConstants';

/**
 * Represents Currency input component
 * @param {currency} string - currency
 * @param {function} onChange - handler onChange currency
 * @returns {JSX.Element}
 */

const CurrencyInput = ({ currency, onChange }) => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(false);
  const ref = useRef();
  const {
    payment: {
      accounts: {
        realAccount: { cryptoBalance },
      },
    },
  } = useRootModel();

  const handleOnClick = () => {
    setShowContent(!showContent);
  };

  // eslint-disable-next-line no-shadow
  const handleClick = (currency) => {
    onChange(currency);
    setShowContent(false);
  };

  useOnClick(ref, () => setShowContent(false));

  return (
    <div>
      <p className={style.currencyTitle}>{t('currency')}</p>
      <div className={style.currencyContainer} ref={ref}>
        <button className={style.currencyButton} type='button' onClick={handleOnClick}>
          <div className={style.titleWrapper}>
            {balanceCurrency[currency]?.icon}
            <p>{balanceCurrency[currency]?.label}</p>
          </div>
          {showContent ? <ArrowUp /> : <ArrowDown />}
        </button>
        <CSSTransition
          in={showContent}
          timeout={700}
          classNames={{
            enter: style.enter,
            enterActive: style.enterActive,
            enterDone: style.enterDone,
            exit: style.exit,
            exitActive: style.exitActive,
            exitDone: style.exitDone,
          }}
          unmountOnExit
        >
          <div className={style.currencyContent}>
            {cryptoBalance
              .filter(
                // eslint-disable-next-line no-shadow
                ({ currency }) =>
                  currency === 'BTC' ||
                  currency === 'ETH' ||
                  currency === 'LTC' ||
                  currency === 'USDT_ERC20',
              ) // todo delete filter after api change
              // eslint-disable-next-line no-shadow
              .map(({ currency }) => (
                <button
                  type='button'
                  className={style.optionButton}
                  key={currency}
                  onClick={() => handleClick(currency)}
                >
                  {balanceCurrency[currency]?.icon}
                  <p>{balanceCurrency[currency]?.label}</p>
                </button>
              ))}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default observer(CurrencyInput);
