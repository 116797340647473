import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../util/error';

const MarketItemModel = types.model('MarketItem', {
  closingTime: types.optional(types.string, ''),
  exchange: types.optional(types.string, ''),
  location: types.optional(types.string, ''),
  openingTime: types.optional(types.string, ''),
});

const MarketModel = types
  .model('Market', {
    items: types.array(MarketItemModel),
    isLoading: types.optional(types.boolean, false),
    alert: types.optional(types.string, ''),
  })
  .actions((market) => ({
    getTradingHours: flow(function* getTradingHours() {
      market.isLoading = true;
      try {
        const { data } = yield axios.get(`/services/trading/api/market/trading-hours`);
        market.items = data;
      } catch (e) {
        market.alert = e.message;
        error.errorHandler(e.message);
      } finally {
        market.isLoading = false;
      }
    }),
  }));

export default MarketModel;
