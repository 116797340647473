import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';
import { Link } from 'react-router-dom';
import style from './Balance.module.scss';

import { useRootModel } from '../../models/RootStore';
import string from '../../util/string';
import { balanceCurrency } from '../../constant/walletConstants';
import BalanceFormat from '../../components/BalanceFormat/BalanceFormat';
import useWindowSize from '../../hooks/useWindowSize';
import DemoAccount from '../../components/RightSideBlock/DemoAccount/DemoAccount';

/**
 * Represents balance component
 * @returns {JSX.Element}
 */
const Balance = () => {
  const { t } = useTranslation();
  const { isDesktop, isLaptop, isMobile } = useWindowSize();
  const {
    user: {
      userData: { balanceUser },
    },
    payment: {
      getAccounts,
      accounts: {
        realAccount: { cryptoBalance },
      },
      setDepositCurrency,
      setWithdrawCurrency,
      setExchangeCurrency,
    },
    modal: { deposit, withdraw, convert, balanceHistory },
  } = useRootModel();

  const percent = useMemo(() => {
    if (!balanceUser) return 0;
    return (balanceUser.profit * 100) / balanceUser.balance;
  }, [balanceUser]);

  const handleDepositOpen = async (currency) => {
    await setDepositCurrency(currency);
    deposit.open();
  };

  const handleWithdrawOpen = async (currency) => {
    await setWithdrawCurrency(currency);
    withdraw.open();
  };

  const handleConvertOpen = async (currency) => {
    await setExchangeCurrency(currency);
    convert.open();
  };

  const handleHistoryOpen = () => {
    balanceHistory.open();
  };

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return (
    <section className={style.section}>
      <div className={style.balance}>
        <div>
          <p className={style.balance__title}>{t('trade_balance')}</p>
          <p className={style.balance__block}>
            <BalanceFormat value={balanceUser?.balance} />
          </p>

          <div className={style.balance__wrapper}>
            <div>
              <BalanceFormat value={balanceUser?.available} />
              <p className={style.balance__text}>{t('available')}</p>
            </div>
            <div>
              <BalanceFormat value={balanceUser?.used} />
              <p className={style.balance__text}>{t('used')}</p>
            </div>
            <div>
              <p
                className={cn({
                  [style.colorDanger]: percent < 0,
                  [style.colorSuccess]: percent > 0,
                })}
              >
                {balanceUser?.profit > 0 && '+'} <BalanceFormat value={balanceUser?.profit} /> (
                {string.formatPercent(percent)})
              </p>
              <p className={style.balance__text}>PnL</p>
            </div>
          </div>

          {isMobile && (
            <div className={style.balance__block_mobile}>
              <div className={style.balance__wrapper_mobile}>
                <Link to='/balance/deposit' className={style.balance__link}>
                  {t('deposit')}
                </Link>
                <Link to='/balance/exchange' className={style.balance__link}>
                  {t('transfer')}
                </Link>
              </div>
              <div className={style.balance__wrapper_mobile}>
                <Link to='/balance/withdraw' className={style.balance__link}>
                  {t('withdraw')}
                </Link>
                <Link to='/balance/payment' className={style.balance__link}>
                  {t('history')}
                </Link>
              </div>
            </div>
          )}
        </div>
        {!isMobile && (
          <div>
            <div className={style.balance__container}>
              <button
                type='button'
                className={style.balance__button}
                onClick={() => handleDepositOpen('USDT_ERC20')}
              >
                {t('deposit')}
              </button>
              <button
                type='button'
                className={style.balance__button}
                onClick={() => handleConvertOpen('USDT_ERC20')}
              >
                {t('convert')}
              </button>
            </div>

            {isLaptop && <DemoAccount />}
          </div>
        )}
      </div>

      <div className={style.wallet}>
        {isDesktop && <p className={style.title}>Wallet</p>}
        <div>
          {cryptoBalance
            ?.filter(
              ({ currency }) =>
                currency === 'BTC' ||
                currency === 'ETH' ||
                currency === 'LTC' ||
                currency === 'USDT_ERC20',
            ) // todo delete filter after change api
            .map(({ currency, balance }) => (
              <div className={style.item} key={currency}>
                <div className={style.item__wrapper}>
                  <div className={style.item__about}>
                    <i>{balanceCurrency[currency].icon}</i>
                    <div>
                      <p className={style.item__title}>{balanceCurrency[currency].title}</p>
                      <p className={style.item__label}>{balanceCurrency[currency].label}</p>
                    </div>
                  </div>
                  <p className={style.item__total}>{balance}</p>
                </div>

                {!isMobile && (
                  <div className={style.item__block}>
                    <button
                      type='button'
                      className={style.item__button}
                      onClick={() => handleDepositOpen(currency)}
                    >
                      {t('deposit')}
                    </button>
                    <button
                      type='button'
                      className={style.item__button}
                      onClick={() => handleWithdrawOpen(currency)}
                    >
                      {t('withdraw')}
                    </button>
                    <button
                      type='button'
                      className={style.item__button}
                      onClick={() => handleConvertOpen(currency)}
                    >
                      {t('convert')}
                    </button>
                    <button
                      type='button'
                      className={style.item__button}
                      onClick={() => handleHistoryOpen(currency)}
                    >
                      {t('history')}
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default observer(Balance);
