import numeral from 'numeral';

/**
 * Returns string with capital first letter
 * @param {string} str - string
 * @returns {string}
 */
const toTitleString = (str = '') => (str ? `${str[0].toUpperCase()}${str.slice(1)}` : '');

const formatNumber = (num) => {
  const formattedString = String(num).split('.');
  const [numerator] = formattedString;
  let [, denominator] = formattedString;

  if (denominator && denominator.length === 1) {
    denominator = `${denominator}0`;
  }
  return `${numeral(numerator).format('0,0')}.${denominator || '00'}`;
};

const formatMoneyWithSign = (num) => {
  if (!num) return `0.00`;
  if (num > 0) return `+${numeral(num).format('0,00.00')}`;
  return `-${numeral(-num).format('0,00.00')}`;
};

const formatMoney = (num, { withoutSign } = {}) => {
  if (withoutSign) return numeral(num).format('0,0.00');
  return formatMoneyWithSign(num);
};

export const formatAmountWithSpaces = (num) => {
  if (!num) return `0.000`;

  const parts = num.toFixed(3).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return parts.join('.');
};

const formatPercent = (num, { withSign } = {}) => {
  return `${withSign && num > 0 ? '+' : ''}${numeral(num / 100).format('0.00%')}`;
};

const decimalAmount = (x) =>
  x.toString().includes('.') ? x.toString().split('.').pop().length : 0;

export const formatStringWithoutComa = (stringToConvert) => {
  return stringToConvert.split(',').join('');
};

export const formatStringToNumberWithoutSpace = (stringToConvert) => {
  return Number(stringToConvert.split(' ').join(''));
};

const string = {
  toTitleString,
  formatNumber,
  formatMoney,
  formatPercent,
  decimalAmount,
};

export default string;
