import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './NotificationItem.module.scss';
import { useRootModel } from '../../../models/RootStore';
import { ReactComponent as NotificationIcon } from '../../../assets/image/common/notification.svg';

/**
 * Represents NotificationItem component
 * @param {number} id - notification id
 * @param {string} text - notification text
 * @param {string} title - notification title
 * @param {boolean} checked - is notification checked
 * @param {boolean} isRead - flag isRead
 * @returns {JSX.Element}
 */
const NotificationItem = ({ id, text, title, isRead }) => {
  const {
    user: {
      notifications: { setIsRead, setActiveNotificationId },
    },
    modal: {
      notifications: { open },
    },
  } = useRootModel();
  const handleOpenModal = () => {
    open();
    setActiveNotificationId(id);
    setIsRead(id);
  };

  return (
    <button type='button' className={style.notificationItem} onClick={handleOpenModal}>
      <i className={cn(style.icon, { [style.isRead]: !isRead })}>
        <NotificationIcon />
        {!isRead && <span className={style.badge} />}
      </i>
      <div style={{ textAlign: 'left' }}>
        <p className={style.title}>{title}</p>
        <p className={style.text}>{text}</p>
      </div>
    </button>
  );
};

export default observer(NotificationItem);
