import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './ButtonToggle.module.scss';
import { ReactComponent as ArrowUp } from '../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../assets/image/common/arrowDown.svg';

/**
 * Represents show more/less button component
 * @param {function} handleToggle - onClick handler for change state
 * @param {boolean} isVisible - current state
 * @returns {JSX.Element|null}
 */

const ButtonToggle = ({ handleToggle, isVisible }) => {
  const { t } = useTranslation();
  return (
    <button type='button' className={style.button} onClick={handleToggle}>
      {isVisible ? t('show_less') : t('show_more')}
      {isVisible ? <ArrowUp className={style.arrow} /> : <ArrowDown className={style.arrow} />}
    </button>
  );
};

export default ButtonToggle;
