import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import style from './MainTableItem.module.scss';
import string from '../../../../../util/string';

import { ReactComponent as ArrowRight } from '../../../../../assets/image/common/arrowNextPage.svg';
import instrumentIcon from '../../../../../assets/instrumentIcon';

/**
 * Represents Instrument item in Main table on Mobile version
 * @param {string} title - instrument title
 * @param {string} s - instrument code
 * @param {number} price - instrument price
 * @param {number} day - price up or down in % for day
 * @param {number} oldPrice - previous instrument price
 * @param {string} type - instrument type
 * @param {JSX.Element} icon - instrument icon
 * @returns {JSX.Element}
 */

const MainTableItem = ({ title = '', s = '', price, day, oldPrice, type }) => {
  const [firstPair, secondPair] = title.split('/');

  const instType = instrumentIcon[type];
  const Icon = instType ? instrumentIcon[type][s] : null;

  return (
    <Link className={style.columnMobile} to={`/products/${type}/${s}`}>
      <div className={style.instrumentWrapper}>
        <i className={style.instrumentIcon}>{Icon ? <Icon /> : null}</i>
        <p className={style.link}>
          <span>{firstPair}</span>
          {secondPair && <span className={style.secondPair}>/{secondPair}</span>}
        </p>
      </div>
      <div className={style.priceBlock}>
        <div>
          <p
            className={cn(
              style.priceBackground,
              { [style.backGreen]: price > oldPrice },
              { [style.backRed]: price < oldPrice },
            )}
          >
            {string.formatNumber(price)}
          </p>
          <p className={cn(style.percent, { [style.priceUp]: day > 0 })}>
            {string.formatPercent(day)}
          </p>
        </div>
        <ArrowRight />
      </div>
    </Link>
  );
};

export default MainTableItem;
