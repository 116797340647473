import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import style from './ResetPassword.module.scss';
import { CONFIRM_PASSWORD, NEW_PASSWORD, PASSWORD_MAX_LENGTH } from '../../util/validationSchema';
import useFormikApp from '../../hooks/useFormikApp';
import { useRootModel } from '../../models/RootStore';
import AlertContext from '../../context/alert/alertContext';
import useWindowSize from '../../hooks/useWindowSize';

import BackButton from '../../components/ui/BackButton/BackButton';
import Logo from '../../components/ui/Logo/Logo';
import TextInput from '../../components/ui/TextInput/TextInput';
import Button from '../../components/ui/Button/Button';
import Copyrights from '../../components/ui/Copyrights/Copyrights';

/**
 * Represents reset password
 * @returns {JSX.Element}
 */
const ResetPassword = () => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const navigate = useNavigate();
  const { resetKey } = useParams();
  const alert = useContext(AlertContext);
  const inputRef = useRef(null);
  const {
    user: { resetPasswordFinish },
  } = useRootModel();
  const [showCopyrights, setShowCopyrights] = useState(true);

  const onSubmit = async ({ newPassword }) => {
    try {
      await resetPasswordFinish(newPassword, resetKey);
      navigate('/login');
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  useEffect(() => {
    inputRef?.current.focus();
  }, []);

  const formik = useFormikApp([NEW_PASSWORD, CONFIRM_PASSWORD], onSubmit);

  if (!resetKey) {
    navigate('/main');
  }

  const handleInputFocus = () => setShowCopyrights(false);
  const handleInputBlur = () => setShowCopyrights(true);

  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <BackButton text='Back' className={style.backButton} url='/login' />
        <header className={style.header}>
          <Logo isLarge />
        </header>
        <p className={style.title}>{t('start')}</p>
        <form onSubmit={formik.handleSubmit} className={style.form}>
          <TextInput
            id={NEW_PASSWORD}
            label={t('new_password')}
            type='password'
            placeholder={t('new_password')}
            formik={formik}
            inputRef={inputRef}
            maxLength={PASSWORD_MAX_LENGTH}
            inputClassName={style.input}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          <TextInput
            id={CONFIRM_PASSWORD}
            label={t('confirm_password')}
            type='password'
            placeholder={t('confirm_new')}
            formik={formik}
            maxLength={PASSWORD_MAX_LENGTH}
            labelClassName={style.confirm}
            inputClassName={style.input}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          <Button text={t('reset')} width='full' size='large' disabled={!formik.isValid} submit />
        </form>
      </div>
      {isDesktop ? <Copyrights /> : showCopyrights && <Copyrights />}
    </section>
  );
};

export default ResetPassword;
