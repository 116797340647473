import { types } from 'mobx-state-tree';

const Period = types.optional(
  types.union(
    types.literal('day'),
    types.literal('week'),
    types.literal('month'),
    types.literal('year'),
  ),
  'day',
);

export default Period;
