import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { tabsForTrades } from '../../constant/tabsConstants';
import style from './Trades.module.scss';

import ActiveTrades from './ActiveTrades/ActiveTrades';
import Pending from './Pending/Pending';
import ClosedTrades from './ClosedTrades/ClosedTrades';
import FlowOfFunds from './FlowOfFunds/FlowOfFunds';
import TabsScrollLink from '../../components/ui/TabsScrollLink/TabsScrollLink';

const trades = {
  active: <ActiveTrades />,
  pending: <Pending />,
  closed: <ClosedTrades />,
  'flow-funds': <FlowOfFunds />,
};

/**
 * Represents main page for Trades
 * @returns {JSX.Element}
 */
const Trades = () => {
  const { type } = useParams();
  const navigate = useNavigate();

  if (!Object.keys(trades).includes(type)) navigate('/404');

  return (
    <section className={style.section}>
      <TabsScrollLink tabs={tabsForTrades} />
      {trades[type]}
    </section>
  );
};

export default Trades;
