import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import StatusInfoIcon from '../../StatusInfoIcon/StatusInfoIcon';
import StatusInfoDescTablet from '../StatusInfoDescTablet/StatusInfoDescTablet';

import styles from './StatusInfoBodyTablet.module.scss';

/**
 * Represents status info item component
 * @returns {JSX.Element}
 */

const StatusInfoBodyTablet = ({ layout, openModal, closeModal }) => {
  const { t } = useTranslation();

  const handleOnKeyDown = (e) => {
    if (e.key === 'Escape') return closeModal();
    return null;
  };

  return (
    <div
      role='button'
      tabIndex={0}
      onClick={() => openModal(layout)}
      onKeyDown={handleOnKeyDown}
      className={styles.statusInfoBodyTablet}
    >
      <StatusInfoIcon type={t(layout.type)} color={layout.type} isActive={layout.isActive} />
      <div className={cn(styles.divider, styles.statusSilver)} />
      <StatusInfoDescTablet
        type={layout.type}
        minBalance={layout.requiredBalance}
        minVolume={layout.requiredBalance}
      />
    </div>
  );
};

export default StatusInfoBodyTablet;
