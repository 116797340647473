import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AboutUs from './AboutUs/AboutUs';
import ObjectivesVision from './ObjectivesVision/ObjectivesVision';
import AffiliateProgram from './AffiliateProgram/AffiliateProgram';

const FooterInfo = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (
    pathname !== '/info/about' &&
    pathname !== '/info/objectives&vision' &&
    pathname !== '/info/affiliate'
  )
    return navigate('/404');

  return (
    <section>
      {pathname === '/info/about' && <AboutUs />}
      {pathname === '/info/objectives&vision' && <ObjectivesVision />}
      {pathname === '/info/affiliate' && <AffiliateProgram />}
    </section>
  );
};

export default FooterInfo;
