import React, { useRef } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useDraggable } from 'react-use-draggable-scroll';
import style from './NavigationMain.module.scss';
import { ReactComponent as ArrowLeft } from '../../assets/image/common/arrowLeftMain.svg';
import { ReactComponent as ArrowRight } from '../../assets/image/common/arrowRightMain.svg';
import { useRootModel } from '../../models/RootStore';

const NavigationMain = ({ term, tabs, activeTab, onChange }) => {
  const {
    user: { isAuthorized },
  } = useRootModel();
  const menuRef = useRef();
  const { events } = useDraggable(menuRef);

  const handleScrollLeft = () => {
    menuRef.current.scrollLeft -= 100;
  };

  const handleScrollRight = () => {
    menuRef.current.scrollLeft += 100;
  };

  return (
    <nav className={style.navigation}>
      <button type='button' className={style.navigation__arrow} onClick={handleScrollLeft}>
        <ArrowLeft />
      </button>
      <div className={style.navigation__block} {...events} ref={menuRef}>
        {tabs
          .filter(({ id }) => (!isAuthorized ? id !== 0 : true))
          .map(({ id, tabLabel }) => (
            <button
              type='button'
              key={id}
              className={cn(style.navigation__button, {
                [style.navigation__active]: id === activeTab && term === '',
              })}
              onClick={() => onChange(id)}
            >
              {tabLabel}
            </button>
          ))}
      </div>
      <button type='button' className={style.navigation__arrow} onClick={handleScrollRight}>
        <ArrowRight />
      </button>
    </nav>
  );
};

export default observer(NavigationMain);
