import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import style from './Logo.module.scss';
import { ReactComponent as LogoIcon } from '../../../assets/image/common/logoBig.svg';

/**
 * Represents a logo
 * @param {CSSStyleRule} className - link className
 * @returns {JSX.Element}
 */
const Logo = ({ className }) => {
  return (
    <Link to='/' className={cn(style.logoLink, className)}>
      <LogoIcon />
    </Link>
  );
};

export default Logo;
