// Make requests to CryptoCompare API

// const { REACT_APP_API, NODE_ENV } = process.env;
// const prod = NODE_ENV === 'production';

import error from '../../util/error';
import axios from "axios";

export async function makeApiRequest(path) {
  try {
    const {data} = await axios(`/services/trading/api/${path}`, {
      method: 'GET',
    });

    return await data;
  } catch (err) {
  //   await makeApiRequest(path);
    error.errorHandler(err.message);
    throw new Error(`CryptoCompare request error: ${error.message}`);
  }
}

export function formatDateString(date) {
  return new Date(date * 1000)
    .toLocaleString('ko-KR', { month: '2-digit', day: '2-digit', year: 'numeric' })
    .replaceAll('. ', '-')
    .slice(0, -1);
}
