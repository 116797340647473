import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from '../../AppModals.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import useWindowSize from '../../../../hooks/useWindowSize';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import CloseTradeContent from './CloseTradeContent/CloseTradeContent';

/**
 * Represents ActiveTradesModal component
 * @returns {JSX.Element}
 */
const CloseTradeModal = () => {
  const { isLaptop } = useWindowSize();
  const { t } = useTranslation();
  const {
    modal: {
      closeTrade: { isOpen, close },
    },
  } = useRootModel();

  return (
    isOpen && (
      <Modal
        wrapperClassname={style.modalWrapper}
        handleClose={close}
        header={isLaptop}
        headerTitle={t('close_trade')}
      >
        <ModalBody className={style.modal}>
          <CloseTradeContent handleClose={close} />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(CloseTradeModal);
