import React from 'react';
import { observer } from 'mobx-react';

import style from './ClosedTradesList.module.scss';
import instrumentIcon from '../../../../../assets/instrumentIcon';

import ClosedTradesItem from './ClosedTradesItem/ClosedTradesItem';
import NoTrade from '../../../../../components/NoTrade/NoTrade';

/**
 * Represents closed trades table
 * @param {boolean} isPercent - show % or $
 * @param {Object} items - close trades items
 * @param {string} tableRef - ref for table body
 * @returns {JSX.Element}
 */
const ClosedTradesList = ({ items, isPercent, tableRef }) => {
  return (
    <div className={style.body} ref={tableRef}>
      {items.length > 0 ? (
        items.map(
          ({
            id,
            openingPrice,
            closingPrice,
            amount,
            multiplier,
            openedAt,
            closedAt,
            symbol,
            result,
            profit,
            profitPercent,
            operation,
          }) => {
            const { alias, s, type } = symbol;
            const icon = instrumentIcon[type][s];
            return (
              <ClosedTradesItem
                key={id}
                id={id}
                title={alias}
                number={s}
                icon={icon}
                dateOpen={openedAt}
                priceOpen={openingPrice}
                dateClose={closedAt}
                priceClose={closingPrice}
                amount={amount}
                multiplier={multiplier}
                result={result}
                profit={profit}
                profitPercent={profitPercent}
                operation={operation}
                isPercent={isPercent}
              />
            );
          },
        )
      ) : (
        <NoTrade />
      )}
    </div>
  );
};

export default observer(ClosedTradesList);
