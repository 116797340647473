import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import style from './StatusInfoSwitch.module.scss';
import Button from '../../../../../components/ui/Button/Button';
import { useRootModel } from '../../../../../models/RootStore';

/**
 * Represents Status Info Switch component
 * @returns {JSX.Element}
 */

const StatusInfoSwitch = () => {
  const { t } = useTranslation();
  const {
    user: { userData, switchAccount },
  } = useRootModel();
  const { demoVersion } = userData;

  return (
    <div className={style.switchBlock}>
      <p className={style.note}>{t('note')}</p>
      <Button
        text={demoVersion ? t('switch_real') : t('switch_demo')}
        color='primary'
        className={style.button}
        onClick={switchAccount}
      />
    </div>
  );
};

export default observer(StatusInfoSwitch);
