import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRootModel } from '../../../models/RootStore';
import style from './SearchMobile.module.scss';
import { ReactComponent as Search } from '../../../assets/image/common/search.svg';

/**
 * Represents Search instruments component in Markets on Mobile version
 * @returns {JSX.Element}
 */

const SearchMobile = () => {
  const { t } = useTranslation();
  const {
    modal: { search },
  } = useRootModel();

  return (
    <div className={style.buttonContainer}>
      <button type='button' className={style.searchButton} onClick={search.open}>
        <Search className={style.image} />
        <span>{t('search_mob')}</span>
      </button>
    </div>
  );
};

export default SearchMobile;
