import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import style from './AffiliateMobile.module.scss';
import BackButton from '../../../../components/ui/BackButton/BackButton';
import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody/ModalBody';
import AffiliateRulesMobile from './AffiliateRulesMobile/AffiliateRulesMobile';
import BalanceFormat from '../../../../components/BalanceFormat/BalanceFormat';
import PaginationMobile from '../../../components/PaginationMobile/PaginationMobile';
import { useRootModel } from '../../../../models/RootStore';
import date from '../../../../util/date';
import { registrationPath } from '../../../../constant/commonConstants';
import Button from '../../../../components/ui/Button/Button';
import AlertContext from '../../../../context/alert/alertContext';
import { ReactComponent as PreloaderMini } from '../../../../assets/image/common/preloaderMini.svg';

/**
 * Represents Affiliate mobile component
 * @returns {JSX.Element}
 */

const AffiliateMobile = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const inputAreaRef = useRef(null);
  const alert = useContext(AlertContext);
  const {
    settings: { language },
    user: {
      getLinkRef,
      refCode,
      getRefBonuses,
      pageAffiliate: page,
      pageSizeAffiliate: pageSize,
      setPageAffiliate: setPage,
      referentsData: { totalUnpaidBonus, referents, totalReferents },
      withdrawRefBonus,
      setIsLoading,
      isLoading,
    },
    payment: { getAccounts },
  } = useRootModel();

  const registrationLink = `${registrationPath}${refCode}`;

  const copyCodeToClipboard = async () => {
    await inputAreaRef.current.select();
    document.execCommand('copy');
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // eslint-disable-next-line no-shadow
  const handleChangePage = async (page) => {
    await setPage(page - 1);
    getRefBonuses();
  };

  const withdrawAllBonuses = async () => {
    try {
      await setIsLoading(true);
      await withdrawRefBonus();
      await getAccounts();
      await getRefBonuses();
      alert.show(t('withdraw_success'), 'success');
      setIsLoading(false);
    } catch (err) {
      alert.show(t(err.message));
    }
  };

  useEffect(() => {
    getLinkRef();
    getRefBonuses();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <BackButton />
        <p className={style.title}>{t('affiliate_program')}</p>
      </div>
      <article className={style.affiliateMobile}>
        <h4 className={style.title}>{t('share_link')}</h4>
        <p className={style.text}>{t('share_text')}</p>
        <input
          className={style.inputAffiliate}
          type='text'
          value={registrationLink}
          readOnly
          ref={inputAreaRef}
        />
        <button type='button' className={style.copyButton} onClick={copyCodeToClipboard}>
          {t('copy')}
        </button>
        <button type='button' className={style.inviteText} onClick={handleOpenModal}>
          {t('program_rules')}
        </button>
      </article>
      <div className={style.withdrawBlock}>
        <div className={style.withdrawBalance}>
          <span>{t('total_balance')}: </span>
          <BalanceFormat value={totalUnpaidBonus} />
        </div>
        <Button
          color='primary'
          className={style.withdrawButton}
          onClick={withdrawAllBonuses}
          disabled={totalUnpaidBonus <= 0 || isLoading}
        >
          {isLoading ? <PreloaderMini className={style.loader} /> : t('withdraw_all')}
        </Button>
      </div>

      {referents.length > 0 ? (
        referents.map(({ userId, email, createdAt, totalDeposits, lastDepositAt, unpaidBonus }) => {
          return (
            <div className={style.affiliateCard} key={userId}>
              <div>
                <div className={style.cardItem}>
                  <p className={style.itemTitle}>{t('email')}</p>
                  <p>{email}</p>
                </div>
                <div className={style.cardItem}>
                  <p className={style.itemTitle}>{t('total_deposited')}</p>
                  <BalanceFormat value={totalDeposits} />
                </div>
                <div>
                  <p className={style.itemTitle}>{t('commission')}</p>
                  <BalanceFormat value={unpaidBonus} />
                </div>
              </div>
              <div>
                <div className={style.cardItem}>
                  <p className={style.itemTitle}>{t('created')}</p>
                  <p>{date.toLocaleString(createdAt, language)}</p>
                </div>
                <div>
                  <p className={style.itemTitle}>{t('last_deposit')}</p>
                  <p> {date.toLocaleString(lastDepositAt, language)}</p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className={style.noData}>{t('no_invite')}</p>
      )}

      <PaginationMobile
        currentPage={page + 1}
        totalCount={totalReferents}
        pageSize={pageSize}
        onPageChange={handleChangePage}
      />

      {showModal && (
        <Modal handleClose={handleCloseModal} wrapperClassname={style.modalWrapper}>
          <ModalBody className={style.modal}>
            <AffiliateRulesMobile handleClose={handleCloseModal} />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default observer(AffiliateMobile);
