import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import { sortByQuantity } from '../../constant/commonConstants';

const PaymentHistoryItemModel = types
  .model('PaymentHistoryItemModel', {
    date: types.string,
    amount: types.number,
    currency: types.string,
    exchangeRate: types.number,
    id: types.number,
    paymentType: types.union(types.literal('DEPOSIT'), types.literal('WITHDRAWAL')),
    status: types.union(types.literal('COMPLETED'), types.literal('FAILED')),
    txId: types.string,
  })
  .views((item) => ({
    get isDeposit() {
      return item.paymentType === 'DEPOSIT';
    },
    get isWithdrawal() {
      return item.paymentType === 'WITHDRAWAL';
    },
  }));

const PaymentHistoryModel = types
  .model('PaymentHistoryModel', {
    period: types.optional(
      types.union(
        types.literal('WEEK'),
        types.literal('MONTH'),
        types.literal('QUARTER'),
        types.literal('ALL_TIME'),
      ),
      'ALL_TIME',
    ),
    pageSize: types.optional(types.number, sortByQuantity[0]),
    items: types.array(PaymentHistoryItemModel),
    total: types.optional(types.number, 0),
    page: types.optional(types.number, 0),
    sortBy: types.optional(types.string, ''),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'desc'),
    isLoading: types.optional(types.boolean, false),
    alert: types.optional(types.string, ''),
  })
  .actions((paymentHistory) => ({
    setIsLoading(loading) {
      paymentHistory.isLoading = loading;
    },
    setAlert(alert) {
      paymentHistory.alert = alert;
    },
    setTotalItems(total) {
      paymentHistory.total = total;
    },
    setItems(items) {
      paymentHistory.items = items;
    },
    addItems(items) {
      paymentHistory.items = [...paymentHistory.items, ...items];
    },
    setPage(page) {
      paymentHistory.page = page;
    },
    setNextPage() {
      const { page, pageSize, setPage, isNextPage } = paymentHistory;
      if (page < pageSize && isNextPage) setPage(page + 1);
    },
    changePeriod(period) {
      paymentHistory.period = period;
      paymentHistory.setPage(0);
    },
    setPageSize(quantity) {
      paymentHistory.pageSize = parseInt(quantity, 10);
    },
    getPaymentHistory: flow(function* getPaymentHistory(isAddItems = false, config) {
      const { setIsLoading, setAlert } = paymentHistory;
      setIsLoading(true);
      try {
        const {
          sortBy,
          sortDirection,
          page,
          pageSize,
          period,
          setTotalItems,
          addItems,

          setItems,
        } = paymentHistory;
        const params = {
          page,
          size: pageSize,
          period,
          sort: `${sortBy},${sortDirection}`,
          ...config,
        };
        const { headers, data } = yield axios.get(
          `/services/payment/api/accounts/real/payment-histories`,
          {
            params,
          },
        );

        setTotalItems(parseInt(headers['x-total-count'], 10));
        if (isAddItems) {
          addItems(data);
        } else {
          setItems(data);
        }
      } catch (err) {
        const message = err.response?.data.message || err.message;
        setAlert(message);
        throw new Error(message);
      } finally {
        setIsLoading(false);
      }
    }),
    updateData(data) {
      paymentHistory.items = data;
    },
    clearData() {
      paymentHistory.items = [];
    },
    setSortBy(key) {
      paymentHistory.sortBy = key;
      paymentHistory.setPage(0);
    },
    setSortDirection(direction) {
      paymentHistory.sortDirection = direction;
    },
    switchSortDirection() {
      paymentHistory.sortDirection = paymentHistory.sortDirection === 'asc' ? 'desc' : 'asc';
    },
  }))
  .views((paymentHistory) => ({
    get isNextPage() {
      const { page, total, pageSize } = paymentHistory;
      return page < total / pageSize;
    },
  }));

export default PaymentHistoryModel;
