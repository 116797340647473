import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import date from '../../util/date';

import style from './Footer.module.scss';

import { ReactComponent as Apple } from '../../assets/image/common/appleApp.svg';
import { ReactComponent as Android } from '../../assets/image/common/androidApp.svg';
import { ReactComponent as MasterCard } from '../../assets/image/common/mastercard.svg';
import { ReactComponent as Visa } from '../../assets/image/common/visa.svg';
import { ReactComponent as Telegram } from '../../assets/image/common/telegram.svg';
import { ReactComponent as Twitter } from '../../assets/image/common/twitter.svg';
import { ReactComponent as Instagram } from '../../assets/image/common/instagram.svg';
import useWindowSize from '../../hooks/useWindowSize';

/**
 * Represents footer
 * @returns {JSX.Element}
 */
const Footer = () => {
  const { t } = useTranslation();
  const { isLaptop } = useWindowSize();

  const socials = (
    <div className={style.iconsWrapper}>
      <a href='https://twitter.com/i88_ai'>
        <i className={cn(style.messengerIcon, style.iconTelegram)}>
          <Telegram />
        </i>
      </a>
      <a href='https://twitter.com/i88_ai'>
        <i className={cn(style.messengerIcon, style.iconTwitter)}>
          <Twitter />
        </i>
      </a>
      <a href='https://instagram.com/i88.io'>
        <i className={cn(style.messengerIcon, style.iconInstagram)}>
          <Instagram />
        </i>
      </a>
    </div>
  );

  return (
    <footer className={style.footer}>
      <div className={style.container}>
        <div className={style.navigation}>
          <ul className={style.list}>
            <li>
              <Link to='/info/about' className={style.link}>
                {t('about')}
              </Link>
            </li>
            <li>
              <Link to='/' className={style.link}>
                {t('news')}
              </Link>
            </li>
            <li>
              <Link to='/info/objectives&vision' className={style.link}>
                {t('Objectives & Vision')}
              </Link>
            </li>
            <li>
              <Link to='/info/affiliate' className={style.link}>
                {t('affiliate_program')}
              </Link>
            </li>
            <li>
              <Link to='/support' className={style.link}>
                {t('support')}
              </Link>
            </li>
            <li>
              <Link to='/schedule' className={style.link}>
                {t('trading_instruments')}
              </Link>
            </li>
          </ul>
          <div className={style.applicationBlock}>
            <div>
              <p className={style.title}>{t('mobile')}:</p>
              <div className={style.applications}>
                <Link to='/'>
                  <Apple />
                </Link>
                <Link to='/'>
                  <Android />
                </Link>
              </div>
            </div>
            <div>
              <p className={style.title}>{t('payments_methods')}:</p>
              <div className={style.payment}>
                <span className={style.mastercard}>
                  <MasterCard />
                </span>
                <span className={style.visa}>
                  <Visa />
                </span>
              </div>
              {isLaptop && socials}
            </div>
          </div>
        </div>
        <div className={style.footerBottomWrapper}>
          <div className={style.footerText}>
            <p>{t('footer_text_line_first')}</p>
            <p>{t('footer_text_line_second')}</p>
            <p>{t('footer_text_line_third')}</p>
            <p>{t('footer_text_line_fourth')}</p>
            <p>{t('footer_text_line_fifth')}</p>
            <p>
              {t('footer_text_line_sixth')}
              <a
                className={style.footerLink}
                href='https://cash.cf'
                target='_blank'
                rel='noreferrer'
              >
                {t('eCache_investment')}
              </a>
            </p>
            <p className={style.footerCopyrights}>
              {t('footer_copyrights')}
              {date.currentYear},
              <a
                className={style.footerLink}
                href='https://cash.cf'
                target='_blank'
                rel='noreferrer'
              >
                {t('eCache_investment')}
              </a>
              {t('footer_copyrights_powered_by')}
              <a
                className={style.footerLink}
                href='https://88.guru'
                target='_blank'
                rel='noreferrer'
              >
                {t('team_88')}
              </a>
            </p>
          </div>
          {!isLaptop && socials}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
