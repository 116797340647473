import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import style from './PriceChangeBlock.module.scss';
import string from '../../util/string';
import ChangePeriodDropdown from '../ChangePeriodDropdown/ChangePeriodDropdown';
import { useRootModel } from '../../models/RootStore';
import PriceHighLowGraph from '../PriceHighLowGraph/PriceHighLowGraph';

const PriceChangeBlock = () => {
  const { t } = useTranslation();
  const {
    finance: { activeItem, activePriceChange },
  } = useRootModel();

  return (
    <>
      <div className={style.priceWrapper}>
        <div className={cn(style.gridColumn, style.price)}>
          <p
            className={cn(style.priceValue, {
              [style.backgroundRed]: activeItem?.lp < activeItem?.oldPrice,
              [style.backgroundGreen]: activeItem?.lp > activeItem?.oldPrice,
            })}
          >
            {string.formatNumber(activeItem?.lp)}
          </p>
          <p className={style.text}>{t('price')}</p>
        </div>
        <PriceHighLowGraph />
      </div>

      <div className={style.container}>
        <p
          className={cn(style.percentDayChange, style.colorGreen, {
            [style.colorRed]: activePriceChange < 0,
          })}
        >
          {string.formatPercent(activePriceChange, { withSign: true })}
        </p>

        <ChangePeriodDropdown />
      </div>
    </>
  );
};

export default observer(PriceChangeBlock);
