import React from 'react';
import styles from './ScrollLoader.module.scss';
import { ReactComponent as ScrollLoaderIcon } from '../../assets/image/common/scrollLoader.svg';

const ScrollLoader = () => {
  return (
    <div className={styles.loader}>
      <ScrollLoaderIcon />
    </div>
  );
};

export default ScrollLoader;
