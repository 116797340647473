// import * as Sentry from '@sentry/react';

const errorHandler = (message) => {
  if (process.env.NODE_ENV === 'production') {
    console.log(message);
    // Sentry.captureException(message);
  }
};

export default {
  errorHandler,
};
