import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from '../../HeaderUserInfo.module.scss';
import { useRootModel } from '../../../../../models/RootStore';

// eslint-disable-next-line max-len
import { ReactComponent as Brightness } from '../../../../../assets/image/common/brightness-mini.svg';
import Switch from '../../../../ui/Switch/Switch';

/**
 * Represents ButtonSwitchBrightness component
 * @returns {JSX.Element}
 */
const ButtonSwitchBrightness = () => {
  const { t } = useTranslation();
  const {
    settings: { theme, toggleTheme },
  } = useRootModel();

  return (
    <div className={cn(style.buttonWrapper, style.darkMode)}>
      <div className={style.icon}>
        <Brightness />
        <span>{t('dark')}</span>
      </div>
      <Switch
        labelClassName={style.switch}
        sliderClassName={style.switch__slider}
        checked={theme === 'dark'}
        onChange={toggleTheme}
      />
    </div>
  );
};

export default observer(ButtonSwitchBrightness);
