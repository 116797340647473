import React from 'react';
import cn from 'classnames';
import style from './TabItem.module.scss';

/**
 * Represents one tab
 * @param {number} activeTab - id of active tab
 * @param {string} label - label for tab
 * @param {function} onClick - handler onClick
 * @param {number} id - tab id
 * @returns {JSX.Element}
 */

const TabItem = ({ activeTab, label, onClick, id }) => {
  const handleOnClick = () => {
    onClick(id);
  };
  const isActive = activeTab === id;

  return (
    <button
      type='button'
      className={cn(style.item, {
        [style.active]: isActive,
      })}
      onClick={handleOnClick}
    >
      {label}
    </button>
  );
};

export default TabItem;
