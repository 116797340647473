import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './TextInput.module.scss';
import { ReactComponent as VerifiedIcon } from '../../../assets/image/common/verified.svg';
import { ReactComponent as NotVerified } from '../../../assets/image/common/not-verified.svg';
import Visibility from '../ButtonVisibility/ButtonVisibility';
import { ReactComponent as PasswordIcon } from '../../../assets/image/common/password.svg';
import { ReactComponent as EmailIcon } from '../../../assets/image/common/email.svg';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * @typedef {("none" | "text" | "tel" | "url" | "email" | "numeric")} InputMode
 */
/**
 * @typedef {('primary' | 'secondary')} IconColorSchema
 */
/**
 * @typedef {('normal' | 'full')} ElementWidth
 */

/**
 * Represents TextInput component
 *
 * For prevent rendering standard icon pass into icon prop null
 *
 * @param {string} label - input label
 * @param {string} id - input id
 * @param {string} value - input value
 * @param {("text" | "password")} type - input type
 * @param {string} placeholder - input placeholder
 * @param {("on" | "nope")} autoComplete - autoComplete
 * @param {boolean} disabled - disabled
 * @param {function} onChange - change handler (e) => void
 * @param {function} onBlur - blur handler (e) => void
 * @param {function} onKeyDown - KeyDown handler
 * @param {function} onKeyPress - KeyPress handler
 * @param {function} onFocus - onFocus handler
 * @param {boolean} touched - is input touched
 * @param {string} error - error text
 * @param {JSX.Element} leftIcon - Icon in the left
 * @param {CSSStyleRule} leftIconClassName - className for left icon
 * @param {JSX.Element} rightIcon - Icon in the right
 * @param {CSSStyleRule} rightIconClassName - className for right icon
 * @param {InputMode} inputMode - input mode
 * @param {CSSStyleRule} labelClassName - className for label
 * @param {CSSStyleRule} labelTitleClassName - className for label title
 * @param {CSSStyleRule} inputClassName - className for input
 * @param {CSSStyleRule} errorClassName - className for error
 * @param {ElementWidth} width - input width
 * @param {boolean} isVerified - is verified field
 * @param {boolean} verifiedLabel - if need to show verified label
 * @param {Formik} formik - formik
 * @param {number} maxLength - input max length
 * @param {Ref} inputRef - ref for input element
 * @returns {JSX.Element}
 */

const TextInput = ({
  label = '',
  id = '',
  value = '',
  type = 'text',
  placeholder = '',
  autoComplete = 'on',
  disabled,
  onChange,
  onBlur,
  onKeyDown,
  onKeyPress,
  onFocus,
  touched = false,
  error = '',
  leftIcon,
  leftIconClassName,
  rightIcon,
  rightIconClassName,
  inputMode = 'text',
  labelClassName,
  labelTitleClassName,
  inputClassName,
  errorClassName,
  width = 'normal',
  isVerified = false,
  verifiedLabel = false,
  formik,
  maxLength,
  inputRef = null,
}) => {
  const { t } = useTranslation();
  const labelRef = useRef(null);
  const { isMobile } = useWindowSize();
  const [showPassword, setShowPassword] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  let inputType = type;
  let LeftIcon = leftIcon;
  let RightIcon = rightIcon;

  const handleTogglePassword = () => setShowPassword((prev) => !prev);
  const VisibilityIcon = () => (
    <Visibility name={id} showPassword={showPassword} onClick={handleTogglePassword} />
  );
  const isTypePassword = type === 'password';
  const isTypeEmail = type === 'email';

  // Standard icons for email and password with visibility
  // For prevent rendering standard icon pass into icon prop null
  if (isTypePassword) {
    inputType = showPassword ? 'text' : 'password';
    LeftIcon = leftIcon === null ? null : leftIcon || PasswordIcon;
    RightIcon = rightIcon === null ? null : rightIcon || VisibilityIcon;
  }
  if (isTypeEmail) {
    LeftIcon = leftIcon || EmailIcon;
  }

  const handleOnChange = (e) => {
    if (maxLength && e.target.value.length > maxLength) return;
    if (onChange) {
      onChange(e);
      return;
    }
    if (formik) formik.handleChange(e);
  };

  const handleOnBlur = (e) => {
    if (onBlur) onBlur(e);

    setIsTouched(true);
    if (formik) {
      formik.handleBlur(e);
    }
  };

  const handleOnFocus = () => {
    if (isMobile) setTimeout(() => labelRef?.current.scrollIntoView());
    if (onFocus) onFocus();
  };

  return (
    <label
      htmlFor={id}
      className={cn(
        style.labelInput,
        {
          [style.withLabel]: !!label,
          [style.error]: (touched && error) || (formik?.touched[id] && formik?.errors[id]),
        },
        style[width],
        labelClassName,
      )}
      ref={labelRef}
    >
      {label && <p className={cn(style.labelTitle, labelTitleClassName)}>{label}</p>}

      <input
        className={cn(
          style.input,
          {
            [style.paddingForLeftIcon]: LeftIcon,
            [style.paddingForRightIcon]: RightIcon,
            [style.errorBorder]: error && isTouched,
          },
          inputClassName,
        )}
        type={inputType}
        placeholder={placeholder}
        id={id}
        name={id}
        value={formik ? formik?.values[id] : value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onFocus={handleOnFocus}
        ref={inputRef}
        inputMode={inputMode}
        autoComplete={autoComplete}
        disabled={disabled}
      />

      {LeftIcon && (
        <div
          className={cn(
            style.icon,
            style.leftIcon,
            // style[leftIconColorScheme],

            leftIconClassName,
          )}
        >
          <LeftIcon />
        </div>
      )}

      {RightIcon && (
        <div className={cn(style.icon, style.rightIcon, rightIconClassName)}>
          <RightIcon />
        </div>
      )}

      {touched && error && <div className={cn(style.errorLabel, errorClassName)}>{error}</div>}
      {formik?.touched[id] && formik?.errors[id] && (
        <div className={cn(style.errorLabel, errorClassName)}>{formik?.errors[id]}</div>
      )}

      {verifiedLabel && (
        <p className={cn(style.verifiedLabel, { [style.verified]: isVerified })}>
          {isVerified ? <VerifiedIcon /> : <NotVerified />}
          {isVerified ? <span>{t('verified')}</span> : <span>{t('not_verified')}</span>}
        </p>
      )}
    </label>
  );
};

export default TextInput;
