import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Navigation.module.scss';
import { ReactComponent as HotIcon } from '../../assets/image/common/hot.svg';

const Navigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <nav className={styles.navigation}>
      <NavLink
        to='/'
        className={({ isActive }) =>
          isActive
            ? `${styles.navigation__item} ${styles.navigation__active}`
            : styles.navigation__item
        }
      >
        <span className={styles.navigation__text}>{t('hot')}</span>
        <HotIcon />
      </NavLink>
      <NavLink
        to='/markets'
        className={({ isActive }) =>
          isActive || pathname.includes('markets')
            ? `${styles.navigation__item} ${styles.navigation__active}`
            : styles.navigation__item
        }
      >
        {t('markets')}
      </NavLink>
      <NavLink
        to='/trades/active'
        className={({ isActive }) =>
          isActive || pathname.includes('trades')
            ? `${styles.navigation__item} ${styles.navigation__active}`
            : styles.navigation__item
        }
      >
        {t('orders')}
      </NavLink>
    </nav>
  );
};

export default Navigation;
