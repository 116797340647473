import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './ChatHeader.module.scss';

import { ReactComponent as CloseIcon } from '../../../assets/image/common/close-circle-white.svg';

/**
 * Represents ChatHeader
 * @param {function} close - close handler
 * @returns {JSX.Element}
 */
const ChatHeader = ({ close }) => {
  const { t } = useTranslation();
  return (
    <header className={style.chatHeader}>
      <p>{t('manager')}</p>
      <button type='button' className={style.close} onClick={close}>
        <i className={style.icon}>
          <CloseIcon />
        </i>
      </button>
    </header>
  );
};

export default ChatHeader;
