import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../ui/Button/Button';

/**
 * Represents HeaderAuthButton
 * @returns {JSX.Element}
 */
const HeaderAuthButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleAuthorization = () => {
    navigate('/registration');
  };
  return (
    <Button
      text={t('demo')}
      color='primary'
      fontWeight='bold'
      onClick={handleAuthorization}
      width='143px'
      height='36px'
    />
  );
};

export default HeaderAuthButton;
