import React from 'react';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';
import { formatAmountWithSpaces } from '../../util/string';

/**
 * Represents Coin Format
 * @param {number} value - value for formatting
 * @returns {JSX.Element}
 */

const BalanceFormat = ({ value }) => {
  const {
    user: { currency },
  } = useRootModel();

  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {currency}
      {formatAmountWithSpaces(value)}
    </span>
  );
};

export default observer(BalanceFormat);
