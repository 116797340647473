import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import style from './InstrumentsContent.module.scss';
import { useRootModel } from '../../../../models/RootStore';

// import Error from '../../../../components/Error/Error';
import InstrumentsContentItem from './InstrumentsContentItem/InstrumentsContentItem';
import { ReactComponent as EmptyBox } from '../../../../assets/image/common/emptyBox.svg';

/**
 * Represents Instruments Content in Left Side Block on Main Page component
 * @param {boolean} isLoading - is content loading
 * @param {function} handleOnClick - on item click handler
 * @returns {JSX.Element}
 */

const InstrumentsContent = ({ activeTab, handleOnClick }) => {
  const { t } = useTranslation();
  const {
    finance: {
      itemsMain,
      getItem,
      closeDataStreamItem,
      getUpdateDataMain,
      closeDataStreamMain,
      term,
      favoriteTrades: { items },
    },
  } = useRootModel();

  const currentItems = useMemo(() => {
    return activeTab || term ? itemsMain : items;
  }, [activeTab, items, itemsMain, term]);

  const handleGetItem = async (type, s) => {
    await closeDataStreamItem();
    getItem(type, s);
    if (handleOnClick) handleOnClick();
  };

  useEffect(() => {
    getUpdateDataMain();

    return () => {
      closeDataStreamMain();
    };
  }, [closeDataStreamMain, getUpdateDataMain]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <p className={style.header__text}>{t('name')}</p>
        <div className={style.header__block}>
          <p className={style.header__text}>{t('price')}</p>
          <p className={style.header__text_right}>{t('change')}</p>
        </div>
      </div>
      <div className={style.content}>
        {currentItems.length > 0 ? (
          currentItems.map(({ alias, s, lp, cpd, oldPrice, type }) => {
            const onClick = () => handleGetItem(type, s);
            return (
              <InstrumentsContentItem
                key={s}
                symbol={s}
                alias={alias}
                lastPrice={lp}
                changePerDay={cpd}
                oldPrice={oldPrice}
                type={type}
                onClick={onClick}
              />
            );
          })
        ) : (
          <div className={style.block}>
            <EmptyBox />
            <p className={style.block__text}>No items found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(InstrumentsContent);
