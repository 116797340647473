import React from 'react';
import cn from 'classnames';

import style from './CurrentBalanceItem.module.scss';
import BalanceFormat from '../../BalanceFormat/BalanceFormat';

/**
 * Represents CurrentBalanceItem
 * @param {string} title - item's title
 * @param {string} value - item's value
 * @param {CSSStyleRule} valueClassName - value className
 * @returns {JSX.Element}
 */
const CurrentBalanceItem = ({ title, value, valueClassName }) => {
  return (
    <div className={style.item}>
      <span>{title}</span>
      <div className={cn(value > 0 ? style.positive : style.negative, valueClassName)}>
        <BalanceFormat value={value} withoutSign={value >= 0} />
      </div>
    </div>
  );
};

export default CurrentBalanceItem;
