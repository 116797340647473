import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './NoTrade.module.scss';

const NoTrade = () => {
  const { t } = useTranslation();
  return <p className={style.noContent}>{t('no_trade')}</p>;
};

export default NoTrade;
