import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useIdleTimer } from 'react-idle-timer';

import './SVGColor.scss';
import { Route, Routes } from 'react-router-dom';
import style from './App.module.scss';

import { useRootModel } from './models/RootStore';

import AlertState from './context/alert/AlertState';
import Preloader from './components/ui/Preloader/Preloader';
// import Alert from './components/ui/Alert/Alert';
import AppModals from './components/AppModals/AppModals';

import MainApp from './MainApp/MainApp';
import Login from './pages/Login/Login';
import Verification from './pages/Verification/Verification';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Alert from './components/ui/Alert/Alert';
import Header from './components/Header/Header';
import eventSource from './eventSource/eventSource';

/**
 * App component
 * @returns {JSX.Element}
 */

const App = () => {
  const [initialization, setInitialization] = useState(true);
  const {
    user: { getUser, setIsNeedSessionStop, getUserCountryBeforeRegistration },
    modal: {
      stopSession: { open },
    },
  } = useRootModel();

  const onIdle = () => {
    setIsNeedSessionStop(true);
    open();
    eventSource.closeAllSteam();
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 30,
  });

  useEffect(() => {
    getUserCountryBeforeRegistration();
  }, [getUserCountryBeforeRegistration]);

  useEffect(() => {
    const fetchData = async () => {
      await getUser();
      setInitialization(false);
    };
    fetchData();
  }, [getUser]);

  if (initialization)
    return (
      <div className={style.fullscreen}>
        <Preloader />
      </div>
    );

  return (
    <AlertState>
      <AppModals />
      <Alert />
      <Header />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/registration' element={<Login />} />
        <Route path='/registration/:refCode' element={<Login />} />
        <Route path='/verification' element={<Verification />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:resetKey' element={<ResetPassword />} />
        <Route path='*' element={<MainApp />} />
      </Routes>
    </AlertState>
  );
};

export default observer(App);
