import React, { Component } from 'react';
import error from '../../../util/error';
import Error from '../Error';

/**
 * Represents ErrorBoundary
 * @constructor
 * @param {string} text - error text
 * @param {JSX.Element} children - children
 */

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(err) {
    error.errorHandler(err);
  }

  render() {
    const {
      state: { hasError },
      props: { text, children, className },
    } = this;
    if (hasError || !children) {
      return <Error text={text} className={className} />;
    }

    return children;
  }
}

export default ErrorBoundary;
