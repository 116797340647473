/**
 * getLanguage - return key or value
 * @param {Array<Array<string, string>>} languages - array of languages - [ ['en','ENG], ...]
 * @param {string} key - key or value what need to find
 * @param {('key' | 'value')} option - what will return
 * @returns {string}
 */
const getLanguage = (languages, key, option = 'key') => {
  const lang = languages.find((lg) => lg.includes(key));
  return lang && (option === 'value' ? lang[1] : lang[0]);
};

export default getLanguage;
