import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import style from './ActiveTradesFooter.module.scss';

import useWindowSize from '../../../../hooks/useWindowSize';
import { useRootModel } from '../../../../models/RootStore';
import BalanceFormat from '../../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents active trades block with total sums
 * @returns {JSX.Element}
 */

const ActiveTradesFooter = () => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const {
    finance: {
      activeTrades: {
        total: { profit, result, amount },
      },
    },
  } = useRootModel();
  return (
    <div className={style.footer}>
      <p>{t('total')}</p>
      <div className={style.text}>
        <BalanceFormat value={amount} />
      </div>
      {isDesktop && (
        <div>
          <BalanceFormat value={result} />
        </div>
      )}
      <div
        className={cn(style.text, {
          [style.green]: profit > 0,
          [style.red]: profit < 0,
        })}
      >
        <BalanceFormat value={profit} />
      </div>
    </div>
  );
};

export default observer(ActiveTradesFooter);
