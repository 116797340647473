import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';
import { useRootModel } from '../../models/RootStore';
import useOnClick from '../../hooks/useOnClick';
import style from './SelectCurrency.module.scss';
import { balanceCurrency } from '../../constant/walletConstants';
import { ReactComponent as ArrowUp } from '../../assets/image/common/arrowUpList.svg';
import { ReactComponent as ArrowDown } from '../../assets/image/common/arrowDownList.svg';

const SelectCurrency = () => {
  const [showContent, setShowContent] = useState(false);
  const ref = useRef();
  const {
    payment: {
      exchangeCurrency,
      setExchangeCurrency,
      accounts: {
        realAccount: { cryptoBalance },
      },
    },
  } = useRootModel();

  const handleOnClick = () => {
    setShowContent(!showContent);
  };

  const handleClick = (currency) => {
    setExchangeCurrency(currency);
    setShowContent(false);
  };

  useOnClick(ref, () => setShowContent(false));

  return (
    <div className={style.currencyContainer} ref={ref}>
      <button className={style.currencyButton} type='button' onClick={handleOnClick}>
        <i className={style.icon}>{balanceCurrency[exchangeCurrency]?.icon}</i>
        <p className={style.title}>{balanceCurrency[exchangeCurrency]?.title}</p>
        {showContent ? <ArrowUp /> : <ArrowDown />}
      </button>
      <CSSTransition
        in={showContent}
        timeout={700}
        classNames={{
          enter: style.enter,
          enterActive: style.enterActive,
          enterDone: style.enterDone,
          exit: style.exit,
          exitActive: style.exitActive,
          exitDone: style.exitDone,
        }}
        unmountOnExit
      >
        <div className={style.currencyContent}>
          {cryptoBalance
            .filter(
              // eslint-disable-next-line no-shadow
              ({ currency }) =>
                currency === 'BTC' ||
                currency === 'ETH' ||
                currency === 'LTC' ||
                currency === 'USDT_ERC20',
            ) // todo delete filter after api change
            // eslint-disable-next-line no-shadow
            .map(({ currency }) => (
              <button
                type='button'
                className={style.optionButton}
                key={currency}
                onClick={() => handleClick(currency)}
              >
                <i className={style.icon}>{balanceCurrency[currency]?.icon}</i>
                <p>{balanceCurrency[currency]?.title}</p>
              </button>
            ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default observer(SelectCurrency);
