import React from 'react';
import { observer } from 'mobx-react';
import numeral from 'numeral';
import cn from 'classnames';

import style from './ClosedTradesItem.module.scss';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { useRootModel } from '../../../../../../models/RootStore';
import string from '../../../../../../util/string';
import date from '../../../../../../util/date';
import { OPERATION } from '../../../../../../constant/commonConstants';

import { ReactComponent as Down } from '../../../../../../assets/image/common/downArr.svg';
import { ReactComponent as Up } from '../../../../../../assets/image/common/upArr.svg';
import BalanceFormat from '../../../../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents instrument item in closed trades
 * @param {number} id - instrument id
 * @param {string} title - title for instrument
 * @param {string} number - number for instrument
 * @param {JSX.Element} Icon - icon for instrument
 * @param {string} dateOpen - opening date
 * @param {number} priceOpen - opening price
 * @param {string} dateClose - closing date
 * @param {number} priceClose - closing price
 * @param {number} amount - amount of rate
 * @param {number} multiplier - multiplier
 * @param {number} result - current account status
 * @param {number} profit - earnings or loss in $
 * @param {number} profitPercent - earnings or loss in %
 * @param {'BUY' | 'SELL'} operation - trade operation
 * @param {boolean} isPercent - show % or $
 * @returns {JSX.Element}
 */

const ClosedTradesItem = ({
  id,
  title = '',
  number,
  icon: Icon,
  dateOpen,
  priceOpen,
  dateClose,
  priceClose,
  amount,
  multiplier,
  result,
  profit,
  profitPercent,
  operation,
  isPercent,
}) => {
  const { isDesktop, isLaptop } = useWindowSize();
  const {
    settings: { language },
    finance: {
      closedTrades: { setClosedTradeId },
    },
    modal: { closeTradeInfo },
  } = useRootModel();

  const [firstPair, secondPair] = title.split('/');

  const setSymbol = async () => {
    await setClosedTradeId(id);
  };

  const handleClickInfo = async () => {
    await setSymbol();
    closeTradeInfo.open();
  };

  return (
    <div
      role='button'
      tabIndex={-1}
      className={style.closedTradesItem}
      onClick={handleClickInfo}
      onKeyDown={handleClickInfo}
    >
      <div className={style.titleWrapper}>
        <i className={style.instrumentIcon}>{Icon && <Icon />}</i>
        <div>
          <p className={style.link}>
            <span>{firstPair}</span>
            {secondPair && <span className={style.secondPair}>/{secondPair}</span>}
          </p>
          {isDesktop ? (
            <p className={style.code}>{number}</p>
          ) : (
            <p className={style.code}>({number})</p>
          )}
        </div>
        {operation === OPERATION.buy ? <Up /> : <Down />}
      </div>

      {isLaptop && (
        <div>
          <p>{string.formatNumber(priceOpen)}</p>
          <p className={style.textSecond}>{date.toLocaleString(dateOpen, language)}</p>
        </div>
      )}

      {isLaptop && (
        <div>
          <p>{priceClose ? string.formatNumber(priceClose) : `-`}</p>
          <p className={style.textSecond}>
            {dateClose ? date.toLocaleString(dateClose, language) : `-`}
          </p>
        </div>
      )}

      {isDesktop && <div>{date.toLocaleString(dateOpen, language)}</div>}

      {isDesktop && <div>{string.formatNumber(priceOpen)}</div>}

      {isDesktop && <div>{dateClose ? date.toLocaleString(dateClose, language) : `-`}</div>}

      {isDesktop && <div>{priceClose ? string.formatNumber(priceClose) : `-`}</div>}

      {isDesktop && (
        <div className={style.amountWrapper}>
          <BalanceFormat value={amount} />
          <span className={style.funds}> x {multiplier}</span>
        </div>
      )}

      <div>
        {result ? <BalanceFormat value={result} /> : `-`}
        {isLaptop && (
          <div className={style.amount}>
            <BalanceFormat value={amount} />
            <span className={style.funds}>x{multiplier}</span>
          </div>
        )}
      </div>

      <div className={style.profitColumn}>
        {(profit === null || !profit) && `-`}
        {isDesktop &&
          Boolean(profit) &&
          (!isPercent ? (
            <div className={cn(style.priceDown, { [style.priceUp]: profit > 0 })}>
              <BalanceFormat value={profit} />
            </div>
          ) : (
            <div
              className={cn(style.priceDown, {
                [style.priceUp]: profitPercent > 0,
              })}
            >
              ({profitPercent > 0 && '+'}
              {numeral(profitPercent).format('0.00%')})
            </div>
          ))}

        {isLaptop && profit && (
          <>
            <div className={cn(style.priceDown, { [style.priceUp]: profit > 0 })}>
              <BalanceFormat value={profit} />
            </div>
            <p
              className={cn(style.percent, style.priceDown, {
                [style.priceUp]: profitPercent > 0,
              })}
            >
              ({profitPercent > 0 && '+'}
              {numeral(profitPercent).format('0.00%')})
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(ClosedTradesItem);
