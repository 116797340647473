import React from 'react';
import { observer } from 'mobx-react';

import style from './SearchModalFooter.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import instrumentIcon from '../../../../../assets/instrumentIcon';

import Error from '../../../../Error/Error';
import Empty from '../../../../Empty/Empty';
import SearchModalItem from '../SearchModalItem/SearchModalItem';
import ModalFooter from '../../../../Modal/ModalFooter/ModalFooter';
import Preloader from '../../../../ui/Preloader/Preloader';

/**
 * Represents SearchModalFooter
 * @returns {JSX.Element}
 */
const SearchModalFooter = () => {
  const {
    search: { items, error, isLoading },
    modal: {
      search: { close },
    },
  } = useRootModel();

  if (isLoading)
    return (
      <ModalFooter className={style.footer}>
        <Preloader className={style.loader} />
      </ModalFooter>
    );

  return (
    <ModalFooter className={style.footer}>
      {error && <Error className={style.error} />}
      {!error && !items?.length && <Empty className={style.empty} />}
      {!error &&
        items.map(({ s, alias, h, l, lp, cpd, type, oldPrice }) => {
          const instType = instrumentIcon[type];
          const Icon = instType ? instrumentIcon[type][s] : null;
          return (
            <SearchModalItem
              key={s}
              symbol={s}
              icon={Icon}
              price={lp}
              low={l}
              high={h}
              change={cpd}
              alias={alias}
              type={type}
              closeModal={close}
              oldPrice={oldPrice}
            />
          );
        })}
    </ModalFooter>
  );
};

export default observer(SearchModalFooter);
