import React, { useState } from 'react';
import { observer } from 'mobx-react';

import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './ActiveTradesRightSide.module.scss';
import { useRootModel } from '../../../models/RootStore';

import Badge from '../../ui/Badge/Badge';
import ActiveTradesDropdown from '../../ActiveTradesDropdown/ActiveTradesDropdown';
import ButtonToggle from '../../ButtonToggle/ButtonToggle';
import ActiveTradesItem from './ActiveTradesItem/ActiveTradesItem';

/**
 * Represents a Active trades block on Main Page.
 * @returns {JSX.Element}
 */
const ActiveTradesRightSide = () => {
  const { t } = useTranslation();
  const {
    finance: {
      activeTrades: { allTradesAmount, sortedByTime },
    },
  } = useRootModel();

  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
  };

  const isTrades = !!allTradesAmount;
  return (
    isTrades && (
      <div className={style.container}>
        <div className={style.buttonsBlock}>
          <div className={style.titleWrapper}>
            <p className={style.titleWrapper__title}>{t('active_trades')}</p>
            {isTrades && <Badge amount={allTradesAmount} className={style.notifications} />}
          </div>
          {isTrades && <ActiveTradesDropdown />}
        </div>
        <div className={style.instrumentTitle}>
          <p>{t('instrument')}</p>
          <p>{t('price')}</p>
          <p className={style.profitBlock}>{t('profit')}</p>
        </div>
        <div className={style.contentVisible}>
          {sortedByTime
            .slice(0, 3)
            .map(
              ({
                symbol: { s },
                id,
                amount,
                profit,
                lastPrice,
                multiplier,
                profitPercent,
                operation,
              }) => {
                return (
                  <ActiveTradesItem
                    key={id}
                    symbol={s}
                    price={lastPrice}
                    amount={amount}
                    percent={profitPercent}
                    multiplier={multiplier}
                    profit={profit}
                    operation={operation}
                    id={id}
                  />
                );
              },
            )}
        </div>
        <div className={cn(style.content, { [style.visible]: isVisible })}>
          {sortedByTime
            .slice(3, sortedByTime.length)
            .map(
              ({
                symbol: { s },
                id,
                amount,
                profit,
                lastPrice,
                multiplier,
                profitPercent,
                operation,
              }) => {
                return (
                  <ActiveTradesItem
                    key={id}
                    symbol={s}
                    price={lastPrice}
                    amount={amount}
                    percent={profitPercent}
                    multiplier={multiplier}
                    profit={profit}
                    operation={operation}
                    id={id}
                  />
                );
              },
            )}
        </div>
        {sortedByTime.length > 3 && (
          <ButtonToggle isVisible={isVisible} handleToggle={handleToggle} />
        )}
        <div className={style.buttonBlock}>
          <Link to='/trades/active' className={style.link}>
            {t('active_trades')}
          </Link>
          <Link to='/trades/closed' className={style.link}>
            {t('closed_trades')}
          </Link>
        </div>
      </div>
    )
  );
};

export default observer(ActiveTradesRightSide);
