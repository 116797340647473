import React from 'react';
import './Preloader.scss';
import cn from 'classnames';

/**
 * Represents Preloader
 * @param {CSSStyleRule} className - wrapper className
 * @returns {JSX.Element}
 */
const Preloader = ({ className }) => {
  return (
    <div className={cn(className, 'loader')}>
      <div className='loadingio-spinner-eclipse-401znmgsz9d'>
        <div className='ldio-8mcexavhis'>
          <div />
        </div>
      </div>
    </div>
  );
};

export default Preloader;
