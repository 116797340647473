import React from 'react';
import styles from '../FooterInfo.module.scss';

const ObjectivesVision = () => {
  return (
    <section>
      <h2 className={styles.pageHeading}>Objectives & Vision</h2>
      <p className={styles.subHeading}>
        Being the number 1 global CFD provider while continuing to lead in technology and
        innovation, and attracting new and existing customers.
      </p>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>Our Strategy</h3>
          <p className={styles.text}>
            i88 has established a strong foundation from which it is well positioned to deliver
            future growth. i88’s strategic priorities have to date differentiated, and will continue
            to differentiate, i88 from its competitors and be fundamental to i88’s future success.
          </p>
        </div>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>
            Continue to acquire New Customers and retain Active Customers
          </h3>
          <p className={styles.text}>
            i88’s own marketing has been and will continue to be the driving force behind New
            Customer growth within i88’s current markets and potential new jurisdictions. These
            innovative marketing initiatives allow i88 to deploy targeted marketing resources and
            develop highly effective marketing campaigns through the monitoring and control of
            customer acquisition spend based on data analytics tools and proprietary algorithms.
          </p>
        </div>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>
            Increase trading volume from Active Customers on the Trading Platform
          </h3>
          <p className={styles.text}>
            i88’s consistent focus on innovation, best-in-class user experience and breadth of
            offering are the key elements of increasing activity of Active Customers on the Trading
            Platform. i88’s continued emphasis on innovation will enable it to continue to be
            amongst the first to market in launching new instruments which customers find desirable,
            such as high profile, newly listed equities.
          </p>
        </div>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>
            Increase market share in existing jurisdictions and expand into new jurisdictions
          </h3>
          <p className={styles.text}>
            i88 intends to increase its market share in the jurisdictions in which its offering is
            currently available and expand its geographic reach over time through entry into new
            jurisdictions in which it does not currently have customers and by focusing on acquiring
            New Customers from regulated markets, particularly those New Customers residing in
            Western European countries.
          </p>
        </div>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>
            Continue leadership in innovation and Research & Development
          </h3>
          <p className={styles.text}>
            Since its inception, i88 has put significant emphasis on its technological edge and
            continuous innovation. The Company understands the need to develop and deploy new and
            innovative financial instruments as part of its strategy to continue to build a loyal
            and engaged customer base. i88 is an industry leader within the CFD sector in mobile
            innovation and customer satisfaction.
          </p>
        </div>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>
            Continue to optimise our operating model to further drive financial performance
          </h3>
          <p className={styles.text}>
            The continued investment in and development of the Marketing Machine will enable i88 to
            further enhance its ability to efficiently and cost-effectively acquire New Customers.
            The nature of i88’s proprietary technology enables it to expand into new geographies
            without the need for a physical presence in those markets, which in turn limits the
            capital expenditure required to increase its geographic reach.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ObjectivesVision;
