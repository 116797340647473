import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { NavLink } from 'react-router-dom';
import style from './TabsScroll.module.scss';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * Represents tabs component
 * @param {Object} tabs - tabs
 * @param {CSSStyleRule} className - className
 * @returns {JSX.Element}
 */
const TabsScrollLink = ({ tabs, className }) => {
  const { isMobile, isLaptop } = useWindowSize();
  const titleRef = useRef();

  useEffect(() => {
    if (titleRef.current.ariaCurrent === 'page' && (isMobile || isLaptop)) {
      titleRef.current.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'center' });
    }
  }, [isLaptop, isMobile]);

  return (
    <div className={style.listContainer}>
      <nav className={cn(style.list, className)}>
        {tabs.map(({ tabLabel, id, url }) => (
          <NavLink
            id={id}
            to={url}
            className={({ isActive }) =>
              isActive ? `${style.tabLink} ${style.active}` : style.tabLink
            }
            ref={titleRef}
          >
            {tabLabel}
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default TabsScrollLink;
