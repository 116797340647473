import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from '../../HeaderUserInfo.module.scss';
import { ReactComponent as Profile } from '../../../../../assets/image/common/profile-mini.svg';

/**
 * Represents ButtonProfile component
 * @returns {JSX.Element}
 */
const ButtonProfile = ({ onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleSwitchToProfile = () => {
    navigate('/profile');
    if (onClick) onClick();
  };

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Profile />
      </i>
      <button type='button' className={style.button} onClick={handleSwitchToProfile}>
        {t('account_settings')}
      </button>
    </div>
  );
};

export default ButtonProfile;
