import React, { useState } from 'react';
import cn from 'classnames';
import style from './ButtonGroup.module.scss';

/**
 * Represents Group of buttons
 * @param {Array<string>} items - buttons labels
 * @param {string} activeItem - name of active button
 * @param {function} onClick - onClick handler - (name, event) => void
 * @param {CSSStyleRule} className - group className
 * @param {CSSStyleRule} buttonClassName - button className
 * @returns {JSX.Element}
 */
const ButtonGroup = ({ items, activeItem, onClick, className, buttonClassName }) => {
  const [active, setActive] = useState(activeItem || items[0]);

  const handleChange = (label, e) => {
    setActive(label);
    if (onClick) onClick(label, e);
  };

  return (
    <div className={cn(style.groupButtons, className)}>
      {items.map((item) => (
        <button
          key={item}
          type='button'
          className={cn(style.button, { [style.activeButton]: item === active }, buttonClassName)}
          onClick={(e) => handleChange(item, e)}
        >
          <span className={style.buttonLabel}>{item}</span>
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
