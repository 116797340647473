import React from 'react';
import { observer } from 'mobx-react';

import style from './PendingRightSideItem.module.scss';
import { OPERATION } from '../../../../constant/commonConstants';
import string from '../../../../util/string';

import { ReactComponent as UpArrow } from '../../../../assets/image/common/upArr.svg';
import { ReactComponent as DownArrow } from '../../../../assets/image/common/downArr.svg';
import BalanceFormat from '../../../BalanceFormat/BalanceFormat';
import { useRootModel } from '../../../../models/RootStore';

/**
 * Represents PendingRightSideItem
 * @param {number} id - pending trade id
 * @param {string} symbol - pending trade symbol
 * @param {number} multiplier - pending trade multiplier
 * @param {number} amount - pending trade amount
 * @param {number} pendingPrice - pending trade pendingPrice
 * @param {'BUY' | 'SELL'} operation - pending trade operation
 * @returns {JSX.Element}
 */
const PendingRightSideItem = ({ id, symbol, multiplier, amount, pendingPrice, operation }) => {
  const {
    finance: {
      pendingTrades: { setPendingTradeId },
    },
    modal: { pendingTrade },
  } = useRootModel();

  const setSymbol = async () => {
    await setPendingTradeId(id);
  };

  const handleClickInfo = async () => {
    await setSymbol();
    pendingTrade.open();
  };

  return (
    <div
      role='button'
      className={style.instrument}
      onClick={handleClickInfo}
      onKeyDown={handleClickInfo}
      tabIndex={-1}
    >
      <div className={style.instrument__block}>
        <p className={style.instrument__text}>{symbol}</p>
        <span className={style.instrument__operation}>
          {operation === OPERATION.buy ? <UpArrow /> : <DownArrow />}
        </span>
      </div>
      <div className={style.priceBlock}>
        <p className={style.price}>{string.formatNumber(pendingPrice)}</p>
        <div className={style.bottomPrice}>
          <BalanceFormat value={amount} />x{multiplier}
        </div>
      </div>
    </div>
  );
};

export default observer(PendingRightSideItem);
