import React from 'react';
import cn from 'classnames';
import style from './ModalBody.module.scss';

/**
 * Represents Modal body
 * @param children
 * @param {CSSStyleRule} className - className
 * @returns {JSX.Element}
 */

const ModalBody = ({ children, className }) => {
  return <div className={cn(style.body, className)}>{children}</div>;
};

export default ModalBody;
