import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import style from './Sidebar.module.scss';

import { ReactComponent as Main } from '../../assets/image/common/main.svg';
import { ReactComponent as Trades } from '../../assets/image/common/trades.svg';
import { ReactComponent as Markets } from '../../assets/image/common/markets.svg';
import { ReactComponent as Balance } from '../../assets/image/common/balance.svg';
import { ReactComponent as Profile } from '../../assets/image/common/profile.svg';

/**
 * @typedef {Object} SidebarItem
 * @property {number} id - item id
 * @property {string} text - text for title and tooltip
 * @property {JSX.Element} icon - item icon
 * @property {string} url - item route
 */

/**
 * Represents a sidebar
 * @returns {JSX.Element}
 */
const Sidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const elements = [
    { id: 1, text: t('hot'), icon: Main, url: '/', rule: false },
    {
      id: 2,
      text: t('trades'),
      icon: Trades,
      url: '/trades/active',
      rule: pathname.includes('trades'),
    },
    {
      id: 3,
      text: t('markets'),
      icon: Markets,
      url: '/products/favorites',
      rule: pathname.includes('products'),
    },
    {
      id: 4,
      text: t('wallet'),
      icon: Balance,
      url: '/balance',
      rule: pathname.includes('balance'),
    },
    {
      id: 5,
      text: t('profile'),
      icon: Profile,
      url: '/profile',
      rule: pathname.includes('profile'),
    },
  ];

  return (
    <nav className={style.nav}>
      {elements.map(({ id, text, icon: Icon, url, rule }) => (
        <NavLink
          key={id}
          to={url}
          className={({ isActive }) =>
            isActive || rule ? `${style.link} ${style.link__active}` : style.link
          }
        >
          <Icon className={style.link__icon} />
          <p>{text}</p>
        </NavLink>
      ))}
    </nav>
  );
};

export default Sidebar;
