import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './Pending.module.scss';

import { PERIOD, sortByQuantity, SORT_BY_TIME } from '../../../constant/commonConstants';
import { useRootModel } from '../../../models/RootStore';
import useWindowSize from '../../../hooks/useWindowSize';

import ButtonGroup from '../../../components/ui/ButtonGroup/ButtonGroup';
import Dropdown from '../../../components/ui/Dropdown/Dropdown';
import PendingContent from './PendingContent/PendingContent';
import Pagination from '../../../components/ui/Pagination/Pagination';
import BalanceFormat from '../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents pending trades component
 * @returns {JSX.Element}
 */
const Pending = () => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const {
    finance: {
      pendingTrades: {
        getPendingTrades,
        pageSize,
        page,
        period,
        setPage,
        totalAmountPending,
        sortedTable,
        sortedTableWithPagination,
        setPageSize,
        changePeriod,
        quantity,
      },
    },
  } = useRootModel();

  const handleChangePage = ({ currentPage }) => {
    setPage(currentPage);
  };

  const handleChangeSortByQuantity = (quant) => {
    setPageSize(quant);
  };

  const handleChangeSortByDate = (time) => {
    Object.entries(PERIOD).forEach(([key, value]) => {
      if (t(key) === time) changePeriod(value);
    });
  };

  useEffect(() => {
    getPendingTrades();
  }, [getPendingTrades]);

  return (
    <div className={style.pending}>
      <div className={style.wrapper}>
        <div className={style.title}>
          <p>{t('pending_orders')}</p>
          <p className={style.notifications}>{quantity}</p>
        </div>

        <div className={style.optionsWrapper}>
          <div className={style.options}>
            <p className={style.optionTitle}>{t('show')}</p>
            <Dropdown
              onChange={handleChangeSortByQuantity}
              className={style.entries}
              contentClassName={style.content}
              value={pageSize}
              currentItemWrapperClassName={style.currentItemWrapperClassName}
            >
              {sortByQuantity}
            </Dropdown>
          </div>
          <ButtonGroup
            items={Object.values(SORT_BY_TIME)}
            activeItem={SORT_BY_TIME[period]}
            className={style.buttonGroup}
            onClick={handleChangeSortByDate}
          />
        </div>
      </div>

      <PendingContent items={isDesktop ? sortedTableWithPagination : sortedTable} />
      <div className={style.footer}>
        <p>{t('total')}</p>
        <div className={style.total}>
          <BalanceFormat value={totalAmountPending} />
        </div>
      </div>
      {isDesktop && (
        <Pagination
          totalRecords={quantity}
          pageLimit={pageSize}
          pageNeighbours={2}
          wrapperClassName={style.pagination}
          onPageChanged={handleChangePage}
          currentPage={page}
        />
      )}
    </div>
  );
};

export default observer(Pending);
