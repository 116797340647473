import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './Notifications.module.scss';
import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';

import NotificationsList from './NotificationsList/NotificationsList';

/**
 * @typedef {Object} NotificationItem
 * @property {number} id - notification id
 * @property {string} title - notification title
 * @property {boolean} status - notification status
 * @property {string} attributeName
 */

/**
 * Represents a notifications component
 * @returns {JSX.Element}
 */
const Notifications = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    user: { userData, changeNotification },
  } = useRootModel();

  const handleSwitch = async (attributeName, value) => {
    try {
      await changeNotification({ [attributeName]: value });
    } catch (e) {
      alert.show(t(e.message));
      // todo error
    }
  };

  const notifications = [
    {
      id: 1,
      title: t('deals'),
      status: userData?.notificationHighlightDealsRiskEnabled,
      attributeName: 'notificationHighlightDealsRiskEnabled',
    },
    {
      id: 2,
      title: t('alerts'),
      status: userData?.notificationTradeAlertsEnabled,
      attributeName: 'notificationTradeAlertsEnabled',
    },
    {
      id: 3,
      title: t('margin_call'),
      status: userData?.marginCallEnabled,
      attributeName: 'marginCallEnabled',
    },
  ];

  return (
    <ErrorBoundary>
      <section className={style.notifications}>
        <NotificationsList notifications={notifications} handleSwitch={handleSwitch} />
      </section>
    </ErrorBoundary>
  );
};

export default observer(Notifications);
