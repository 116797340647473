/**
 * setStorageRestoreTime - function for save Date.now() + seconds in localStorage
 * @param {string} key - key for localStorage
 * @param {number} seconds - seconds
 */
const setLocalRestoreTime = (key = '', seconds = 0) => {
  const codeRestoreTime = Date.now() + seconds * 1000;
  localStorage.setItem(key, JSON.stringify(codeRestoreTime));
};

/**
 * setSecondsFromStorage - function for setting seconds from localStore
 * @param {string} storageKey - key for localStorage
 * @param {function} setSeconds - setSeconds handler (setState)
 */
const setSecondsFromStorage = (storageKey, setSeconds) => {
  const timeStorage = localStorage.getItem(storageKey);
  const time = parseInt(timeStorage, 10);

  if (!time || Date.now() >= time) {
    setSeconds(0);
    return;
  }
  const delta = time - Date.now();
  const seconds = Math.ceil(delta / 1000);

  setSeconds(seconds);
};

const storage = {
  setLocalRestoreTime,
  setSecondsFromStorage,
};

export default storage;
