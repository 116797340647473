import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AboutUs from './AboutUsMobile/AboutUsMobile';
import ObjectivesVision from './ObjectivesVisionMobile/ObjectivesVisionMobile';
import AffiliateMobile from './AffiliateMobile/AffiliateMobile';

const FooterInfoMobile = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (
    pathname !== '/info/about' &&
    pathname !== '/info/objectives&vision' &&
    pathname !== '/info/affiliate'
  )
    return navigate('/404');

  return (
    <section>
      {pathname === '/info/about' && <AboutUs />}
      {pathname === '/info/objectives&vision' && <ObjectivesVision />}
      {pathname === '/info/affiliate' && <AffiliateMobile />}
    </section>
  );
};

export default FooterInfoMobile;
