import { types, flow, getParent } from 'mobx-state-tree';
import axios from 'axios';

import getLanguage from '../../util/getLanguage';
import error from '../../util/error';
import tokenUtils from '../../util/tokenUtils';

import UserModel from './UserModel';
import PaymentHistoryModel from './PaymentHistoryModel';
import NotificationsModel from './NotificationsModel';
import ReferentsModel from './ReferentsModel';

if (process.env.NODE_ENV === 'production') axios.defaults.baseURL = process.env.REACT_APP_API;

axios.interceptors.response.use(
  (response) => response,
  async (err) => {
    const { status } = err.response;
    if (status === 401) {
      localStorage.removeItem('id_token');
    }
    return Promise.reject(err);
  },
);

const AuthUserModel = types
  .model('AuthUserModel', {
    email: types.maybeNull(types.string),
    userData: types.maybeNull(UserModel),
    alert: types.optional(types.string, ''),
    isAuthorized: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    twoFAEnabled: types.maybeNull(types.boolean),
    paymentHistory: types.optional(PaymentHistoryModel, {}),
    notifications: types.optional(NotificationsModel, {}),
    refCode: types.optional(types.string, ''),
    referentsData: types.optional(ReferentsModel, {}),
    pageAffiliate: types.optional(types.number, 0),
    pageSizeAffiliate: types.optional(types.number, 10),
    isNeedSessionStop: types.optional(types.boolean, false),
    currency: types.optional(types.string, '$'),
    countryCode: types.optional(types.string, ''),
  })
  .actions((user) => ({
    setIsLoading(isLoading) {
      user.isLoading = isLoading;
    },
    setAlert(message) {
      user.alert = message;
    },
    setPageAffiliate(page) {
      user.pageAffiliate = page;
    },
    setIsNeedSessionStop(data) {
      user.isNeedSessionStop = data;
    },
    getUserCountryBeforeRegistration: flow(function* getUserCountryBeforeRegistration() {
      user.isLoading = true;

      try {
        const { data } = yield axios.get(`api/registration/user/country`);
        user.countryCode = data.country_code;
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    register: flow(function* register(email, password, phoneCode, phoneNumber, refCode, currency) {
      user.isLoading = true;

      try {
        const { settings } = getParent(user);
        const langKey = getLanguage(settings.langKeys, settings.language);

        const config = {
          email,
          langKey,
          password,
          phoneCode,
          phoneNumber,
          currency,
        };

        const refCodeParam = refCode ? `?ref-code=${refCode}` : '';

        const response = yield axios.post(`/api/register${refCodeParam}`, config);
        const { data } = response;
        localStorage.setItem('id_token', data.id_token);
        tokenUtils.writeBearerToken(data.id_token);
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    login: flow(function* login(email, password, rememberMe = true) {
      user.isLoading = true;
      try {
        const config = {
          password,
          username: email,
          rememberMe,
        };
        const { data } = yield axios.post(`/api/authenticate`, config);

        user.email = email;
        user.twoFAEnabled = data.twoFAEnabled;

        if (data.id_token) {
          localStorage.setItem('id_token', data.id_token);
          tokenUtils.writeBearerToken(data.id_token);
        }
        user.getUser();
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    logout: flow(function* logout() {
      try {
        yield axios.post(`/api/logout`);
        localStorage.removeItem('id_token');
        localStorage.removeItem('theme');
        const {
          finance: { activeTrades },
          settings: { setDefaultTheme },
        } = getParent(user);
        setDefaultTheme();
        activeTrades.clearData();
        tokenUtils.deleteToken();
        user.userData = null;
        user.isAuthorized = false;
        user.twoFAEnabled = null;
        user.notifications.closeStream();
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
      }
    }),
    verify: flow(function* verify({ email, confirmCode }) {
      user.isLoading = true;
      try {
        const { data } = yield axios.post(`/api/verify`, {
          email,
          confirmCode,
        });

        localStorage.setItem('id_token', data.id_token);
        tokenUtils.writeBearerToken(data.id_token);
        user.isAuthorized = true;
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    verifySendCode: flow(function* verifySendCode() {
      user.isLoading = true;
      try {
        yield axios.post(`/api/authenticate/send-code`, {
          username: user.email,
        });
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    getUser: flow(function* getUser() {
      if (!localStorage.id_token) return;

      tokenUtils.writeBearerToken(localStorage.id_token);
      user.isLoading = true;
      try {
        const { data } = yield axios.get(`/api/extended-user-attributes/current`);

        if (!data) return;
        user.userData = data;
        user.isAuthorized = true;
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
      } finally {
        user.isLoading = false;
      }
    }),
    setUserLanguage(lang) {
      user.userData.user.langKey = lang;
    },
    changeEmail: flow(function* changeEmail(email) {
      user.isLoading = true;
      try {
        yield axios.post(`/api/account/change-email`, {}, { params: { email } });
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    changeEmailApprove: flow(function* changeEmailApprove(body) {
      user.isLoading = true;
      try {
        const { data } = yield axios.post(`/api/account/change-email/approve`, body);

        localStorage.setItem('id_token', data.id_token);
        tokenUtils.writeBearerToken(data.id_token);
        const { getUser } = user;
        yield getUser();
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    verifyEmail: flow(function* verifyEmail() {
      user.isLoading = true;
      try {
        yield axios.post(`/api/account/confirm-email`);
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    verifyEmailConfirm: flow(function* verifyEmailConfirm(token) {
      user.isLoading = true;
      try {
        yield axios.get(`/api/email/confirm/${token}`);
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    changePassword: flow(function* changePassword(currentPassword, newPassword) {
      user.isLoading = true;
      try {
        yield axios.post(`/api/account/change-password`, {
          currentPassword,
          newPassword,
        });
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    resetPasswordInit: flow(function* resetPassword(email) {
      user.isLoading = true;
      try {
        yield axios.post(`/api/reset-password/init`, {}, { params: email });
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    resetPasswordFinish: flow(function* resetPasswordFinish(newPassword, resetKey) {
      user.isLoading = true;
      try {
        yield axios.post(`/api/reset-password/finish`, {
          newPassword,
          resetKey,
        });
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    verifyPhone: flow(function* verifyPhone({ phone, phoneCode }) {
      user.isLoading = true;
      try {
        const {
          data: { attempts },
        } = yield axios.post(
          `/api/extended-user-attributes/current/verify-phone`,
          {},
          {
            params: { phone, phoneCode },
          },
        );

        if (attempts) {
          user.phoneVerifyAttempts = attempts;
        }
        user.getUser();
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    verifyPhoneConfirm: flow(function* verifyPhoneConfirm(confirmPhoneCode) {
      user.isLoading = true;
      try {
        const { data } = yield axios.post(
          `/api/extended-user-attributes/current/verify-phone/approve`,
          {},
          {
            params: { confirmPhoneCode },
          },
        );

        if (data.verified) user.userData.phoneConfirmed = true;
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    changePhone: flow(function* changePhone({ phone, phoneCode }) {
      user.isLoading = true;
      try {
        yield axios.post(
          `/api/extended-user-attributes/current/change-phone`,
          {},
          {
            params: { phone, phoneCode },
          },
        );

        if (!user.userData.phoneConfirmed) {
          user.userData.phone = phone;
        }
        user.userData.phoneConfirmed = false;
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        error.errorHandler(message);
        user.alert = message;
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    changePhoneConfirm: flow(function* changePhoneConfirm(phoneCode, emailCode) {
      user.isLoading = true;
      try {
        yield axios.post(`/api/extended-user-attributes/current/change-phone/approve`, {
          phoneCode,
          emailCode,
        });
        const { getUser } = user;
        getUser();
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    getAttemptsVerifyPhone: flow(function* getAttemptsVerifyPhone() {
      try {
        const {
          data: { attempts },
        } = yield axios.get(`/api/extended-user-attributes/current/verify-phone`);
        if (attempts) user.userData.phoneVerifyAttempts = attempts;
      } catch (err) {
        //
      }
    }),
    changeUserData: flow(function* changeMainUserData(
      { firstName, lastName, dateOfBirth, passportId, city, countryCode },
      extendedAttributes = false,
    ) {
      user.isLoading = true;
      try {
        if (!extendedAttributes) {
          yield axios.patch(`/api/account`, { firstName, lastName });
          user.userData.user.firstName = firstName;
          user.userData.user.lastName = lastName;
        } else {
          yield axios.patch(`/api/extended-user-attributes/current`, {
            dateOfBirth,
            passportId,
            city,
            countryCode,
          });
          user.userData.city = city;
          user.userData.dateOfBirth = dateOfBirth;
          user.userData.passportId = passportId;
          user.userData.countryCode = countryCode;
        }
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    terminateActiveSessions: flow(function* terminateActiveSessions() {
      user.isLoading = true;
      try {
        const { data } = yield axios.post(`/api/terminate-active-sessions/`);

        localStorage.setItem('id_token', data.id_token);
        tokenUtils.writeBearerToken(data.id_token);
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    changeNotification: flow(function* changeNotification({
      marginCallEnabled,
      notificationHighlightDealsRiskEnabled,
      notificationTradeAlertsEnabled,
    }) {
      try {
        const { data } = yield axios.patch(`/api/extended-user-attributes/current`, {
          marginCallEnabled,
          notificationHighlightDealsRiskEnabled,
          notificationTradeAlertsEnabled,
        });

        user.userData = data;
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    toggleTwoFAEnabled(twoFaEnabled) {
      user.twoFAEnabled = twoFaEnabled;
    },
    changeTwoFaAuthorization: flow(function* changeTwoFaAuthorization(twoFaEnabled) {
      try {
        const { data } = yield axios.patch(`/api/extended-user-attributes/current`, {
          twoFaEnabled,
        });

        user.userData = data;
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    clearError() {
      user.alert = '';
    },
    switchAccount: flow(function* switchAccount() {
      user.isLoading = true;

      try {
        const { data } = yield axios.post(`/api/extended-user-attributes/current/switch-account`);

        // if (user.userData.demoVersion) {
        //   const {
        //     modal: { deposit },
        //   } = getParent(user);
        //   deposit.open();
        // }
        user.userData = data;
        document.location.reload();
        // user.userData.getBalance();
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    deposit: flow(function* deposit(data) {
      // todo remove ?
      user.isLoading = true;
      try {
        const response = yield axios.post(`/api/real-accounts/current/balance/deposit`, data);
        user.userData.realAccount.balance += response.data.deposits;
        user.userData.demoAccount = null;
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    getPassportPage: flow(function* getPassportPage() {
      user.isLoading = true;
      try {
        const { data } = yield axios.get(`/api/extended-user-attributes/documents`);
        user.userData.documents = data.documents;
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    loadPassportPage: flow(function* loadPassportPage(page) {
      user.isLoading = true;
      try {
        yield axios.post(`/api/extended-user-attributes/documents`, page, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      } finally {
        user.isLoading = false;
      }
    }),
    sendRequest: flow(function* sendRequest(data, files) {
      try {
        const formData = new FormData();

        files.forEach((f) => {
          formData.append('files', f);
        });
        formData.append(
          'support-form',
          new Blob([JSON.stringify(data)], {
            type: 'application/json',
          }),
        );

        yield axios.post(`/api/support`, formData, {
          headers: { 'Content-Type': 'multipart/form-data; multipart/mixed' },
        });
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
    updateDemoBalance: flow(function* updateDemoBalance() {
      try {
        yield axios.post(`/api/demo-accounts/current/update`);
        const { getUser } = user;
        getUser();
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
    getLinkRef: flow(function* getLinkRef() {
      try {
        const { data } = yield axios.get(`/api/extended-user-attributes/ref-code`);
        user.refCode = data?.refCode;
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
    setRefBonuses(data) {
      user.referentsData = data;
    },
    getRefBonuses: flow(function* getRefBonuses() {
      const { setRefBonuses, pageAffiliate: page, pageSizeAffiliate: size } = user;

      try {
        const params = {
          page,
          size,
        };

        const { data } = yield axios.get(`/api/extended-user-attributes/ref-bonus`, { params });
        setRefBonuses(data);
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
    withdrawRefBonus: flow(function* withdrawRefBonus() {
      try {
        yield axios.post(`/api/extended-user-attributes/ref-bonus/withdraw`);
      } catch (err) {
        const message = err.response?.data.message || err.message;
        user.alert = message;
        error.errorHandler(message);
        throw new Error(message);
      }
    }),
  }))
  .views((user) => ({
    get isDemoVersion() {
      return user.userData?.demoVersion;
    },
    get isDemoVersionNull() {
      return user.userData?.demoVersion === null;
    },
  }));

export default AuthUserModel;
