import React, { useState, useRef, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useRootModel } from '../../../models/RootStore';

import style from './AffiliateProgram.module.scss';

import Modal from '../../../components/Modal/Modal';
import ModalBody from '../../../components/Modal/ModalBody/ModalBody';
import AffiliateRules from './AffiliateRules/AffiliateRules';
import AffiliateTable from './AffiliateTable/AffiliateTable';
import BalanceFormat from '../../../components/BalanceFormat/BalanceFormat';
import Pagination from '../../../components/ui/Pagination/Pagination';
import { registrationPath } from '../../../constant/commonConstants';
import AlertContext from '../../../context/alert/alertContext';
import Button from '../../../components/ui/Button/Button';
import { ReactComponent as PreloaderMini } from '../../../assets/image/common/preloaderMini.svg';

/**
 * Represents Affiliate program component
 * @returns {JSX.Element}
 */

const AffiliateProgram = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const inputAreaRef = useRef(null);
  const alert = useContext(AlertContext);
  const {
    user: {
      getLinkRef,
      refCode,
      getRefBonuses,
      pageAffiliate: page,
      pageSizeAffiliate: pageSize,
      setPageAffiliate: setPage,
      referentsData: { totalUnpaidBonus, totalReferents },
      withdrawRefBonus,
      setIsLoading,
      isLoading,
    },
    payment: { getAccounts },
  } = useRootModel();

  const registrationLink = `${registrationPath}${refCode}`;

  const copyCodeToClipboard = async () => {
    await navigator.clipboard.writeText(registrationLink);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChangePage = async ({ currentPage }) => {
    await setPage(currentPage - 1);
    getRefBonuses();
  };

  const withdrawAllBonuses = async () => {
    try {
      await setIsLoading(true);
      await withdrawRefBonus();
      await getAccounts();
      await getRefBonuses();
      alert.show(t('withdraw_success'), 'success');
      setIsLoading(false);
    } catch (err) {
      alert.show(t(err.message));
    }
  };

  useEffect(() => {
    getLinkRef();
    getRefBonuses();
  }, []);

  return (
    <>
      <p className={style.startText}>{t('affiliate_program')}</p>
      <article className={style.affiliate}>
        <div className={style.affiliateTextBlock}>
          <h4 className={style.title}>{t('share_link')}</h4>
          <p className={style.text}>{t('share_text')}</p>
        </div>
        <div>
          <div className={style.inputBlock}>
            <input
              className={style.inputAffiliate}
              type='text'
              value={registrationLink}
              readOnly
              ref={inputAreaRef}
            />
            <button type='button' className={style.copyButton} onClick={copyCodeToClipboard}>
              {t('copy')}
            </button>
          </div>
          <button type='button' className={style.inviteText} onClick={handleOpenModal}>
            {t('program_rules')}
          </button>
        </div>
      </article>
      <div className={style.withdrawBlock}>
        <div className={style.withdrawBalance}>
          <span className={style.secondaryColor}>{t('total_balance')}: </span>
          <div className={style.boldText}>
            <BalanceFormat value={totalUnpaidBonus} />
          </div>
        </div>
        <Button
          color='primary'
          className={style.withdrawButton}
          onClick={withdrawAllBonuses}
          disabled={totalUnpaidBonus <= 0 || isLoading}
        >
          {isLoading ? <PreloaderMini className={style.loader} /> : t('withdraw_all')}
        </Button>
      </div>

      <AffiliateTable />

      <Pagination
        totalRecords={totalReferents}
        pageLimit={pageSize}
        pageNeighbours={2}
        wrapperClassName={style.pagination}
        onPageChanged={handleChangePage}
        currentPage={page + 1}
      />

      {showModal && (
        <Modal handleClose={handleCloseModal} wrapperClassname={style.modalWrapper}>
          <ModalBody className={style.modal}>
            <AffiliateRules handleClose={handleCloseModal} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default observer(AffiliateProgram);
