import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootModel } from '../../../../../../models/RootStore';
import AlertContext from '../../../../../../context/alert/alertContext';

import style from './PendingItem.module.scss';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import string from '../../../../../../util/string';
import date from '../../../../../../util/date';
import { OPERATION } from '../../../../../../constant/commonConstants';

import Button from '../../../../../../components/ui/Button/Button';
import { ReactComponent as Up } from '../../../../../../assets/image/common/upArr.svg';
import { ReactComponent as Down } from '../../../../../../assets/image/common/downArr.svg';
import { ReactComponent as Delete } from '../../../../../../assets/image/common/basket.svg';
import BalanceFormat from '../../../../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents instrument item in pending trades
 * @param {number} id - instrument id
 * @param {string} title - title for instrument
 * @param {string} code - code of instrument
 * @param {JSX.Element} Icon - icon for instrument
 * @param {string} date - opening date
 * @param {number} pendingPrice - execution conditions
 * @param {number} takeProfit - profit limit
 * @param {number} stopLoss - loss limit
 * @param {number} amount - amount
 * @param {'BUY' | 'SELL'} operation - trade operation
 * @returns {JSX.Element}
 */
const PendingItem = ({
  id,
  title,
  code,
  icon: Icon,
  date: openedAt,
  pendingPrice,
  takeProfit,
  stopLoss,
  amount,
  operation,
}) => {
  const { isDesktop, isLaptop } = useWindowSize();
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    settings: { language },
    finance: {
      // setActiveSymbol,
      trade: {
        current: { setId },
      },
      pendingTrades: { getPendingTrades, setActive, setPendingTradeId },
      activeTrades: { getActiveTrades },
    },
    modal: { cancelTrade, pendingTrade },
  } = useRootModel();

  const handleSetActive = async () => {
    try {
      await setActive(id);
      getPendingTrades();
      getActiveTrades();
      alert.show(t('order_set_active'), 'success');
    } catch (err) {
      alert.show(err);
    }
  };

  const handleClickCloseTrade = () => {
    setId(id);
    setPendingTradeId(id);
    // setActiveSymbol(code);
    cancelTrade.open();
  };

  const setSymbol = async () => {
    await setPendingTradeId(id);
  };

  const handleClickInfo = async (e) => {
    e.stopPropagation();
    await setSymbol();
    pendingTrade.open();
  };

  return (
    <div
      className={style.item}
      role='button'
      onClick={handleClickInfo}
      onKeyDown={handleClickInfo}
      tabIndex={-1}
    >
      <div className={style.titleWrapper}>
        <i className={style.instrumentIcon}>
          <Icon />
        </i>
        <div>
          <p>{title}</p>
          <p className={style.code}>{code}</p>
        </div>
        {operation === OPERATION.buy ? <Up /> : <Down />}
      </div>

      <div>{date.toLocaleString(openedAt, language)}</div>

      <div>{string.formatNumber(pendingPrice)}</div>

      {isDesktop && <div>{takeProfit ? <BalanceFormat value={takeProfit} /> : `-`}</div>}

      {isDesktop && <div>{stopLoss ? <BalanceFormat value={stopLoss} /> : `-`}</div>}

      {isLaptop && (
        <div className={style.limitsWrapper}>
          {takeProfit ? <BalanceFormat value={takeProfit} /> : `-`}/
          {stopLoss ? <BalanceFormat value={stopLoss} /> : `-`}
        </div>
      )}

      <div>
        <BalanceFormat value={amount} />
      </div>
      <div>
        <Button
          text={t('set_as_active')}
          fontWeight='normal'
          className={style.button}
          onClick={handleSetActive}
        />
      </div>
      <div>
        <button type='button' className={style.delete} onClick={handleClickCloseTrade}>
          <Delete />
        </button>
      </div>
    </div>
  );
};

export default PendingItem;
