import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import style from './FlowOfFundsItem.module.scss';

import { useRootModel } from '../../../../../../models/RootStore';
import { operationTitle } from '../../../../../../constant/commonConstants';
import date from '../../../../../../util/date';
import BalanceFormat from '../../../../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents instrument item in flow of funds
 * @param {string} tradeCodeId - trade Id
 * @param {string} date - operation date
 * @param {string} operation - operation title
 * @param {number} balance - balance in $
 * @param {number} amount - amount in $
 * @returns {JSX.Element}
 */

const FlowOfFundsItem = ({ tradeCodeId, date: openedAt, operation, balance, amount }) => {
  const {
    settings: { language },
  } = useRootModel();
  return (
    <div className={style.flowOfFundsItem}>
      <div>{date.toLocaleString(openedAt, language)}</div>

      <div>
        {operationTitle[operation]} {tradeCodeId}
      </div>

      <div className={style.positionRight}>
        <BalanceFormat value={balance} />
      </div>
      <div className={cn(style.priceDown, style.positionRight, { [style.priceUp]: amount > 0 })}>
        <BalanceFormat value={amount} />
      </div>
    </div>
  );
};

export default observer(FlowOfFundsItem);
