import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './ChatList.module.scss';
import { useRootModel } from '../../../models/RootStore';

import ChatListItem from './ChatListItem/ChatListItem';
import Preloader from '../../ui/Preloader/Preloader';
import date from '../../../util/date';

/**
 * Represents ChatList component
 * @param {Ref} chatListRef - chatListRef
 * @param {function} showArrow - show arrow handler
 * @param {function} hideArrow - hide arrow handler
 * @param {boolean} initialization - chat initialization
 * @param {Object} pictures - pictures state
 * @param {function} setPictures - setPictures handler
 * @returns {JSX.Element}
 */
const ChatList = ({ chatListRef, showArrow, hideArrow, initialization, pictures, setPictures }) => {
  const {
    chat: {
      messages: { sortedItems, setNextPage, getMessages, isLoading },
    },
    user: { userData },
    settings: { locale },
  } = useRootModel();
  const elemsRefs = useRef({});

  const [listScrollTop, setListScrollTop] = useState(0);

  const renderDay = (currentMessage, previousMessage) => {
    const currentDate = date.toLocaleString(currentMessage.createdAt, locale, false);
    const previousDate =
      previousMessage && date.toLocaleString(previousMessage.createdAt, locale, false);
    const isTheSameDays = currentDate === previousDate;

    if (!isTheSameDays) {
      return (
        <p className={style.dayWrapper}>
          <span className={style.line} />
          <span className={style.day}>{currentDate}</span>
          <span className={style.line} />
        </p>
      );
    }

    return null;
  };

  useEffect(() => {
    if (pictures.count === pictures.loaded && sortedItems.length === 20) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
    return () => {
      setPictures({ count: 0, loaded: 0 });
    };
  }, [pictures.loaded]);

  const handleScrollToBottom = () => {
    const elem = chatListRef.current;
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  };

  useEffect(() => {
    handleScrollToBottom();
  }, [initialization]);

  const [firstLoad, setFirstLoad] = useState(true);
  const [firstElementId, setFirstElementId] = useState(0);

  useEffect(() => {
    const onScroll = async ({ target: { scrollHeight, scrollTop } }) => {
      setListScrollTop(scrollTop);
      if (scrollHeight - scrollTop > 700) showArrow();
      else if (scrollHeight - scrollTop < 700) hideArrow();

      if (!firstLoad && scrollTop < 40 && !isLoading) {
        setFirstElementId(sortedItems[0].id);
        await setNextPage();
        await getMessages();
      }
    };
    chatListRef.current?.addEventListener('scroll', onScroll);
    return () => {
      chatListRef.current?.removeEventListener('scroll', onScroll);
    };
  });

  useEffect(() => {
    const lastElementId = sortedItems[sortedItems.length - 1].id;
    if (firstLoad) {
      elemsRefs.current[lastElementId].scrollIntoView();
      setFirstLoad(false);
      return;
    }
    if (firstElementId !== lastElementId) {
      elemsRefs.current[firstElementId].scrollIntoView();
    }
  }, [sortedItems]);

  if (initialization) return <Preloader className={style.loader} />;

  return (
    <div className={style.chatListWrapper} ref={chatListRef}>
      {sortedItems.map(
        (
          { id, text, createdAt, status, operatorId, sender, firstName, uri, type, originalName },
          index,
          arr,
        ) => (
          <div key={id}>
            {index !== 0 && renderDay(arr[index], arr[index - 1])}
            {/* eslint-disable-next-line no-unsafe-optional-chaining */}
            {listScrollTop - elemsRefs.current[id]?.getBoundingClientRect().top < 900 &&
              status === 'sent' && (
                <div className={style.newMessages}>
                  <span className={style.line} />
                  <span className={style.text}>New messages</span>
                  <span className={style.line} />
                </div>
              )}
            <div className={cn(style.chatList, { [style.justifyRight]: userData.id === sender })}>
              <ChatListItem
                body={text}
                time={createdAt}
                isRead={status === 'read'}
                operatorId={operatorId}
                sender={sender}
                firstName={firstName}
                documentUri={uri}
                type={type}
                filename={originalName}
                chatListRef={chatListRef}
                setPictures={setPictures}
                id={id}
                listScrollTop={listScrollTop}
                elemsRefs={elemsRefs}
              />
            </div>
          </div>
        ),
      )}
    </div>
  );
};

export default observer(ChatList);
