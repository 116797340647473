import React, { useRef } from 'react';
import cn from 'classnames';

import style from './TextArea.module.scss';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * Represents TextArea
 * @param {string} id - id for textarea
 * @param {string} label - label for textarea
 * @param {number} rows - number of textarea rows
 * @param {CSSStyleRule} inputClassName - input className
 * @param {CSSStyleRule} labelTitleClassName - className for label
 * @param {string} placeholder - textarea placeholder
 * @param {Formik} formik - formik
 * @param {string} value - textarea value
 * @returns {JSX.Element}
 */
const TextArea = ({
  id = 'textarea',
  label = '',
  rows = 1,
  inputClassName,
  labelTitleClassName,
  placeholder = '',
  formik,
  value,
}) => {
  const { isMobile } = useWindowSize();
  const labelRef = useRef(null);
  const handleOnChange = (e) => {
    if (formik) formik.handleChange(e);
  };

  const handleOnBlur = (e) => {
    if (formik) {
      formik.handleBlur(e);
    }
  };

  const handleOnFocus = () => {
    if (isMobile) labelRef.current.scrollIntoView();
  };
  return (
    <label
      htmlFor={id}
      className={cn(style.textArea, {
        [style.error]: formik?.touched[id] && formik?.errors[id],
      })}
      ref={labelRef}
    >
      {label && <p className={cn(style.labelTitle, labelTitleClassName)}>{label}</p>}

      <textarea
        id={id}
        className={cn(style.input, inputClassName)}
        rows={rows}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={formik ? formik?.values[id] : value}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
      />

      {formik?.touched[id] && formik?.errors[id] && (
        <div className={style.errorLabel}>{formik?.errors[id]}</div>
      )}
    </label>
  );
};

export default TextArea;
