import React, { lazy } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useRootModel } from '../../models/RootStore';
import withSuspense from '../../HOC/withSuspense';

import Tabs from '../../components/ui/SectionWithTabs/SectionWithTabs';
import GeneralInformation from './GeneralInformation/GeneralInformation';

const PersonalDetails = lazy(() => import('./PersonalDetails/PersonalDetails'));
const ActiveSession = lazy(() => import('./ActiveSession/ActiveSession'));
const Security = lazy(() => import('./Security/Security'));
const Notifications = lazy(() => import('./Notifications/Notifications'));

/**
 * Represents profile component
 * @returns {JSX.Element}
 */
const Profile = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    user: { isAuthorized },
  } = useRootModel();

  const token = localStorage.getItem('id_token');
  if (!isAuthorized && !token) navigate('/login');

  const tabs = [
    {
      id: 1,
      tabLabel: t('general'),
      component: GeneralInformation,
      pageTitle: t('general_info'),
    },
    {
      id: 2,
      tabLabel: t('verification_title'),
      component: withSuspense(PersonalDetails),
      pageTitle: t('verification_title'),
    },
    {
      id: 3,
      tabLabel: t('sessions'),
      component: withSuspense(ActiveSession),
      pageTitle: t('sessions'),
    },
    {
      id: 4,
      tabLabel: t('security'),
      component: withSuspense(Security),
      pageTitle: t('security'),
    },
    {
      id: 5,
      tabLabel: t('notifications'),
      component: withSuspense(Notifications),
      pageTitle: t('notifications'),
    },
  ];

  return <Tabs title={t('profile')} tabs={tabs} currentTab={state?.activeTab} />;
};

export default observer(Profile);
