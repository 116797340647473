import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './ActiveTradesContent.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import useWindowSize from '../../../../hooks/useWindowSize';

import Title from '../../../../components/ui/Table/TableTitle/TableTitle';
import ActiveTradesList from './ActiveTradesList/ActiveTradesList';

/**
 * Represents active trades content
 * @returns {JSX.Element}
 */
const ActiveTradesContent = ({ items }) => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const [isPercent, setIsPercent] = useState(false);
  const {
    finance: {
      activeTrades: { sortBy, setSortBy, setSortDirection, sortDirection, switchSortDirection },
    },
  } = useRootModel();

  const handleToggle = () => {
    setIsPercent(!isPercent);
  };

  return (
    <div>
      <div className={style.head}>
        <Title
          title={t('name')}
          isActive={sortBy === 'alias'}
          handleOnClick={setSortBy}
          labelKey='alias'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        {isDesktop && (
          <Title
            title={t('opening_date')}
            isActive={sortBy === 'openedAt'}
            handleOnClick={setSortBy}
            labelKey='openedAt'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}

        <Title
          title={t('opening_price')}
          isActive={sortBy === 'openingPrice'}
          handleOnClick={setSortBy}
          labelKey='openingPrice'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        {isDesktop && (
          <Title
            title={t('used_funds')}
            isActive={sortBy === 'amount'}
            handleOnClick={setSortBy}
            labelKey='amount'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}

        {isDesktop && (
          <Title
            title={t('result')}
            isActive={sortBy === 'result'}
            handleOnClick={setSortBy}
            labelKey='result'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}

        <div>{t('profit_limit')}</div>

        <div>{t('loss_limit')}</div>

        <div className={style.profitWrapper}>
          <Title
            title={t('profit')}
            isActive={isPercent ? sortBy === 'profitPercent' : sortBy === 'profit'}
            handleOnClick={setSortBy}
            labelKey={isPercent ? 'profitPercent' : 'profit'}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
          {isDesktop && (
            <label htmlFor='switcher' className={style.switch}>
              <input id='switcher' type='checkbox' onChange={handleToggle} />
              <p className={style.slider}>
                <span className={style.signValue}>$</span>
                <span className={style.sign}>%</span>
              </p>
            </label>
          )}
        </div>
      </div>
      <ActiveTradesList items={items} isPercent={isPercent} />
    </div>
  );
};

export default observer(ActiveTradesContent);
