import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from './GeneralInformation.module.scss';
import {
  CONFIRM_PASSWORD,
  NEW_PASSWORD,
  PASSWORD,
  PASSWORD_MAX_LENGTH,
} from '../../../util/validationSchema';
import useFormikApp from '../../../hooks/useFormikApp';
import formikUtil from '../../../util/formikUtil';
import { useRootModel } from '../../../models/RootStore';
import AlertContext from '../../../context/alert/alertContext';

import TextInput from '../../../components/ui/TextInput/TextInput';
import Button from '../../../components/ui/Button/Button';

/**
 * Represents password component for GeneralInformation
 * @returns {JSX.Element}
 */
const GeneralPassword = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    user: { changePassword, isLoading },
  } = useRootModel();
  const [isEdit, setIsEdit] = useState(false);

  const handleChangePassword = async (values, form) => {
    try {
      if (isLoading) return;
      await changePassword(values[PASSWORD], values[NEW_PASSWORD]);
      setIsEdit(false);
      form.resetForm();
      alert.show(t('password_changed'), 'success');
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const formik = useFormikApp([PASSWORD, NEW_PASSWORD, CONFIRM_PASSWORD], handleChangePassword);

  const isPasswordValid = formik.dirty && !formik.errors[PASSWORD];

  const handleInputPasswordKeyDown = (e) => {
    if (e.key === 'Enter' && isPasswordValid) {
      setIsEdit(true);
    }
  };

  const handleOnChangePassword = async ({ target: { value } }) => {
    await formikUtil.setValue(NEW_PASSWORD, value, formik);
  };

  const handleEditPassword = () => {
    setIsEdit(true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className={style.formPasswordTitle}>{t('settings')}</h2>
      <TextInput
        id={PASSWORD}
        label={t('current_password')}
        type='password'
        placeholder={t('enter_password')}
        formik={formik}
        onKeyDown={handleInputPasswordKeyDown}
        maxLength={PASSWORD_MAX_LENGTH}
      />
      {!isEdit && (
        <Button
          text={t('edit')}
          onClick={handleEditPassword}
          color='secondary'
          size='small'
          width='full'
          height={45}
          disabled={!isPasswordValid}
        />
      )}
      {isEdit && (
        <>
          <TextInput
            id={NEW_PASSWORD}
            label={t('new_password')}
            type='password'
            placeholder={t('new_password')}
            formik={formik}
            maxLength={PASSWORD_MAX_LENGTH}
            onChange={handleOnChangePassword}
          />
          <TextInput
            id={CONFIRM_PASSWORD}
            label={t('confirm_password')}
            type='password'
            placeholder={t('confirm_new')}
            formik={formik}
            maxLength={PASSWORD_MAX_LENGTH}
          />
          <Button
            text={t('save_changes')}
            size='small'
            submit
            disabled={!formik.isValid}
            width='full'
            height={45}
          />
        </>
      )}
    </form>
  );
};

export default GeneralPassword;
