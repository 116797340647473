import emptyFunction from '../util/emptyFunction';

/**
 * Hook change item cursor in dropdown
 * @param {Event} e - event
 * @param {number} position - current position
 * @param {Ref} refs - object with refs
 * @param {function} onClose - onClose handler
 * @param {function} [callback] - callback will be invoke with focused item value
 */
const useChangeCursor = (e, position, refs, onClose, callback = emptyFunction) => {
  e.stopPropagation();
  switch (e?.key) {
    case 'ArrowDown': {
      // Prevent scrolling
      e.preventDefault();
      const nextIndex = position < Object.keys(refs.current).length - 1 ? position + 1 : 0;
      const nextItem = refs.current[nextIndex];
      if (!nextItem) return;

      nextItem.focus();
      if (callback) callback(nextItem.textContent);
      break;
    }
    case 'ArrowUp': {
      // Prevent scrolling
      e.preventDefault();
      const renderedItems = Object.entries(refs.current).filter(([, value]) => value);
      const lastItem = renderedItems[renderedItems.length - 1];
      const prevIndex = position > 0 ? position - 1 : lastItem[0];
      const prevItem = refs.current[prevIndex];

      if (!prevItem) return;

      prevItem.focus();
      if (callback) callback(prevItem.textContent);
      break;
    }
    case 'Tab':
    case 'Escape':
      if (onClose) onClose();
      break;

    default:
  }
};

export default useChangeCursor;
