import React, { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import style from './HeaderUserInfo.module.scss';
import useOnClick from '../../../hooks/useOnClick';
import { useRootModel } from '../../../models/RootStore';

import ButtonProfile from './HeaderButtons/ButtonProfile/ButtonProfile';
import ButtonAffilateProgram from './HeaderButtons/ButtonAffilateProgram/ButtonAffilateProgram';
import ButtonStatusInfo from './HeaderButtons/ButtonStatusInfo/ButtonStatusInfo';
import ButtonSupport from './HeaderButtons/ButtonSupport/ButtonSupport';
import ButtonSwitchBrightness from './HeaderButtons/ButtonSwitchBrightness/ButtonSwitchBrightness';
import ButtonLogout from './HeaderButtons/ButtonLogout/ButtonLogout';
import Languages from '../../Languages/Languages';
import { ReactComponent as Crown } from '../../../assets/image/common/crownDropdownMenu.svg';
import { ReactComponent as ArrowUp } from '../../../assets/image/common/arrowUpHeader.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/common/arrowDownHeader.svg';
import userIcon from '../../../assets/image/common/avatar.png';
import ButtonVerification from './HeaderButtons/ButtonVerification/ButtonVerification';
import { formatAmountWithSpaces } from '../../../util/string';

/**
 * Represents HeaderUserInfo component
 * @returns {JSX.Element}
 */
const HeaderUserInfo = () => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(false);
  const wrapperRef = useRef(null);
  const {
    user: {
      userData: {
        user: { email },
        userLevelVM: {
          name,
          progress,
          balanceDepositCurrentLevelProgress,
          balanceDepositNextLevelProgress,
          tradeVolumeCurrentLevelProgress,
          tradeVolumeNextLevelProgress,
        },
      },
    },
  } = useRootModel();

  const balanceDepositLeft = useMemo(
    () => balanceDepositNextLevelProgress - balanceDepositCurrentLevelProgress,
    [balanceDepositCurrentLevelProgress, balanceDepositNextLevelProgress],
  );

  const tradeAmountLeft = useMemo(
    () => tradeVolumeNextLevelProgress - tradeVolumeCurrentLevelProgress,
    [tradeVolumeCurrentLevelProgress, tradeVolumeNextLevelProgress],
  );

  const handleOpenMenu = () => {
    setShowContent(!showContent);
  };

  const handleCloseMenu = () => {
    setShowContent(false);
  };
  useOnClick(wrapperRef, handleCloseMenu);

  const transitionStyles = {
    enter: style.enter,
    enterActive: style.enterActive,
    enterDone: style.enterDone,
    exit: style.exit,
    exitActive: style.exitActive,
    exitDone: style.exitDone,
  };

  return (
    <div className={style.userInfoDropdown} ref={wrapperRef}>
      <button type='button' className={style.currentItemWrapper} onClick={handleOpenMenu}>
        <img src={userIcon} alt='avatar' className={style.avatar} />

        <i className={style.arrow}>{showContent ? <ArrowUp /> : <ArrowDown />}</i>
      </button>

      <CSSTransition in={showContent} timeout={700} classNames={transitionStyles} unmountOnExit>
        <div role='menu' className={style.userInfoDropdownContent}>
          <p className={style.email}>{email}</p>

          <div className={style.status}>
            <div className={style.wrapper}>
              <div className={style.level}>
                <Crown />

                <p>{name}</p>
              </div>
              <p className={style.text}>{progress}%</p>
            </div>

            <div className={style.statusLine}>
              <div className={style.progressLine} style={{ width: `${progress}%` }} />
            </div>
            <div className={style.container}>
              <p>{t('deposit')}</p>
              <p>
                ${formatAmountWithSpaces(balanceDepositLeft)} {t('left')}
              </p>
            </div>
            <div className={style.container}>
              <p>{t('amount')}</p>
              <p>
                ${formatAmountWithSpaces(tradeAmountLeft)} {t('left')}
              </p>
            </div>
          </div>

          <ButtonSwitchBrightness />
          <Languages />

          <div className={style.userMenu}>
            {/* eslint-disable-next-line max-len */}
            {/* {!isDemoAccountNull && <ButtonUpdateDemo onClick={() => setShowContent(false)} />} // todo delete if comment logic moved to another place */}
            {/* eslint-disable-next-line max-len */}
            {/* {!isDemoAccountNull && <ButtonSwitchReal onClick={() => setShowContent(false)} />} */}
            <ButtonAffilateProgram onClick={() => setShowContent(false)} />
            <ButtonProfile onClick={() => setShowContent(false)} />
            <ButtonVerification />
            <ButtonSupport onClick={() => setShowContent(false)} />
            <ButtonStatusInfo onClick={() => setShowContent(false)} />
          </div>
          <ButtonLogout />
        </div>
      </CSSTransition>
    </div>
  );
};

export default HeaderUserInfo;
