import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './PaginationMobile.module.scss';
import { usePagination, DOTS } from '../../../hooks/usePagination';

const PaginationMobile = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}) => {
  const { t } = useTranslation();
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={cn(styles.paginationMobile, className)}>
      <ul className={styles.paginationContainer}>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li key={pageNumber} className={styles.paginationItem}>
                &#8230;
              </li>
            );
          }

          return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              key={pageNumber}
              className={cn(styles.paginationItem, {
                [styles.selected]: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
              onKeyDown={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      </ul>
      <p className={styles.buttonContainer}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span
          className={cn(styles.paginationButton, {
            [styles.disabled]: currentPage === 1,
          })}
          onClick={onPrevious}
          onKeyDown={onPrevious}
        >
          {t('previous')}
        </span>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span
          className={cn(styles.paginationButton, {
            [styles.disabled]: currentPage === lastPage,
          })}
          onClick={onNext}
          onKeyDown={onNext}
        >
          {t('next')}
        </span>
      </p>
    </div>
  );
};

export default PaginationMobile;
