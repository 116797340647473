import React from 'react';
import cn from 'classnames';
import BackButton from '../../../../components/ui/BackButton/BackButton';

import styles from '../FooterInfoMobile.module.scss';

const AboutUsMobile = () => {
  return (
    <section>
      <div className={styles.pageHeadingWrapper}>
        <BackButton url='/profile' />
        <h2 className={styles.pageHeading}>About us</h2>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>i88 app for active trading & long-term investing.</h3>
          <p className={styles.text}>
            i88 is a leading provider of Contracts for Difference (CFDs), delivering trading
            facilities on shares, forex, commodities, cryptocurrencies, ETFs, options and indices,
            alongside innovative trading technology. Access global financial markets commission-free
            and hassle-free. Start with a free, lifetime practice account with virtual money.
          </p>
        </div>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>i88 app for active trading & long-term investing.</h3>
          <p className={styles.text}>
            i88 is a leading provider of Contracts for Difference (CFDs), delivering trading
            facilities on shares, forex, commodities, cryptocurrencies, ETFs, options and indices,
            alongside innovative trading technology. Access global financial markets commission-free
            and hassle-free. Start with a free, lifetime practice account with virtual money.
          </p>
        </div>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>i88 Invest & ISA:</h3>
          <p className={styles.text}>
            - Unlimited commission-free trades;
            <br />- 10,000+ Real Stocks and ETFS from the UK, the US, Germany, France, Spain, the
            Netherlands and other;
            <br />- Uncompromising, direct trade execution;
            <br /> - We don’t sell your order flow.
          </p>
        </div>
      </div>
      <div className={styles.blockContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.textHeading}>i88 CFD:</h3>
          <p className={styles.text}>
            - 3,000+ CFDS on Stocks, Forex, Gold, Oil, Indices and more;
            <br />- Competitive spreads even at news time;
            <br />- Smooth and easy to use charts for technical analysis.
            <br />- Always an outstanding live customer service, responding within seconds.
          </p>
          <p className={cn(styles.text, styles.textMargin)}>
            Investments can fall and rise. You may get back less than you invested. Past performance
            is no guarantee of future results. CFDS are complex instruments and come with a high
            risk of losing money rapidly due to leverage. 68% of retail investor accounts lose money
            when trading CFDS with this provider. You should consider whether you understand how
            CFDS work and whether you can afford to take the high risk of losing your money.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsMobile;
