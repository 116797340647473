import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './Copyrights.module.scss';
import date from '../../../util/date';

const Copyrights = () => {
  const { t } = useTranslation();
  return (
    <p className={style.rights}>
      &copy; {date.currentYear} i88.io. {t('rights')}
    </p>
  );
};

export default Copyrights;
