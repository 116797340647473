import React from 'react';
import style from './ChatSendFileItem.module.scss';

import { ReactComponent as FileIcon } from '../../../../assets/image/common/file.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/image/common/close-circle.svg';

/**
 * Represents ChatSendFileItem
 * @param {string} filename - filename
 * @param {string} url - photo url
 * @param {'photo' | 'file'} type - file type
 * @param {function} handleDeleteFile - delete file handler
 * @returns {JSX.Element}
 */
const ChatSendFileItem = ({ filename, url, type, handleDeleteFile }) => {
  return (
    <div className={style.chatSendFileItem}>
      <p className={style.file}>
        {type === 'photo' && <img className={style.image} src={url} alt='' />}
        {type === 'file' && (
          <span className={style.fileIcon}>
            <FileIcon />
          </span>
        )}
        <span className={style.filename}>{filename}</span>
      </p>
      <button type='button' className={style.deleteIcon} onClick={handleDeleteFile}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default ChatSendFileItem;
