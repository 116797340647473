import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './FlowOfFundsContent.module.scss';
import { useRootModel } from '../../../../models/RootStore';

import FlowOfFundsList from './FlowOfFundsList/FlowOfFundsList';
import Title from '../../../../components/ui/Table/TableTitle/TableTitle';
import Preloader from '../../../../components/ui/Preloader/Preloader';

/**
 * Represents flow of funds content
 * @returns {JSX.Element}
 */
const FlowOfFundsContent = ({ items }) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const {
    finance: {
      flowOfFunds: {
        isLoading,
        setSortDirection,
        sortDirection,
        switchSortDirection,
        sortBy,
        setSortBy,
        getFlowOfFunds,
        setNextPage,
        isNextPage,
      },
    },
  } = useRootModel();

  const handleSortBy = async (item) => {
    await setSortBy(item);
    getFlowOfFunds();
  };

  useEffect(() => {
    const onScroll = async ({ target: { scrollTop, scrollHeight } }) => {
      if (!isLoading && scrollHeight - scrollTop < 650 && isNextPage) {
        await setNextPage();
        getFlowOfFunds(true);
      }
    };
    tableRef.current?.addEventListener('scroll', onScroll);
    return () => {
      tableRef?.current?.removeEventListener('scroll', onScroll);
    };
  }, [tableRef, isLoading, isNextPage, setNextPage, getFlowOfFunds]);

  if (items.length === 0 && isLoading) {
    return <Preloader className={style.loader} />;
  }

  return (
    <div>
      <div className={style.head}>
        <Title
          title={t('date')}
          isActive={sortBy === 'date'}
          handleOnClick={handleSortBy}
          labelKey='date'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        <div>{t('operation')}</div>

        <div className={style.positionRight}>{t('balance')}</div>

        <Title
          title={t('amount')}
          isActive={sortBy === 'amount'}
          handleOnClick={handleSortBy}
          labelKey='amount'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
          wrapperClassName={style.wrapper}
        />
      </div>
      <FlowOfFundsList items={items} tableRef={tableRef} />
    </div>
  );
};

export default observer(FlowOfFundsContent);
