import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './ChatListItem.module.scss';
import date from '../../../../util/date';
import { useRootModel } from '../../../../models/RootStore';
import fileUtils from '../../../../util/fileUtils';

import { ReactComponent as ArrowDoubleIcon } from '../../../../assets/image/common/arrowDouble.svg';
import { ReactComponent as FileIcon } from '../../../../assets/image/common/file.svg';
import { ReactComponent as PreviewIcon } from '../../../../assets/image/common/preview.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/image/common/download.svg';
import { ReactComponent as PreloaderMini } from '../../../../assets/image/common/preloaderMini.svg';

/**
 * Represents ChatListItem component
 * @param {number} id - message id
 * @param {string} sender - message sender
 * @param {string} body - message body
 * @param {string} time - message time
 * @param {boolean} isRead - is read message
 * @param {number} sender - sender
 * @param {string} firstName - firstName
 * @param {string} documentUri - document uri
 * @param {'photo' | 'file'} type - document type
 * @param {string} filename - filename
 * @param {function} setPictures - setPictures handler
 * @param {number} listScrollTop - listScrollTop
 * @param {Ref} elemsRefs - elements ref
 * @returns {JSX.Element}
 */
const ChatListItem = ({
  id,
  body,
  time,
  isRead,
  sender,
  firstName,
  documentUri = '',
  type,
  filename,
  setPictures,
  listScrollTop,
  elemsRefs,
}) => {
  const { t } = useTranslation();
  const {
    settings: { language },
    user: {
      userData: { id: userId },
    },
    chat: {
      setPicture,
      messages: { setIsRead, isLoading },
    },
    modal: {
      chatPicturePreview: { open },
    },
  } = useRootModel();

  const imageRef = useRef(null);

  useEffect(() => {
    if (elemsRefs.current[id] && userId !== sender) {
      const { top } = elemsRefs.current[id].getBoundingClientRect();
      if (
        !isLoading &&
        !isRead &&
        listScrollTop > 0 &&
        listScrollTop - top < 1500 &&
        listScrollTop - top > 750
      ) {
        setIsRead(id);
      }
    }
  }, [elemsRefs.current[id], listScrollTop]);

  const [loadPending, setLoadPending] = useState(false);
  const [imageHover, setImageHover] = useState(false);
  const handleImageMouseEnter = () => setImageHover(true);
  const handleImageMouseLeave = () => setImageHover(false);

  const supportFirstName = firstName ? ` (${firstName})` : '';
  const supportName = `${t('support')}${supportFirstName}`;

  const onClickImage = () => {
    setPicture(documentUri);
    open();
  };

  useEffect(() => {
    const listener = () => {
      setPictures((prev) => ({ ...prev, loaded: prev.loaded + 1 }));
    };
    if (imageRef.current) {
      imageRef.current.addEventListener('load', listener);
    }
    return () => {
      imageRef.current?.removeEventListener('load', listener);
    };
  }, [imageRef, imageRef.current]);

  const message = body
    .replace(
      // eslint-disable-next-line max-len
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
      `<a href="$1" target='_blank'>$1</a>`,
    )
    .replace(/([a-zA-Z0-9._-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,4})/, `<a href="mailto:$1">$1</a>`);

  const handleDownload = () => {
    fileUtils.download(documentUri, filename, setLoadPending);
  };

  return (
    <div
      className={style.chatListItem}
      ref={(element) => {
        elemsRefs.current[id] = element;
      }}
    >
      <p className={style.sender}>{userId === sender ? t('you') : supportName}</p>
      <p className={style.body} dangerouslySetInnerHTML={{ __html: message }} />
      {type === 'photo' && (
        <div
          className={style.imageWrapper}
          onMouseEnter={handleImageMouseEnter}
          onMouseLeave={handleImageMouseLeave}
        >
          {imageHover && (
            <div className={style.imageButtons}>
              <button type='button' className={style.imageButton} onClick={onClickImage}>
                <PreviewIcon />
              </button>
              <button type='button' className={style.imageButton} onClick={handleDownload}>
                <DownloadIcon />
              </button>
            </div>
          )}
          <img
            className={cn(style.image, { [style.imageHover]: imageHover })}
            src={documentUri}
            alt=''
            ref={imageRef}
          />
        </div>
      )}
      {type === 'file' && (
        <div className={style.file}>
          <FileIcon />
          <button
            type='button'
            onClick={handleDownload}
            className={style.filename}
            disabled={loadPending}
          >
            {filename}
          </button>
          {loadPending && <PreloaderMini />}
        </div>
      )}
      <p className={style.time}>
        <i className={cn(style.icon, { [style.isRead]: isRead })}>
          <ArrowDoubleIcon />
        </i>
        {date.toLocaleString(time, language, false, { hour: '2-digit', minute: '2-digit' })}
      </p>
    </div>
  );
};

export default observer(ChatListItem);
