import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import i18n from '../../../translations/i18n';
import style from './MainPage.module.scss';
import MainTable from './MainTable/MainTable';
import SearchMain from '../../../components/SearchMain/SearchMain';
import NavigationMain from '../../../components/NavigationMain/NavigationMain';
import { useRootModel } from '../../../models/RootStore';
import useDebounce from '../../../hooks/useDebounce';

const tabs = [
  {
    id: 0,
    tabLabel: i18n.t('favorites'),
    filter: 'favorites',
  },
  {
    id: 1,
    tabLabel: i18n.t('popular'),
    filter: 'popular',
  },
  {
    id: 2,
    tabLabel: i18n.t('crypto'),
    filter: 'crypto',
  },
  {
    id: 3,
    tabLabel: i18n.t('rising'),
    filter: 'rising',
  },
  {
    id: 4,
    tabLabel: i18n.t('falling'),
    filter: 'falling',
  },
  {
    id: 5,
    tabLabel: i18n.t('volatility'),
    filter: 'volatility',
  },
  {
    id: 6,
    tabLabel: i18n.t('uprising_trend'),
    filter: 'uptrend',
  },
  {
    id: 7,
    tabLabel: i18n.t('falling_trend'),
    filter: 'falltrend',
  },
  {
    id: 8,
    tabLabel: i18n.t('commodities'),
    filter: 'commodities',
  },
];

/**
 * Represents Main page in Mobile version
 * @returns {JSX.Element}
 */

const MainPage = () => {
  const {
    finance: { getItems, term, setTerm, getSearch },
  } = useRootModel();
  const [activeTab, setActiveTab] = useState(1);

  const handleChangeTab = (id) => {
    setTerm('');
    setActiveTab(id);
  };

  const debouncedSearchValue = useDebounce(term, 800);

  const handleOnChange = ({ target: { value } }) => {
    setTerm(value);
  };

  const clearSearch = () => {
    setTerm('');
  };

  useEffect(() => {
    if (!term) {
      getItems(tabs[activeTab].filter);
    }
  }, [activeTab, getItems, term]);

  useEffect(() => {
    const fetchData = async () => {
      await getSearch(debouncedSearchValue);
    };
    if (debouncedSearchValue) fetchData().then();
  }, [debouncedSearchValue, getSearch]);

  return (
    <div className={style.container}>
      <SearchMain value={term} onChange={handleOnChange} clearSearch={clearSearch} />
      <NavigationMain term={term} tabs={tabs} activeTab={activeTab} onChange={handleChangeTab} />
      <MainTable activeTab={activeTab} />
    </div>
  );
};

export default observer(MainPage);
