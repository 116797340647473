import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import style from './InstrumentDetails.module.scss';
import InstrumentInfo from '../InstrumentInfo/InstrumentInfo';
import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';
import TVChart from '../../../components/TVChart/TVChart';
import TraderSentiment from '../../../components/TraderSentiment/TraderSentiment';
import News from '../../../components/News/News';
import { useRootModel } from '../../../models/RootStore';
import useWindowSize from '../../../hooks/useWindowSize';
import { DEFAULT_CATEGORY, DEFAULT_SYMBOL } from '../../../constant/commonConstants';
import OpenTradeContent from '../../../components/OpenTradeContent/OpenTradeContent';

const InstrumentDetails = () => {
  const { isDesktop, isLaptop } = useWindowSize();
  const {
    settings: { theme, language },
    finance: { activeInterval, getItem, closeDataStreamItem, clearItem },
    user: { userData },
  } = useRootModel();

  useEffect(() => {
    getItem(DEFAULT_CATEGORY, DEFAULT_SYMBOL);

    return () => {
      clearItem();
      closeDataStreamItem();
    };
  }, [closeDataStreamItem, getItem]);

  return (
    <div className={style.about}>
      <div className={style.instrumentInfoTop}>
        <InstrumentInfo />
        <TraderSentiment className={style.item} />
      </div>

      <div className={style.tvChart}>
        <ErrorBoundary>
          <TVChart
            theme={theme}
            interval={activeInterval}
            userId={userData?.id || null}
            language={language}
            isFullscreenButton={isDesktop}
            wrapperClassName={style.chartWrapper}
            className={style.chart}
          />
        </ErrorBoundary>
      </div>
      {isLaptop && <OpenTradeContent />}
      <News />
    </div>
  );
};

export default observer(InstrumentDetails);
