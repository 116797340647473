import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './StatusDesc.module.scss';

/**
 * Represents loyalty status description component
 * @returns {JSX.Element}
 */

const StatusDesc = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.statusDesc}>
        <p>{t('analytical_support')}</p>
      </div>
      <div className={styles.statusDesc}>
        <p>{t('hours_support')}</p>
      </div>
      <div className={styles.statusDesc}>
        <p>{t('multiplier_change')}</p>
      </div>
      <div className={styles.statusDesc}>
        <p>{t('premium_signals')}</p>
      </div>
      <div className={styles.statusDesc}>
        <p>{t('vip_manager')}</p>
      </div>
      <div className={styles.statusDesc}>
        <p>{t('individual_conditions')}</p>
      </div>
    </div>
  );
};

export default StatusDesc;
