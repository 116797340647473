import React from 'react';
import { observer } from 'mobx-react';
import i18n from '../../../translations/i18n';

import style from './HeaderLanguageWithoutAuth.module.scss';
import { useRootModel } from '../../../models/RootStore';
import getLanguage from '../../../util/getLanguage';

import Dropdown from '../../ui/Dropdown/Dropdown';

/**
 * Represents HeaderLanguage
 * @returns {JSX.Element}
 */
const HeaderLanguageWithoutAuth = () => {
  const {
    user: { isAuthorized, setUserLanguage },
    settings: { langKeys, language, changeLanguage },
  } = useRootModel();
  const handleChangeLanguage = async (lang) => {
    const langKey = getLanguage(langKeys, lang);
    await changeLanguage(langKey);
    await i18n.changeLanguage(langKey);
    if (isAuthorized) {
      setUserLanguage(langKey);
    }
  };
  return (
    <Dropdown
      onChange={handleChangeLanguage}
      className={style.langDropdown}
      contentClassName={style.langContent}
      currentItemWrapperClassName={style.currentItemWrapper}
      value={getLanguage(langKeys, language, 'value')}
      openOnHover
    >
      {langKeys.map(([, lang]) => {
        return (
          <button key={lang} type='button'>
            {lang}
          </button>
        );
      })}
    </Dropdown>
  );
};

export default observer(HeaderLanguageWithoutAuth);
