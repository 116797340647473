import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import style from './Login.module.scss';
import { useRootModel } from '../../models/RootStore';

import Logo from '../../components/ui/Logo/Logo';
import Copyrights from '../../components/ui/Copyrights/Copyrights';
import LoginForm from './LoginForm/LoginForm';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import useWindowSize from '../../hooks/useWindowSize';

/**
 * Represents a login page.
 * @returns {JSX.Element}
 */
const Login = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { user } = useRootModel();
  const { isAuthorized } = user;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { refCode } = useParams();

  useEffect(() => {
    if (user.twoFAEnabled) {
      navigate('/verification');
    }

    if (isAuthorized) {
      navigate('/');
    }
  }, [isAuthorized, navigate, user.twoFAEnabled]);

  return (
    <section className={style.wrapper}>
      <div className={style.login}>
        {!isMobile && <Logo />}

        <div className={style.buttonContainer}>
          {!refCode && (
            <Link
              to='/login'
              className={cn(style.loginLink, { [style.active]: pathname === '/login' })}
            >
              {t('login')}
            </Link>
          )}
          <Link
            to='/registration'
            className={cn(
              style.loginLink,
              { [style.disabled]: refCode },
              { [style.active]: pathname === '/registration' },
            )}
          >
            {t('registration_button')}
          </Link>
        </div>
        {pathname === '/login' && <LoginForm />}
        {(pathname === '/registration' || refCode) && <RegistrationForm />}
      </div>
      <Copyrights />
    </section>
  );
};

export default observer(Login);
