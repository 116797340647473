import React from 'react';
import cn from 'classnames';

import style from './ChatArrow.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/image/common/chatArrow.svg';

/**
 * Represents ChatArrow
 * @param {function} onClick - onClick handler
 * @param {boolean} isFile - isFile
 * @returns {JSX.Element}
 */
const ChatArrow = ({ onClick, isFile }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={cn(style.chatArrow, { [style.withFile]: isFile })}
    >
      <ArrowIcon />
    </button>
  );
};

export default ChatArrow;
