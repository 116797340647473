import React, { useContext, useEffect, useRef, useState } from 'react';

import style from '../TwoFactorAuth.module.scss';
import TabsContext from '../../../../../context/tabsContext';
import useFormikApp from '../../../../../hooks/useFormikApp';
import { PHONE } from '../../../../../util/validationSchema';

import PhoneCodeWithInput from '../../../../../components/ui/PhoneCodeWithInput/PhoneCodeWithInput';
import Button from '../../../../../components/ui/Button/Button';
import { useRootModel } from '../../../../../models/RootStore';

/**
 * Represents security phone
 * @param {function} onSubmit - onSubmit (value: number) => void
 * @returns {JSX.Element}
 */
const TwoFactorAuthPhone = ({ onSubmit }) => {
  const {
    user: { userData, isLoading },
  } = useRootModel();
  const [phone, setPhone] = useState({});
  const formRef = useRef(null);
  const { tabWrapperRef } = useContext(TabsContext);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollTop = -300;
    }
    if (tabWrapperRef?.current) {
      tabWrapperRef.current.scrollTop = 0;
    }
  }, []);

  const handleSubmit = () => {
    if (onSubmit) onSubmit(phone);
    // todo submit phone with code
  };

  const formik = useFormikApp([PHONE], handleSubmit);

  const isPhoneValid = formik.isValid && phone;

  return (
    <form onSubmit={formik.handleSubmit} ref={formRef}>
      <p className={style.text}>To enable two-factor authentication, confirm your phone number</p>
      <PhoneCodeWithInput
        handleChange={setPhone}
        phoneVerified={userData?.phoneConfirmed}
        showVerifiedLabel
        wrapperClassName={style.phoneCodeWithInputWrapper}
        verifiedLabelClassName={style.verifiedLabel}
        labelClassName={style.label}
        errorClassName={style.error}
        autoFocus
        formik={formik}
      />
      <Button
        text='Verify'
        submit
        fontWeight='normal'
        width='full'
        disabled={!(isPhoneValid || isLoading)}
      />
    </form>
  );
};

export default TwoFactorAuthPhone;
