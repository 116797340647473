import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useParams, useNavigate } from 'react-router-dom';
import style from './TradesPage.module.scss';
import SearchTradesMobile from '../../components/SearchTradesMobile/SearchTradesMobile';

import ActiveTradesMobile from './ActiveTradesMobile/ActiveTradesMobile';
import PendingTradesMobile from './PendingTradesMobile/PendingTradesMobile';
import ClosedTradesMobile from './ClosedTradesMobile/ClosedTradesMobile';
import FlowOfFundsMobile from './FlowOfFundsMobile/FlowOfFundsMobile';
import PeriodFilterTrades from '../../components/PeriodFilterTrades/PeriodFilterTrades';

const trades = {
  active: <ActiveTradesMobile />,
  pending: <PendingTradesMobile />,
  closed: <ClosedTradesMobile />,
  'flow-funds': <FlowOfFundsMobile />,
};

/**
 * Represents Trades page in Mobile version
 * @returns {JSX.Element}
 */

const TradesPage = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const navigate = useNavigate();

  if (!Object.keys(trades).includes(type)) navigate('/404');

  return (
    <>
      <section className={style.contentSection}>
        {type !== 'flow-funds' && (
          <div className={style.searchBlock}>
            <SearchTradesMobile />
            <Link className={style.openTradeButton} to='/'>
              {t('open_new')}
            </Link>
          </div>
        )}
        <nav className={style.navigation}>
          <NavLink
            to='/trades/active'
            className={({ isActive }) => (isActive ? `${style.link} ${style.active}` : style.link)}
          >
            {t('active')}
          </NavLink>
          <NavLink
            to='/trades/pending'
            className={({ isActive }) => (isActive ? `${style.link} ${style.active}` : style.link)}
          >
            {t('pending')}
          </NavLink>
          <NavLink
            to='/trades/closed'
            className={({ isActive }) => (isActive ? `${style.link} ${style.active}` : style.link)}
          >
            {t('closed')}
          </NavLink>
          <NavLink
            to='/trades/flow-funds'
            className={({ isActive }) => (isActive ? `${style.link} ${style.active}` : style.link)}
          >
            {t('flow')}
          </NavLink>
        </nav>
        {trades[type]}
      </section>
      <PeriodFilterTrades />
    </>
  );
};

export default TradesPage;
