/**
 * @typedef {Object} AnimationClasses of css class for animation
 *
 * @property {CSSStyleRule} enter - style for enter animation
 * @property {CSSStyleRule} enterActive - style for enterActive animation
 * @property {CSSStyleRule} enterDone - style for enterDone animation
 *
 * @property {CSSStyleRule} exit - style for exit animation
 * @property {CSSStyleRule} exitActive - style for exitActive animation
 * @property {CSSStyleRule} exitDone - style for exitDone animation
 */

/**
 * Create object with CSSTransition classes
 * @param {AnimationClasses} classes - classes for animate
 * @returns {{enter, enterActive, enterDone, exit, exitActive, exitDone}}
 */
const getAnimationClasses = (classes) =>
  classes
    ? {
        enter: classes.enter,
        enterActive: classes.enterActive,
        enterDone: classes.enterDone,
        exit: classes.exit,
        exitActive: classes.exitActive,
        exitDone: classes.exitDone,
      }
    : {};

export default getAnimationClasses;
