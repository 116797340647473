import { createContext } from 'react';
import emptyFunction from '../util/emptyFunction';

/**
 * @callback SetActiveTab
 * @param {number} tabId - tab id
 * @returns void
 */
/**
 * @typedef {Object} DefaultValues
 * @param {Ref | null} tabRef - ref for tab wrapper
 * @param {Ref | null} contentRef - ref for content wrapper
 * @param {SetActiveTab} setActiveTab - callback for setting active tab
 */
const defaultValues = {
  setShowItem: emptyFunction,
  setActiveTab: emptyFunction,
  contentRef: null,
  tabRef: null,
};
/**
 * @typedef {Object} TabsContext
 * @param {Ref | null} tabRef - tab reference
 * @param {Ref | null} contentRef - reference fot the tab content wrapper
 * @param {SetActiveTab} setActiveTab - setActiveTab handler
 * @param {function} setShowItem - pass boolean value for show tab content
 */
/**
 * @memberOf module.React
 * @type {React.Context<TabsContext>}
 */
const TabsContext = createContext(defaultValues);

export default TabsContext;
