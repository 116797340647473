import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './PhoneCodeWithInput.module.scss';
import { useRootModel } from '../../../models/RootStore';
import formikUtil from '../../../util/formikUtil';
import { PHONE, PHONE_MAX_LENGTH } from '../../../util/validationSchema';

import CountryAndCodeDropdown from '../CountryAndCodeDropdown/CountryAndCodeDropdown';
import TextInput from '../TextInput/TextInput';
import { ReactComponent as VerifiedIcon } from '../../../assets/image/common/verified.svg';
import { ReactComponent as NotVerified } from '../../../assets/image/common/not-verified.svg';

/**
 * Represents phone component with code
 * @param {function} handleChange - change handler
 * @param {function} handleInputKeyDown - phone input KeyDown handler
 * @param {function} handleInputKeyPress - phone input KeyPress handler
 * @param {boolean} showVerifiedLabel - show verified label
 * @param {boolean} phoneConfirmed - verified phone value
 * @param {CSSStyleRule} wrapperClassName - class name for wrapper
 * @param {CSSStyleRule} inputWithCodeClassName - class name for input with code wrapper
 * @param {CSSStyleRule} inputClassName - class name for input wrapper
 * @param {CSSStyleRule} labelClassName - class name for label
 * @param {CSSStyleRule} verifiedLabelClassName - class name for verified label
 * @param {boolean} autoFocus - focus on input after render
 * @param {function} setIsError - setIsError
 * @param {Formik} formik - formik
 * @param {boolean} disabled - disabled
 * @returns {JSX.Element}
 */
const PhoneCodeWithInput = ({
  handleChange,
  handleInputKeyDown,
  handleInputKeyPress,
  showVerifiedLabel = false,
  phoneConfirmed,
  wrapperClassName,
  inputWithCodeClassName,
  inputClassName,
  labelClassName,
  verifiedLabelClassName,
  autoFocus = false,
  setIsError,
  formik,
  disabled,
}) => {
  const { t } = useTranslation();
  const {
    user: { userData },
  } = useRootModel();
  const inputRef = useRef(null);
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [codeError, setCodeError] = useState('');

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  }, []);

  useEffect(() => {
    if (userData && userData.phone && userData.phoneCode) {
      setPhoneCode(userData.phoneCode);
      setPhoneNumber(userData.phone);
      formikUtil.setValue(PHONE, userData.phone, formik);
    }
  }, [userData && userData.phone, userData && userData.phoneCode]);

  useEffect(() => {
    if (setIsError) {
      setIsError(!!(formik.errors[PHONE] || codeError));
    }
  }, [formik.errors[PHONE], codeError]);

  const handleChangeCode = (code) => {
    setPhoneCode(code);
    if (handleChange) {
      handleChange({ phoneCode: code, phone: phoneNumber });
    }
  };

  const handleOnChangePhone = (e) => {
    const number = e.target.value;

    if (!(/^[0-9]*$/.test(number) || number === '')) return;
    setPhoneNumber(number);
    formik.handleChange(e);
    if (handleChange) {
      handleChange({ phoneCode, phone: number });
    }
  };

  return (
    <div className={cn(style.inputWithVerifyIcon, wrapperClassName)}>
      <div className={cn(style.labelTitle, labelClassName)}>{t('phone')}</div>
      <div className={cn(style.phoneInputWrapper, inputWithCodeClassName)}>
        <CountryAndCodeDropdown
          content='dialCode'
          onChange={handleChangeCode}
          showError
          error={(formik.touched[PHONE] && formik.errors[PHONE]) || codeError}
          setIsError={setCodeError}
          value={phoneCode}
        />
        <TextInput
          id={PHONE}
          placeholder={t('number')}
          inputMode='numeric'
          value={formik.values[PHONE]}
          inputClassName={inputClassName}
          onChange={handleOnChangePhone}
          onKeyDown={handleInputKeyDown}
          onKeyPress={handleInputKeyPress}
          onBlur={formik.handleBlur}
          maxLength={PHONE_MAX_LENGTH}
          inputRef={inputRef}
          error={formik.errors[PHONE] || codeError}
          disabled={disabled}
        />
      </div>
      {showVerifiedLabel && (
        <p
          className={cn(
            phoneConfirmed ? style.verified : style.notVerified,
            verifiedLabelClassName,
          )}
        >
          {phoneConfirmed ? <VerifiedIcon /> : <NotVerified />}
          {phoneConfirmed ? t('verified') : t('not_verified')}
        </p>
      )}
    </div>
  );
};

export default PhoneCodeWithInput;
