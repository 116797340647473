import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import style from './FAQItem.module.scss';
import ButtonOpenDown from '../../../components/ui/ButtonOpenDown/ButtonOpenDown';
import getAnimationClasses from '../../../util/getAnimationClasses';

/**
 * Represents FAQ item
 * @param {string} title - title for the item
 * @param {string} text - text for the item
 * @returns {JSX.Element}
 */

const FaqItem = ({ title, text }) => {
  const [showText, setShowText] = useState(false);

  const handleShowText = () => {
    setShowText((prev) => !prev);
  };

  return (
    <div className={style.item}>
      <p className={style.title}>{title}</p>

      <ButtonOpenDown isOpen={showText} handleClick={handleShowText} />

      <CSSTransition in={showText} timeout={700} classNames={getAnimationClasses(style)}>
        <p className={style.text}>{text}</p>
      </CSSTransition>
    </div>
  );
};

export default FaqItem;
