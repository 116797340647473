import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import style from './MobileApp.module.scss';

import { useRootModel } from '../models/RootStore';

import MainPage from './pages/MainPage/MainPage';
import TradesPage from './pages/TradesPage/TradesPage';
import MarketsPage from './pages/MarketsPage/MarketsPage';
import BalancePage from './pages/BalancePage/BalancePage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import DepositMoneyMobile from './pages/BalancePage/DepositMoneyMobile/DepositMoneyMobile';
import WithdrawMoneyMobile from './pages/BalancePage/WithdrawMoneyMobile/WithdrawMoneyMobile';
import PaymentHistoryMobile from './pages/BalancePage/PaymentHistoryMobile/PaymentHistoryMobile';
// eslint-disable-next-line max-len
import GeneralInformationMobile from './pages/ProfilePage/GeneralInformationMobile/GeneralInformationMobile';
import PersonalDetailsMobile from './pages/ProfilePage/PersonalDetailsMobile/PersonalDetailsMobile';
import ActiveSessions from './pages/ProfilePage/ActiveSessions/ActiveSessions';
import SecurityMobile from './pages/ProfilePage/SecurityMobile/SecurityMobile';
import NotificationsMobile from './pages/ProfilePage/NotificationsMobile/NotificationsMobile';
import DocumentsMobile from './pages/ProfilePage/DocumentsMobile/DocumentsMobile';
import SupportMobile from './pages/ProfilePage/SupportMobile/SupportMobile';
import RequestPage from './pages/ProfilePage/RequestPage/RequestPage';
import StatusInfoMobile from './pages/ProfilePage/StatusInfoMobile/StatusInfoMobile';
import NotFound from '../pages/NotFound/NotFound';
import ScrollToTop from '../hooks/useScrollToTop';
import FooterInfoMobile from './pages/FooterInfoMobile/FooterInfoMobile';
import FooterMobile from './components/FooterMobile/FooterMobile';
import ExchangeSchedule from '../pages/ExchangeSchedule/ExchangeSchedule';
import ExchangeMoneyMobile from './pages/BalancePage/ExchangeMoneyMobile/ExchangeMoneyMobile';
import Sidebar from '../components/Sidebar/Sidebar';

/**
 * Represents Mobile version of Application
 * @returns {JSX.Element}
 */

const MobileApp = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    user: { isAuthorized },
  } = useRootModel();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/login');
    }
  }, [isAuthorized, navigate]);

  return (
    <>
      <ScrollToTop />
      <main className={style.main}>
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/trades/:type' element={<TradesPage />} />
          <Route path='/products/:category/:s?' element={<MarketsPage />} />
          <Route path='/balance' element={<BalancePage />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/balance/deposit' element={<DepositMoneyMobile />} />
          <Route path='/balance/withdraw' element={<WithdrawMoneyMobile />} />
          <Route path='/balance/exchange' element={<ExchangeMoneyMobile />} />
          <Route path='/balance/payment' element={<PaymentHistoryMobile />} />
          <Route path='/profile/general' element={<GeneralInformationMobile />} />
          <Route path='/profile/details' element={<PersonalDetailsMobile />} />
          <Route path='/profile/sessions' element={<ActiveSessions />} />
          <Route path='/profile/security' element={<SecurityMobile />} />
          <Route path='/profile/notification' element={<NotificationsMobile />} />
          <Route path='/profile/documents' element={<DocumentsMobile />} />
          <Route path='/support' element={<SupportMobile />} />
          <Route path='/profile/request' element={<RequestPage />} />
          <Route path='/info/:type' element={<FooterInfoMobile />} />
          <Route path='/profile/status' element={<StatusInfoMobile />} />
          <Route path='/404' element={<NotFound />} />
          <Route path='/schedule' element={<ExchangeSchedule />} />
          {/* <Route path='*' to='/404' /> */}
        </Routes>
      </main>
      {pathname === '/' && <FooterMobile />}
      <Sidebar />
    </>
  );
};

export default observer(MobileApp);
