import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useRootModel } from '../../models/RootStore';

import style from './ActiveTradesDropdownDesktop.module.scss';
import { ReactComponent as Dots } from '../../assets/image/common/dots.svg';
import { ReactComponent as Up } from '../../assets/image/common/arrowUpRound.svg';
import { ReactComponent as Down } from '../../assets/image/common/arrowDownRound.svg';
import { ReactComponent as Done } from '../../assets/image/common/closeAllTrades.svg';
import Dropdown from '../ui/Dropdown/Dropdown';
import BalanceFormat from '../BalanceFormat/BalanceFormat';

/**
 * Represents dropdown on Active trades page
 * @returns {JSX.Element}
 */

const ActiveTradesDropdownDesktop = () => {
  const { t } = useTranslation();
  const {
    finance: {
      activeTrades: {
        profitableTradesSum,
        profitableTradesAmount,
        lossTradesSum,
        lossTradesAmount,
        allTradesSum,
        allTradesAmount,
        getActiveTrades,
      },
      trade: { closeTrades },
    },
  } = useRootModel();

  const handleCloseProfitableTrades = async () => {
    await closeTrades('PROFITABLE');
    getActiveTrades();
  };

  const handleCloseLossTrades = async () => {
    await closeTrades('LOSS');
    getActiveTrades();
  };

  const handleCloseAllTrades = async () => {
    await closeTrades();
    getActiveTrades();
  };

  return (
    <Dropdown
      mainComponent={() => (
        <>
          <p className={style.buttonText}>{t('close_all')}</p>
          <Dots />
        </>
      )}
      changeMainComponent={false}
      showArrow={false}
      className={style.wrapper}
      currentItemWrapperClassName={style.button}
      contentClassName={style.dropdown}
      itemClassName={style.itemClass}
    >
      <div
        role='button'
        className={style.item}
        onClick={handleCloseProfitableTrades}
        onKeyDown={handleCloseProfitableTrades}
        tabIndex={-1}
      >
        <Up />
        <div>
          <p>{t('close_profitable')}</p>
          <div className={cn(style.amountWrapper, style.textGreen)}>
            <BalanceFormat value={profitableTradesSum} />
            <span className={style.trades}>({profitableTradesAmount} trades)</span>
          </div>
        </div>
      </div>
      <div
        role='button'
        className={style.item}
        onClick={handleCloseLossTrades}
        onKeyDown={handleCloseLossTrades}
        tabIndex={-1}
      >
        <Down />
        <div>
          <p>{t('close_loss')}</p>
          <div className={cn(style.amountWrapper, style.textRed)}>
            <BalanceFormat value={lossTradesSum} />
            <span className={style.trades}>({lossTradesAmount} trades)</span>
          </div>
        </div>
      </div>
      <div
        role='button'
        className={style.item}
        onClick={handleCloseAllTrades}
        onKeyDown={handleCloseAllTrades}
        tabIndex={-1}
      >
        <Done />
        <div>
          <p>{t('close_all')}</p>
          <div
            className={cn(style.amountWrapper, style.textGreen, {
              [style.textRed]: allTradesSum < 0,
            })}
          >
            <BalanceFormat value={allTradesSum} />
            <span className={style.trades}>({allTradesAmount} trades)</span>
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default observer(ActiveTradesDropdownDesktop);
