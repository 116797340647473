import React from 'react';

import GeneralPhoneChange from './GeneralPhoneChange';
import GeneralPhoneVerify from './GeneralPhoneVerify';

const GeneralPhoneCodes = ({
  isPhoneChanged,
  isPhoneWasConfirmed,
  setIsPhoneChanged,
  setIsVerifyCodeSent,
  changePhoneNumber,
  verifyPhoneNumber,
}) => {
  return (
    <div>
      {!isPhoneChanged && isPhoneWasConfirmed ? (
        <GeneralPhoneChange
          sendCode={changePhoneNumber}
          setIsVerifyCodeSent={setIsVerifyCodeSent}
        />
      ) : (
        <GeneralPhoneVerify
          sendCode={verifyPhoneNumber}
          setIsPhoneChanged={setIsPhoneChanged}
          setIsVerifyCodeSent={setIsVerifyCodeSent}
        />
      )}
    </div>
  );
};

export default GeneralPhoneCodes;
