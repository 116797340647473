import React, { useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import style from './OpenTradeContent.module.scss';

import { useRootModel } from '../../models/RootStore';
import AlertContext from '../../context/alert/alertContext';
import LimitProfitsAndLost from '../LimitProfitsAndLost/LimitProfitsAndLost';
import TabsForModalItem from '../ui/TabsForModal/TabsForModalItem/TabsForModalItem';
import InstrumentPriceInput from '../InstrumentPriceInput/InstrumentPriceInput';
import useWindowSize from '../../hooks/useWindowSize';
import OpenTradeButtons from '../OpenTradeButtons/OpenTradeButtons';
import Now from '../Now/Now';
import { formatAmountWithSpaces } from '../../util/string';

/**
 * Represents Open Trade Component
 * @returns {JSX.Element}
 */

const OpenTradeContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile, isLaptop } = useWindowSize();
  const alert = useContext(AlertContext);

  const tabs = [
    {
      id: 1,
      tabLabel: t('market_tab'),
    },
    {
      id: 2,
      tabLabel: t('at_price'),
    },
  ];

  const {
    user: {
      userData: { demoVersion, documentsVerificationStatus, balanceUser },
    },
    payment: {
      accounts: { realAccount },
    },
    finance: {
      activeSymbol,
      trade: {
        amountData: { amount },
        setOperation,
        openTrade,
        getTradeSettings,
        setInitialStateForTrade,
        openTradeCommission,
      },
      pendingTrades: { getPendingTrades },
      activeTrades: { getActiveTrades },
    },
  } = useRootModel();
  const [activeTab, setActiveTab] = useState(1);

  const spread = useMemo(() => (amount / 100) * openTradeCommission, [amount, openTradeCommission]);

  const handleOperation = async (operation) => {
    try {
      if (!demoVersion && realAccount.balance < amount) {
        alert.show(t('replenish_balance'), 'error');
        navigate('/balance');
        return;
      }
      if (!demoVersion && documentsVerificationStatus !== 'APPROVED') {
        alert.show(t('upload_description'), 'error');
        navigate('/profile');
        return;
      }
      await setOperation(operation);
      await openTrade();
      setInitialStateForTrade();
      getActiveTrades();
      getPendingTrades();
      alert.show(t('trade_open'), 'success');
    } catch (err) {
      alert.show(t(err.message));
    }
  };

  useEffect(() => {
    getTradeSettings();

    return () => {
      setInitialStateForTrade();
    };
  }, [activeSymbol, getTradeSettings, setInitialStateForTrade]);

  return (
    <div className={cn(style.wrapper, { [style.authorized]: true })}>
      <div className={style.container}>
        <div className={style.buttonsAndTitleWrapper}>
          {tabs.map(({ tabLabel, id }) => (
            <TabsForModalItem
              key={id}
              id={id}
              activeTab={activeTab}
              label={tabLabel}
              onClick={setActiveTab}
            />
          ))}
        </div>
        {activeTab === 2 && <InstrumentPriceInput />}
        <Now />
      </div>

      <div className={style.container}>
        {isLaptop && (
          <div className={style.funds}>
            <p>Available funds</p>
            <p className={style.funds__text}>{formatAmountWithSpaces(balanceUser?.available)}</p>
          </div>
        )}
        <LimitProfitsAndLost />
        <div className={style.block}>
          <OpenTradeButtons
            onClickSell={() => handleOperation('SELL')}
            onClickBuy={() => handleOperation('BUY')}
            disabled={amount < 1}
          />
          {!isMobile && (
            <p className={style.spread}>
              {t('spread')}: {formatAmountWithSpaces(spread)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(OpenTradeContent);
