import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as Crown } from '../../../assets/image/common/crown.svg';
import { ReactComponent as Lock } from '../../../assets/image/common/lock.svg';

import styles from './StatusInfoIcon.module.scss';

/**
 * Represents loyalty status icon component
 * @returns {JSX.Element}
 */

const StatusInfoIcon = ({ type, isActive, color }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.statusInfoIcon}>
      <div className={styles.iconWrapper}>
        <Crown className={cn(styles[color])} />
        <div className={cn(styles.lockWrap, { [styles.noVisible]: isActive })}>
          <Lock className={styles.lock} />
        </div>
      </div>
      <div className={styles.statusText}>
        <span>{type}</span>
      </div>
      <div className={styles.texWrap}>
        <span className={cn(styles.status, { [styles.active]: isActive })}>
          {t(isActive ? 'status_active' : 'status_locked')}
        </span>
      </div>
    </div>
  );
};

export default StatusInfoIcon;
