import { types, flow, getRoot } from 'mobx-state-tree';
import axios from 'axios';

import eventSourceUtil from '../../eventSource/eventSource';
import error from '../../util/error';
import sort from '../../util/sort';
import { DEFAULT_INTERVAL, DEFAULT_SYMBOL } from '../../constant/commonConstants';

import ActiveTradesModel from './TradesModels/ActiveTradesModel';
import Period from '../common/PeriodModel';
import PendingTradesModel from './TradesModels/PendingTradesModel';
import FavoriteTradesModel from './TradesModels/FavoriteTradesModel';
import InstrumentItemModel from './InstrumentItemModel';
import TradeModel from './TradeModel';
import ClosedTradesModel from './TradesModels/ClosedTradesModel';
import FlowOfFundsModel from './TradesModels/FlowOfFundsModel';
import NewsItemModel from './NewsItemModel';
import CategoriesModel from './CategoriesModel';

const FinanceServiceModel = types
  .model('FinanceServiceModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    graphError: types.optional(types.string, ''),
    categories: types.array(CategoriesModel),
    items: types.array(InstrumentItemModel),
    itemsMain: types.array(InstrumentItemModel),
    activeItem: types.optional(InstrumentItemModel, {}),
    sortBy: types.optional(types.string, ''),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'asc'),
    activeSymbol: types.optional(types.string, DEFAULT_SYMBOL),
    activeInterval: types.optional(types.string, DEFAULT_INTERVAL),
    period: Period,
    traderSentiment: types.optional(types.number, 0),
    trade: types.optional(TradeModel, {}),
    activeTrades: types.optional(ActiveTradesModel, {}),
    pendingTrades: types.optional(PendingTradesModel, {}),
    favoriteTrades: types.optional(FavoriteTradesModel, {}),
    closedTrades: types.optional(ClosedTradesModel, {}),
    flowOfFunds: types.optional(FlowOfFundsModel, {}),
    currentCategory: types.optional(types.number, 0),
    newsItems: types.array(NewsItemModel),
    term: types.optional(types.string, ''),
  })
  .actions((finance) => ({
    setError(err) {
      finance.error = err;
    },
    clearError() {
      finance.error = '';
    },
    setGraphError(err) {
      finance.graphError = err;
    },
    clearGraphError() {
      finance.graphError = '';
    },
    setIsLoading(loading) {
      finance.isLoading = loading;
    },
    setSortBy(key) {
      finance.sortBy = key;
    },
    setSortDirection(direction) {
      finance.sortDirection = direction;
    },
    switchSortDirection() {
      finance.sortDirection = finance.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    setActiveItem(item) {
      finance.activeItem = item;
    },
    setActiveSymbol(symbol) {
      if (finance.activeSymbol === symbol) return;
      finance.activeSymbol = String(symbol);
    },
    setActiveCategory(category) {
      finance.activeCategory = category;
    },
    setItems(items) {
      finance.items = items;
    },
    setItemsMain(items) {
      finance.itemsMain = items;
    },
    setCurrentCategory(category) {
      finance.currentCategory = category;
    },
    setActivePeriod(period) {
      finance.period = period;
    },
    clearData() {
      finance.setItems([]);
    },
    clearItem() {
      finance.activeItem = {};
    },
    setTerm(term) {
      finance.term = term;
    },
    getCategories: flow(function* getCategories() {
      const { clearError, setError, setIsLoading, setCurrentCategory } = finance;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(`/services/trading/api/cash/symbol/category`);
        finance.categories = data;
        setCurrentCategory(data[0]?.id);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    getData: flow(function* getData() {
      const { clearError, setItems, setError, setIsLoading, currentCategory } = finance;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(
          `/services/trading/api/symbols/category?category=${currentCategory}`,
        );
        setItems(data.map((item) => ({ ...item, oldPrice: item.lp })));
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    closeDataStream() {
      console.log('im closed markets --/-- in store');
      eventSourceUtil.closeStream();
    },
    updateDataState(data) {
      const { items } = finance;
      items.forEach((item) => {
        const newData = data.find((i) => i.s === item.s);
        if (newData) item.update({ ...newData, oldPrice: item.lp });
      });
    },
    getUpdateData() {
      console.log('im opened markets --/-- in store');
      const { updateDataState } = finance;
      const eventSource = eventSourceUtil.createEventSource();
      eventSource.onmessage = (e) => {
        updateDataState(JSON.parse(e.data));
      };
    },
    closeDataStreamMain() {
      console.log('im closed main --/-- in store');
      eventSourceUtil.closeStreamMain();
    },
    updateDataMainState(data) {
      const { itemsMain } = finance;
      itemsMain.forEach((item) => {
        const newData = data.find((i) => i.s === item.s);
        if (newData) item.update({ ...newData, oldPrice: item.lp });
      });
    },
    getUpdateDataMain() {
      const { updateDataMainState } = finance;
      console.log('im opened main');
      const eventSource = eventSourceUtil.createEventSourceMain();
      eventSource.onmessage = (e) => {
        updateDataMainState(JSON.parse(e.data));
      };
    },
    getItems: flow(function* getItems(filter) {
      const { setItemsMain, favoriteTrades, setError, setIsLoading, clearError } = finance;
      setIsLoading(true);
      clearError();
      try {
        if (filter === 'favorites') {
          favoriteTrades.getFavorites();
          return;
        }
        if (filter === 'crypto') {
          const { data } = yield axios.get(`/services/trading/api/symbols/crypto`);
          const newData = data.map((item) => ({ ...item, oldPrice: item.lp }));
          setItemsMain(newData);
          return;
        }
        const {
          user: { isAuthorized },
        } = getRoot(finance);
        const { data } = yield axios.get(
          `/services/trading/api/symbols/top?criteria=${filter}&isAuthenticated=${isAuthorized}`,
        );
        const newData = data.map((item) => ({ ...item, oldPrice: item.lp }));
        setItemsMain(newData);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      }
    }),
    getSearch: flow(function* getSearch(value) {
      const { term, setIsLoading, setError, clearError, setItemsMain } = finance;
      if (!term) return;
      setIsLoading(true);
      clearError();
      try {
        const { data } = yield axios.get(
          `/services/trading/api/symbols/search?occurrence=${value}`,
        );

        const newData = data.map((item) => ({ ...item, oldPrice: item.lp }));

        setItemsMain(newData);
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    getItem: flow(function* getItem(category, symbol) {
      const {
        setActiveItem,
        setError,
        setIsLoading,
        clearError,
        clearGraphError,
        setGraphError,
        setActiveSymbol,
        getUpdateItem,
      } = finance;
      setIsLoading(true);
      clearError();
      clearGraphError();
      try {
        const { data } = yield axios.get(`/services/trading/api/symbols/${category}/${symbol}`);

        setActiveItem(data);
        setActiveSymbol(data.s);
        getUpdateItem();
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        setGraphError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
    closeDataStreamItem() {
      console.log('-- / close item update / --');
      eventSourceUtil.closeStreamItem();
    },
    getUpdateItem() {
      console.log('-- / start item update / --');

      const eventSource = eventSourceUtil.createEventSourceItem(
        finance.activeItem.type,
        finance.activeItem.s,
      );
      eventSource.onmessage = (e) => {
        const data = JSON.parse(e.data);
        const { activeItem } = finance;
        const newData = { ...data, oldPrice: activeItem.lp };
        activeItem.update({ ...activeItem, ...newData });
      };
    },
    getTraderSentiment: flow(function* getTraderSentiment() {
      try {
        const { currentCategory, activeSymbol } = finance;
        const { data } = yield axios.get(
          `/services/trading/api/symbols/${currentCategory}/${activeSymbol}/sentiment`,
        );
        finance.traderSentiment = data;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        finance.error = message;
        error.errorHandler(message);
      }
    }),
    getNews: flow(function* getNews() {
      try {
        const { activeSymbol } = finance;
        const { data } = yield axios.get(
          `/services/trading/api/symbols/news?symbolCode=${activeSymbol}`,
        );
        finance.newsItems = data;
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        finance.error = message;
        error.errorHandler(message);
      }
    }),
  }))
  .views((finance) => ({
    get sortedData() {
      const { items, sortDirection, sortBy } = finance;
      if (!sortBy) return items;

      return sort.sortArrayBy(items, sortDirection, sortBy);
    },
    get activePriceChange() {
      const { activeItem, period } = finance;
      if (!activeItem) return 0;
      const { cpd, cpw, cpm, cpy } = activeItem;
      const value = {
        day: cpd,
        week: cpw,
        month: cpm,
        year: cpy,
      };
      return value[period];
    },
    get activeHigh() {
      const { activeItem, period } = finance;
      if (!activeItem) return 0;
      const { h, hw, hm, hy } = activeItem;
      const value = {
        day: h,
        week: hw,
        month: hm,
        year: hy,
      };
      return value[period];
    },
    get activeLow() {
      const { activeItem, period } = finance;
      if (!activeItem) return 0;
      const { l, lw, lm, ly } = activeItem;
      const value = {
        day: l,
        week: lw,
        month: lm,
        year: ly,
      };
      return value[period];
    },
    get pricePercent() {
      const { activeLow, activeHigh, activeItem } = finance;
      if (!activeItem) return 0;

      return ((activeItem.lp - activeLow) * 100) / (activeHigh - activeLow);
    },
    get categoryName() {
      const { categories, currentCategory } = finance;

      return categories.find((category) => category.id === currentCategory)?.name;
    },
  }));

export default FinanceServiceModel;
