import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import style from './ProductsItemDetails.module.scss';
import { useRootModel } from '../../../models/RootStore';

import ProductsItemDetailHeader from './ProductsItemDetailHeader/ProductsItemDetailHeader';
import Preloader from '../../../components/ui/Preloader/Preloader';
import TVChart from '../../../components/TVChart/TVChart';
import News from '../../../components/News/News';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * Represents Product Item Detail component
 * @returns {JSX.Element}
 */
const ProductsItemDetail = () => {
  const { isDesktop } = useWindowSize();
  const {
    settings: { theme, language },
    finance: {
      getItem,
      activeItem,
      clearItem,
      closeDataStreamItem,
      isLoading,
      activeSymbol,
      activeInterval,
    },
  } = useRootModel();

  const { category, symbol } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await getItem(category, symbol);
    };
    fetchData();

    return () => {
      closeDataStreamItem();
      clearItem();
    };
  }, [symbol]);

  if (isLoading)
    return (
      <div className={style.preloader}>
        <Preloader />
      </div>
    );

  return (
    activeItem.s && (
      <article className={style.main}>
        <ProductsItemDetailHeader />
        <TVChart
          symbol={activeSymbol}
          interval={activeInterval}
          language={language}
          theme={theme}
          isFullscreenButton={isDesktop}
          className={style.charts}
        />
        <News />
      </article>
    )
  );
};

export default observer(ProductsItemDetail);
