import React from 'react';
import { useTranslation } from 'react-i18next';
import style from '../../HeaderUserInfo.module.scss';
// eslint-disable-next-line max-len
import { ReactComponent as Verification } from '../../../../../assets/image/common/verificationIcon.svg';

const ButtonVerification = () => {
  const { t } = useTranslation();

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Verification />
      </i>
      <button type='button' className={style.button} onClick={() => {}}>
        {t('verification_menu')}
      </button>
    </div>
  );
};

export default ButtonVerification;
