import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './CurrentBalance.module.scss';
import { useRootModel } from '../../models/RootStore';
import CurrentBalanceItem from './CurrentBalanceItem/CurrentBalanceItem';

/**
 * Represents CurrentBalance
 * @returns {JSX.Element}
 */
const CurrentBalance = () => {
  const { t } = useTranslation();
  const {
    user: {
      userData: { balanceUser },
    },
  } = useRootModel();

  const items = [
    { id: 1, title: t('balance'), value: balanceUser?.balance },
    { id: 2, title: t('profit'), value: balanceUser?.profit },
    { id: 3, title: t('used'), value: balanceUser?.used },
    { id: 4, title: t('available'), value: balanceUser?.available },
  ];

  return (
    <div className={style.currentBalance}>
      <p className={style.currentBalance__title}>{t('my_balance')}</p>
      {items.map(({ id, title, value }) => (
        <CurrentBalanceItem
          valueClassName={cn({ [style.usedValue]: id !== 2 })}
          title={title}
          value={value}
          key={title}
        />
      ))}
    </div>
  );
};

export default observer(CurrentBalance);
