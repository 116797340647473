import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from '../../HeaderUserInfo.module.scss';
import { ReactComponent as Star } from '../../../../../assets/image/common/star-mini.svg';

/**
 * Represents ButtonAffilateProgram component
 * @returns {JSX.Element}
 */
const ButtonAffilateProgram = ({ onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleAffilateProgram = () => {
    navigate('/info/affiliate');
    if (onClick) onClick();
  };

  return (
    <div className={style.buttonWrapper}>
      <i className={style.icon}>
        <Star />
      </i>
      <button type='button' className={style.button} onClick={handleAffilateProgram}>
        {t('affiliate_program')}
      </button>
    </div>
  );
};

export default ButtonAffilateProgram;
