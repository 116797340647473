import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import style from './SectionWithTabs.module.scss';
import ErrorBoundary from '../../Error/ErrorBoundary/ErrorBoundary';

import TabContent from './TabContent/TabContent';
import Tabs from '../Tabs/Tabs';

/**
 * @typedef {Object} TabComponent
 * @property {number} id - tab id
 * @property {string} label - tab label
 * @property {JSX.Element} component - Component for render
 */

/**
 * Represents tabs component
 * @param {Array<TabComponent>} tabs - array of tabs - [{ id, label, component }]
 * @param {number} currentTab - what tab is active
 * @param {string} title - page title
 * @param {CSSStyleRule} className - className
 * @returns {JSX.Element}
 */
const SectionWithTabs = ({ tabs, currentTab = 1, className }) => {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    }
  }, [currentTab]);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  const activeItem = tabs?.find(({ id }) => id === activeTab);

  return (
    <article className={cn(style.tabs, className)}>
      <Tabs tabs={tabs} onChange={onClickTabItem} activeTab={activeTab} />
      <ErrorBoundary>
        <TabContent
          pageTitle={activeItem?.pageTitle}
          component={activeItem?.component}
          className={activeItem?.className}
        />
      </ErrorBoundary>
    </article>
  );
};

export default SectionWithTabs;
