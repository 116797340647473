import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './ForgotPasswordConfirm.module.scss';

const ForgotPasswordConfirm = () => {
  const { t } = useTranslation();

  return (
    <div className={style.forgotPasswordConfirm}>
      <div className={style.info}>
        <p>{t('confirmation')}</p>
        <p>{t('confirm_email')}</p>
      </div>
    </div>
  );
};

export default ForgotPasswordConfirm;
