import React, { useReducer } from 'react';
import AlertContext from './alertContext';
import alertReducer, { HIDE_ALERT, SHOW_ALERT } from './alertReducer';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents AlertState
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 */
const AlertState = ({ children }) => {
  const {
    user: { clearError },
  } = useRootModel();
  const [state, dispatch] = useReducer(alertReducer, { visible: false });

  const hide = () => {
    dispatch({ type: HIDE_ALERT });
    clearError();
  };

  const show = (text, type = 'error', status) => {
    dispatch({
      type: SHOW_ALERT,
      payload: { text, type, status },
    });

    setTimeout(hide, 5000);
  };

  return (
    <AlertContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        show,
        hide,
        alert: state,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertState;
