import React from 'react';
import style from './Tabs.module.scss';
import TabItem from './TabItem/TabItem';

/**
 * @typedef {Object} TabComponent
 * @property {number} id - tab id
 * @property {string} label - tab label
 * @property {JSX.Element} component - Component for render
 */

/**
 * Represents tabs component
 * @param {Array<TabComponent>} tabs - array of tabs - [{ id, label, component }]
 * @returns {JSX.Element}
 */
const Tabs = ({ tabs, onChange, activeTab }) => {
  return (
    <nav className={style.tabList}>
      {tabs.map(({ tabLabel, id }) => (
        <TabItem key={id} id={id} activeTab={activeTab} label={tabLabel} onClick={onChange} />
      ))}
    </nav>
  );
};

export default Tabs;
