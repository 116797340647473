import React from 'react';
import style from './SearchMain.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/image/common/search.svg';
import { ReactComponent as Clear } from '../../assets/image/common/clearSearch.svg';

const SearchMain = ({ value, onChange, clearSearch }) => {
  return (
    <label htmlFor='searchInput' className={style.search}>
      <SearchIcon className={style.icon} />
      <input
        id='searchInput'
        value={value}
        type='text'
        placeholder='Search'
        onChange={onChange}
        className={style.inputSearch}
        autoComplete='off'
      />
      {value && (
        <button type='button' className={style.button} onClick={clearSearch}>
          <Clear />
        </button>
      )}
    </label>
  );
};

export default SearchMain;
