import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import style from './StatusMain.module.scss';
import { ReactComponent as Left } from '../../../../../assets/image/common/arrowBack.svg';
import { ReactComponent as Crown } from '../../../../../assets/image/common/crown.svg';
import { ReactComponent as Tick } from '../../../../../assets/image/common/tick.svg';
import { ReactComponent as Lock } from '../../../../../assets/image/common/lock.svg';

/**
 * Represents Status Info Main component
 * @returns {JSX.Element}
 */

const loyaltyStatuses = [
  {
    id: 1,
    balance: '$50',
    requiredBalance: '$50',
    volume: '$50',
    requiredVolume: '$50',
    status: 'silver',
    isActive: true,
  },
  {
    id: 2,
    balance: '$50',
    requiredBalance: '$500',
    volume: '$50',
    requiredVolume: '$5000',
    status: 'gold',
    isActive: false,
  },
  {
    id: 3,
    balance: '$50',
    requiredBalance: '$5000',
    volume: '$50',
    requiredVolume: '$50000',
    status: 'platinum',
    isActive: false,
  },
  {
    id: 4,
    balance: '$50',
    requiredBalance: '$50000',
    volume: '$50',
    requiredVolume: '$500000',
    status: 'diamond',
    isActive: false,
  },
  {
    id: 3,
    balance: '$50',
    requiredBalance: '$5000000',
    volume: '$50',
    requiredVolume: '$50000000',
    status: 'vip',
    isActive: false,
  },
];

const StatusInfoMain = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { balance, requiredBalance, volume, requiredVolume, status, isActive } =
    loyaltyStatuses[currentIndex];

  const silverStatus = status === 'silver';
  const goldStatus = status === 'silver' || status === 'gold';
  const platinumStatus = status === 'silver' || status === 'gold' || status === 'platinum';
  const diamondStatus =
    status === 'silver' || status === 'gold' || status === 'platinum' || status === 'diamond';

  const handleChangePrev = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const handleChangeNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  return (
    <section className={style.statusInfo}>
      <div className={style.statusWrapper}>
        <button
          type='button'
          className={cn(style.buttonArr, { [style.disabledButton]: currentIndex === 0 })}
          onClick={handleChangePrev}
        >
          <Left className={cn({ [style.disabled]: currentIndex === 0 })} />
        </button>
        <div className={style.statusContainer}>
          <div className={style.iconWrapper}>
            <Crown className={style[status]} />
            <div className={cn(style.lockWrap, { [style.noVisible]: isActive })}>
              <Lock className={style.lock} />
            </div>
          </div>
          <p className={style.status}>{t(status)}</p>
          <p className={cn(style.state, { [style.active]: isActive })}>
            {t(isActive ? 'status_active' : 'status_locked')}
          </p>
        </div>
        <button
          type='button'
          className={cn(style.buttonArr, {
            [style.disabledButton]: currentIndex === loyaltyStatuses.length - 1,
          })}
          onClick={handleChangeNext}
        >
          <Left
            className={cn(style.right, {
              [style.disabled]: currentIndex === loyaltyStatuses.length - 1,
            })}
          />
        </button>
      </div>
      <div className={style.statusInfoBalanceBlock}>
        <div className={style.balanceWrapper}>
          <p className={style.text}>{t('current_balance')}</p>
          <p>{balance}</p>
        </div>
        <div className={style.balanceWrapper}>
          <p className={style.text}>{t('required_balance')}</p>
          <p>{requiredBalance}</p>
        </div>
        <Link to='/balance/deposit' className={cn(style.button, style[`${status}Background`])}>
          {t('deposit')}
        </Link>
      </div>

      <div className={style.statusInfoBalanceBlock}>
        <div className={style.balanceWrapper}>
          <p className={style.text}>{t('current_volume')}</p>
          <p>{volume}</p>
        </div>
        <div className={style.balanceWrapper}>
          <p className={style.text}>{t('required_volume')}</p>
          <p>{requiredVolume}</p>
        </div>
        <Link to='/' className={cn(style.button, style[`${status}Background`])}>
          {t('trade')}
        </Link>
      </div>

      <div className={style.statusList}>
        <div className={style.statusItem}>
          <p>{t('analytical_support')}</p>
          <Tick className={cn(style[status])} />
        </div>
        <div className={style.statusItem}>
          <p>{t('hours_support')}</p>
          <Tick className={cn(style[status])} />
        </div>
        <div
          className={cn(style.statusItem, {
            [style.disabledText]: silverStatus,
          })}
        >
          <p>{t('multiplier_change')}</p>
          <Tick className={cn(style[status], { [style.disabled]: silverStatus })} />
        </div>
        <div
          className={cn(style.statusItem, {
            [style.disabledText]: goldStatus,
          })}
        >
          <p>{t('premium_signals')}</p>
          <Tick className={cn(style[status], { [style.disabled]: goldStatus })} />
        </div>
        <div
          className={cn(style.statusItem, {
            [style.disabledText]: platinumStatus,
          })}
        >
          <p>{t('vip_manager')}</p>
          <Tick className={cn(style[status], { [style.disabled]: platinumStatus })} />
        </div>
        <div
          className={cn(style.statusItem, {
            [style.disabledText]: diamondStatus,
          })}
        >
          <p>{t('individual_conditions')}</p>
          <Tick className={cn(style[status], { [style.disabled]: diamondStatus })} />
        </div>
      </div>
    </section>
  );
};

export default StatusInfoMain;
