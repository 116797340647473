import React from 'react';
import StatusInfoTablet from './StatusInfoTablet/StatusInfoTablet';
import useWindowSize from '../../hooks/useWindowSize';
import StatusInfoWeb from './StatusInfoWeb/StatusInfoWeb';

const StatusInfo = () => {
  const { isDesktop } = useWindowSize();

  return <div>{isDesktop ? <StatusInfoWeb /> : <StatusInfoTablet />}</div>;
};

export default StatusInfo;
