import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import style from './OpenTradeButtons.module.scss';
import { ReactComponent as Down } from '../../assets/image/common/curveDown.svg';
import { ReactComponent as Up } from '../../assets/image/common/curveUp.svg';
import { useRootModel } from '../../models/RootStore';

const OpenTradeButtons = ({ onClickSell, onClickBuy, disabled }) => {
  const { t } = useTranslation();
  const {
    finance: {
      activeItem: { bid, ask },
    },
  } = useRootModel();

  return (
    <div className={style.buttonContainer}>
      <button
        type='button'
        className={cn(style.button, style.red)}
        onClick={onClickSell}
        disabled={disabled}
      >
        <div>
          <p className={style.button__label}>{t('sell')}</p>
          <p className={style.button__text}>
            {t('bid')}: {bid}
          </p>
        </div>
        <Down />
      </button>
      <button
        type='button'
        className={cn(style.green, style.button)}
        onClick={onClickBuy}
        disabled={disabled}
      >
        <div>
          <p className={style.button__label}>{t('buy')}</p>
          <p className={style.button__text}>
            {t('ask')}: {ask}
          </p>
        </div>
        <Up />
      </button>
    </div>
  );
};

export default observer(OpenTradeButtons);
