import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './InstrumentsContent.module.scss';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useRootModel } from '../../../../models/RootStore';

import TableTitle from '../../../../components/ui/Table/TableTitle/TableTitle';
import InstrumentsList from './InstrumentsList/InstrumentsList';

/**
 * Represents Instruments content
 * @returns {JSX.Element}
 */
const InstrumentsContent = () => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const {
    finance: {
      setSortBy,
      setSortDirection,
      sortDirection,
      switchSortDirection,
      sortBy,
      getUpdateData,
      closeDataStream,
    },
  } = useRootModel();

  useEffect(() => {
    getUpdateData();

    return () => {
      closeDataStream();
    };
  }, [closeDataStream, getUpdateData]);

  return (
    <div className={style.mainContent}>
      <div className={style.head}>
        <TableTitle
          title={t('instruments')}
          isActive={sortBy === 'alias'}
          handleOnClick={setSortBy}
          labelKey='alias'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        <div className={style.price}>{t('price')}</div>

        <TableTitle
          title={t('day')}
          isActive={sortBy === 'cpd'}
          handleOnClick={setSortBy}
          labelKey='cpd'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        {isDesktop && <div>{t('movement')}</div>}

        <TableTitle
          title={t('month')}
          isActive={sortBy === 'cpm'}
          handleOnClick={setSortBy}
          labelKey='cpm'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        <TableTitle
          title={t('half')}
          isActive={sortBy === 'cpsm'}
          handleOnClick={setSortBy}
          labelKey='cpsm'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        <TableTitle
          title={t('year')}
          isActive={sortBy === 'cpy'}
          handleOnClick={setSortBy}
          labelKey='cpy'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />
      </div>

      <InstrumentsList />
    </div>
  );
};

export default observer(InstrumentsContent);
