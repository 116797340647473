import React, { useContext, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import i18n from '../../translations/i18n';

import style from './Languages.module.scss';
import getAnimationClasses from '../../util/getAnimationClasses';
import { useRootModel } from '../../models/RootStore';
import { LANGUAGES } from '../../constant/commonConstants';
import AlertContext from '../../context/alert/alertContext';

import { ReactComponent as LanguagesIcon } from '../../assets/image/common/languageGlobe.svg';
import { ReactComponent as DoneIcon } from '../../assets/image/common/done.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/image/common/arrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/image/common/arrowUp.svg';

/**
 * Represents Languages list
 * @returns {JSX.Element}
 */
const Languages = () => {
  const alert = useContext(AlertContext);
  const { t } = useTranslation();
  const {
    settings: { language, changeLanguage },
    user: { setUserLanguage, isAuthorized },
  } = useRootModel();
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpen = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);

  const handleClick = async (lang) => {
    let langKey;
    Object.entries(LANGUAGES).forEach((langItem) => {
      if (langItem.includes(lang)) {
        [langKey] = langItem;
      }
    });
    try {
      await changeLanguage(langKey);
      await i18n.changeLanguage(langKey);
      if (isAuthorized) {
        setUserLanguage(langKey);
      }
    } catch (e) {
      alert.show(t(e.message));
    } finally {
      handleClose();
    }
  };

  // const handleChangeLanguage = async (lang) => {
  //   setShowLanguages(false);
  //   const langKey = getLanguage(langKeys, lang);
  //   await changeLanguage(langKey);
  //   await i18n.changeLanguage(langKey);
  //   if (isAuthorized) {
  //     setUserLanguage(langKey);
  //   }
  // };

  return (
    <div className={style.languages}>
      <button className={style.menuButton} type='button' onClick={handleToggleOpen}>
        <span>
          <LanguagesIcon />
        </span>
        <p className={style.text}>{LANGUAGES[language]}</p>
        <span className={style.arrow}>{isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}</span>
      </button>

      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames={getAnimationClasses(style)}
        unmountOnExit
      >
        <div ref={listRef} className={style.list}>
          {Object.values(LANGUAGES).map((languageItem) => (
            <button
              key={languageItem}
              type='button'
              className={style.buttonItem}
              onClick={() => handleClick(languageItem)}
            >
              <div className={style.block}>
                <LanguagesIcon />
                <p>{languageItem}</p>
              </div>

              {LANGUAGES[language] === languageItem && <DoneIcon />}
            </button>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default observer(Languages);
