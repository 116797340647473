import React from 'react';
import { observer } from 'mobx-react';

import cn from 'classnames';
import { useRootModel } from '../../../../models/RootStore';

import style from './ActiveTradeItem.module.scss';
import emptyFunction from '../../../../util/emptyFunction';
import { OPERATION } from '../../../../constant/commonConstants';
import string from '../../../../util/string';
import BalanceFormat from '../../../BalanceFormat/BalanceFormat';
import { ReactComponent as ArrowDown } from '../../../../assets/image/common/downArr.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/image/common/upArr.svg';

/**
 * Represents ActiveTradesItem component
 * @param {number} id - trade id
 * @param {string} symbol - trade symbol
 * @param {number} price - trade price
 * @param {number} amount - trade openPrice
 * @param {number} multiplier - trade multiplier
 * @param {number} profit - trade profit
 * @param {number} percent - trade percent
 * @param {'BUY' | 'SELL'} operation - trade amount
 * @returns {JSX.Element}
 */
const ActiveTradesItem = ({
  id,
  symbol,
  price,
  amount,
  profit,
  percent,
  multiplier,
  operation,
}) => {
  const {
    finance: {
      activeTrades: { setActiveTradeId },
    },
    modal: { activeTrade },
  } = useRootModel();

  const setSymbol = async () => {
    await setActiveTradeId(id);
  };

  const handleClickInfo = async () => {
    await setSymbol();
    activeTrade.open();
  };

  return (
    <div
      role='button'
      tabIndex={-1}
      className={style.instrument}
      onKeyDown={emptyFunction}
      onClick={handleClickInfo}
    >
      <div className={style.instrument__block}>
        <p className={style.instrument__text}>{symbol}</p>
        <span>{operation === OPERATION.buy ? <ArrowUp /> : <ArrowDown />}</span>
      </div>
      <div>
        <p className={style.price}>{string.formatNumber(price)}</p>
        <div className={style.bottomPrice}>
          <BalanceFormat value={amount} />x{multiplier}
        </div>
      </div>
      <div className={style.priceBlock}>
        <div className={style.price}>
          <div
            className={cn({ [style.colorDanger]: profit < 0, [style.colorSuccess]: profit > 0 })}
          >
            <BalanceFormat value={profit} />
          </div>
        </div>
        <p className={style.bottomPrice}>
          <span
            className={cn({ [style.colorDanger]: percent < 0, [style.colorSuccess]: percent > 0 })}
          >
            ({string.formatPercent(percent, { withSign: true })})
          </span>
        </p>
      </div>
    </div>
  );
};

export default observer(ActiveTradesItem);
