import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './News.module.scss';
import { useRootModel } from '../../models/RootStore';
import NewsItem from './NewsItem/NewsItem';
import ButtonToggle from '../ButtonToggle/ButtonToggle';

/**
 * Represents News block
 * @returns {JSX.Element}
 */

const News = () => {
  const { t } = useTranslation();
  const {
    finance: { getNews, activeSymbol, newsItems },
  } = useRootModel();

  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
  };

  useEffect(() => {
    if (activeSymbol) getNews();
  }, [activeSymbol]);

  return (
    <div className={style.newsContainer}>
      <p className={style.mainTitle}>{t('latest_news')}</p>
      {newsItems?.length > 0 ? (
        <>
          <div className={style.contentVisible}>
            {newsItems?.slice(0, 3).map(({ date, description, title, url }, i) => (
              <NewsItem
                key={`${date + i}`}
                date={date}
                description={description}
                title={title}
                url={url}
              />
            ))}
          </div>
          <div className={cn(style.content, { [style.visible]: isVisible })}>
            {newsItems?.slice(3, newsItems.length).map(({ date, description, title, url }, i) => (
              <NewsItem
                key={`${date + i}`}
                date={date}
                description={description}
                title={title}
                url={url}
              />
            ))}
          </div>
          {newsItems?.length > 3 && (
            <ButtonToggle isVisible={isVisible} handleToggle={handleToggle} />
          )}
        </>
      ) : (
        <p className={style.notFound}>{t('not_found')}</p>
      )}
    </div>
  );
};

export default observer(News);
