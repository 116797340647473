import React, { useState, useRef, Children, useEffect } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import style from './Dropdown.module.scss';
import useOnClick from '../../../hooks/useOnClick';
import { ReactComponent as ArrowUp } from '../../../assets/image/common/arrowUp.svg';
import { ReactComponent as ArrowDown } from '../../../assets/image/common/arrowDown.svg';

/**
 * Pass array of buttons like children in the component,
 * also you have to pass style (mainElementClassName) with subclass - button,
 * for element what will be shown as the main
 */
/**
 * Represents an universal dropdown
 * @param {function} onChange - on change handler
 * @param {CSSStyleRule} className - className for dropdown
 * @param {CSSStyleRule} contentClassName - className for dropdown
 * @param {CSSStyleRule} itemClassName - className for dropdown items
 * @param {AnimationClasses} transitionClasses - classNames for animation
 * @param {('up' | 'down' | 'left' | 'right')} sideToOpen - open side
 * @param {string | JSX.Element} value - current value
 * @param {CSSStyleRule} currentItemWrapperClassName - className for current value (main wrapper)
 * @param {CSSStyleRule} currentItemClassName - className for current item
 * @param {string} description - description before current value
 * @param {JSX.Element} mainComponent - component what will be render as main
 * @param {boolean} changeMainComponent - if need to have main static component set false
 * @param {boolean} closeWithClick - if don't need to close on click set false
 * @param {boolean} showArrow - show arrow
 * @param {CSSStyleRule} arrowClassName - className for arrow
 * @param {number} tabIndex - wrapper tabIndex
 * @param {JSX.Element} children - children for dropdown
 * @param {CSSStyleRule} activeClassName - className for active state
 * @param {boolean} isOpen - prop isOpen for show dropdown content
 * @param {function} setIsOpen - change state for isOpen prop
 * @returns {JSX.Element}
 */
const Dropdown = ({
  onChange,
  className,
  currentItemClassName,
  currentItemWrapperClassName,
  contentClassName,
  itemClassName,
  transitionClasses = {},
  sideToOpen = 'down',
  value,
  description,
  mainComponent,
  changeMainComponent = true,
  closeWithClick = true,
  showArrow = true,
  arrowClassName,
  tabIndex,
  children,
  activeClassName,
  isOpen,
  setIsOpen,
}) => {
  const [currentItem, setItem] = useState(value || '');
  const [showContent, setShowContent] = useState(false);
  const refs = useRef({});
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (typeof isOpen === 'boolean') setShowContent(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (setIsOpen) setIsOpen(showContent);
  }, [showContent]);

  useEffect(() => {
    if (value) setItem(value);
  }, [value]);

  useEffect(() => {
    if (!currentItem && !value) setItem(children[0]);
  }, [children]);

  const closeMenu = () => setShowContent(false);
  useOnClick(wrapperRef, closeMenu);

  const handleOnChangeItem = (e, elem) => {
    e.stopPropagation();
    if (changeMainComponent) setItem(children[elem]);
    if (onChange) onChange(e.target.textContent, elem);
    if (closeWithClick) closeMenu();
  };

  const handleOnClick = () => setShowContent((prev) => !prev);

  const handleOnKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp': {
        const key = Object.keys(refs.current).length - 1;
        refs.current[key]?.focus();
        e.preventDefault();
        break;
      }
      case 'ArrowDown':
        refs.current[0]?.focus();
        e.preventDefault();
        break;
      case 'Enter':
        setShowContent((prev) => !prev);
        break;
      case 'Escape':
        closeMenu();
        break;
      default:
    }
  };

  const handleChangeCursor = (e, position) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.key === 'ArrowDown' && position < Object.keys(refs.current).length - 1) {
      refs.current[position + 1].focus();
    }
    if (e.key === 'ArrowUp' && position > 0) {
      refs.current[position - 1].focus();
    }
    if (e.key === 'Enter') {
      setItem(e.target.firstChild.textContent);
      if (e.target.firstChild.click) e.target.firstChild?.click();
      closeMenu();
      wrapperRef.current.focus();
    }
  };

  const transitionStyles = {
    enter: cn(style.enter, transitionClasses?.enter),
    enterActive: cn(style.enterActive, transitionClasses?.enterActive),
    enterDone: cn(style.enterDone, transitionClasses?.enterDone),
    exit: cn(style.exit, transitionClasses?.exit),
    exitActive: cn(style.exitActive, transitionClasses?.exitActive),
    exitDone: cn(style.exitDone, transitionClasses?.exitDone),
  };

  const Component = mainComponent;
  const currentValueContent = changeMainComponent ? (
    <div className={currentItemClassName}>{currentItem}</div>
  ) : (
    mainComponent && <Component />
  );

  return (
    <div
      role='button'
      className={cn(
        style.dropdown,
        { [style.active]: showContent },
        style[sideToOpen],
        className,
        showContent && activeClassName,
      )}
      tabIndex={tabIndex || 0}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      ref={wrapperRef}
    >
      <div className={cn(style.currentItemWrapper, currentItemWrapperClassName)}>
        {description && <span className={style.description}>{description}</span>}
        {currentValueContent}
        {showArrow && (
          <i tabIndex={-1} className={cn(style.arrow, arrowClassName)}>
            {showContent ? <ArrowUp /> : <ArrowDown />}
          </i>
        )}
      </div>

      <CSSTransition in={showContent} timeout={700} classNames={transitionStyles} unmountOnExit>
        <div
          role='menu'
          className={cn(
            style.dropdownContent,
            {
              [style.showContent]: showContent,
            },
            contentClassName,
          )}
        >
          {Children.map(children, (itm, idx) => {
            const key = 1000 * idx;
            return (
              <div
                role='button'
                ref={(elem) => {
                  refs.current[idx] = elem;
                }}
                className={cn(style.item, itemClassName)}
                onKeyDown={(e) => handleChangeCursor(e, idx)}
                onClick={(e) => handleOnChangeItem(e, idx)}
                tabIndex={-1}
                key={key}
              >
                {itm}
              </div>
            );
          })}
        </div>
      </CSSTransition>
    </div>
  );
};

export default Dropdown;
