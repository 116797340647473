import { CODE_LENGTH } from './validationSchema';

/**
 * set formik value
 * @param {string} field - field to change
 * @param {string} value - value
 * @param {Formik} formik - formik
 * @returns {Promise<void>}
 */
const setValue = async (field, value, formik) => {
  await formik.setFieldValue(field, value);
  await formik.setFieldTouched(field, true, true);
};

/**
 * check target value to valid code
 * @param e
 * @param formik
 */
const checkByCode = (e, formik) => {
  if (e.target.value.length > CODE_LENGTH) return;
  if (/\d?/.test(e.target.value)) {
    formik.handleChange(e);
  }
};

export default {
  setValue,
  checkByCode,
};
