import { useEffect } from 'react';

/**
 * Hook Executor for keydown
 * @param {string} key - pressed key
 * @param {function} callback - callback what will be execute
 */

const useExecutorForKey = (key, callback) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === key) {
        callback(e);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useExecutorForKey;
