import React, { useState } from 'react';
import { observer } from 'mobx-react';
import style from './NewsItem.module.scss';
import { useRootModel } from '../../../models/RootStore';
import date from '../../../util/date';

/**
 * Represents News item
 * @param {string} date - news item date
 * @param {string} title - news item title
 * @param {string} description - news item description
 * @param {string} url - news item url
 * @returns {JSX.Element}
 */

const NewsItem = ({ date: newsDate, title, description, url }) => {
  const [isShow, setIsShow] = useState(false);
  const {
    settings: { language },
  } = useRootModel();

  const handleToggle = () => {
    setIsShow(!isShow);
  };
  return (
    <div className={style.newsItem}>
      <div
        role='button'
        className={style.titleBlock}
        onClick={handleToggle}
        tabIndex={-1}
        onKeyDown={handleToggle}
      >
        <p className={style.title}>{title}</p>
        <p className={style.date}>{date.toLocaleString(newsDate, language)}</p>
      </div>
      {isShow && (
        <a href={url} target='_blank' rel='noopener noreferrer' className={style.description}>
          {description}
        </a>
      )}
    </div>
  );
};

export default observer(NewsItem);
