import { createContext } from 'react';
import emptyFunction from '../../util/emptyFunction';

/**
 * @typedef {Object} Alert
 * @property {string} text - alert text
 * @property {boolean} visible - alert visibility
 */
const alert = {
  text: '',
  visible: false,
};

/**
 * @callback ShowAlert
 * @param {string} text - alert text
 * @param {('error' | 'warning' | 'success' | 'info')} [type] - alert type
 * @param {number} [status] - status
 */

/**
 * @typedef {Object} DefaultValues
 * @param {ShowAlert} show - show alert
 * @param {function} hide - hide alert
 * @param {Alert} alert - alert
 */
const defaultValues = {
  show: (str) => str,
  hide: emptyFunction,
  alert,
};

/**
 * @memberOf module.React
 * @type {React.Context<{hide: Function, alert: Alert, show: ShowAlert}>}
 */
const AlertContext = createContext(defaultValues);

export default AlertContext;
