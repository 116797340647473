import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import style from '../BalancePage.module.scss';
import BalanceHistory from '../../../../components/BalanceHistory/BalanceHistory';
import { ReactComponent as BackIcon } from '../../../../assets/image/common/back.svg';

/**
 * Represents Payment history component in Mobile version
 * @returns {JSX.Element}
 */

const PaymentHistoryMobile = () => {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <Link to='/balance' className={style.back}>
          <BackIcon />
        </Link>
        <p className={style.title}>{t('history')}</p>
      </div>
      <BalanceHistory />
    </div>
  );
};

export default observer(PaymentHistoryMobile);
