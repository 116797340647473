import { types } from 'mobx-state-tree';

const ReferentsItemModel = types.model('ReferentsItemModel', {
  createdAt: types.maybeNull(types.string),
  email: types.optional(types.string, ''),
  lastDepositAt: types.maybeNull(types.string),
  totalBonus: types.optional(types.number, 0),
  totalDeposits: types.optional(types.number, 0),
  unpaidBonus: types.optional(types.number, 0),
  userId: types.number,
});

const ReferentsModel = types
  .model('ReferentsModel', {
    referents: types.array(ReferentsItemModel),
    totalReferents: types.optional(types.number, 0),
    totalUnpaidBonus: types.optional(types.number, 0),
  })
  .actions((referentsInfo) => ({
    setPage(page) {
      referentsInfo.page = page;
    },
  }));

export default ReferentsModel;
