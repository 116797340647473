import { useEffect, useState } from 'react';
import {
  VERIFY_ATTEMPTS_COUNT,
  VERIFY_ATTEMPTS_RESTORE_SECONDS,
  VERIFY_ATTEMPTS_STORAGE_STRING,
  ENTER_CODE_RESTORE_SECONDS,
  ENTER_CODE_RESTORE_STORAGE_STRING,
  GENERAL_SEND_CODE_RESTORE_SECONDS,
  SEND_CODE_RESTORE_SECONDS,
  SEND_CODE_RESTORE_STORAGE_STRING,
  VERIFY_SEND_CODE_RESTORE_SECONDS,
} from '../constant/commonConstants';
import storage from '../util/storage';
import { useRootModel } from '../models/RootStore';

/**
 * Type for useSendCodeState
 * @typedef {Object} UseSendCodeState
 * @property {number} attemptsLeft - how many attempts left before pause
 * @property {number} secondsRestoreAttempts - seconds to restore attempts
 * @property {function} setSecondsRestoreAttempts - setSeconds callback (value: number) => void
 * @property {number} secondsRestoreSendCode - seconds to restore send code
 * @property {function} setSecondsRestoreSendCode - setSeconds callback (value: number) => void
 * @property {number} secondsRestoreSendCodeGeneral - seconds to restore send code
 * @property {function} setSecondsRestoreSendCodeGeneral - setSeconds
 * @property {number} secondsRestoreEnterCode - seconds to restore enter code
 * @property {function} setSecondsRestoreEnterCode - setSeconds callback (value: number) => void
 * @property {function} handleSetAttempts - handleSetAttempts
 */
/**
 * Hook useSendCodeState - control security restore times states & save localStorage
 * @returns {UseSendCodeState}
 */
const useSendCodeState = () => {
  const {
    user: { userData },
  } = useRootModel();
  const attemptsDefaultValue = { left: VERIFY_ATTEMPTS_COUNT, restoreTime: 0 };
  const [attempts, setAttempts] = useState(attemptsDefaultValue);
  const [secondsRestoreAttempts, setSecondsRestoreAttempts] = useState(
    VERIFY_ATTEMPTS_RESTORE_SECONDS,
  );
  const [secondsRestoreSendCode, setSecondsRestoreSendCode] = useState(SEND_CODE_RESTORE_SECONDS);
  const [secondsRestoreSendCodeGeneral, setSecondsRestoreSendCodeGeneral] = useState(
    GENERAL_SEND_CODE_RESTORE_SECONDS,
  );
  const [secondsRestoreEnterCode, setSecondsRestoreEnterCode] = useState(
    ENTER_CODE_RESTORE_SECONDS,
  );
  const [secondsRestoreSendVerifyCode, setSecondsRestoreSendVerifyCode] = useState(
    VERIFY_SEND_CODE_RESTORE_SECONDS,
  );

  useEffect(() => {
    if (secondsRestoreAttempts === 0) {
      setAttempts({ left: VERIFY_ATTEMPTS_COUNT });
    }
  }, [secondsRestoreAttempts]);

  useEffect(() => {
    if (userData) setAttempts((prev) => ({ ...prev, left: userData.phoneVerifyAttempts }));
  }, [userData?.phoneVerifyAttempts]);

  useEffect(() => {
    storage.setSecondsFromStorage(SEND_CODE_RESTORE_STORAGE_STRING, setSecondsRestoreSendCode);
    storage.setSecondsFromStorage(ENTER_CODE_RESTORE_STORAGE_STRING, setSecondsRestoreEnterCode);

    const attemptsStorage = localStorage.getItem(VERIFY_ATTEMPTS_STORAGE_STRING);
    if (!attemptsStorage) return;

    const { restoreTime: xRestoreTime, left } = JSON.parse(attemptsStorage);
    const restoreTime = parseInt(xRestoreTime, 10);

    if (!restoreTime || Date.now() > restoreTime) {
      setAttempts(attemptsDefaultValue);
      return;
    }
    if (left > 0) {
      setAttempts({ left, restoreTime });
      return;
    }

    setAttempts({ left: 0, restoreTime });
    const secondsToRestore = Math.ceil((restoreTime - Date.now()) / 1000);
    setSecondsRestoreAttempts(secondsToRestore);
  }, []);

  const handleSetAttempts = async () => {
    const restoreTime = Date.now() + VERIFY_ATTEMPTS_RESTORE_SECONDS * 1000;

    const { left } = attempts;
    localStorage.setItem(
      VERIFY_ATTEMPTS_STORAGE_STRING,
      JSON.stringify({
        left: left - 1,
        restoreTime,
      }),
    );
    if (left === 1) {
      setSecondsRestoreAttempts(VERIFY_ATTEMPTS_RESTORE_SECONDS);
    }

    storage.setLocalRestoreTime(ENTER_CODE_RESTORE_STORAGE_STRING, ENTER_CODE_RESTORE_SECONDS);
    setSecondsRestoreEnterCode(ENTER_CODE_RESTORE_SECONDS);

    setAttempts((prev) => ({
      restoreTime,
      left: prev.left - 1,
    }));
  };

  return {
    handleSetAttempts,
    attemptsLeft: attempts.left,
    secondsRestoreAttempts,
    setSecondsRestoreAttempts,
    secondsRestoreSendCode,
    setSecondsRestoreSendCode,
    secondsRestoreEnterCode,
    setSecondsRestoreEnterCode,
    secondsRestoreSendCodeGeneral,
    setSecondsRestoreSendCodeGeneral,
    secondsRestoreSendVerifyCode,
    setSecondsRestoreSendVerifyCode,
  };
};

export default useSendCodeState;
