import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StatusInfoHeader from '../StatusInfoHeader/StatusInfoHeader';
import StatusInfoBodyTablet from './StatusInfoBodyTablet/StatusInfoBodyTablet';
import StatusInfoActions from '../StatusInfoActions/StatusInfoActions';
import Modal from '../../../components/Modal/Modal';
import ModalBody from '../../../components/Modal/ModalBody/ModalBody';
import StatusInfoModalBody from './StatusInfoModalBody/StatusInfoModalBody';

import styles from './StatusInfoTablet.module.scss';

const layouts = [
  {
    type: 'silver',
    isActive: true,
    requiredBalance: '50',
    requiredVolume: '500',
  },
  {
    type: 'gold',
    isActive: false,
    requiredBalance: '500',
    requiredVolume: '5000',
  },
  {
    type: 'platinum',
    isActive: false,
    requiredBalance: '5000',
    requiredVolume: '50000',
  },
  {
    type: 'diamond',
    isActive: false,
    requiredBalance: '50000',
    requiredVolume: '500000',
  },
  {
    type: 'vip',
    isActive: false,
    requiredBalance: '500000',
    requiredVolume: '5000000',
  },
];

/**
 * Represents loyalty status component [tablet]
 * @returns {JSX.Element}
 */

const StatusInfoTablet = () => {
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState({});

  const { t } = useTranslation();

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (layout) => {
    if (layout.isActive) return;
    setContent(layout);
    setShowModal(true);
  };

  return (
    <div className={styles.statusInfoTablet}>
      <StatusInfoHeader />
      {layouts.map((layout) => (
        <StatusInfoBodyTablet
          key={layout.type}
          layout={layout}
          openModal={openModal}
          closeModal={closeModal}
        />
      ))}
      <StatusInfoActions />
      {showModal && (
        <Modal
          wrapperClassname={styles.modal}
          handleClose={closeModal}
          header
          headerTitle={`${t(content.type)} Status`}
        >
          <ModalBody className={styles.modalBody}>
            <StatusInfoModalBody
              type={content.type}
              requiredBalance={content.requiredBalance}
              requiredVolume={content.requiredVolume}
            />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default StatusInfoTablet;
