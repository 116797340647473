import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../../util/error';
import { sortByQuantity } from '../../../constant/commonConstants';
import TradesSymbolModel from './TradesSymbolModel';
import PeriodForTables from '../../common/PeriodForTables';

const ClosedTradesItemModel = types.model('ClosedTradesItemModel', {
  amount: types.number,
  closedAt: types.maybeNull(types.string),
  closingPrice: types.maybeNull(types.number),
  id: types.maybeNull(types.integer),
  multiplier: types.integer,
  openedAt: types.string,
  openingPrice: types.number,
  operation: types.string,
  pendingPrice: types.maybeNull(types.number),
  profit: types.maybeNull(types.number),
  profitPercent: types.maybeNull(types.number),
  result: types.maybeNull(types.number),
  status: types.string,
  stopLoss: types.maybeNull(types.number),
  symbol: TradesSymbolModel,
  takeProfit: types.maybeNull(types.number),
  userId: types.integer,
});

const SummaryModel = types.model('SummaryModel', {
  amount: types.optional(types.number, 0),
  profit: types.optional(types.number, 0),
  profitPercent: types.optional(types.number, 0),
  result: types.optional(types.number, 0),
});

const ClosedTradesModel = types
  .model('ClosedTradesModel', {
    isLoading: types.optional(types.boolean, true),
    error: types.optional(types.string, ''),
    closeTradeId: types.optional(types.number, 1),
    trades: types.array(ClosedTradesItemModel),
    summary: types.optional(SummaryModel, {}),
    total: types.optional(types.number, 0),
    sortBy: types.optional(types.string, ''),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'asc'),
    page: types.optional(types.number, 0),
    pageSize: types.optional(types.number, sortByQuantity[0]),
    period: PeriodForTables,
    name: types.optional(types.string, ''),
  })
  .actions((closedTrades) => {
    return {
      setSortBy(key) {
        closedTrades.sortBy = key;
        closedTrades.setPage(0);
      },
      setSortDirection(direction) {
        closedTrades.sortDirection = direction;
      },
      switchSortDirection() {
        closedTrades.sortDirection = closedTrades.sortDirection === 'asc' ? 'desc' : 'asc';
      },
      setPage(page) {
        closedTrades.page = page;
      },
      setPageSize(quantity) {
        closedTrades.pageSize = parseInt(quantity, 10);
        closedTrades.setPage(0);
      },
      changePeriod(period) {
        closedTrades.period = period;
        closedTrades.setPage(0);
      },
      setName(name) {
        closedTrades.name = name;
      },
      setItems(items) {
        closedTrades.trades = items;
      },
      addItems(items) {
        closedTrades.trades = [...closedTrades.trades, ...items];
      },
      setError(err) {
        closedTrades.error = err;
      },
      setIsLoading(loading) {
        closedTrades.isLoading = loading;
      },
      setNextPage() {
        const { page, pageSize, setPage, isNextPage } = closedTrades;
        if (page < pageSize && isNextPage) setPage(page + 1);
      },
      setClosedTradeId(id) {
        closedTrades.closeTradeId = id;
      },
      getClosedTrades: flow(function* getClosedTrades(isAddItems = false) {
        const {
          setIsLoading,
          setError,
          page,
          pageSize: size,
          period,
          sortBy,
          sortDirection,
          name,
          addItems,
          setItems,
        } = closedTrades;
        setIsLoading(true);

        try {
          const params = {
            page,
            size,
            period,
            sort: `${sortBy},${sortDirection}`,
            name,
          };

          const {
            headers,
            data: { trades, summary },
          } = yield axios.get(`/services/trading/api/trades/closed`, { params });

          closedTrades.total = parseInt(headers['x-total-count'], 10);
          closedTrades.summary = summary;
          closedTrades.closeTradeId = trades[0].id;
          if (isAddItems) {
            addItems(trades);
          } else {
            setItems(trades);
          }
        } catch (err) {
          const message = err.response?.data.errorCode || err.message;
          setError(message);
          error.errorHandler(message);
        } finally {
          setIsLoading(false);
        }
      }),
    };
  })
  .views((closedTrades) => ({
    get quantity() {
      const { trades } = closedTrades;
      return trades.length > 99 ? '99+' : trades.length;
    },
    get isNextPage() {
      const { page, total, pageSize } = closedTrades;
      return page < total / pageSize;
    },
    get totalItems() {
      const { total } = closedTrades;
      return total > 99 ? '99+' : total;
    },
    get closedTrade() {
      const { trades, closeTradeId } = closedTrades;
      return trades.find((i) => i.id === closeTradeId);
    },
  }));

export default ClosedTradesModel;
