import React from 'react';
import { observer } from 'mobx-react';
import style from './RigthSideBlock.module.scss';
import DemoAccount from './DemoAccount/DemoAccount';
import ActiveTradesRightSide from './ActiveTradesRightSide/ActiveTradesRightSide';
import PendingRightSide from './PendingRightSide/PendingRightSide';
import OpenTradeContent from '../OpenTradeContent/OpenTradeContent';
import CurrentBalance from '../CurrentBalance/CurrentBalance';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents Right side blocks on Main Page.
 * @returns {JSX.Element}
 */

const RightSideBlock = () => {
  const {
    finance: { activeItem },
  } = useRootModel();

  return (
    <div className={style.rightSideBlock}>
      <DemoAccount />
      {activeItem.s && <OpenTradeContent />}
      <CurrentBalance />
      <ActiveTradesRightSide />
      <PendingRightSide />
    </div>
  );
};

export default observer(RightSideBlock);
