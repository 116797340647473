import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useRootModel } from '../../../../../models/RootStore';
import LimitProfitsAndLost from '../../../../LimitProfitsAndLost/LimitProfitsAndLost';
import TradeInformation from '../../../../TradeInformation/TradeInformation';
import style from '../../../AppModals.module.scss';
import Button from '../../../../ui/Button/Button';
import { ReactComponent as Tip } from '../../../../../assets/image/common/tip.svg';
import AlertContext from '../../../../../context/alert/alertContext';

/**
 * Represents Active Trade Content in Modal after click on Active trades block
 * @returns {JSX.Element}
 */
const PendingTradeContent = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    modal: {
      pendingTrade: { close },
    },
    finance: {
      activeTrades: { getActiveTrades },
      pendingTrades: { getPendingTrades, pendingTrade, setActive },
      trade: { cancelTrade, changeLimits, setInitialLimits },
    },
  } = useRootModel();

  const handleUpdateLimits = async () => {
    try {
      await changeLimits(pendingTrade.id);
      setInitialLimits();
      alert.show(t('changes_saved'), 'success');
      close();
      getPendingTrades();
    } catch (err) {
      alert.show(err);
    }
  };

  const handleSetActive = async () => {
    try {
      await setActive(pendingTrade.id);
      getPendingTrades();
      getActiveTrades();
      alert.show(t('order_set_active'), 'success');
      close();
    } catch (err) {
      alert.show(err);
    }
  };

  const handleDeleteOrder = async () => {
    try {
      await cancelTrade(pendingTrade.id);
      alert.show(t('order_canceled'), 'success');
      close();
    } catch (err) {
      alert.show(err);
    }
  };

  const buttonText = () => {
    return (
      <div className={style.pending__header}>
        <p>{t('set_as_active')}</p>
        <div className={style.pending__tip}>
          <Tip />
          <p className={style.pending__content}>
            Set as active dolor sit amet consectetur. Diam velit massa pretium neque pellentesque
            convallis et praesent lectus.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={style.container}>
        <TradeInformation trade={pendingTrade} />
        <div className={style.line} />
        <div style={{ width: '100%' }}>
          <LimitProfitsAndLost />
          <button type='button' className={style.saveLimits} onClick={handleUpdateLimits}>
            {t('save_limits')}
          </button>
        </div>
      </div>

      <div className={style.buttonContainer}>
        <Button
          text={t('delete_order')}
          color='danger'
          fontWeight='bold'
          onClick={handleDeleteOrder}
          width='100%'
        />
        <Button
          text={buttonText()}
          color='primary'
          fontWeight='bold'
          width='100%'
          onClick={handleSetActive}
        />
      </div>
    </div>
  );
};

export default observer(PendingTradeContent);
