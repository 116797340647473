import React from 'react';

import style from './PendingList.module.scss';
import instrumentIcon from '../../../../../assets/instrumentIcon';

import PendingItem from './PendingItem/PendingItem';
import NoTrade from '../../../../../components/NoTrade/NoTrade';

/**
 * Represents pending trades table
 * @returns {JSX.Element}
 */
const PendingList = ({ items }) => {
  return (
    <div className={style.body}>
      {items?.length > 0 ? (
        items.map(
          ({ id, symbol, openedAt, pendingPrice, takeProfit, stopLoss, amount, operation }) => {
            const { alias, s, type } = symbol;
            const icon = instrumentIcon[type][s];
            return (
              <PendingItem
                key={id}
                id={id}
                title={alias}
                code={s}
                icon={icon}
                date={openedAt}
                pendingPrice={pendingPrice}
                takeProfit={takeProfit}
                stopLoss={stopLoss}
                amount={amount}
                operation={operation}
              />
            );
          },
        )
      ) : (
        <NoTrade />
      )}
    </div>
  );
};

export default PendingList;
