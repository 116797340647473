import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './RequestPage.module.scss';
import Question from '../../../../pages/Support/Question/Question';
import BackButton from '../../../../components/ui/BackButton/BackButton';

/**
 * Represents Request page in Mobile version
 * @returns {JSX.Element}
 */

const RequestPage = () => {
  const { t } = useTranslation();
  return (
    <section className={style.section}>
      <BackButton className={style.backButton} url='/support' />
      <h3 className={style.title}>{t('contact_client_department')}</h3>
      <Question />
    </section>
  );
};

export default RequestPage;
