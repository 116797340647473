import React from 'react';
import { observer } from 'mobx-react';
import Modal from '../../../Modal/Modal';
import style from '../../AppModals.module.scss';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import WithdrawMoney from '../../../WithdrawMoney/WithdrawMoney';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useRootModel } from '../../../../models/RootStore';

const WithdrawModal = () => {
  const { isMobile } = useWindowSize();
  const {
    modal: {
      withdraw: { isOpen, close },
    },
  } = useRootModel();

  return (
    !isMobile &&
    isOpen && (
      <Modal handleClose={close} header headerTitle='Withdraw' wrapperClassname={style.modal}>
        <ModalBody>
          <WithdrawMoney />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(WithdrawModal);
