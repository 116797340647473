import React from 'react';
import cn from 'classnames';
import style from './TabsForModalItem.module.scss';

/**
 * Represents one tab in tabs for modal
 * @param {number} activeTab - id of active tab
 * @param {string} label - label for tab
 * @param {function} onClick - handler onClick
 * @param {number} id - tab id
 * @param {CSSStyleRule} itemClassName - className for item
 * @param {CSSStyleRule} textClassName - className for span
 * @returns {JSX.Element}
 */

const TabsForModalItem = ({ activeTab, label, onClick, id, itemClassName, textClassName }) => {
  const handleOnClick = () => {
    onClick(id);
  };
  const isActive = activeTab === id;

  return (
    <button
      type='button'
      className={cn(
        style.item,
        {
          [style.active]: isActive,
          [style.notActive]: !isActive,
        },
        itemClassName,
      )}
      onClick={handleOnClick}
    >
      <span className={cn(style.text, textClassName)}>{label}</span>
    </button>
  );
};

export default TabsForModalItem;
