import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from '../GeneralPhone.module.scss';
import emptyFunction from '../../../../../util/emptyFunction';
import useFormikApp from '../../../../../hooks/useFormikApp';
import formikUtil from '../../../../../util/formikUtil';
import { CODE_LENGTH, EMAIL_CODE, PHONE, SMS_CODE } from '../../../../../util/validationSchema';
import {
  ENTER_CODE_RESTORE_SECONDS,
  GENERAL_SEND_CODE_RESTORE_SECONDS,
} from '../../../../../constant/commonConstants';
import useSendCodeState from '../../../../../hooks/useSendCodeState';
import { useRootModel } from '../../../../../models/RootStore';
import AlertContext from '../../../../../context/alert/alertContext';

import TextInput from '../../../../../components/ui/TextInput/TextInput';
import Button from '../../../../../components/ui/Button/Button';
import ButtonCountdown from '../../../../../components/ui/ButtonCountdown/ButtonCountdown';

/**
 * Represents phone change component for GeneralInformation
 * @param {function} sendCode - handleSendCode
 * @param {function} setIsVerifyCodeSent - setIsVerifyCodeSent
 * @returns {JSX.Element}
 */
const GeneralPhoneChange = ({ sendCode, setIsVerifyCodeSent }) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    user: { userData, changePhoneConfirm },
  } = useRootModel();
  const {
    setSecondsRestoreEnterCode,
    secondsRestoreSendCodeGeneral,
    setSecondsRestoreSendCodeGeneral,
  } = useSendCodeState();

  const formik = useFormikApp([SMS_CODE, EMAIL_CODE], emptyFunction);

  useEffect(() => {
    const match = search.match(/\?code=([^&]+)/);
    if (pathname === '/profile/change-phone' && match && match[1]) {
      formikUtil.setValue(EMAIL_CODE, match[1], formik);
    }
  }, []);

  const handleChangeCode = (e) => {
    formikUtil.checkByCode(e, formik);
  };

  const handleChangePhoneNumber = async () => {
    try {
      await changePhoneConfirm(formik.values[SMS_CODE], formik.values[EMAIL_CODE]);
      setSecondsRestoreEnterCode(ENTER_CODE_RESTORE_SECONDS);
      setIsVerifyCodeSent(false);
      navigate('/profile');
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const handleSendCode = () => {
    try {
      sendCode();
      setSecondsRestoreSendCodeGeneral(GENERAL_SEND_CODE_RESTORE_SECONDS);
    } catch (e) {
      alert.show(t(e.message));
    }
  };

  const isValidSmsCode = formik.values[SMS_CODE] && !formik.errors[SMS_CODE];
  const isValidEmailCode = formik.values[EMAIL_CODE] && !formik.errors[EMAIL_CODE];
  const isValidCodes =
    userData && userData[PHONE] ? isValidSmsCode && isValidEmailCode : isValidSmsCode;

  return (
    <>
      <div className={style.code}>
        <p className={style.text}>
          {`${t('security_codes')} `}
          <br />
          {t('will_receive')}
        </p>

        <ButtonCountdown
          text={`${t('resend')}${secondsRestoreSendCodeGeneral > 0 ? ` ${t('in')}` : ''}`}
          onClick={handleSendCode}
          seconds={secondsRestoreSendCodeGeneral}
          handleChangeSeconds={setSecondsRestoreSendCodeGeneral}
          buttonClassName={style.buttonCountdown}
        />

        <div className={style.codeInputWrapper}>
          <p className={style.codeLabel}>SMS Code</p>
          <TextInput
            id={SMS_CODE}
            placeholder={t('code')}
            onChange={handleChangeCode}
            formik={formik}
            inputMode='numeric'
            maxLength={CODE_LENGTH}
            autoComplete='nope'
          />
        </div>
        <div className={style.codeInputWrapper}>
          <p className={style.codeLabel}>{t('email_code')}</p>
          <TextInput
            id={EMAIL_CODE}
            placeholder={t('code')}
            onChange={handleChangeCode}
            formik={formik}
            inputMode='numeric'
            maxLength={CODE_LENGTH}
            autoComplete='nope'
          />
        </div>
      </div>

      <Button
        text={t('verify_phone')}
        size='small'
        onClick={handleChangePhoneNumber}
        marginBottom
        width='full'
        height={45}
        disabled={!isValidCodes}
      />
    </>
  );
};

export default GeneralPhoneChange;
