import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import useWindowSize from '../../../hooks/useWindowSize';
import { useRootModel } from '../../../models/RootStore';
import { PERIOD, SORT_BY_TIME, sortByQuantity } from '../../../constant/commonConstants';
import style from './ClosedTrades.module.scss';
import { ReactComponent as Search } from '../../../assets/image/common/searchIcon.svg';
import Dropdown from '../../../components/ui/Dropdown/Dropdown';
import ButtonGroup from '../../../components/ui/ButtonGroup/ButtonGroup';
import ClosedTradesContent from './ClosedTradesContent/ClosedTradesContent';
import Pagination from '../../../components/ui/Pagination/Pagination';
import Preloader from '../../../components/ui/Preloader/Preloader';
import BalanceFormat from '../../../components/BalanceFormat/BalanceFormat';

/**
 * Represents closed trades component
 * @returns {JSX.Element}
 */

const ClosedTrades = () => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const {
    finance: {
      closedTrades: {
        trades,
        total,
        setPage,
        setPageSize,
        page,
        pageSize,
        period,
        changePeriod,
        setName,
        summary: { amount, result, profit },
        getClosedTrades,
        isLoading,
        totalItems,
      },
    },
  } = useRootModel();
  const [searchedValue, setSearchedValue] = useState('');

  const handleChangePage = async ({ currentPage }) => {
    await setPage(currentPage - 1);
    getClosedTrades();
  };

  const handleChangeSortByQuantity = async (quant) => {
    await setPageSize(quant);
    getClosedTrades();
  };

  const handleChangeSortByDate = (time) => {
    Object.entries(PERIOD).forEach(async ([key, value]) => {
      if (t(key) === time) await changePeriod(value);
    });
    getClosedTrades();
  };

  const handleChange = (e) => {
    setSearchedValue(e.target.value);
  };

  useEffect(() => {
    return () => {
      setSearchedValue('');
      setName('');
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await setName(searchedValue);
      await setPageSize(isDesktop ? 10 : 20);
      getClosedTrades();
    };
    fetchData();
  }, [searchedValue]);

  const table = (
    <>
      <ClosedTradesContent items={trades} />
      <div className={style.footer}>
        <p className={style.footerText}>{t('total')}</p>
        {isDesktop && <BalanceFormat value={amount} />}
        <BalanceFormat value={result} />
        <div className={cn(style.red, { [style.green]: profit > 0 })}>
          <BalanceFormat value={profit} />
        </div>
      </div>
    </>
  );

  return (
    <div className={style.closedTrades}>
      <div className={style.wrapper}>
        <div className={style.titleWithSearch}>
          <p className={style.title}>{t('closed_orders')}</p>
          <p className={style.notifications}>{totalItems}</p>

          <form className={style.search}>
            <span className={style.underline} />
            <i className={style.image}>
              <Search />
            </i>
            <input
              type='text'
              className={style.input}
              value={searchedValue}
              onChange={handleChange}
            />
          </form>
        </div>

        <div className={style.optionsWrapper}>
          <div className={style.options}>
            <p className={style.optionTitle}>{t('show')}</p>
            <Dropdown
              onChange={handleChangeSortByQuantity}
              className={style.entries}
              contentClassName={style.content}
              value={pageSize}
              currentItemWrapperClassName={style.currentItemWrapperClassName}
            >
              {sortByQuantity}
            </Dropdown>
          </div>
          <ButtonGroup
            items={Object.values(SORT_BY_TIME)}
            className={style.buttonGroup}
            activeItem={SORT_BY_TIME[period]}
            onClick={handleChangeSortByDate}
          />
        </div>
      </div>

      {trades.length === 0 && isLoading ? <Preloader className={style.loader} /> : table}

      {isDesktop && (
        <Pagination
          totalRecords={total}
          pageLimit={pageSize}
          pageNeighbours={2}
          wrapperClassName={style.pagination}
          onPageChanged={handleChangePage}
          currentPage={page + 1}
        />
      )}
    </div>
  );
};

export default observer(ClosedTrades);
