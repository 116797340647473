import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import style from './Slider.module.scss';
import { ReactComponent as TooltipIcon } from '../../assets/image/common/tooltipSlider.svg';
import { useRootModel } from '../../models/RootStore';
import { formatAmountWithSpaces } from '../../util/string';

const Slider = () => {
  const {
    finance: {
      trade: {
        amountData: { amount, setAmount },
      },
    },
    user: { userData },
  } = useRootModel();
  const [rangeAmount, setRangeAmount] = useState(0);

  const available = userData ? userData.balanceUser?.available : 0;

  const setCurrentAmount = (amountValue) => {
    setAmount(formatAmountWithSpaces((available * amountValue) / 100));
  };

  const handleRangeChange = (e) => {
    setRangeAmount(+e.target.value);
    setCurrentAmount(+e.target.value);
  };

  const handleRangeButtonClick = (amountValue) => {
    if (!available) return;

    setCurrentAmount(amountValue);
    setRangeAmount(amountValue);
  };

  const tooltipPosition = useMemo(() => {
    if (rangeAmount < 8) return rangeAmount + 4;
    if (rangeAmount < 25) return rangeAmount + 3;
    if (rangeAmount < 51) return rangeAmount + 1;
    if (rangeAmount > 90) return rangeAmount - 2;
    if (rangeAmount > 80) return rangeAmount - 1;

    return rangeAmount;
  }, [rangeAmount]);

  useEffect(() => {
    if (available) {
      const rangeAmountPercent = Math.ceil((amount * 100) / available);

      setRangeAmount(rangeAmountPercent > 100 ? 100 : rangeAmountPercent);
    }
  }, [amount, available]);

  return (
    <div className={style.slider}>
      <input
        type='range'
        name='points'
        min={0}
        max={100}
        value={rangeAmount}
        onChange={handleRangeChange}
        className={style.slider__input}
      />

      {Boolean(available) && (
        <div className={style.tooltip} style={{ left: `${tooltipPosition}%` }}>
          <TooltipIcon />
          <p className={style.tooltip__text}>{rangeAmount}%</p>
        </div>
      )}

      <div className={style.slider__tracker} style={{ width: `${rangeAmount}%` }} />
      <button
        type='button'
        className={cn(style.slider__dot, {
          [style.slider__dot__active]: rangeAmount >= 0,
        })}
        style={{ left: '0%' }}
        onClick={() => handleRangeButtonClick(0)}
      />
      <button
        type='button'
        className={cn(style.slider__dot, {
          [style.slider__dot__active]: rangeAmount >= 25,
        })}
        style={{ left: '25%', transform: 'translateX(-3px)' }}
        onClick={() => handleRangeButtonClick(25)}
      />
      <button
        type='button'
        className={cn(style.slider__dot, {
          [style.slider__dot__active]: rangeAmount >= 50,
        })}
        style={{ left: '50%', transform: 'translateX(-6px)' }}
        onClick={() => handleRangeButtonClick(50)}
      />
      <button
        type='button'
        className={cn(style.slider__dot, {
          [style.slider__dot__active]: rangeAmount >= 75,
        })}
        style={{ left: '75%', transform: 'translateX(-8px)' }}
        onClick={() => handleRangeButtonClick(75)}
      />
      <button
        type='button'
        className={cn(style.slider__dot, {
          [style.slider__dot__active]: rangeAmount === 100,
        })}
        style={{ left: `100%`, transform: 'translateX(-12px)' }}
        onClick={() => handleRangeButtonClick(100)}
      />
    </div>
  );
};

export default observer(Slider);
