import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import style from './GeneralInformationTop.module.scss';
import date from '../../../../../../../util/date';
import TitleNumberFormat from '../../../../../../ui/TitleNumberFormat/TitleNumberFormat';
import { OPERATION } from '../../../../../../../constant/commonConstants';
// eslint-disable-next-line max-len
import { ReactComponent as ArrRight } from '../../../../../../../assets/image/common/arrowTurnRight.svg';
import string from '../../../../../../../util/string';
import { useRootModel } from '../../../../../../../models/RootStore';
import BalanceFormat from '../../../../../../BalanceFormat/BalanceFormat';

const GeneralInformationTop = () => {
  const { t } = useTranslation();
  const {
    settings: { language },
    finance: {
      activeTrades: { activeTrade },
    },
  } = useRootModel();

  const {
    openedAt,
    openingPrice,
    amount,
    multiplier,
    profit,
    profitPercent,

    operation,
  } = activeTrade;
  return (
    <div className={style.container}>
      <div>
        <div className={style.item}>
          <p className={style.title}>{t('opening_date')}</p>
          <p>{date.toLocaleString(openedAt, language)}</p>
        </div>
        <TitleNumberFormat title={t('opening_price')} value={openingPrice} className={style.item} />
        <TitleNumberFormat
          title={t('operation')}
          value={amount}
          className={style.item}
          textBeforeValue={t(operation === OPERATION.buy ? 'to_buy' : 'to_sell')}
          options={{ money: true }}
        />
        <TitleNumberFormat
          title={t('multiplier')}
          value={multiplier}
          textBeforeValue='×'
          formatted={false}
          className={cn(style.item, style.itemMultiplayer)}
        />
        <div className={style.itemSecondary}>
          <ArrRight />
          <p>{t('added_toward_reaching')}</p>
        </div>
      </div>
      <div className={style.item}>
        <p className={style.title}>Profit</p>
        <div className={style.price}>
          <div className={cn({ [style.textRed]: profit < 0, [style.textGreen]: profit > 0 })}>
            <BalanceFormat value={profit} />
          </div>
          <span className={style.equally}>=</span>
          <span
            className={cn({
              [style.textRed]: profitPercent < 0,
              [style.textGreen]: profitPercent > 0,
            })}
          >
            {string.formatPercent(profitPercent)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default observer(GeneralInformationTop);
