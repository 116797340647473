import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useRootModel } from '../../../models/RootStore';

import style from './ActiveTrades.module.scss';
import { ReactComponent as Search } from '../../../assets/image/common/searchIcon.svg';
import Dropdown from '../../../components/ui/Dropdown/Dropdown';
import { sortByQuantity } from '../../../constant/commonConstants';
import ActiveTradesContent from './ActiveTradesContent/ActiveTradesContent';
import ActiveTradesFooter from './ActiveTradesFooter/ActiveTradesFooter';
import Pagination from '../../../components/ui/Pagination/Pagination';
// eslint-disable-next-line max-len
import ActiveTradesDropdownDesktop from '../../../components/ActiveTradesDropdownDesktop/ActiveTradesDropdownDesktop';
import useWindowSize from '../../../hooks/useWindowSize';

/**
 * Represents active trades component
 * @returns {JSX.Element}
 */

const ActiveTrades = () => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const {
    finance: {
      activeTrades: {
        filteredWithSearchAndPagination,
        filteredWithSearch,
        page,
        pageSize,
        setPage,
        setPageSize,
        setName,
        quantity,
      },
    },
  } = useRootModel();
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (e) => {
    setPage(1);
    setSearchValue(e.target.value);
    setName(e.target.value);
  };

  const handleChangeSortByQuantity = (quant) => {
    setPageSize(quant);
  };

  const handleChangePage = ({ currentPage }) => {
    setPage(currentPage);
  };

  return (
    <div className={style.activeTrades}>
      <div className={style.wrapper}>
        <div className={style.titleContainer}>
          <p className={style.title}>{t('active_orders')}</p>
          <p className={style.notifications}>{quantity}</p>
          <form className={style.search}>
            <span className={style.underline} />
            <i className={style.image}>
              <Search />
            </i>
            <input
              type='text'
              className={style.input}
              value={searchValue}
              onChange={handleChange}
            />
          </form>
        </div>

        <div className={style.buttonGroup}>
          <div className={style.options}>
            <p className={style.optionTitle}>{t('show')}</p>
            <Dropdown
              onChange={handleChangeSortByQuantity}
              className={style.entries}
              contentClassName={style.content}
              value={pageSize}
              currentItemWrapperClassName={style.currentItemWrapperClassName}
            >
              {sortByQuantity}
            </Dropdown>
          </div>
          <ActiveTradesDropdownDesktop />
        </div>
      </div>
      <ActiveTradesContent
        items={isDesktop ? filteredWithSearchAndPagination : filteredWithSearch}
      />
      <ActiveTradesFooter />
      {isDesktop && (
        <Pagination
          totalRecords={quantity}
          pageLimit={pageSize}
          pageNeighbours={2}
          wrapperClassName={style.pagination}
          onPageChanged={handleChangePage}
          currentPage={page}
        />
      )}
    </div>
  );
};

export default observer(ActiveTrades);
