const EN = {
  email: 'Email',
  password: 'Password',
  login: 'Login',
  registration_button: 'Registration',
  forgot: 'Forgot password?',
  register: 'Register',
  verification: 'Verification code has been sent to your phone number',
  code: 'Code',
  verify: 'Verify',
  send_password: 'Send password',
  submit_email: 'Submit the email you signed up with to reset your password',
  back: 'Back',
  enter_email: 'Enter email address',
  confirmation: 'A confirmation email was sent to the email address you specified.',
  confirm_email: 'Please confirm your email.',
  okay: 'Okay',
  wrong_password: 'Error: Wrong password',
  hot: 'Hot',
  markets: 'Markets',
  wallet: 'Wallet',
  orders: 'Orders',
  main: 'Main',
  trades: 'Trades',
  favorites: 'Favorites',
  balance: 'Balance',
  profile: 'Profile',
  support: 'Support',
  all_instruments: 'All instruments',
  deposit: 'Deposit',
  popular: 'Popular',
  rising: 'Top rising',
  falling: 'Top falling',
  volatility: 'Top volatility (1 day)',
  uprising_trend: 'Uprising trend 30d',
  falling_trend: 'Falling trend 30d',
  commodities: 'Commodities',
  crypto: 'Crypto',
  indices: 'Indices',
  oil: 'Oil and gas',
  metals: 'Metals',
  shares: 'Shares',
  currencies: 'Currencies',
  agriculture: 'Agriculture',
  etfs: 'ETFs',
  update: 'Update Demo Balance',
  switch_real: 'Switch to real account',
  switch_demo: 'Switch to demo account',
  demo_account: 'Demo Account',
  real_account: 'Real Account',
  my_balance: 'My Balance',
  delete: 'Delete item',
  delete_order: 'Delete order',
  mark: 'Mark all as read',
  show: 'Show entries',
  notifications: 'Notifications',
  profit: 'Profit',
  used: 'Used',
  available: 'Available',
  active_trades: 'Active trades',
  active_orders: 'Active orders',
  pending_orders: 'Pending orders',
  closed_orders: 'Closed orders',
  instrument: 'Instrument',
  price: 'Price',
  increase: 'Increase',
  info: 'More info',
  close_trade: 'Close trade',
  closed_trades: 'Closed trades',
  name: 'Name',
  high: 'High',
  low: 'Low',
  price_change: 'Price Change',
  week: 'Week',
  sentiment: 'Trader sentiment',
  full: 'Full screen',
  latest_news: 'Latest News',
  column: 'Show: 1 column',
  quick_news: 'Quick News',
  center: 'Trading Center',
  open: 'Open trade',
  depth: 'Depth',
  close_profitable: 'Close all profitable orders',
  close_loss: 'Close all loss orders',
  close_all: 'Close all orders',
  trades_sm: 'trades',
  search: 'Search for instruments',
  typing: 'Start typing here...For example "Gold" or "BTC"',
  suggested: 'SUGGESTED INSTRUMENTS',
  results: 'RESULTS',
  not_found: 'Unfortunately, we have not found anything',
  analysis: 'Technical Analysis',
  now: 'Now',
  at_price: 'At the price',
  log: 'log',
  trade_amount: 'Trade amount',
  multiplier: 'Multiplier',
  limit: 'Limit profits and loss',
  sell: 'Sell',
  buy: 'Buy',
  bid: 'Bid',
  ask: 'Ask',
  spread: 'Spread',
  commission: 'commission',
  opening_date: 'Opening date',
  opening_price: 'Opening price',
  operation: 'Operation',
  operation_type: 'Operation type',
  to_buy: 'to buy',
  to_sell: 'to sell',
  cancel: 'Cancel',
  close: 'Close',
  accruals: 'Commissions and accruals',
  operations: 'For making operations',
  market_tab: 'Market',
  market: 'American trade market commission',
  total: 'Total',
  not_available: 'This action is currently not available',
  inaccessible:
    'Unfortunately, the trading stock is currently inaccessible.' +
    ' You cannot open new trades right now. Please try again later.',
  payment: 'Payment method',
  card: 'Debit or Credit card',
  amount: 'Amount',
  bonus: 'Bonus',
  card_number: 'Card number',
  card_name: 'Name on card',
  name_surname: 'NAME SURNAME',
  expiration: 'Expiration date',
  mm: 'MM/YY',
  cvv: 'CVV/CVC',
  compensated: 'Compensated with bonus funds. Holcomb Finance Limited (1087 Nicosia Cyprus)',
  verified_visa: 'Verified by VISA',
  secure: 'Mastercard Secure Code',
  troubles: 'Having troubles with depositing? Please contact',
  away: "You've been away for too long.",
  suspended: 'The session has been suspended due to inactivity.',
  upload_passport: 'Upload a scan of your passport',
  need_verified:
    'As required by current legislation, you cannot start trading until' +
    ' you verify your identity and verify your profile with a scan of your passport.',
  passport: 'Passport',
  number_visible: 'The number must be clearly visible',
  delete_file: 'Delete file',
  upload_another: 'Upload another',
  registration: 'Page with registration',
  address: 'Address',
  drag: 'Drag and drop file or upload file from your PC',
  new_passport: 'I have a new passport (Load scan of previous document)',
  documents: 'Documents',
  documents_added: 'Documents added successfully',
  successfully_upload:
    'You have successfully uploaded your documents. ' +
    'Our manager will review and process them as soon as possible. ' +
    'As soon as he approves the documents, you will start trading.',
  open_trade: 'Open trade position',
  active: 'Active',
  pending: 'Pending',
  closed: 'Closed',
  flow: 'Flow of funds',
  date: 'Date',
  month: 'Month',
  quarter: 'Quarter',
  all_time: 'All time',
  used_funds: 'Used funds',
  result: 'Result',
  all: 'All',
  increase_trade: 'Increase trade amount',
  invest: 'Invest current profit',
  information: 'Information on the trade',
  about: 'About company',
  trading_instruments: 'Trading instruments',
  news: 'News and Signals',
  faq: 'FAQ',
  terms: 'Terms and conditions of use',
  mobile: 'Mobile Applications',
  movement: 'Strength of movement',
  half: 'Half year',
  year: 'Year',
  favorite: 'Favorite instruments',
  for_day: 'For Day',
  for_week: 'For Week',
  for_year: 'For Year',
  for_month: 'For Month',
  remove: 'Remove from Favorites',
  deposit_money: 'Deposit money',
  withdraw_money: 'Withdraw money',
  personal_balance: 'Personal balance',
  payment_history: 'Payment history',
  surname: 'Surname',
  birth: 'Date of birth',
  day: 'Day',
  phone: 'Phone number',
  number: 'Number',
  request: 'Send request',
  contact:
    'After sending a request, our managers process the information' +
    ' and will contact you as soon as possible.',
  current_balance: 'Current balance',
  bonus_balance: 'Bonus balance',
  other_balance: 'Other balance',
  hide: 'Hide low value assets',
  overall: 'Overall',
  order: 'In order',
  actions: 'Actions',
  send_request: 'Send request to withdraw',
  id: 'ID',
  account: 'Account',
  method: 'Payment method',
  deposits: 'Deposits',
  withdrawal: 'Withdrawal',
  exchange: 'Exchange rate',
  status: 'Status',
  history: 'History',
  completed: 'Completed',
  declined: 'Declined',
  failed: 'Failed',
  verified: 'Verified',
  not_verified: 'Not verified',
  verify_phone: 'Verify phone number',
  settings: 'Password settings',
  current_password: 'Current password',
  edit: 'Edit',
  country: 'Country',
  city: 'City',
  passport_id: 'Passport/ID number',
  save_changes: 'Save changes',
  general: 'General',
  personal_details: 'Personal details',
  sessions: 'Active sessions',
  security: 'Security',
  terminated: 'After pressing the button, all sessions except the current one will be terminated.',
  logout: 'Logout',
  logout_from_all: 'Log out from all the devices except current',
  two_factor: 'Two Factor Authorization',
  fa: '2FA Status: Disabled',
  deals: 'Highlight deals at risk',
  enabled: 'Enabled',
  alerts: 'Trade alerts',
  margin_call: 'Margin call',
  error_mail: 'Current email already exists',
  email_verification: 'Email verification',
  code_sent_email:
    'The code has been sent to the email address you specified. Please enter it below',
  enter_code: 'Enter code here',
  code_again: 'Send me code again in ',
  send_me_code_again: 'Send me code again',
  change_email: 'Change email address',
  specified:
    'To change your e-mail address, you must enter the password specified during registration ',
  change: 'Change',
  enable_2fa: 'To enable two-factor authentication, confirm phone number',
  code_sent_number: 'The code has been sent to the number you specified. Please enter it below.',
  change_number: 'Change number',
  invalid: 'Invalid code: 2 attempts left',
  attempt: 'You have tried every possible attempt, but the code does not match what we submited.',
  submit_later: 'Submit the code later',
  error_505: 'Error 505: HTTP VERSION NOT SUPPORTED ',
  warning: 'Warning: HTTP VERSION NOT SUPPORTED ',
  successful: 'Successful operation',
  authorization: 'Authorization',
  find: 'Find',
  light: 'Light mode',
  dark: 'Dark mode',
  start: 'The Better place to start out the Trade.',
  enter_password: 'Enter password',
  wrong: 'Something went wrong!',
  refresh: 'Try to refresh the page.',
  verify_email: 'Verify email',
  new_password: 'New password',
  confirm_password: 'Confirm password',
  confirm_new: 'Confirm new password',
  send_code: 'Send code to change phone number',
  send_code_again: 'Send code again',
  change_phone: 'Change phone number',
  instruments: 'Instruments',
  total_week: 'Total for week',
  total_day: 'Total for day',
  close_out: 'Close out',
  pending_trades: 'Pending trades',
  conditions: 'Execution conditions',
  profit_limit: 'Profit limit',
  loss_limit: 'Loss limit',
  profit_loss_limit: 'Profit/Loss limit',
  no_trade: 'No trade found',
  search_mob: 'Search',
  download: 'Download',
  open_price_and_date: 'Opening price & Date',
  close_price_and_date: 'Closing price & Date',
  closing_date: 'Closing date',
  closing_price: 'Closing price',
  amount_of_rate: 'Amount of rate',
  trade_profit: 'Trade info / Profit',
  details: 'Details',
  open_again: 'Open up again',
  increase_investment: 'Increase investment for',
  verification_send: 'Verification code has been sent to your phone.',
  daily: 'Price/Daily change',
  reset: 'Reset password',
  customer_support: 'customer support',
  available_funds: 'Available funds',
  transfer: 'Transfer',
  how_reset: 'How do I reset my password?',
  after_change: 'What happens after you change your password?',
  general_info: 'General information',
  sms: 'SMS Code',
  resend: 'Resend the code',
  in: 'in',
  will_receive: 'You will receive the SMS within 3 minutes.',
  security_codes: 'SMS and email message with security codes have been sent to you.',
  try_again: 'Please try again in 1 hour.',
  disabled: 'Disabled',
  other_settings: 'Other settings',
  categories_currencies: 'Categories and currencies',
  rights: 'All rights reserved',
  chart: 'Chart',
  down: 'down',
  up: 'up',
  commissions: 'Commissions',
  deposit_success: 'You have successfully replenished your balance, now you can open trades.',
  enter_sent_code: 'Please enter the code sent to you by email.',
  code_sent_to: 'The code has been sent to the',
  enter_below: 'Please enter it below.',
  email_code: 'Email Code',
  loss: 'Stop loss',
  take_profit: 'Take profit',
  multiplied_sum: 'Multiplied sum',
  set: 'Set',
  add: 'Add',
  general_information: 'General information',
  commissions_and_reports: 'Commissions and reports',
  auto_increase: 'Auto-increase ',
  close_trade_position: 'Close trade positions',
  new_trade_position: 'New trade position',
  for_swap: 'For a swap',
  trade_amount_will_increased:
    'Trade amount will be increased automatically by 50% when margin call occurs.',
  trade_number: 'Trade number',
  trade_history: 'Trade history',
  added_toward_reaching: 'Added toward reaching',
  commission_for_increasing: 'Commissions for increase trade amount',
  you_can_increase: 'You can increase your current trade position size without closing it out to:',
  increase_speed: '1) Increase your earnings speed.',
  avoid_loss: '2) Avoid automatic trade close out, upon reaching maximum loss.',
  increasing_your_trade:
    'By increasing your trade size and avoiding automatic close out,' +
    ' you will fully control your trading process.',
  save_phone: 'Save phone number',
  sms_sent: 'SMS message with security code has been sent to you.',
  you_will_receive_sms: 'You will receive the SMS within 3 minutes.',
  continuing_verification_number:
    'By continuing you will receive a one-time verification code to your phone number by SMS. ' +
    'Message and data rates may apply',
  commissions_for_operation: 'Commissions for the operation',
  set_limits: 'Set limits',
  open_investment: 'Open an investment',
  trade_equity: 'Trade equity',
  display_rollover: 'Display the accruals for rollover',
  show_for: 'Show for',
  set_trade_renewal: 'Set trade renewal for a new contract',
  swap_amount: 'Swap accrual amount',
  cant_find_answer: "Can't find an answer to your question?",
  submit_request: 'Submit a request',
  contact_client_department: "Contacts of the Client's Department",
  subject: 'Subject',
  description: 'Description',
  submit: 'Submit',
  type: 'Type',
  attach_file: 'Attach file',
  type_here: 'Type here...',
  select: 'Select...',
  email_saved: 'Your email address has been successfully saved',
  personal_data_saved: 'Your personal data has been successfully saved',
  password_changed: 'Your password has been successfully changed',
  phone_changed: 'Your phone has been successfully changed',
  logout_from_account: 'Logout from account',
  upload_documents: 'Upload documents',
  upload_documents_title: 'Upload a scan of your passport',
  upload_description:
    'As required by current legislation, you cannot start trading until you verify ' +
    'your identity and verify your profile with a scan of your passport.',
  passport_first_page: 'First page of passport',
  passport_description: 'The number must be clearly visible',
  passport_first_page_image: 'Image first page of passport',
  passport_registration_page: 'Page with registration',
  passport_registration_page_description: 'The address information',
  passport_registration_page_image: 'Image registration page of passport',
  passport_new_load: 'I have a new passport (Load scan of previous document)',
  additional_photo: 'Additional photo',
  upload_new: 'Upload new',
  upload_file: 'Upload file',
  edit_files: 'Edit files',
  photo_of_passport_or_other_document: 'Photo of passport or other ID',
  documents_uploaded: 'Documents have been uploaded',
  documents_uploaded_successfully: 'Documents have been uploaded successfully',
  documents_upload_info: 'The file must be in png/jpg format, size up to 10 MB',
  payments_methods: 'Payments methods',
  go_home: 'Go to Homepage',
  broken: 'Oops! Something is broken.',
  sorry: 'Sorry, we can’t find the page you were looking for.',
  404: 'Error: 404',
  documents_progress: 'In processing',
  documents_approved: 'Approved',
  documents_rejected: 'Rejected',
  documents_expired: 'Expired',
  500: 'Internal server error',
  '0101': 'Bad credentials',
  '0102': 'Full authentication is required to access this resource',
  '0103': 'Forbidden',
  '0104': 'Maximum upload size exceeded',
  '01O5': 'Argument is not valid',
  GT0201: 'Login is already in use',
  GT0202: 'Email is already in use',
  GT0203: 'Phone number is already in use',
  GT0204: 'Phone number should be verified',
  GT0205: 'Email should be confirmed',
  GT0206: "Confirmation code temporarily can't be sent. Try in 1 hour.",
  GT0207: 'Confirmation code attempts exceeded',
  GT0208: "Confirmation code temporarily can't be approved. Try in 3 minutes",
  GT0209: 'Confirmation code is wrong',
  GT0210: 'Confirmation code expired',
  GT0211: 'Confirmation token is wrong',
  GT0212: 'Confirmation token expired',
  GT0213: 'Reset key not found',
  GT0214: 'Reset key expired',
  GT0215: "Trader hasn't specified a phone number yet",
  GT0216: "Closed ticket can't be updated",
  GT0217: 'User not found',
  GT0218: 'Principal user not found',
  GT0219: "Verification SMS temporarily can't be sent. Try in 1 minute",
  GT0231: 'Account blocked',
  NT0201: 'Template name is already in use',
  NT0202: 'Template with required params already exists',
  NT0203: "Template can't be deleted",
  FN0218: 'Instrument is unavailable due to non-trading hours',
  mark_all_read: 'Mark all as read',
  delete_selected_notifications: 'Delete selected notifications',
  atPrice: 'At price',
  open_new: 'Open new',
  cancel_order: 'Cancel order',
  set_as_active: 'Set as active',
  total_month: 'Total for month',
  total_year: 'Total for year',
  total_all_time: 'Total for all time',
  open_trade_position: 'Open trade position',
  need_to_verify_mail: 'First, you need to verify your mail.',
  select_all: 'Select all',
  yes_delete: 'Yes, delete',
  order_deleted: 'Order was successfully deleted',
  trade_open: 'Trade was successfully open',
  save: 'Save',
  order_increased: 'Order was successfully increased',
  order_set_active: 'Order set as active',
  order_canceled: 'Order was successfully canceled',
  order_closed: 'Order was successfully closed',
  write_message_here: 'Write message here...',
  you: 'You',
  trade_increase: 'Increase trade',
  request_sent: 'Request sent',
  changes_saved: 'Changes saved',
  manager: 'Manager',
  sort_by: 'Sort by',
  show_less: 'Show less',
  show_more: 'Show more',
  popular_instruments: 'Popular instruments',
  close_out_trade_position: 'Close out trade position',
  status_info: 'Status info',
  footer_text_line_first:
    'Risk Warning: CFDs are complex instruments and come with a high risk of losing money' +
    'rapidly due to leverage. 74% of retail investor accounts lose money when trading ' +
    'CFDs with this provider.',
  footer_text_line_second:
    'You should consider whether you understand how CFDs work ' +
    'and whether you can afford to take the high risk of losing your money.',
  footer_text_line_third:
    'The value of an investment in stocks and shares can fall as well as rise, ' +
    'so you may get back less than you invested. Past performance is no guarantee of ' +
    'future results.',
  footer_text_line_fourth:
    'Tax treatment depends on your individual circumstances and may be subject to change.',
  footer_text_line_fifth:
    'The information on this site is not directed at residents ' +
    'of the United States and Canada, and is not intended for distribution to, ' +
    'or use by, any person in any country or jurisdiction ' +
    'where such distribution or use would be contrary to local law or regulation.',
  footer_text_line_sixth: 'i88 is a trading platform used by ',
  footer_copyrights: 'Secured By SSL. Copyright © ',
  footer_copyrights_powered_by: 'Powered by ',
  eCache_investment: ' ECash Investment Ltd. ',
  team_88: '88 team.',
  currency: 'Currency',
  copied: 'Copied',
  withdraw: 'Withdraw',
  withdraw_text:
    'After sending a request, our managers process the information ' +
    'and will contact you as soon as possible.',
  affiliate_program: 'Affiliate program',
  invite_friends: 'Invite friends',
  share_link: 'Share the link - Get 15% reward',
  share_text:
    'Share the link and get 15% of the trade costs paid by a friend. The more friends you' +
    'invite, the more rewards you will receive.',
  copy: 'Copy',
  program_rules: 'Programm Rules “Invite a friend"',
  rules_participation: 'Rules of participation in the “Refer a Friend” programme',
  share_the_link: 'Share the link → Get 15% reward',
  share_text_rules:
    'Share the link and get 15% of trading costs paid by people you refer. The more friends you ' +
    'invite the more reward you earn. Rules of participation',
  get_rewards: 'How to get rewards',
  get_rewards_answer:
    'Programme participants receive 15% of the trading costs paid by the clients who register ' +
    'using the participant’s personalised link. For what period of my referrals’ trading ' +
    'activity will my payment be calculated? If the new user registers using your link, you ' +
    'will earn and receive payment for six months from the moment the referral first opens a ' +
    'trade. How many friends can you refer as part of the “Refer a Friend” programme? There is ' +
    'no restriction on the number of friends you are allowed to refer.',
  when_receive: 'When will I receive payment?',
  when_receive_answer:
    'Any payment due shall be credited to the referring client’s bonus account on the first day ' +
    'of the calendar month that follows the month when the friends’ trading activity took ' +
    'place. You can set up the automatic transfer of these funds to your trading account or ' +
    'save them to your bonus account.',
  how_find: ' How do I find out how much I will be paid for my participation in the programme?',
  how_find_answer:
    'To find out how much you will be paid for your participation in the “Refer a Friend” ' +
    'programme in the current month, simply check the “Refer a Friend” tab on the Libertex ' +
    'platform. This tab details the amount of reward you are due to be paid on the first day of ' +
    'the next month.',
  how_update:
    'How frequently do you update the information on my personal account regarding the amount ' +
    'of reward due?',
  we_update: 'We update information on the reward amount due once every 24 hours at 21:00 GMT.',
  further_provisions: 'Further provisions:',
  how_update_first:
    'The Company reserves the right to change the terms of the programme at any time.',
  how_update_second:
    'The Company reserves the right to exclude any client’s account from participation in ' +
    'the programme, without explanation, and to remove any reward already earned by that ' +
    'client.',
  how_update_third:
    'A single client may not create multiple accounts to participate in the programme. When ' +
    'it is discovered that one client has created several accounts with the aim of ' +
    'participating in the programme, that client shall be immediately disqualified from the ' +
    '“Refer a Friend” programme and all rewards earned by that client shall be ' +
    'automatically forfeited.',
  max_upper: 'Max',
  objectives_and_vision: 'Objectives & Vision',
  loyalty_statuses: 'Loyalty statuses',
  status_program_rules: 'Status program rules',
  note: "Please note: Our Loyalty Program doesn't cover i88 Portfolio accounts",
  current_trading_balance: 'Current trading balance',
  current_trading_volume: 'Current trading volume',
  analytical_support: 'Analytical support in terminal',
  hours_support: '24 hours support',
  multiplier_change: 'Multiplier change for open deals',
  premium_signals: 'Premium trading signals',
  vip_manager: 'VIP manager (premium support)',
  individual_conditions: 'Individual trading conditions',
  trade: 'Trade',
  status_active: 'Status active',
  status_locked: 'Status locked',
  silver: 'Silver',
  gold: 'Gold',
  platinum: 'Platinum',
  diamond: 'Diamond',
  vip: 'V.I.P',
  balance_required: 'Current trading balance required',
  volume_required: 'Current trading volume required',
  required_balance: 'Required balance',
  required_volume: 'Required volume ',
  loyalty_rules: 'Loyalty program rules and  conditions',
  loyalty_program:
    'The loyalty program was developed as a way of providing more favourable terms for clients ' +
    'with elevated trading activity and high trading volume. The specific terms enjoyed ' +
    "shall be determined on the basis of the Client's individual status.",
  how_to_quality: 'How to qualify for a given status',
  status_determined: 'Client status shall be determined on the following basis:',
  status_determined_first: "The total available balance of the Client's accounts",
  status_determined_second: "The Client's trading volume for the relevant calendar month",
  status_qualifies: 'The Client shall receive the status for which he or she qualifies:',
  status_qualifies_first: 'After depositing funds into his or her account or',
  status_qualifies_second: 'At 21:00 GMT on any day of the month',
  status_qualifies_text:
    'The account balance and trading volume thresholds ' +
    'for the various statuses are given in the i88',
  validity_period: 'Validity period of statuses',
  validity_period_title_first:
    'Any status awarded to the Client shall be valid until the end of the calendar month ' +
    'following the month in which such status was acquired.',
  validity_period_title_second:
    'On the last calendar day of the month following the month in which the status was awarded, ' +
    'such status may be extended, upgraded or downgraded depending on:',
  validity_period_first: "The total available balance of the client's accounts or",
  validity_period_second: "The client's trading volume for the current calendar month",
  validity_period_text: 'The active status of the Client as shown in the i88',
  current_volume: 'Current volume',
  qualify: 'To qualify for',
  meet_conditions: 'you must meet the following conditions',
  file_load_error: 'You can upload no more than 5 files up to 5mb',
  flat_view: 'Fiat view',
  hide_balance: 'Hide 0 balance',
  account_settings: 'Account settings',
  level: 'Level',
  my_bonuses: 'My bonuses',
  wallet_changed: 'Wallet changed',
  learn_more: 'Learn More',
  my_profile: 'My Profile',
  total_deposited: 'Total Deposited',
  created: 'Created',
  last_deposit: 'Last Deposit',
  previous: 'Previous',
  next: 'Next',
  replenish_balance: 'Please, replenish the balance ',
  withdraw_request: 'Request on withdraw money accepted',
  exchange_money: 'Exchange money',
  cryptocurrency_rate: 'Cryptocurrency Rate',
  exchange_to: 'Exchange to',
  amount_to_exchange: 'Amount to exchange',
  pre_calculated_amount: 'Pre-calculated amount',
  exchange_success: 'Money successful exchanged',
  payment_type: 'Payment type',
  address_small: 'address',
  min: 'Min',
  max: 'Max',
  your: 'Your',
  deposit_address: 'deposit address',
  only_send: 'Only send',
  deposit_confirmation: 'to this address, 3 confirmation(s) required.',
  withdraw_all: 'Withdraw all',
  total_balance: 'Total Balance',
  no_invite: 'You have no invited friends yet',
  session_stop: 'Your session has been suspended due to inactivity.',
  continue: 'Continue',
  withdraw_success: 'All bonuses withdrawn successfully',
  trade_balance: 'Trade balance',
  convert: 'Convert',
  quantity: 'Quantity',
  no_extra_fees: 'No extra fees',
  demo: 'DEMO',
  verification_menu: 'Verification',
  trade_type: 'Trade type',
  trade_sum: 'Trade sum',
  open_price: 'Open price',
  close_price: 'Close price',
  open_date: 'Open date',
  close_date: 'Close date',
  close_trade_commission: 'Close trade commission',
  open_trade_commission: 'Open trade commission',
  verification_title: 'Verification',
  select_or_type: 'Select or type',
  fa_status: '2FA Status',
  save_limits: 'Save limits',
  main_platform_currency: 'Main platform currency',
  clear_all: 'Clear all',
  no_notifications: 'You have no notifications',
  sell_small: 'sell',
  buy_small: 'buy',
  left: 'left',
};

export default EN;
