import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './MainTable.module.scss';
import { useRootModel } from '../../../../models/RootStore';
import MainTableItem from './MainTableItem/MainTableItem';
import { ReactComponent as EmptyBox } from '../../../../assets/image/common/emptyBox.svg';

/**
 * Represents Main page table in Mobile version
 * @returns {JSX.Element}
 */

const MainTable = ({ activeTab }) => {
  const { t } = useTranslation();
  const {
    finance: {
      itemsMain,
      getUpdateDataMain,
      closeDataStreamMain,
      term,
      favoriteTrades: { items },
    },
  } = useRootModel();

  const currentItems = useMemo(() => {
    return activeTab || term ? itemsMain : items;
  }, [activeTab, items, itemsMain, term]);

  useEffect(() => {
    getUpdateDataMain();

    return () => {
      closeDataStreamMain();
    };
  }, [closeDataStreamMain, getUpdateDataMain]);

  return (
    <div>
      <div className={style.header}>
        <p className={style.headerTitle}>{t('name')}</p>
        <p className={style.headerTitle}>{t('daily')}</p>
      </div>
      <div style={{ paddingTop: '4px' }}>
        {currentItems.length > 0 ? (
          currentItems.map(({ alias: title, s, lp: price, cpd: day, oldPrice, type }) => {
            return (
              <MainTableItem
                key={s}
                title={title}
                s={s}
                price={price}
                day={day}
                oldPrice={oldPrice}
                type={type}
              />
            );
          })
        ) : (
          <div className={style.block}>
            <EmptyBox />
            <p className={style.block__text}>No items found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(MainTable);
