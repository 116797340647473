import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './CloseTradeContent.module.scss';
import AlertContext from '../../../../../context/alert/alertContext';

import useWindowSize from '../../../../../hooks/useWindowSize';
import Button from '../../../../ui/Button/Button';
import CloseTrade from './CloseTrade/CloseTrade';
import Commissions from './Commissions/Commissions';
import TabsForModal from '../../../../ui/TabsForModal/TabsForModal';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';
import TVChartForModal from '../../../../TVChartForModal/TVChartForModal';
import { useRootModel } from '../../../../../models/RootStore';

/**
 * Represents Close Trade content after click on Close Trade button
 * @param {function} handleClose - handle close Modal button
 * @returns {JSX.Element}
 */
const CloseTradeContent = ({ handleClose }) => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const { isDesktop } = useWindowSize();
  const {
    finance: {
      activeTrades: { activeTrade, getActiveTrades },
      trade: { closeTrade },
    },
    modal: {
      closeTrade: { close },
    },
  } = useRootModel();

  const tabs = [
    {
      id: 1,
      tabLabel: t('close_trade'),
      component: CloseTrade,
    },
    {
      id: 2,
      tabLabel: t('commissions'),
      component: Commissions,
    },
  ];

  const { id } = activeTrade;

  const handleCloseTrade = async () => {
    try {
      await closeTrade(id);
      alert.show(t('order_closed'), 'success');
      close();
      getActiveTrades();
    } catch (err) {
      alert.show(err);
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.tvChart}>
        <TVChartForModal item={activeTrade.symbol} />
      </div>
      <div className={style.container}>
        {isDesktop && (
          <button type='button' onClick={handleClose} className={style.closeButton}>
            <CloseIcon />
          </button>
        )}
        <TabsForModal tabs={tabs} />
        <div className={style.buttonContainer}>
          <Button text={t('cancel')} color='secondary' fontWeight='bold' onClick={handleClose} />
          <Button text={t('close')} color='primary' fontWeight='bold' onClick={handleCloseTrade} />
        </div>
      </div>
    </div>
  );
};

export default observer(CloseTradeContent);
