import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../../util/error';
import { sortByQuantity } from '../../../constant/commonConstants';
import PeriodForTables from '../../common/PeriodForTables';

const FlowOfFundsItemModel = types.model('FlowOfFundsItemModel', {
  amount: types.optional(types.number, 0),
  balance: types.optional(types.number, 0),
  date: types.optional(types.string, ''),
  operation: types.optional(types.string, ''),
  tradeCodeId: types.maybeNull(types.string),
});

const FlowOfFundsModel = types
  .model('FlowOfFundsModel', {
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.string, ''),
    items: types.array(FlowOfFundsItemModel),
    total: types.optional(types.number, 0),
    sortBy: types.optional(types.string, ''),
    sortDirection: types.optional(types.union(types.literal('asc'), types.literal('desc')), 'asc'),
    page: types.optional(types.number, 0),
    pageSize: types.optional(types.number, sortByQuantity[0]),
    period: PeriodForTables,
  })
  .actions((funds) => ({
    setIsLoading(loading) {
      funds.isLoading = loading;
    },
    setError(err) {
      funds.error = err;
    },
    setItems(data) {
      funds.items = data;
    },
    addItems(items) {
      funds.items = [...funds.items, ...items];
    },
    setSortBy(key) {
      funds.sortBy = key;
      funds.setPage(0);
    },
    setSortDirection(direction) {
      funds.sortDirection = direction;
    },
    switchSortDirection() {
      funds.sortDirection = funds.sortDirection === 'asc' ? 'desc' : 'asc';
    },
    setPage(page) {
      funds.page = page;
    },
    setPageSize(quantity) {
      funds.pageSize = parseInt(quantity, 10);
      funds.setPage(0);
    },
    setNextPage() {
      const { setPage, page, pageSize, isNextPage } = funds;
      if (page < pageSize && isNextPage) setPage(page + 1);
    },
    changePeriod(period) {
      funds.period = period;
      funds.setPage(0);
    },
    getFlowOfFunds: flow(function* getFlowOfFunds(isAddItems = false) {
      const {
        setIsLoading,
        setError,
        setItems,
        period,
        sortBy,
        sortDirection,
        page,
        pageSize: size,
        addItems,
      } = funds;
      setIsLoading(true);
      try {
        const params = {
          period,
          sort: `${sortBy},${sortDirection}`,
          page,
          size,
        };

        const { headers, data } = yield axios.get(`/services/trading/api/trades/flow-of-funds`, {
          params,
        });
        funds.total = parseInt(headers['x-total-count'], 10);
        if (isAddItems) {
          addItems(data);
        } else {
          setItems(data);
        }
      } catch (err) {
        const message = err.response?.data.errorCode || err.message;
        setError(message);
        error.errorHandler(message);
      } finally {
        setIsLoading(false);
      }
    }),
  }))
  .views((funds) => ({
    get totalProfit() {
      const { items } = funds;
      return items.reduce((acc, current) => acc + current.amount, 0);
    },
    get isNextPage() {
      const { page, total, pageSize } = funds;
      return page < total / pageSize;
    },
  }));

export default FlowOfFundsModel;
