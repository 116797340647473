import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';

import style from './PlatformCurrencyInput.module.scss';
import useOnClick from '../../hooks/useOnClick';
import { ReactComponent as ArrowUp } from '../../assets/image/common/arrowUpList.svg';
import { ReactComponent as ArrowDown } from '../../assets/image/common/arrowDownList.svg';
import { platformCurrency } from '../../constant/walletConstants';

/**
 * Represents Platform currency input component
 * @returns {JSX.Element}
 */

const PlatformCurrencyInput = ({ currency, setCurrency }) => {
  const [showContent, setShowContent] = useState(false);
  const ref = useRef();

  const handleOnClick = () => {
    setShowContent(!showContent);
  };

  const handleClick = (newCurrency) => {
    setCurrency(newCurrency);
    setShowContent(false);
  };

  useOnClick(ref, () => setShowContent(false));

  return (
    <div className={style.currencyContainer} ref={ref}>
      <button className={style.currencyButton} type='button' onClick={handleOnClick}>
        <div className={style.titleWrapper}>
          {currency.icon}
          <p>
            {currency.label}, <span className={style.label}>{currency.id}</span>
          </p>
        </div>
        {showContent ? <ArrowUp /> : <ArrowDown />}
      </button>
      <CSSTransition
        in={showContent}
        timeout={700}
        classNames={{
          enter: style.enter,
          enterActive: style.enterActive,
          enterDone: style.enterDone,
          exit: style.exit,
          exitActive: style.exitActive,
          exitDone: style.exitDone,
        }}
        unmountOnExit
      >
        <div className={style.currencyContent}>
          {platformCurrency.map((item) => (
            <button
              type='button'
              className={style.optionButton}
              key={item.id}
              onClick={() => handleClick(item)}
            >
              {item.icon}
              <p>
                {item.label}, <span className={style.label}>{item.id}</span>
              </p>
            </button>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default observer(PlatformCurrencyInput);
