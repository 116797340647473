import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRootModel } from '../../../models/RootStore';
import style from './PendingRightSide.module.scss';
import Badge from '../../ui/Badge/Badge';
import ButtonToggle from '../../ButtonToggle/ButtonToggle';
import PendingRightSideItem from './PendingRightSideItem/PendingRightSideItem';

/**
 * Represents a Pending orders block on right panel on Main Page.
 * @returns {JSX.Element}
 */
const PendingRightSide = () => {
  const { t } = useTranslation();
  const {
    finance: {
      pendingTrades: { getPendingTrades, items, allTradesAmount },
    },
  } = useRootModel();
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible((prev) => !prev);
  };

  const isTrades = !!allTradesAmount;

  useEffect(() => {
    getPendingTrades();
  }, [getPendingTrades]);

  return (
    items.length > 0 && (
      <div className={style.container}>
        <div className={style.titleWrapper}>
          <p className={style.titleWrapper__title}>{t('pending_orders')}</p>
          {isTrades && <Badge amount={allTradesAmount} className={style.notifications} />}
        </div>
        {!!items.length && (
          <div className={style.instrumentTitle}>
            <p>{t('instrument')}</p>
            <p className={style.atPrice}>{t('atPrice')}</p>
          </div>
        )}
        <div className={style.contentVisible}>
          {items.slice(0, 3).map(({ symbol, amount, pendingPrice, multiplier, operation, id }) => (
            <PendingRightSideItem
              key={id}
              symbol={symbol?.s}
              amount={amount}
              pendingPrice={pendingPrice}
              multiplier={multiplier}
              operation={operation}
              id={id}
            />
          ))}
        </div>
        <div className={cn(style.content, { [style.visible]: isVisible })}>
          {items
            .slice(3, items.length)
            .map(({ symbol, amount, pendingPrice, multiplier, operation, id }) => (
              <PendingRightSideItem
                key={id}
                symbol={symbol?.s}
                amount={amount}
                pendingPrice={pendingPrice}
                multiplier={multiplier}
                operation={operation}
                id={id}
              />
            ))}
        </div>
        {items.length > 3 && <ButtonToggle isVisible={isVisible} handleToggle={handleToggle} />}
      </div>
    )
  );
};

export default observer(PendingRightSide);
