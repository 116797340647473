import React, { useEffect, useRef } from 'react';

import style from './TwoFactorAuth.module.scss';
import useSendCodeState from '../../../../hooks/useSendCodeState';

import SecurityPhone from './TwoFactorAuthContent/TwoFactorAuthPhone';
import SecurityCode from './TwoFactorAuthContent/TwoFactorAuthCode';
import SecurityVerifiedError from './TwoFactorAuthContent/TwoFactorAuthVerifiedError';

/**
 * Represent mobile page for security Two Factor Authorization
 * @param {function} handleSendCode - send code handler
 * @param {function} showPhone - showPhone
 * @param {function} setShowPhone - setShowPhone
 * @param {function} handleVerifyPhoneConfirm - handleVerifyPhoneConfirm
 * @param {number} attemptsLeft - attemptsLeft
 * @returns {JSX.Element}
 */
const TwoFactorAuthMobile = ({
  handleSendCode,
  showPhone,
  setShowPhone,
  handleVerifyPhoneConfirm,
  attemptsLeft,
}) => {
  const wrapRef = useRef(null);

  useEffect(() => {
    wrapRef.current.scrollTop = 0;
  }, []);

  const { secondsRestoreAttempts, setSecondsRestoreAttempts } = useSendCodeState();

  useEffect(() => {
    if (attemptsLeft === 3) setShowPhone(true);
    else setShowPhone(false);
  }, [attemptsLeft]);

  const content = showPhone ? (
    <SecurityPhone onSubmit={handleSendCode} />
  ) : (
    <SecurityCode
      onSubmit={handleVerifyPhoneConfirm}
      setShowPhone={setShowPhone}
      sendCode={handleSendCode}
    />
  );

  return (
    <div className={style.mobile} ref={wrapRef}>
      {attemptsLeft === 0 ? (
        <SecurityVerifiedError
          handleChangeSeconds={setSecondsRestoreAttempts}
          seconds={secondsRestoreAttempts}
        />
      ) : (
        content
      )}
    </div>
  );
};

export default TwoFactorAuthMobile;
