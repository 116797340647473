import string from './string';

const HOUR = 1000 * 60 * 60;
const DAY = HOUR * 24;
const YEAR = DAY * 365;
const VALID_AGE = 18;

const DATE = new Date();
const CURRENT_YEAR = DATE.getFullYear();
const CURRENT_MONTH = DATE.getMonth();
const CURRENT_DAY = DATE.getDate();

/**
 * Function for checking is leap year
 * @param {number} [year] - year, default - current year
 * @returns {boolean}
 */
const isLeapYear = (year = CURRENT_YEAR) => new Date(year, 1, 29).getDate() === 29;

/**
 * Function for count how many leap years in range
 * @param {number} start - start year in range
 * @param {number} end - end year in `range
 * @returns {number}
 */
const countLeapYears = (start, end) => {
  const xStart = start < end ? start : end;
  const xEnd = start > end ? start : end;
  let result = 0;

  for (let i = xStart; i <= xEnd; i += 1) {
    if (isLeapYear(i)) {
      result += 1;
    }
  }
  return result;
};

/**
 * Returns
 * @param amount
 * @param startYear
 * @returns {number}
 */
const getDaysInYears = (amount, startYear = CURRENT_YEAR) => {
  const endYear = startYear - amount;
  const amountLeapYears = countLeapYears(startYear, endYear);
  return YEAR * amount + DAY * amountLeapYears;
};

const VALID_START_DATE = new Date(Date.now() - getDaysInYears(VALID_AGE));

/**
 * Returns amount of days in month
 * @param {number} [month] - month, default - current month
 * @param {number} [year] - year, default - current year
 * @returns {number}
 */
const getDaysAmount = (month = CURRENT_MONTH, year = CURRENT_YEAR) =>
  new Date(year, month + 1, 0).getDate();

/**
 * Returns array of days numbers
 * @param {number} [month] - month, default - current month
 * @param {number} [year] year, , default - current year
 * @returns {string[]}
 */
const getDays = (month = CURRENT_MONTH, year = CURRENT_YEAR) =>
  Array.from({ length: getDaysAmount(month, year) }, (_, idx) => String(idx + 1));

/**
 * Returns title of the month
 * @param {number} [month] - month, default - current month
 * @param {string} [locale] - locale, default - 'en'
 * @returns {string}
 */
const getMothTitle = (month = CURRENT_MONTH, locale = 'en') =>
  string.toTitleString(new Date(CURRENT_YEAR, month, 0).toLocaleString(locale, { month: 'long' }));

/**
 * Returns array of months titles
 * @param {string} [locale] - locale, default - 'en'
 * @returns {string[]}
 */
const getMonths = (locale = 'en') =>
  Array.from({ length: 12 }, (_, idx) => getMothTitle(idx + 1, locale));

/**
 * Returns array of years
 * @param {number} [length] - array length, default - 100
 * @param {number} [startYear] - start year, default - current year
 * @returns {string[]}
 */
const getYears = (length = 100, startYear = VALID_START_DATE.getFullYear()) =>
  Array.from({ length }, (_, idx) => String(startYear - idx));

const toLocaleString = (date, locale = 'en', full = true, options) => {
  if (!full)
    return options
      ? new Date(date).toLocaleString(locale, options)
      : new Date(date).toLocaleString(locale, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });
  return new Date(date).toLocaleString(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};
export default {
  HOUR,
  DAY,
  YEAR,
  VALID_AGE,
  VALID_START_DATE,
  currentDay: CURRENT_DAY,
  currentMonth: CURRENT_MONTH,
  currentYear: CURRENT_YEAR,
  getDaysAmount,
  getDays,
  getMothTitle,
  getMonths,
  getYears,
  countLeapYears,
  getDaysInYears,
  toLocaleString,
};
