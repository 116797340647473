import countries from '../assets/countries-codes-flags';

const defaultValue = {
  country: '',
  flag: '',
  dialCode: '',
};

const data = Object.entries(countries).map(([key, value]) => ({
  [key]: value,
}));

const getObjectKey = (obj) => (obj ? Object.keys(obj)[0] : '');
const getObjectData = (item) => (item ? item[getObjectKey(item)] : {});

/**
 * Returns country object
 * @param {string} code - code
 * @param {number} pos - search position
 * @returns {Country | null}
 */
const getCountryByPhone = (code, pos = 0) => {
  if (!code) return null;
  const c = code.slice(0, code.length - pos);
  const country = data.find((item) => {
    return getObjectData(item).dialCode === c;
  });

  if (!country && code.length > pos) {
    return getCountryByPhone(code, pos + 1);
  }

  return country;
};

/**
 * Returns country object
 * @param {string} countryName - countryName
 * @returns {Object}
 */
const getCountryByCountryName = (countryName) =>
  data.find((country) => getObjectData(country).country === countryName);

/**
 * Returns country object
 * @param {string} countryCode - countryCode
 * @returns {Object}
 */
const getCountryByCountryCode = (countryCode) =>
  data.find((country) => getObjectKey(country) === countryCode);

/**
 * Returns phone code
 * @param {string} phone
 * @returns {string}
 */
const getPhoneCode = (phone) => {
  const country = getCountryByPhone(phone);
  return country ? country[getObjectKey(country)].dialCode : '';
};

/**
 * Returns phone number without code
 * @param phone
 * @returns {string}
 */
const getPhoneNumber = (phone) => {
  const code = getPhoneCode(phone);
  return phone.slice(code.length);
};

/**
 * Returns filtered array of countries
 * @param {string} input
 * @param {('dialCode' | 'country')} contentToShow
 * @returns {Array<{key: Country}>}
 */
const filter = (input, contentToShow) =>
  data.filter((item) => {
    const key = getObjectKey(item);
    if (item[key]) {
      return item[key][contentToShow]?.toLowerCase().includes(input?.toLowerCase());
    }
    return null;
  });

export default {
  data,
  defaultValue,
  filter,
  getObjectKey,
  getObjectData,
  getPhoneCode,
  getPhoneNumber,
  getCountryByPhone,
  getCountryByCountryName,
  getCountryByCountryCode,
};
