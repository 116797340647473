import React from 'react';
import InstrumentDetails from './InstrumentDetails/InstrumentDetails';
import style from './Main.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import InstrumentsLeftSideBlock from './InstrumentsLeftSideBlock/InstrumentsLeftSideBlock';

/**
 * Represents Main page
 * @returns {JSX.Element}
 */
const Main = () => {
  const { isDesktop } = useWindowSize();

  return (
    <section className={style.filters}>
      {isDesktop && <InstrumentsLeftSideBlock />}
      <InstrumentDetails />
    </section>
  );
};

export default Main;
