import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './IncreaseTradeContent.module.scss';
import { useRootModel } from '../../../../../models/RootStore';
import { OPERATION } from '../../../../../constant/commonConstants';
import AlertContext from '../../../../../context/alert/alertContext';
import { ReactComponent as DownArrow } from '../../../../../assets/image/common/operationSell.svg';
import { ReactComponent as UpArrow } from '../../../../../assets/image/common/operationBuy.svg';
import Button from '../../../../ui/Button/Button';
import BalanceFormat from '../../../../BalanceFormat/BalanceFormat';
import TradeInput from '../../../../TradeInput/TradeInput';

/**
 * Represents Increase Trade content
 * @returns {JSX.Element}
 */
const IncreaseTradeContent = () => {
  const { t } = useTranslation();
  const alert = useContext(AlertContext);
  const {
    modal: {
      increaseTrade: { close },
    },
    finance: {
      activeTrades: {
        activeTrade: { id, operation, amount },
        getActiveTrades,
      },
      trade: { increase, increaseAmount },
    },
  } = useRootModel();

  const handleIncrease = async () => {
    try {
      await increase(id);
      alert.show(t('order_increased'), 'success');
      close();
      getActiveTrades();
    } catch (err) {
      alert.show(err.message, 'error');
    }
  };

  return (
    <div className={style.container}>
      <div className={style.mainContent}>
        <p className={style.operation}>
          {t('operation_type')}:
          <span
            className={cn(style.operation__type, {
              [style.colorDanger]: operation === OPERATION.sell,
              [style.colorSuccess]: operation === OPERATION.buy,
            })}
          >
            {t(operation.toLowerCase())}
          </span>
          {operation === OPERATION.buy ? <UpArrow /> : <DownArrow />}
        </p>

        <p style={{ fontWeight: '500' }}>
          <BalanceFormat value={amount} />
        </p>
      </div>
      <TradeInput />
      <div className={style.bottomBlock}>
        <p>{t('commission_for_increasing')}:</p>
        <p style={{ fontWeight: '500' }}>$00.00</p>
      </div>
      <div className={style.buttonContainer}>
        <Button
          text={t('cancel')}
          color='secondary'
          fontWeight='bold'
          onClick={close}
          width='100%'
        />
        <Button
          text={t('increase')}
          color='primary'
          fontWeight='bold'
          width='100%'
          onClick={handleIncrease}
          disabled={increaseAmount <= 0}
        />
      </div>
    </div>
  );
};

export default observer(IncreaseTradeContent);
