import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useRootModel } from '../../../models/RootStore';

import Button from '../../../components/ui/Button/Button';
import styles from './StatusInfoActions.module.scss';

/**
 * Represents loyalty status actions component
 * @returns {JSX.Element}
 */

const StatusInfoActions = () => {
  const { t } = useTranslation();
  const {
    user: {
      userData: { demoVersion },
      switchAccount,
    },
  } = useRootModel();
  return (
    <div className={styles.statusInfoActions}>
      <p className={styles.text}>{t('note')}</p>
      <Button
        text={demoVersion ? t('switch_real') : t('switch_demo')}
        onClick={switchAccount}
        color='primary'
        size='medium'
      />
    </div>
  );
};

export default observer(StatusInfoActions);
