import React, { useContext, useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './ChatSend.module.scss';
import { useRootModel } from '../../../models/RootStore';
// import { CHAT_FILE_SIZE, CHAT_FILE_TYPES } from '../../../constant/commonConstants';
import AlertContext from '../../../context/alert/alertContext';

import { ReactComponent as AddIcon } from '../../../assets/image/common/chatAdd.svg';
import { ReactComponent as SendIcon } from '../../../assets/image/common/send.svg';
import TextInput from '../../ui/TextInput/TextInput';
import ChatSendFileItem from './ChatSendFileItem/ChatSendFileItem';

/**
 * Represents ChatSend component
 * @param {File} file - file for send
 * @param {function} setFile - setFile handler
 * @param {function} handleDeleteFile - delete file handler
 * @param {function} handleScrollToBottom - scroll to bottom handler
 * @returns {JSX.Element}
 */
const ChatSend = ({ file, setFile, handleDeleteFile, handleScrollToBottom }) => {
  const alert = useContext(AlertContext);
  const { t } = useTranslation();
  const {
    chat: {
      messages: { sendMessage, sendFile },
    },
  } = useRootModel();
  const [message, setMessage] = useState('');
  const fileType = file?.type.includes('image') ? 'photo' : 'file';

  const changeMessage = ({ target: { value } }) => setMessage(value);

  const inputFileRef = useRef(null);
  const handleClickInputFileButton = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };
  const handleInputFileChange = ({ target: { files } }) => {
    const picture = files[0];
    // todo validate files
    // if (!CHAT_FILE_TYPES.includes(picture.type) || picture.size > CHAT_FILE_SIZE) {
    //   alert.show(t('documents_upload_info'), 'warning');
    //   return;
    // }
    setFile(picture);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', file.name);

        const fileUri = await sendFile(formData);
        await sendMessage(message, fileUri, file.name, fileType);
        setFile(null);
      } else {
        if (!file && !message) return;
        await sendMessage(message);
      }
      handleScrollToBottom();
      setMessage('');
    } catch (err) {
      alert.show(err.message);
    }
  };

  return (
    <form className={style.chatSend} onSubmit={handleSend}>
      <input
        value=''
        type='file'
        className={style.inputFile}
        ref={inputFileRef}
        onChange={handleInputFileChange}
        accept='.jpeg,.jpg,.png,.doc,.docx,.txt,.word,.odt,.xls,.xlsx,.pdf'
      />
      <button
        type='button'
        className={cn(style.button, style.buttonAdd)}
        onClick={handleClickInputFileButton}
      >
        <AddIcon />
      </button>
      <div className={style.inputWrapper}>
        <TextInput
          placeholder={t('write_message_here')}
          labelClassName={style.textInputLabel}
          inputClassName={style.textInput}
          onChange={changeMessage}
          value={message}
        />
        {file && (
          <ChatSendFileItem
            filename={file && file.name}
            url={file && URL.createObjectURL(file)}
            type={file && fileType}
            handleDeleteFile={handleDeleteFile}
          />
        )}
      </div>
      <button type='submit' className={cn(style.button, style.buttonSend)}>
        <SendIcon />
      </button>
    </form>
  );
};

export default observer(ChatSend);
