import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import numeral from 'numeral';
import style from './TradeInformation.module.scss';
import { useRootModel } from '../../models/RootStore';
import { OPERATION } from '../../constant/commonConstants';
import BalanceFormat from '../BalanceFormat/BalanceFormat';
import date from '../../util/date';
import string from '../../util/string';
import { ReactComponent as ArrowDown } from '../../assets/image/common/operationSell.svg';
import { ReactComponent as ArrowUp } from '../../assets/image/common/operationBuy.svg';

const TradeInformation = ({ trade }) => {
  const { t } = useTranslation();
  const {
    settings: { language },
  } = useRootModel();

  return (
    <div className={style.container}>
      <div className={style.block}>
        <p>{t('trade_type')}:</p>
        <div
          className={cn(style.block__text, style.block__container, {
            [style.colorDanger]: trade?.operation === OPERATION.sell,
            [style.colorSuccess]: trade?.operation === OPERATION.buy,
          })}
        >
          {trade?.operation === OPERATION.buy ? <ArrowUp /> : <ArrowDown />}
          <p>{t(trade.operation.toLowerCase())}</p>
        </div>
      </div>
      <div className={style.block}>
        <p>{t('trade_sum')}:</p>
        <p className={style.block__text}>
          <BalanceFormat value={trade?.amount} />
        </p>
      </div>
      <div className={style.block}>
        <p>{t('multiplier')}:</p>
        <p className={style.block__text}>x{trade?.multiplier}</p>
      </div>
      <div className={style.block}>
        <p>{t('multiplied_sum')}:</p>
        <p className={style.block__text}>
          <BalanceFormat value={(trade?.amount || 0) * (trade?.multiplier || 0)} />
        </p>
      </div>
      <div className={style.block}>
        <p>{t('open_price')}:</p>
        <p className={style.block__text}>{trade?.openingPrice}</p>
      </div>
      <div className={style.block}>
        <p>{t('close_price')}:</p>
        <p className={style.block__text}>{trade?.closingPrice || '-'}</p>
      </div>
      <div className={style.block}>
        <p>{t('open_date')}:</p>
        <p className={style.block__text}>{date.toLocaleString(trade?.openedAt, language)}</p>
      </div>
      <div className={style.block}>
        <p>{t('close_date')}:</p>
        <p className={style.block__text}>
          {trade?.closedAt ? date.toLocaleString(trade?.closedAt, language) : '-'}
        </p>
      </div>
      <div className={style.block}>
        <p>PnL:</p>
        <p
          className={cn(style.block__text, {
            [style.colorDanger]: trade?.profit < 0,
            [style.colorSuccess]: trade?.profit > 0,
          })}
        >
          {trade?.profit > 0 && '+'}
          <BalanceFormat value={trade?.profit} />{' '}
          <span style={{ fontWeight: '400' }}>
            (
            {trade?.closingPrice
              ? numeral(trade?.profitPercent).format('0.00%')
              : string.formatPercent(trade?.profitPercent)}
            )
          </span>
        </p>
      </div>
      <div className={style.block}>
        <p>{t('open_trade_commission')}:</p>
        <p className={style.block__text}>
          {trade?.openingCommission ? <BalanceFormat value={trade?.openingCommission} /> : '-'}
        </p>
      </div>
      <div className={style.block}>
        <p>{t('close_trade_commission')}:</p>
        <p className={style.block__text}>
          {trade?.closingCommission ? <BalanceFormat value={trade?.closingCommission} /> : '-'}
        </p>
      </div>
    </div>
  );
};

export default observer(TradeInformation);
