import axios from 'axios';

const deleteToken = () => {
  delete axios.defaults.headers.common.Authorization;
};

const writeBearerToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  // axios.defaults.headers.common['access-token'] = `Bearer ${token}`;
};

export default {
  writeBearerToken,
  deleteToken,
};
