import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { formatAmountWithSpaces } from '../../util/string';
import style from './Now.module.scss';
import { useRootModel } from '../../models/RootStore';
import { ReactComponent as MinusIcon } from '../../assets/image/common/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/image/common/plus.svg';
import Slider from '../Slider/Slider';
import { VALUE_FOR_AMOUNT_CHANGE } from '../../constant/calculationConstants';
import { NUMBER_WITH_THREE_DIGITS_AFTER_DOT } from '../../constant/regexConstants';

/**
 * Represents Now Tab in Modal after click Open Trade Button
 * @returns {JSX.Element}
 */

const Now = () => {
  const { t } = useTranslation();
  const {
    user: { currency },
    finance: {
      trade: {
        amountData: { amount, amountView, setAmount },
        maxMultiplier,
        multiplier,
        setMultiplier,
        setProfit,
        setLoss,
      },
    },
  } = useRootModel();

  const total = useMemo(() => amount * multiplier, [amount, multiplier]);

  const resetProfitAndLostLimits = () => {
    setProfit('');
    setLoss('');
  };

  const handleAmountChange = (e) => {
    resetProfitAndLostLimits();
    const { value } = e.target;

    const formattedValue = value.split(' ').join('');

    if (!(NUMBER_WITH_THREE_DIGITS_AFTER_DOT.test(formattedValue) || formattedValue === '')) return;

    setAmount(formattedValue);
  };

  const handleAmountBlur = () => {
    setAmount(formatAmountWithSpaces(amount));
  };

  const handleMultiplayerChange = (e) => {
    const { value } = e.target;

    if ((/^[0-9]+$/.test(value) && value <= maxMultiplier) || value === '') {
      setMultiplier(Number(value));
    }
  };

  const handleMultiplayerBlur = () => {
    if (multiplier === 0) setMultiplier(1);
  };

  const handleDecreaseTradeAmount = () => {
    if (amount <= 0) return;

    resetProfitAndLostLimits();

    const currentTradeAmount = amount - VALUE_FOR_AMOUNT_CHANGE;
    setAmount(formatAmountWithSpaces(currentTradeAmount <= 0 ? 0 : currentTradeAmount));
  };

  const handleIncreaseTradeAmount = () => {
    resetProfitAndLostLimits();
    setAmount(formatAmountWithSpaces(amount + VALUE_FOR_AMOUNT_CHANGE));
  };

  const handleDecreaseMultiplier = () => {
    if (multiplier <= 1) return;

    setMultiplier(multiplier - 1);
  };

  const handleIncreaseMultiplier = () => {
    if (multiplier < maxMultiplier) setMultiplier(multiplier + 1);
  };

  return (
    <div className={style.container}>
      <p className={style.label}>{t('trade_amount')}</p>
      <div className={style.wrapper}>
        <div className={style.wrapper__input}>
          <p className={style.block__icon}>{currency}</p>
          <input
            name='amount'
            type='text'
            value={amountView}
            onChange={handleAmountChange}
            onBlur={handleAmountBlur}
            placeholder='0.000'
            inputMode='decimal'
            autoComplete='off'
            className={style.block__input}
          />
          <div />
        </div>

        <div className={style.block}>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__left)}
            onClick={handleDecreaseTradeAmount}
          >
            <MinusIcon className={style.button__icon} />
          </button>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__right)}
            onClick={handleIncreaseTradeAmount}
          >
            <PlusIcon className={style.button__icon} />
          </button>
        </div>
      </div>

      <div className={style.block_percent}>
        <p>0%</p>
        <p>100%</p>
      </div>

      <Slider />

      <p className={style.label}>
        <span>{t('multiplier')}</span>{' '}
        <span className={style.label__text}>(max: {maxMultiplier})</span>
      </p>

      <div className={style.wrapper}>
        <div className={style.wrapper__input}>
          <span className={style.block__icon}>x</span>
          <input
            name='multiplayer'
            type='text'
            value={multiplier}
            onChange={handleMultiplayerChange}
            onBlur={handleMultiplayerBlur}
            inputMode='numeric'
            autoComplete='off'
            maxLength={3}
            className={style.block__input}
          />
        </div>

        <div className={style.block}>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__left)}
            onClick={handleDecreaseMultiplier}
          >
            <MinusIcon className={style.button__icon} />
          </button>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__right)}
            onClick={handleIncreaseMultiplier}
          >
            <PlusIcon className={style.button__icon} />
          </button>
        </div>
      </div>

      <p className={style.label}>{t('multiplied_sum')}</p>
      <div className={style.total}>
        <p className={style.total__icon}>{currency}</p>
        <p className={style.total__sum}>{formatAmountWithSpaces(total)}</p>
      </div>
    </div>
  );
};

export default observer(Now);
