import React from 'react';
import cn from 'classnames';

import style from './ProductsItemDetailPriceValue.module.scss';
import string from '../../../../util/string';

/**
 * Represents ProductsItemDetailPriceValue component
 * @param {number} value - current value
 * @param {string} title - item title
 * @returns {JSX.Element}
 */
const ProductsItemDetailPriceValue = ({ value, title }) => {
  return (
    <div
      className={cn(style.value, {
        [style.colorDanger]: value < 0,
        [style.colorSuccess]: value > 0,
      })}
    >
      <p className={style.title}>{title}</p>
      <p className={style.price}>{string.formatPercent(value, { withSign: true })}</p>
    </div>
  );
};

export default ProductsItemDetailPriceValue;
