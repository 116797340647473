import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import style from './HistoryContent.module.scss';
import ButtonGroup from '../../../../ui/ButtonGroup/ButtonGroup';
import { PERIOD, SORT_BY_TIME } from '../../../../../constant/commonConstants';
import HistoryTableItem from './HistoryTableItem/HistoryTableItem';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';
import { useRootModel } from '../../../../../models/RootStore';
import Preloader from '../../../../ui/Preloader/Preloader';

/**
 * Represents History content after click on trade history button
 * @param {function} handleClose - handle close Modal button
 * @returns {JSX.Element}
 */
const HistoryContent = ({ handleClose }) => {
  const { t } = useTranslation();
  const {
    finance: {
      activeTrades: { activeTrade },
      trade: { isLoading, getHistory, historyItems, period, changePeriod },
    },
  } = useRootModel();

  const { id } = activeTrade;

  const handleChangeSortByDate = (time) => {
    Object.entries(PERIOD).forEach(([key, value]) => {
      if (t(key) === time) changePeriod(value);
    });
    getHistory(id);
  };

  useEffect(() => {
    getHistory(id);
  }, []);

  return (
    <div className={style.wrapper}>
      <button type='button' onClick={handleClose} className={style.closeButton}>
        <CloseIcon />
      </button>
      <p className={style.title}>
        {t('trade_history')} <span className={style.number}>{historyItems[0]?.tradeCodeId}</span>
      </p>
      <div className={style.sortButtons}>
        <p className={style.titleButtonGroup}>{t('show_for')}</p>
        <ButtonGroup
          items={Object.values(SORT_BY_TIME)}
          activeItem={SORT_BY_TIME[period]}
          onClick={handleChangeSortByDate}
        />
      </div>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className={style.table}>
          <div className={style.tableHeader}>
            <p>{t('date')}</p>
            <p>{t('operation')}</p>
            <p>{t('price')}</p>
            <p>{t('multiplier')}</p>
            <p>{t('trade_equity')}</p>
          </div>
          {historyItems.map(
            ({
              date,
              fundsChangeAmount,
              multiplier,
              openingPrice,
              operation,
              result,
              stopLoss,
              takeProfit,
              tradeCodeId,
            }) => (
              <HistoryTableItem
                key={tradeCodeId}
                date={date}
                operation={operation}
                openingPrice={openingPrice}
                multiplier={multiplier}
                result={result}
                fundsChangeAmount={fundsChangeAmount}
                takeProfit={takeProfit}
                stopLoss={stopLoss}
              />
            ),
          )}
        </div>
      )}
      <div className={style.footerBlock}>
        <p>{t('swap_amount')}</p>
        <p>$0.00</p>
      </div>
    </div>
  );
};

export default observer(HistoryContent);
