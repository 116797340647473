import React from 'react';
import style from './AreaItem.module.scss';

/**
 * Represents area item component.
 * @param {string} city - city
 * @param {string} time - work hours
 * @returns {JSX.Element}
 */

const AreaItem = ({ exchange, openingTime, closingTime }) => {
  return (
    <div className={style.container}>
      <p>{exchange}</p>
      <p>
        {openingTime} - {closingTime}
      </p>
    </div>
  );
};

export default AreaItem;
