import { useFormik } from 'formik';
import { getFormikInitialValuesWithSchema } from '../util/validationSchema';

/**
 * @typedef {Object<string, string>} FormikValues
 * @property {string} id - field id
 * @property {string} value - value
 */
/**
 * @typedef {Object<string, string>} FormikErrors
 * @property {string} id - field id
 * @property {string} error - error
 */
/**
 * @typedef {Object<string, boolean>} FormikTouched
 * @property {string} id - field id
 * @property {boolean} touched - touched
 */
/**
 * @callback FormikSetFieldTouched
 * @param {string} field - field
 * @param {boolean} [touched] - touched
 * @param {boolean} [shouldValidate] - shouldValidate
 */
/**
 * @callback FormikSetErrors
 * @param {FormikErrors<FormikValues>} errors - errors
 */
/**
 * @callback FormikSetValue
 * @param {string} field - field id
 * @param {string} value - value
 * @returns {void}
 */
/**
 * @callback FormikSetValues
 * @param {React.SetStateAction<FormikValues>} values - values
 * @param {boolean} [shouldValidate] - shouldValidate
 * @returns {Promise<FormikErrors<FormikValues>> | Promise<void>}
 */
/**
 * @callback FormikOnSubmit
 * @param {FormikValues} values
 */
/**
 * @callback FormikValidateField
 * @param {string} field
 */

/**
 * @typedef {Object} Formik
 * @property {function} handleBlur
 * @property {function} handleChange
 * @property {function} handleReset
 * @property {function} resetForm
 * @property {FormikSetErrors} setErrors
 * @property {function} setFormikState
 * @property {FormikSetValue} setFieldValue
 * @property {FormikSetValue} setFieldError
 * @property {boolean | undefined} shouldValidate
 * @property {FormikSetValues} setValues
 * @property {boolean} isValid - is valid
 * @property {boolean} dirty - is dirty
 * @property {FormikValues} values - values
 * @property {FormikErrors<FormikValues>} errors - errors
 * @property {FormikTouched<FormikValues>} touched
 * @property {FormikSetFieldTouched} setFieldTouched
 * @property {function} handleSubmit
 * @property {function} submitForm
 * @property {function} validateForm
 * @property {FormikValidateField} validateField
 */

/**
 * @param {Array<string>} fields - names of fields to validate
 * @param {FormikOnSubmit} onSubmit - onSubmit handler
 * @returns {Formik}
 */
const useFormikApp = (fields, onSubmit) => {
  const { initialValues, schema } = getFormikInitialValuesWithSchema(fields);
  return useFormik({
    initialValues,
    validationSchema: schema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit,
  });
};

export default useFormikApp;
