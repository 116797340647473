import React from 'react';
import { observer } from 'mobx-react';

import ErrorBoundary from '../Error/ErrorBoundary/ErrorBoundary';

import IncreaseModal from './Modals/IncreaseModal/IncreaseModal';
import ActiveTradeModal from './Modals/ActiveTradeModal/ActiveTradeModal';
import PendingTradeModal from './Modals/PendingTradeModal/PendingTradeModal';
import CloseTradeModal from './Modals/CloseTradeModal/CloseTradeModal';
import HistoryModal from './Modals/HistoryModal/HistoryModal';
import DepositModal from './Modals/DepositModal/DepositModal';
import CancelTradeModal from './Modals/CancelTradeModal/CancelTradeModal';
import CurrentBalanceModal from './Modals/CurrentBalanceModal/CurrentBalanceModal';
import SearchModal from './Modals/SearchModal/SearchModal';
import NotificationsModal from '../Notifications/NotificationsModal/NotificationsModal';
import ChatPicturePreview from './Modals/ChatPicturePreview/ChatPicturePreview';
import CloseTradeModalMobile from './Modals/CloseTradeModalMobile/CloseTradeModalMobile';
import StopSessionModal from './Modals/StopSessionModal/StopSessionModal';
import WithdrawModal from './Modals/WithdrawModal/WithdrawModal';
import ConvertModal from './Modals/ConvertModal/ConvertModal';
import BalanceHistoryModal from './Modals/BalanceHistoryModal/BalanceHistoryModal';
import CloseTradeInfoModal from './Modals/CloseTradeInfoModal/CloseTradeInfoModal';

/**
 * Represents ActiveTradesModals component
 * @returns {JSX.Element}
 */
const AppModals = () => {
  return (
    <ErrorBoundary>
      <ActiveTradeModal />
      <PendingTradeModal />
      <CloseTradeInfoModal />
      <CloseTradeModal />
      <CloseTradeModalMobile />
      <HistoryModal />
      <IncreaseModal />
      <DepositModal />
      <WithdrawModal />
      <ConvertModal />
      <BalanceHistoryModal />
      <CancelTradeModal />
      <CurrentBalanceModal />
      <SearchModal />
      <NotificationsModal />
      <ChatPicturePreview />
      <StopSessionModal />
    </ErrorBoundary>
  );
};

export default observer(AppModals);
