import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useRootModel } from '../../models/RootStore';

/**
 * Represents PrivateRoute
 */
const PrivateRoute = () => {
  const {
    user: { isAuthorized },
  } = useRootModel();

  return isAuthorized ? <Outlet /> : <Navigate to='/login' />;
};

export default PrivateRoute;
