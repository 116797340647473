import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useRootModel } from '../../../../../../models/RootStore';
import style from './HistoryTableItem.module.scss';
import { historyOperation } from '../../../../../../constant/commonConstants';
import string from '../../../../../../util/string';
import date from '../../../../../../util/date';

/**
 * Represents History table item
 * @param {string} date - trade operation date
 * @param {number} openingPrice - trade opening price
 * @param {number} multiplier - multiplier
 * @param {number} fundsChangeAmount - trade funds change amount
 * @param {string} operation - trade operation
 * @param {number} result - result
 * @param {number} stopLoss - limit loss
 * @param {number} takeProfit - limit profit
 * @returns {JSX.Element}
 */

const HistoryTableItem = ({
  date: openingDate,
  openingPrice,
  multiplier,
  fundsChangeAmount,
  operation,
  result,
  stopLoss,
  takeProfit,
}) => {
  const { t } = useTranslation();
  const {
    settings: { language },
  } = useRootModel();

  return (
    <div className={style.tableRow}>
      <p>{date.toLocaleString(openingDate, language)}</p>
      <div className={style.operation}>
        <p>{historyOperation[operation]}</p>
        <p className={style.text}>{t('commissions_for_operation')}</p>
        <p>{t('set_limits')}</p>
        <p className={style.text}>
          {t('profit')}: {takeProfit ? string.formatMoney(takeProfit, { withoutSign: true }) : `-`}
        </p>
        <p className={style.text}>
          {t('loss')}: {stopLoss ? string.formatMoney(stopLoss, { withoutSign: true }) : `-`}
        </p>
      </div>
      <p>{openingPrice}</p>
      <p>x {multiplier}</p>
      <div className={style.equity}>
        <p>{string.formatMoney(result, { withoutSign: true })}</p>
        <p className={style.text}>{string.formatMoney(fundsChangeAmount)}</p>
      </div>
    </div>
  );
};

export default observer(HistoryTableItem);
