import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styles from './Header.module.scss';
import { useRootModel } from '../../models/RootStore';
import Navigation from '../Navigation/Navigation';
import HeaderAuthButton from './HeaderAuthButton/HeaderAuthButton';
import UserInfo from './HeaderUserInfo/HeaderUserInfo';
import HeaderNotifications from './HeaderNotifications/HeaderNotifications';
import { ReactComponent as LogoIcon } from '../../assets/image/common/logo.svg';
import useWindowSize from '../../hooks/useWindowSize';
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';

/**
 * Represents header
 * @returns {JSX.Element}
 */
const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isMobile, isDesktop } = useWindowSize();
  const {
    user: { isAuthorized },
  } = useRootModel();

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <Link to='/' className={styles.logo}>
          <LogoIcon />
        </Link>

        {isAuthorized && isDesktop && <Navigation />}
      </div>

      {isAuthorized && !isMobile && (
        <Link to='/balance' className={styles.depositButton}>
          {t('deposit')}
        </Link>
      )}

      {isAuthorized ? (
        <div className={styles.container}>
          <HeaderNotifications />
          <UserInfo />
        </div>
      ) : (
        <div className={styles.wrapperNotAuthorized}>
          <HeaderNavigation />
          {pathname === '/' && <HeaderAuthButton />}
        </div>
      )}
    </header>
  );
};

export default observer(Header);
