import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import style from './ClosedTradesContent.module.scss';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useRootModel } from '../../../../models/RootStore';

import Title from '../../../../components/ui/Table/TableTitle/TableTitle';
import ClosedTradesList from './ClosedTradesList/ClosedTradesList';

/**
 * Represents closed trades content
 * @returns {JSX.Element}
 */
const ClosedTradesContent = ({ items }) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const { isDesktop, isLaptop } = useWindowSize();
  const [isPercent, setIsPercent] = useState(false);
  const {
    finance: {
      closedTrades: {
        sortBy,
        setSortBy,
        setSortDirection,
        sortDirection,
        switchSortDirection,
        getClosedTrades,
        isLoading,
        setNextPage,
        isNextPage,
      },
    },
  } = useRootModel();

  useEffect(() => {
    const onScroll = async ({ target: { scrollTop, scrollHeight } }) => {
      if (!isLoading && scrollHeight - scrollTop < 650 && isNextPage) {
        await setNextPage();
        getClosedTrades(true);
      }
    };
    tableRef.current?.addEventListener('scroll', onScroll);
    return () => {
      tableRef.current?.removeEventListener('scroll', onScroll);
    };
  }, [tableRef, tableRef.current, isLoading]);

  const handleToggle = () => {
    setIsPercent(!isPercent);
  };

  const handleSortBy = async (item) => {
    await setSortBy(item);
    getClosedTrades();
  };

  return (
    <div>
      <div className={style.head}>
        <Title
          className={style.title}
          title={t('name')}
          isActive={sortBy === 'symbol.alias'}
          handleOnClick={handleSortBy}
          labelKey='symbol.alias'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />

        {isLaptop && (
          <Title
            className={style.title}
            title={t('open_price_and_date')}
            isActive={sortBy === 'openingPrice'}
            handleOnClick={handleSortBy}
            labelKey='openingPrice'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}

        {isLaptop && (
          <Title
            className={style.title}
            title={t('close_price_and_date')}
            isActive={sortBy === 'closingPrice'}
            handleOnClick={handleSortBy}
            labelKey='closingPrice'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}

        {isDesktop && (
          <Title
            title={t('opening_date')}
            isActive={sortBy === 'openedAt'}
            handleOnClick={handleSortBy}
            labelKey='openedAt'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}
        {isDesktop && (
          <Title
            title={t('opening_price')}
            isActive={sortBy === 'openingPrice'}
            handleOnClick={handleSortBy}
            labelKey='openingPrice'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}
        {isDesktop && (
          <Title
            title={t('closing_date')}
            isActive={sortBy === 'closedAt'}
            handleOnClick={handleSortBy}
            labelKey='closedAt'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}
        {isDesktop && (
          <Title
            title={t('closing_price')}
            isActive={sortBy === 'closingPrice'}
            handleOnClick={handleSortBy}
            labelKey='closingPrice'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}
        {isDesktop && (
          <Title
            title={t('amount_of_rate')}
            isActive={sortBy === 'amount'}
            handleOnClick={handleSortBy}
            labelKey='amount'
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
        )}
        <Title
          className={style.title}
          title={t('result')}
          isActive={sortBy === 'result'}
          handleOnClick={handleSortBy}
          labelKey='result'
          setSortDirection={setSortDirection}
          sortDirection={sortDirection}
          switchSortDirection={switchSortDirection}
        />
        <div className={style.profitWrapper}>
          <Title
            className={style.title}
            title={t('profit')}
            isActive={isPercent ? sortBy === 'profitPercent' : sortBy === 'profit'}
            handleOnClick={handleSortBy}
            labelKey={isPercent ? 'profitPercent' : 'profit'}
            setSortDirection={setSortDirection}
            sortDirection={sortDirection}
            switchSortDirection={switchSortDirection}
          />
          {isDesktop && (
            <label htmlFor='switcher' className={style.switch}>
              <input id='switcher' type='checkbox' onChange={handleToggle} />
              <p className={style.slider}>
                <span className={style.signValue}>$</span>
                <span className={style.sign}>%</span>
              </p>
            </label>
          )}
        </div>
      </div>
      <ClosedTradesList isPercent={isPercent} items={items} tableRef={tableRef} />
    </div>
  );
};

export default observer(ClosedTradesContent);
