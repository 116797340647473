import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import style from '../LimitProfitsAndLost/LimitProfitsAndLost.module.scss';
import { formatAmountWithSpaces, formatStringToNumberWithoutSpace } from '../../util/string';
import { useRootModel } from '../../models/RootStore';
import { ReactComponent as MinusIcon } from '../../assets/image/common/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/image/common/plus.svg';
import { NUMBER_WITH_THREE_DIGITS_AFTER_DOT } from '../../constant/regexConstants';
import { VALUE_FOR_AMOUNT_CHANGE } from '../../constant/calculationConstants';

const TakeProfitInput = () => {
  const { t } = useTranslation();
  const {
    user: { currency },
    finance: {
      trade: {
        amountData: { amount },
        takeProfit,
        setProfit,
        limitBuyStopLoss,
      },
    },
  } = useRootModel();

  const currentProfit = useMemo(() => formatStringToNumberWithoutSpace(takeProfit), [takeProfit]);

  const currentProfitInPercent = useMemo(
    () => Number(((currentProfit / amount) * 100).toFixed(3)),
    [amount, currentProfit],
  );

  const maxProfit = useMemo(
    () => Number(((amount * limitBuyStopLoss) / 100).toFixed(3)),
    [amount, limitBuyStopLoss],
  );

  const handleDecreaseProfit = () => {
    if (takeProfit <= 0) return;

    const decreasedProfit = currentProfit - VALUE_FOR_AMOUNT_CHANGE;
    setProfit(formatAmountWithSpaces(decreasedProfit <= 0 ? 0 : decreasedProfit));
  };

  const handleIncreaseProfit = () => {
    const increasedProfit = currentProfit + VALUE_FOR_AMOUNT_CHANGE;
    if (limitBuyStopLoss && increasedProfit >= maxProfit) {
      return setProfit(formatAmountWithSpaces(maxProfit));
    }

    return setProfit(formatAmountWithSpaces(increasedProfit));
  };

  const handleBlurProfit = () => {
    if (limitBuyStopLoss && currentProfitInPercent >= limitBuyStopLoss) {
      return setProfit(formatAmountWithSpaces(maxProfit));
    }

    return setProfit(formatAmountWithSpaces(currentProfit));
  };

  const handleChangeProfit = (e) => {
    const { value } = e.target;
    const formattedValue = value.split(' ').join('');

    if (!(NUMBER_WITH_THREE_DIGITS_AFTER_DOT.test(formattedValue) || formattedValue === '')) return;

    setProfit(formattedValue);
  };

  return (
    <div className={style.container}>
      <p className={style.label}>
        <span>{t('take_profit')}</span>{' '}
        {Boolean(amount) && Boolean(limitBuyStopLoss) && (
          <span className={style.label__text}>(max: {limitBuyStopLoss}%)</span>
        )}
      </p>
      <div className={style.wrapper}>
        <div className={style.block}>
          <p className={style.block__icon}>{currency}</p>
          <input
            name='profit'
            type='text'
            value={takeProfit}
            onChange={handleChangeProfit}
            onBlur={handleBlurProfit}
            placeholder='0.000'
            inputMode='decimal'
            autoComplete='off'
            className={style.block__input}
          />
        </div>

        <div className={style.buttons}>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__left)}
            onClick={handleDecreaseProfit}
          >
            <MinusIcon className={style.button__icon} />
          </button>
          <button
            type='button'
            className={cn(style.button, style.button__rounded__right)}
            onClick={handleIncreaseProfit}
          >
            <PlusIcon className={style.button__icon} />
          </button>
        </div>
      </div>

      {Boolean(currentProfit) && Boolean(amount) && (
        <p className={style.percent}>+{currentProfitInPercent}%</p>
      )}
    </div>
  );
};

export default observer(TakeProfitInput);
