import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import style from '../BalancePage.module.scss';
import ConvertMoney from '../../../../components/ConvertMoney/ConvertMoney';
import { ReactComponent as BackIcon } from '../../../../assets/image/common/back.svg';

const ExchangeMoneyMobile = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.titleBlock}>
        <Link to='/balance' className={style.back}>
          <BackIcon />
        </Link>
        <p className={style.title}>{t('convert')}</p>
      </div>
      <ConvertMoney />
    </div>
  );
};

export default ExchangeMoneyMobile;
