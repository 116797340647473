import React from 'react';
import cn from 'classnames';
import style from './ButtonVisibility.module.scss';
import { ReactComponent as VisibleLogo } from '../../../assets/image/common/visibility.svg';
import { ReactComponent as VisibleOff } from '../../../assets/image/common/visibilityOff.svg';

/**
 * Represents button visibility for password
 * @param {string} name - name for the button
 * @param {function} onClick - click handler
 * @param {boolean} showPassword - is show password
 * @param {CSSStyleRule} className - className for the button
 * @returns {JSX.Element}
 */
const ButtonVisibility = ({ name, onClick, showPassword, className }) => {
  // Prevent losing focus on an input element
  const preventFocus = (e) => {
    e.preventDefault();
  };

  const handleClick = (e) => {
    onClick(e, name);
  };

  return (
    <button
      className={cn(style.showPasswordButton, className)}
      type='button'
      onClick={handleClick}
      onMouseDown={preventFocus}
      name={name}
      tabIndex={-1}
    >
      {showPassword ? <VisibleOff /> : <VisibleLogo />}
    </button>
  );
};

export default ButtonVisibility;
