import React, { useEffect } from 'react';

import style from './GeneralInformation.module.scss';
import ErrorBoundary from '../../../components/Error/ErrorBoundary/ErrorBoundary';

import GeneralEmail from './GeneralEmail/GeneralEmail';
import GeneralPassword from './GeneralPassword';
import GeneralPhone from './GeneralPhone/GeneralPhone';
import { useRootModel } from '../../../models/RootStore';

/**
 * Represents a general information component
 * @returns {JSX.Element}
 */
const GeneralInformation = () => {
  const {
    user: { getUser },
  } = useRootModel();

  useEffect(() => {
    getUser();
  }, []);

  return (
    <ErrorBoundary>
      <div className={style.generalInformation}>
        <GeneralEmail />
        <GeneralPhone />
        <hr />
        <GeneralPassword />
      </div>
    </ErrorBoundary>
  );
};

export default GeneralInformation;
