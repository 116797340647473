export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

const handlers = {
  [SHOW_ALERT]: (state, { payload }) => ({ ...payload, visible: true }),
  [HIDE_ALERT]: (state) => ({ ...state, visible: false }),
  DEFAULT: (state) => state,
};

/**
 * @typedef {Object} AlertPayload
 * @property {string} type - alert type
 * @property {string} text - alert text
 * @property {string} title - alert title
 */
/**
 * @typedef {Object} AlertAction
 * @property {string} type - action type
 * @property {AlertPayload} payload - action payload
 */
/**
 * Represents alert reducer
 * @param {Object} state
 * @param {AlertAction} action
 * @returns {*&{visible: boolean}}
 */
const alertReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};

export default alertReducer;
