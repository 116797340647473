import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './NotFound.module.scss';
import { ReactComponent as ErrorMobile } from '../../assets/image/common/404-mobile.svg';
import { ReactComponent as Error } from '../../assets/image/common/404.svg';

/**
 * Represents 404 page
 * @returns {JSX.Element}
 */

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={style.notFound}>
      <span className={style.errorDesktop}>
        <Error />
      </span>
      <div className={style.container}>
        <span className={style.error}>
          <ErrorMobile />
        </span>
        <p className={style.header}>{t('broken')}</p>
        <p className={style.text}>{t('sorry')}</p>
        <Link to='/' className={style.button}>
          {t('go_home')}
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
