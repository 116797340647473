import cn from 'classnames';
import React from 'react';
import styles from './Button.module.scss';

/**
 * @callback OnClick
 * @param {Event} event - event
 */
/**
 * @typedef {('default' | 'primary' | 'secondary' | 'secondaryBackground' | 'danger')} ColorType
 */
/**
 * @typedef {('small' | 'medium' | 'large')} SizeType
 */
/**
 * @typedef {('normal' | 'full')} WidthType
 */
/**
 * @typedef {('normal' | 'bold')} FontWeightType
 */
/**
 * Represent custom button
 * @param {string} text - button title
 * @param {boolean} submit - button type
 * @param {ColorType} color - button color
 * @param {SizeType} size - small / medium / large
 * @param {Width} width - button width
 * @param {Height} height - button height
 * @param {FontWeightType} fontWeight - normal / bold
 * @param {boolean} disabled - disable
 * @param {OnClick} onClick - button handleClick
 * @param {boolean} marginBottom - margin-bottom
 * @param {CSSStyleRule} className - className for button
 * @param {JSX.Element} children - children
 * @param {Ref} buttonRef - ref for the button
 * @returns {JSX.Element}
 */

const Button = ({
  text = '',
  submit = false,
  color = 'default',
  size = 'large',
  width,
  height,
  fontWeight = 'bold',
  disabled = false,
  onClick,
  marginBottom,
  className,
  children,
  buttonRef,
}) => {
  return (
    <button
      ref={buttonRef}
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      style={{ width, height }}
      className={cn(
        styles.button,
        styles[color],
        styles[size],
        styles[width],
        styles[fontWeight],
        {
          [styles.marginBottom]: marginBottom,
        },
        className,
      )}
    >
      {children || text}
    </button>
  );
};

export default Button;
