import { flow, types } from 'mobx-state-tree';
import axios from 'axios';
import error from '../../util/error';

const User = types.model('User', {
  active: types.optional(types.boolean, false),
  email: types.optional(types.string, ' '),
  firstName: types.maybeNull(types.string),
  id: types.optional(types.number, 0),
  imageUrl: types.maybeNull(types.string),
  langKey: types.optional(types.string, ''),
  lastName: types.maybeNull(types.string),
  login: types.optional(types.string, ''),
  resetDate: types.maybeNull(types.string),
});

const UserLevelModel = types.model('UserLevelModel', {
  balanceDepositCurrentLevelProgress: types.optional(types.number, 0),
  balanceDepositNextLevelProgress: types.optional(types.number, 0),
  icon: types.maybeNull(types.string),
  id: types.optional(types.number, 0),
  isMaxLevel: types.optional(types.boolean, false),
  name: types.optional(types.string, ''),
  progress: types.optional(types.number, 0),
  tradeVolumeCurrentLevelProgress: types.optional(types.number, 0),
  tradeVolumeNextLevelProgress: types.optional(types.number, 0),
});

const AccountModel = types.model('AccountModel', {
  balance: types.number,
  available: types.optional(types.number, 0),
  profit: types.optional(types.number, 0),
  used: types.optional(types.number, 0),
});

const DocumentsModel = types.model('DocumentsModel', {
  resource: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const UserModel = types
  .model('UserModel', {
    city: types.maybeNull(types.string),
    countryCode: types.maybeNull(types.string),
    dateOfBirth: types.maybeNull(types.string),
    demoVersion: types.boolean,
    documentsVerificationStatus: types.maybeNull(types.string),
    emailConfirmed: types.boolean,
    // id: types.number,
    marginCallEnabled: types.boolean,
    notificationHighlightDealsRiskEnabled: types.boolean,
    notificationTradeAlertsEnabled: types.boolean,
    passportId: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    phoneCode: types.maybeNull(types.string),
    phoneConfirmed: types.boolean,
    tradingEnabled: types.boolean,
    twoFaEnabled: types.boolean,
    user: types.optional(User, {}),
    userCurrency: types.optional(types.string, 'USD'),
    userLevelVM: types.optional(UserLevelModel, {}),
    documents: types.array(DocumentsModel), // todo remove ?
    accountsData: types.maybeNull(AccountModel), // todo remove ?
    balanceUser: types.maybeNull(AccountModel), // todo remove ?
  })
  .actions((userData) => ({
    getBalance: flow(function* getUpdateBalance() {
      try {
        const { data } = yield axios.get(`/services/trading/api/accounts/balance`);
        userData.balanceUser = data;
      } catch (e) {
        const message = e.response?.data.errorCode || e.message;
        error.errorHandler(message);
      }
    }),
  }))
  .views((userData) => ({
    get balance() {
      const { balanceUser } = userData;
      return balanceUser?.balance;
    },
    get profit() {
      const { demoVersion, demoAccount, realAccount } = userData;
      return demoVersion ? demoAccount?.profit : realAccount?.profit;
    },
    get used() {
      const { demoVersion, demoAccount, realAccount } = userData;
      return demoVersion ? demoAccount?.used : realAccount?.used;
    },
    get available() {
      const { demoVersion, demoAccount, realAccount } = userData;
      return demoVersion ? demoAccount?.available : realAccount?.available;
    },
    get id() {
      return userData?.user?.id || null;
    },
    get firstName() {
      return userData.user.firstName || '';
    },
    get lastName() {
      return userData.user.lastName || '';
    },
  }));

export default UserModel;
