import React, { useEffect } from 'react';
import cn from 'classnames';

import style from './Pagination.module.scss';
import useStateCallback from '../../../hooks/useStateCallback';
import { ReactComponent as Arrow } from '../../../assets/image/common/paginationArrowRight.svg';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const rangeArr = [];

  while (i <= to) {
    rangeArr.push(i);
    i += step;
  }

  return rangeArr;
};

/**
 * Represents pagination component
 * @param {number} totalRecords - total records
 * @param {number} pageLimit - limit items on page
 * @param {number} pageNeighbours - amount shown neighbours page numbers
 * @param {function} onPageChanged - change handler
 * @param {CSSStyleRule} wrapperClassName - cla|ssName for pagination wrapper
 * @param {number} currentPage - active page
 * @returns {JSX.Element|null}
 */
const Pagination = ({
  totalRecords,
  pageLimit = 30,
  pageNeighbours = 0,
  onPageChanged,
  wrapperClassName,
  currentPage = 1,
}) => {
  const [state, setStateCallback] = useStateCallback({ currentPage });
  const totalPages = Math.ceil(totalRecords / pageLimit);

  useEffect(() => {
    if (currentPage) setStateCallback({ currentPage });
  }, [currentPage]);

  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, state.currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, state.currentPage + pageNeighbours);
      let pages = range(startPage, endPage);
      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };

  const gotoPage = (page) => {
    const handlePageChanged = onPageChanged || ((f) => f);
    const currPage = Math.max(0, Math.min(page, totalPages));
    const paginationData = {
      currentPage: currPage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    setStateCallback({ currentPage: currPage }, () => handlePageChanged(paginationData));
  };

  const handleClick = (page) => (evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(state.currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(state.currentPage + pageNeighbours * 2 + 1);
  };

  if (!totalRecords || totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <div className={cn(style.paginationWrapper, wrapperClassName)}>
      <nav aria-label='pagination'>
        <ul className={style.pagination}>
          {pages.map((page, index) => {
            const key = `${index}${page}`;
            if (page === LEFT_PAGE)
              return (
                <li key={key} className={style.pageItem}>
                  <button
                    type='button'
                    aria-label='previous'
                    onClick={handleMoveLeft}
                    className={style.arrowLeft}
                  >
                    <Arrow />
                  </button>
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={key} className={style.pageItem}>
                  <button
                    type='button'
                    aria-label='next'
                    onClick={handleMoveRight}
                    className={style.arrowRight}
                  >
                    <Arrow />
                  </button>
                </li>
              );

            return (
              <li
                key={key}
                className={cn(style.pageItem, { [style.active]: state.currentPage === page })}
              >
                <button type='button' className={style.pageLink} onClick={handleClick(page)}>
                  {page}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
