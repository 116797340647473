import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useRootModel } from '../../../../models/RootStore';
import style from './StopSessionModal.module.scss';

import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody/ModalBody';
import Button from '../../../ui/Button/Button';

/**
 * Represents Stop session modal
 * @returns {JSX.Element}
 */

const StopSessionModal = () => {
  const { t } = useTranslation();
  const {
    modal: {
      stopSession: { isOpen, close },
    },
  } = useRootModel();

  const handleContinueSession = () => {
    close();
    document.location.reload();
  };

  return (
    isOpen && (
      <Modal wrapperClassname={style.modalWrapper} handleClose={close} useOnClickParam={false}>
        <ModalBody className={style.modal}>
          <p className={style.text}>{t('session_stop')}</p>
          <Button onClick={handleContinueSession} text={t('continue')} className={style.button} />
        </ModalBody>
      </Modal>
    )
  );
};

export default observer(StopSessionModal);
