import React from 'react';
import cn from 'classnames';

import style from './ModalFooter.module.scss';

/**
 * Represents ModalFooter component
 * @param {JSX.Element} children - children
 * @param {CSSStyleRule} className - footer className
 * @returns {JSX.Element}
 */
const ModalFooter = ({ children, className }) => {
  return <div className={cn(style.modalFooter, className)}>{children}</div>;
};

export default ModalFooter;
