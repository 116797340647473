import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import cn from 'classnames';

import style from './ActiveTradesDropdown.module.scss';
import { useRootModel } from '../../models/RootStore';

import { ReactComponent as Dots } from '../../assets/image/common/dots.svg';
import { ReactComponent as Up } from '../../assets/image/common/arrowUpRound.svg';
import { ReactComponent as Down } from '../../assets/image/common/arrowDownRound.svg';
import { ReactComponent as Done } from '../../assets/image/common/done.svg';
import Dropdown from '../ui/Dropdown/Dropdown';
import BalanceFormat from '../BalanceFormat/BalanceFormat';

/**
 * Represents ActiveTradesDropdown
 * @returns {JSX.Element}
 */
const ActiveTradesDropdown = () => {
  const { t } = useTranslation();
  const {
    user: {
      userData: { getBalance },
    },
    finance: {
      activeTrades: {
        profitableTradesSum,
        profitableTradesAmount,
        lossTradesSum,
        lossTradesAmount,
        allTradesSum,
        allTradesAmount,
        getActiveTrades,
      },
      closedTrades: { getClosedTrades },
      trade: { closeTrades },
    },
  } = useRootModel();
  const handleCloseProfitableTrades = async () => {
    await closeTrades('PROFITABLE');
    getActiveTrades();
    getClosedTrades();
    getBalance();
  };
  const handleCloseLossTrades = async () => {
    await closeTrades('LOSS');
    getActiveTrades();
    getClosedTrades();
    getBalance();
  };
  const handleCloseAllTrades = async () => {
    await closeTrades();
    getActiveTrades();
    getClosedTrades();
    getBalance();
  };
  return (
    <Dropdown
      mainComponent={() => <Dots />}
      changeMainComponent={false}
      showArrow={false}
      className={style.dotsButton}
      contentClassName={style.dropdown}
      itemClassName={style.itemClass}
    >
      <button
        type='button'
        className={cn(style.item, style.justifyStart)}
        onClick={handleCloseProfitableTrades}
      >
        <Up />
        <div className={style.text}>
          <p>{t('close_profitable')}</p>
          <div className={style.textGreen}>
            <BalanceFormat value={profitableTradesSum} />
            <span className={style.amount}>({profitableTradesAmount} trades)</span>
          </div>
        </div>
      </button>

      <button
        type='button'
        className={cn(style.item, style.justifyStart)}
        onClick={handleCloseLossTrades}
      >
        <Down />
        <div className={style.text}>
          <p>{t('close_loss')}</p>
          <div className={style.textRed}>
            <BalanceFormat value={lossTradesSum} />
            <span className={style.amount}>({lossTradesAmount} trades)</span>
          </div>
        </div>
      </button>

      <button
        type='button'
        className={cn(style.item, style.justifyStart)}
        onClick={handleCloseAllTrades}
      >
        <Done />
        <div className={style.text}>
          <p>{t('close_all')}</p>
          <div className={cn(style.textGreen, { [style.textRed]: allTradesSum < 0 })}>
            <BalanceFormat value={allTradesSum} />
            <span className={style.amount}>({allTradesAmount} trades)</span>
          </div>
        </div>
      </button>
    </Dropdown>
  );
};

export default observer(ActiveTradesDropdown);
