import React from 'react';
import { ReactComponent as BTC } from '../assets/image/instruments/wallet/BTC.svg';
import { ReactComponent as ETH } from '../assets/image/instruments/wallet/ETH.svg';
import { ReactComponent as LTC } from '../assets/image/instruments/wallet/LTC.svg';
import { ReactComponent as USDT } from '../assets/image/instruments/wallet/USDT.svg';
import { ReactComponent as USD } from '../assets/image/instruments/wallet/USD.svg';
import { ReactComponent as EURO } from '../assets/image/instruments/wallet/EURO.svg';

export const balanceCurrency = {
  BTC: {
    title: 'BTC',
    label: 'Bitcoin',
    fullInfo: 'Bitcoin BTC',
    icon: <BTC />,
  },
  ETH: {
    title: 'ETH',
    label: 'Ethereum',
    fullInfo: 'Ethereum ETH',
    icon: <ETH />,
  },
  LTC: {
    title: 'LTC',
    label: 'Litcoin',
    fullInfo: 'Litcoin LTC',
    icon: <LTC />,
  },
  USDT_ERC20: {
    title: 'USDT',
    label: 'TetherUS',
    fullInfo: 'TetherUS USDT',
    icon: <USDT />,
  },
};

export const platformCurrency = [
  {
    id: 'USD',
    label: 'Dollar USA',
    icon: <USD />,
  },
  {
    id: 'EUR',
    label: 'Euro',
    icon: <EURO />,
  },
];
