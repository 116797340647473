import React, { useEffect } from 'react';
import displayTimeLeft from '../../util/displayTimeLeft';

/**
 * Represents countdown timer - only from parent state
 * @param {number} seconds - seconds for counting
 * @param {function} onChange - change handler (setSeconds)
 * @param {boolean} fullTime - full time with hours text and minutes text
 * @param {boolean} leadMinutes - if need to lead minutes set true
 * @param {string} endText - text when count ends
 * @param {function} callback - execute after 0 seconds
 * @returns {JSX.Element}
 */
const TimeCountdown = ({
  seconds = 0,
  onChange,
  fullTime = false,
  leadMinutes = false,
  endText = '',
  callback,
}) => {
  useEffect(() => {
    let interval;
    if (seconds) {
      interval = setTimeout(callback, seconds);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        onChange((prev) => prev - 1);
      }
      if (seconds <= 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  return seconds !== 0 ? <span>{displayTimeLeft(seconds, fullTime, leadMinutes)}</span> : endText;
};

export default TimeCountdown;
