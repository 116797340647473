import { flow, getParent, types } from 'mobx-state-tree';
import axios from 'axios';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_THEME,
  THEME_DARK,
  THEME_LIGHT,
} from '../../constant/commonConstants';

import error from '../../util/error';

const changeLanguageHeader = (lang) => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
changeLanguageHeader(DEFAULT_LANGUAGE);

const themeMode = localStorage.getItem('theme') || DEFAULT_THEME;

const SettingsModel = types
  .model('Settings', {
    language: types.optional(types.string, DEFAULT_LANGUAGE),
    langKeys: types.optional(types.array(types.array(types.string)), [['en', 'ENG']]),
    isLoading: types.optional(types.boolean, false),
    alert: types.optional(types.string, ''),
    theme: types.optional(types.string, themeMode),
  })
  .actions((settings) => ({
    setIsLoading(isLoading) {
      settings.isLoading = isLoading;
    },
    getLangKeys: flow(function* getLangKeys() {
      settings.isLoading = true;
      try {
        const { data } = yield axios.get(`/api/lang-keys`);
        settings.langKeys = Object.entries(data);
      } catch (e) {
        settings.alert = e.message;
        error.errorHandler(e.message);
      } finally {
        settings.isLoading = false;
      }
    }),
    setDefaultLanguage() {
      settings.language = DEFAULT_LANGUAGE;
      changeLanguageHeader(DEFAULT_LANGUAGE);
    },
    setUserDefaultLanguage() {
      const lang = getParent(settings).user.userData.user.langKey;
      settings.language = lang;
      changeLanguageHeader(lang);
    },
    changeLanguage: flow(function* changeLanguage(langKey) {
      settings.language = langKey;
      changeLanguageHeader(langKey);

      if (getParent(settings).user.isAuthorized) {
        settings.isLoading = true;
        try {
          yield axios.patch(`/api/account`, { langKey });
        } catch (e) {
          const message = e.response?.data.errorCode || e.message;
          settings.alert = message;
          error.errorHandler(message);
        } finally {
          settings.isLoading = false;
        }
      }
    }),
    setDefaultTheme() {
      settings.theme = DEFAULT_THEME;
      document.querySelector('html').setAttribute('data-theme', DEFAULT_THEME);
    },
    toggleTheme() {
      const trans = () => {
        document.body.classList.add('transition');
        window.setTimeout(() => {
          document.documentElement.classList.remove('transition');
        }, 500);
      };

      const theme = () => (settings.theme === THEME_LIGHT ? THEME_DARK : THEME_LIGHT);
      trans();
      document.querySelector('html').setAttribute('data-theme', theme());
      localStorage.setItem('theme', theme());
      settings.theme = theme();
    },
    afterCreate() {
      settings.getLangKeys();
      settings.theme = themeMode;
      document.querySelector('html').setAttribute('data-theme', themeMode);
    },
  }))
  .views((settings) => ({
    get isLightMode() {
      return settings.theme === THEME_LIGHT;
    },
  }));

export default SettingsModel;

/**
 *
 * @typedef {Object} SettingsModel
 * @property {string} language
 * @property {Array<string>} langKeys
 * @property {boolean} isLoading
 * @property {string} alert
 */
