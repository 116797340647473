import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './AffiliateRulesMobile.module.scss';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/common/close-circle.svg';

/**
 * Represents Affiliate rules component in Mobile version
 * @param {function} handleClose - handle close Modal button
 * @returns {JSX.Element}
 */

const AffiliateRulesMobile = ({ handleClose }) => {
  const { t } = useTranslation();
  return (
    <section className={style.affiliateRulesMobile}>
      <button type='button' onClick={handleClose} className={style.closeButton}>
        <CloseIcon />
      </button>
      <h2 className={style.rulesTitle}>{t('rules_participation')}</h2>
      <p className={style.shareBlock}>{t('share_the_link')}</p>
      <p className={style.rulesText}>{t('share_text_rules')}</p>
      <article className={style.questionBlock}>
        <h4 className={style.title}>{t('get_rewards')}</h4>
        <p className={style.text}>{t('get_rewards_answer')}</p>
      </article>
      <article className={style.questionBlock}>
        <h4 className={style.title}>{t('when_receive')}</h4>
        <p className={style.text}>{t('when_receive_answer')}</p>
      </article>
      <article className={style.questionBlock}>
        <h4 className={style.title}>{t('how_find')}</h4>
        <p className={style.text}>{t('how_find_answer')}</p>
      </article>
      <article className={style.questionBlock}>
        <h4 className={style.title}>{t('how_update')}</h4>
        <div className={style.text}>
          <p>{t('we_update')}</p>
          <p>{t('further_provisions')}</p>
          <ol className={style.list}>
            <li>{t('how_update_first')}</li>
            <li>{t('how_update_second')}</li>
            <li>{t('how_update_third')}</li>
          </ol>
        </div>
      </article>
    </section>
  );
};

export default AffiliateRulesMobile;
