import numeral from 'numeral';

/**
 * Util for convert seconds into format string
 * @param {number} seconds - seconds
 * @param {boolean} full - full time with hours and minutes
 * @param {boolean} leadMinutes - minutes before seconds
 * @returns {string}
 */

const displayTimeLeft = (seconds, full = false, leadMinutes = false) => {
  const hour = 60 * 60;
  const hours = Math.floor(seconds / hour);
  const minutes = Math.floor((seconds % hour) / 60);
  const secs = seconds % 60;

  const formattedMinutes = numeral(minutes).format('00');
  const formattedHours = numeral(hours).format('00');
  const formattedSeconds = numeral(secs).format('00');

  const hoursString = hours > 0 ? `${formattedHours} hour${hours > 1 ? 's ' : ' '}` : '';
  const minutesString = minutes > 0 ? `${formattedMinutes}` : '';

  const fullText = `${hoursString}${minutesString}${
    minutesString ? ` minute${minutes > 1 ? 's' : ''} ` : ''
  }${numeral(secs).format('00')} seconds`;
  const shortTextWithMinutes = `${numeral(minutes).format('00')}:${formattedSeconds}`;
  const shortText = `${hours ? `${formattedHours}:` : ''}${minutesString}${
    minutes > 0 ? ':' : ''
  }${formattedSeconds}`;

  if (full) return fullText;
  if (leadMinutes) return shortTextWithMinutes;
  return shortText;
};

export default displayTimeLeft;
