import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './ForgotPasswordEmail.module.scss';
import useFormikApp from '../../../hooks/useFormikApp';
import { EMAIL, EMAIL_MAX_LENGTH } from '../../../util/validationSchema';

import TextInput from '../../../components/ui/TextInput/TextInput';
import Button from '../../../components/ui/Button/Button';

/**
 * Represents ForgotPasswordEmail
 * @param {function} onSubmit - submit handler
 * @param {function} onInputFocus - onInputFocus handler
 * @param {function} onInputBlur - onInputBlur handler
 * @returns {JSX.Element}
 */
const ForgotPasswordEmail = ({ onSubmit, onInputFocus, onInputBlur }) => {
  const { t } = useTranslation();
  const formik = useFormikApp([EMAIL], onSubmit);

  return (
    <>
      <p className={style.mainText}>{t('submit_email')}</p>
      <form className={style.form} onSubmit={formik.handleSubmit}>
        <TextInput
          id={EMAIL}
          label={t('email')}
          type='email'
          placeholder={t('enter_email')}
          formik={formik}
          width='full'
          maxLength={EMAIL_MAX_LENGTH}
          inputClassName={style.input}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        <Button
          width='full'
          text={t('send_password')}
          submit
          color='primary'
          disabled={!formik.isValid}
        />
      </form>
    </>
  );
};

export default ForgotPasswordEmail;
