import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import style from './InstrumentsItem.module.scss';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import string from '../../../../../../util/string';
import FavoritesButton from '../../../../../../components/ui/FavoritesButton/FavoritesButton';

/**
 * Represents Instruments item
 * @param {string} title - title for instrument
 * @param {string} symbol - short title of instrument
 * @param {string} type - instrument category
 * @param {JSX.Element} Icon - icon for instrument
 * @param {number} price - instrument price
 * @param {number} day - price up or down in % for day
 * @param {number} strength - value for graph
 * @param {number} month - price change in % for month
 * @param {number} halfYear - price change in % for half year
 * @param {number} year - price change in % for year
 * @param {number} oldPrice - previous instrument price
 * @param {boolean} isFavorite - is instrument favorite
 * @param {function} toggleFavorite - toggleFavorite
 * @param {boolean} isLoading - is favorite Loading
 * @returns {JSX.Element}
 */
const InstrumentsItem = ({
  title = '',
  symbol,
  type,
  icon: Icon,
  price = 0,
  day = 0,
  strength = 0,
  month = 0,
  halfYear = 0,
  year = 0,
  oldPrice,
  isFavorite,
  toggleFavorite,
  isLoading,
}) => {
  const { isDesktop } = useWindowSize();
  const pathname = `/markets/${type}/${symbol}`;
  const [firstPair, secondPair] = title.split('/');

  return (
    <Link to={{ pathname }} className={style.item}>
      <div className={style.titleWrapper}>
        <FavoritesButton onClick={toggleFavorite} isFavorite={isFavorite} isLoading={isLoading} />
        <i className={style.instrumentIcon}>{Icon ? <Icon /> : null}</i>
        <p>
          <span>{firstPair}</span>
          {secondPair && <span className={style.secondPair}>/{secondPair}</span>}
        </p>
      </div>

      <div className={style.price}>
        <span
          className={cn(style.priceBackground, {
            [style.priceBackgroundSuccess]: price > oldPrice,
            [style.priceBackgroundDanger]: price < oldPrice,
          })}
        >
          {string.formatNumber(price)}
        </span>
      </div>

      <div
        className={cn({
          [style.colorSuccess]: day > 0,
          [style.colorDanger]: day < 0,
        })}
      >
        {string.formatPercent(day)}
      </div>

      {isDesktop && (
        <div className={style.graph}>
          <div
            style={{ width: `${strength}%` }}
            className={cn(style.percent, {
              [style.backgroundGreen]: strength > 50,
            })}
          />
        </div>
      )}

      <div
        className={cn({
          [style.colorSuccess]: month > 0,
          [style.colorDanger]: month < 0,
        })}
      >
        {string.formatPercent(month)}
      </div>

      <div
        className={cn({
          [style.colorSuccess]: halfYear > 0,
          [style.colorDanger]: halfYear < 0,
        })}
      >
        {string.formatPercent(halfYear)}
      </div>

      <div
        className={cn({
          [style.colorSuccess]: year > 0,
          [style.colorDanger]: year < 0,
        })}
      >
        {string.formatPercent(year)}
      </div>
    </Link>
  );
};

export default InstrumentsItem;
